import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MasterInteractivesDiagnosesShowController extends Controller {
  queryParams = ['exercise'];

  @service diagnosis;

  @tracked exercise;
}
