import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MasterController extends Controller {
  queryParams = ['help_list'];

  @service contextHelper;

  @service entityInspector;

  @service helpList;

  @service session;

  @tracked cookiesNotAccepted = false;
}
