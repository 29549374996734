import EmberObject from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EntitySearchService extends Service {
  // SERVICE
  @service store;

  // PROPERTIES
  @tracked searchResults = [];
  @tracked searchStatus = null;
  @tracked searchVal;
  @tracked isLoading = false;
  @tracked pageNumber = 1;
  @tracked numPages = 0;
  @tracked entities;
  @tracked entity;
  @tracked totalHits = 0;
  @tracked showingResultLow = 1;
  @tracked showingResultHight = 24;
  perPage = 24;

  resetSearch(newSearch = false) {
    this.searchResults = [];
    this.searchStatus = null;
    this.pageNumber = 1;
    this.numPages = 0;
    this.showingResultLow = 1;
    this.showingResultHight = 24;
    if (!newSearch) {
      this.searchVal = null;
    }
  }

  setResultNumbers() {
    if (this.numPages > 1) {
      if (this.pageNumber === this.numPages) {
        const allButLastPageResults = (this.numPages - 1) * 24;
        const lastResultPageResults = this.totalHits - allButLastPageResults;
        this.showingResultHight = this.totalHits;
        this.showingResultLow = this.totalHits - lastResultPageResults;
        return;
      } else if (this.pageNumber > 1) {
        this.showingResultLow = this.pageNumber * 24 - 23;
        this.showingResultHight = this.pageNumber * 24 + 1;
        return;
      } else {
        this.showingResultLow = 1;
        this.showingResultHight = 24;
      }
    } else {
      this.showingResultLow = this.totalHits;
      this.showingResultHight = this.totalHits;
    }
  }

  search(entity, entities, newSearch) {
    if (newSearch) {
      this.resetSearch(newSearch);
    }
    this.entities = entities;
    this.entity = entity;
    this.isLoading = true;
    return this.store
      .query('entity', {
        page: {
          size: this.perPage,
          number: this.pageNumber,
        },
        filter: {
          book_id: entities ? entities.mapBy('id') : entity.book_id,
        },
        search: this.searchVal,
      })
      .then((hits) => {
        let entities = hits.sortBy('rank');
        entities = entities.map((entity) => {
          return EmberObject.create({
            entity,
          });
        });
        if (entities.length === 0) {
          this.searchStatus = 'noHits';
        } else {
          this.searchStatus = 'validSearch';
        }
        this.isLoading = false;
        this.searchResults = entities;
        this.totalHits = hits?.meta?.total;
        this.numPages = hits?.meta?.totalPages;
        this.pageNumber = Number(this.pageNumber);
        this.setResultNumbers();

        // Matomo tracking specific for searching
        if (window._paq) {
          window._paq.push([
            'trackSiteSearch',
            this.searchVal,
            this.entities?.firstObject?.title ?? false,
            this.totalHits,
          ]);
        }
      });
  }
}
