import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import imageSource from 'compton/utils/image-source';
import { resolve } from 'rsvp';

export default class ExerciseViewCommonItemImageComponent extends Component {
  /**
   * Arguments:
   * imageId (string)
   * width (number)
   * height (number)
   */

  @service store;

  @cached get imageProxy() {
    let promise = resolve();

    const imageId = this.args.imageId;

    if (imageId) {
      promise = this.store.findRecord('material', imageId);
    }

    return load(promise, this);
  }

  get image() {
    return this.imageProxy.isResolved ? this.imageProxy.value : null;
  }

  get allowCrop() {
    return !this.image?.metadata?.keep_proportions;
  }

  get imagePadding() {
    return this.allowCrop && !this.args.disablePadding;
  }

  @cached get imageSrc() {
    const image = this.image;
    if (!image) {
      return false;
    }

    let width;
    let height;
    const allowCrop = this.allowCrop;

    if (this.args.isHorizontal || this.args.isGroup) {
      const assignmentId = this.args.assignmentId;
      const selector = `.target-width-${assignmentId}`;
      const box = document.querySelector(selector);
      if (allowCrop) {
        if (image.imageIsPortrait) {
          height = box.offsetHeight * 2;
        } else {
          width = box.offsetWidth * 2;
        }
      } else {
        height = box.offsetHeight * 2;
        width = box.offsetWidth * 2;
      }
    } else {
      if (this.allowCrop) {
        if (image.imageIsPortrait) {
          height = this.args.height;
        } else {
          width = this.args.width;
        }
      } else {
        height = this.args.height;
        width = this.args.width;
      }
    }

    // If width and height is set we will crop the image keeping in mind the keep_proportions
    if (width || height) {
      const focusPoint = image.metadata?.focus_point
        ? {
            px: parseInt(image.metadata.focus_point.x),
            py: parseInt(image.metadata.focus_point.y),
          }
        : null;

      if (focusPoint && !allowCrop) {
        return imageSource(image.imageUrl, {
          crop: true,
          w: width,
          h: height,
          ...focusPoint,
        });
      } else {
        return imageSource(image.imageUrl, {
          embed: !allowCrop,
          w: width,
          h: height,
          ...focusPoint,
        });
      }
    } else {
      return imageSource(image.imageUrl);
    }
  }
}
