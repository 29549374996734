import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-center\" ...attributes>\n  <DcIcon @name=\"countdown\" class=\"nr1\" />\n  <div class=\"text-ui countdown-timer\">{{this.timer}}\n    {{t \"components.countdownTimer.minutesShort\"}}</div>\n</div>", {"contents":"<div class=\"flex flex-center\" ...attributes>\n  <DcIcon @name=\"countdown\" class=\"nr1\" />\n  <div class=\"text-ui countdown-timer\">{{this.timer}}\n    {{t \"components.countdownTimer.minutesShort\"}}</div>\n</div>","moduleName":"babel/components/countdown-timer/index.hbs","parseOptions":{"srcName":"babel/components/countdown-timer/index.hbs"}});
import { later } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';

export default class CountdownTimerComponent extends Component {
  /**
   * Arguments:
   * from (date)
   * minutes (number)
   * onFinish (function)
   */

  constructor() {
    super(...arguments);

    const minutes = Number(this.args.minutes);
    const start = this._getTime(this.args.from);

    this.stop = start + minutes * 60;
    this.secondsLeft = stop - start;

    if (config.environment !== 'test') {
      later(this, this._step, this.interval);
    }
  }

  @tracked secondsLeft = 0;

  @tracked stop = null;

  interval = 100;

  get timer() {
    const minutes = Math.floor(this.secondsLeft / 60);
    const seconds = (this.secondsLeft % 60).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  _getTime(time) {
    const t = time && new Date(time);

    return t instanceof Date && !isNaN(t.valueOf())
      ? Math.floor(t.getTime() / 1000)
      : Math.floor(new Date().getTime() / 1000);
  }

  _step() {
    this.secondsLeft = this.stop - this._getTime();

    if (this.secondsLeft > 0) {
      later(this, this._step, this.interval);
    } else {
      this.args.onFinish?.();
    }
  }
}
