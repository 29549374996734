import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ContentStoryComponent extends Component {
  @service speaker;

  @tracked showStoryModal =
    !this.args.isWorkspaceOpen &&
    !this.speaker?.active &&
    !document.querySelector('body.entity-inspector--open');

  get isSection() {
    return this.args.activeSection?.type === 'sections';
  }

  @action
  handleOnCloseModal() {
    this.showStoryModal = false;
  }
}
