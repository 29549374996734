import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

const STATE = {
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
};

export default class Resource {
  @tracked state = STATE.pending;
  @tracked value = null;
  @tracked error = null;

  get isPending() {
    return this.state === STATE.pending;
  }

  get isResolved() {
    return this.state === STATE.resolved;
  }

  get isRejected() {
    return this.state === STATE.rejected;
  }

  get isFulfilled() {
    return this.isResolved || this.isRejected;
  }

  constructor(callback, initialParams = {}) {
    assert(
      `The first argument passed to Resource must be a function. Got ${typeof callback}`,
      typeof callback === 'function'
    );

    this.callback = callback;
    this.load(initialParams);
  }

  @action
  @waitFor
  async load(params = {}) {
    try {
      this.value = await this.callback(params);
      this.error = null;
      this.state = STATE.resolved;
    } catch (error) {
      this.error = error;
      this.value = null;
      this.state = STATE.rejected;
    }
  }
}
