import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderCodeComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-code';

  theme = 'dracula';

  get extraClasses() {
    return [[this.baseClass, this.theme].join('--')];
  }

  get lineNumbers() {
    return this.args.part?.settings?.line_numbers;
  }

  get mode() {
    return this.args.part?.settings?.language;
  }

  get showCaption() {
    return this.args.part?.settings?.show_caption;
  }

  get viewportMargin() {
    return Infinity;
  }

  get value() {
    return this.args.part?.value;
  }
}
