import Component from '@glimmer/component';

export default class CollectionExerciseIndexComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * activeExercise (object)
   * answers (object)
   * showStatus (bool)
   * showNumAnswers (bool)
   * useOriginalAnswer (bool)
   * onExerciseChange (function)
   * routed (bool)
   */

  get showTitles() {
    const collection = this.args.collection;

    if (collection?.isTeacherCollection) return true;

    const type = collection?.type;

    if (type === 'sections') {
      return true;
    }

    if (collection?.isDiagnosis || collection?.isFlow) {
      return false;
    }

    return true;
  }

  get indexTypeClass() {
    if (!this.showTitles) {
      return 'exercise-index-compact';
    }

    return 'exercise-index-lengthy';
  }
}
