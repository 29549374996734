import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { inject as service } from '@ember/service';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import config from 'babel/config/environment';
import Model from 'babel/models/model';
import imageSource from 'compton/utils/image-source';
import moment from 'moment';
import { all, resolve } from 'rsvp';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);

export default Model.extend({
  // SETTINGS

  hiddenBooks: alias('setting.hiddenBooks'),

  showKey: alias('setting.showKey'),

  showDiagnosis: alias('setting.showDiagnosis'),

  language: alias('setting.language'),

  cover_url: alias('setting.cover_url'),

  sortedMissionIds: alias('setting.metadata.sorted_mission_ids'),

  sortedShortcutIds: alias('setting.metadata.sorted_shortcut_ids'),

  overviewDisplayFirst: alias('setting.metadata.overview_display_first'),

  shortcuts: alias('setting.shortcuts'),

  // SERVICES

  ajax: service(),

  session: service(),

  store: service(),

  name: attr('string'),

  start_date: attr('only-date'),

  end_date: attr('only-date'),

  active: attr('boolean', {
    defaultValue() {
      return true;
    },
  }),

  metadata: attr({
    defaultValue() {
      return {};
    },
  }),

  imageUploaded: alias('metadata.image_uploaded'),

  created: attr('date'),

  updated: attr('date'),

  // INTERNAL

  licensesHaveChanged: false,

  missingLicenseProductsIsReloading: false,

  // RELATIONS

  setting: belongsTo('setting', { async: true, inverse: null }),

  users: hasMany('user', { async: true, inverse: null }),

  teachers: hasMany('user', { async: true, inverse: null }),

  groups: hasMany('group', { async: true, inverse: null }),

  school: belongsTo('school', { async: true, inverse: null }),

  products: hasMany('product', { async: true, inverse: null }),

  missions: hasMany('mission', { async: true, inverse: 'course' }),

  missingLicenseProducts: hasMany('missing-license-product', {
    async: true,
    inverse: null,
  }),

  is_new: attr('boolean', {
    defaultValue() {
      return false;
    },
  }),

  // PROPERTIES

  hasLicenseWarnings: computed(
    'session.user.isTeacher',
    'school.metadata.external_licenses',
    'usersMissingLicenses.length',
    function () {
      if (!this.session.user?.isTeacher) return false;
      if (this.school?.get('metadata.external_licenses')) return false;
      return this.usersMissingLicenses?.length > 0;
    }
  ),

  numUsers: computed('users.{[],isFulfilled}', function () {
    return (this.hasMany('users').ids() || []).length;
  }),

  allBooks: computed('products.@each.books', function () {
    const promise = resolve(this.products)
      .then((products) => all(products.map((product) => product.userBooks)))
      .then((productBooks) => productBooks.flat());

    return ArrayPromiseProxy.create({ promise });
  }),

  hasExternalBooks: computed('allBooks.@each.type', function () {
    return this.allBooks?.filterBy('type', 'books_external')?.length > 0;
  }),

  hasInternalBooks: computed('allBooks.@each.type', function () {
    return this.allBooks?.filterBy('type', 'books')?.length > 0;
  }),

  usersMissingLicenses: computed(
    'missingLicenseProducts.{[],isFulfilled}',
    'session.user.isTeacher',
    'licensesHaveChanged',
    function () {
      let promise;

      if (!this.get('session.user.isTeacher')) {
        promise = resolve(A());
      } else if (
        this.licensesHaveChanged &&
        this.hasMany('missingLicenseProducts').value() !== null
      ) {
        promise = this.reloadMissingLicenseProducts();
      } else {
        promise = this.missingLicenseProducts;
      }

      return ArrayPromiseProxy.create({
        promise: promise.then((arr) => arr.sortBy('product.title')),
      });
    }
  ),

  students: computed(
    'users.{[],isFulfilled}',
    'teachers.{[],isFulfilled}',
    function () {
      const users = this.users || A();
      const teachers = this.teachers || A();

      return users.filter((user) => !teachers.includes(user));
    }
  ),

  books: computed(
    'hiddenBooks.[]',
    'products.[]',
    'session.user.isTeacher',
    'setting.books.[]',
    function () {
      const promise = all([
        resolve(this.setting)
          .then((setting) => resolve(setting?.books))
          .catch(() => []),
        this.hiddenBooks,
        this.products,
      ])
        .then(([settingBooks, hiddenBooks, products]) => {
          // use old books relation if present,
          // it is removed when shown books are altered
          if (settingBooks?.length > 0) return settingBooks;

          return all(
            products
              .sortBy('title')
              .map((product) =>
                resolve(product.userBooks).then((productBooks) =>
                  productBooks.filter((book) => !hiddenBooks.includes(book))
                )
              )
          );
        })
        .then((books) =>
          books
            .flat()
            .filter((book) => {
              const isBookTeacherOnly = book?.body?.teacher_only || false;
              return this.session.user.isTeacher || !isBookTeacherOnly;
            })
            .uniqBy('id')
        )
        .catch(() => []);

      return ArrayPromiseProxy.create({ promise });
    }
  ),

  firstProductImage: computed('products.@each.image', function () {
    const products = this.products;
    const productsWithImage = products.filter(
      (product) =>
        product.get('metadata.background_type') !== 'color' &&
        product.get('image')
    );

    if (productsWithImage.length > 0) {
      return productsWithImage.get('firstObject.image');
    }

    return null;
  }),

  coverImage: computed(
    'cover_url',
    'firstProductImage.imageURL',
    'imageUploaded',
    function () {
      if (this.get('imageUploaded')) {
        return (
          `${config.imgProcessingEndpoint}?url=` +
          encodeURIComponent(this.cover_url) +
          '&h=480&w=640&embed=true'
        );
      } else {
        return this.firstProductImage
          ? imageSource(this.get('firstProductImage.imageURL'), {
              preset: 'small',
            })
          : null;
      }
    }
  ),

  coverBackground: computed(
    'cover_url',
    'firstProductImage.imageURL',
    'imageUploaded',
    function () {
      if (this.get('imageUploaded')) {
        return (
          `${config.imgProcessingEndpoint}?url=` +
          encodeURIComponent(this.cover_url) +
          '&w=1920&h=500&embed=true'
        );
      } else {
        return this.firstProductImage
          ? imageSource(this.get('firstProductImage.imageURL'), {
              preset: 'landscape',
            })
          : null;
      }
    }
  ),

  isComing: computed('start_date', 'end_date', function () {
    return moment().isBefore(moment(this.start_date).format('YYYY-MM-DD'));
  }),

  // METHODS

  newMission(fields) {
    const store = this.store;
    const user = this.session.user;

    return store.createRecord('mission', {
      user,
      ...fields,
      course: this,
    });
  },

  async sortMissions(missions) {
    this.sortedMissionIds = missions.mapBy('id');
    const setting = await resolve(this.setting);
    if (!setting) return;
    return setting.save();
  },

  async sortShortcuts(shortcuts) {
    this.sortedShortcutIds = shortcuts.mapBy('id');
    const setting = await resolve(this.setting);
    if (!setting) return;
    return setting.save();
  },

  reloadMissingLicenseProducts() {
    if (this.missingLicenseProductsIsReloading) {
      return this.missingLicenseProducts;
    }

    this.set('missingLicenseProductsIsReloading', true);

    return this.missingLicenseProducts
      .reload()
      .then((missingLicenseProducts) => {
        this.set('missingLicenseProductsIsReloading', false);
        this.set('licensesHaveChanged', false);

        return missingLicenseProducts;
      });
  },
});
