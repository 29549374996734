import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class AdditionCustomizerComponent extends Component {
  /**
   * Arguments:
   * teacherAssignments (array)
   * wordlists (array)
   * includedTeacherAssignments (array)
   * includedWordlists (array)
   * onIncludeTeacherAssignment (function)
   * onExcludeTeacherAssignment (function)
   * onIncludeWordlist (function)
   * onExcludeWordlist (function)
   */

  @cached get additionsGroupedByUserProxy() {
    const promise = all([
      this.args.teacherAssignments,
      this.args.wordlists,
    ]).then(async (types) => {
      const result = [];

      await all(
        types.map(async (arr, idx) => {
          const key = idx === 0 ? 'teacherAssignments' : 'wordlists';

          await all(
            (arr || []).map(async (item) => {
              const user = await resolve(item.user);

              if (!result.findBy('user', user)) {
                result.addObject({
                  user,
                  teacherAssignments: [],
                  wordlists: [],
                });
              }

              result.findBy('user', user)[key].addObject(item);
            })
          );
        })
      );

      return result.sortBy('user.showname');
    });

    return load(promise, this);
  }

  get additionsGroupedByUser() {
    return this.additionsGroupedByUserProxy.isResolved
      ? this.additionsGroupedByUserProxy.value
      : [];
  }
}
