import Component from '@ember/component';

export default Component.extend({
  contents: null,

  showIndex: true,

  // SETUP

  classNames: ['area-view-list', 'mb2'],
});
