import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterArchivesFoldersMaterialsController extends Route {
  @service pageviews;

  @service store;

  model({ material_id }) {
    return this.store.findRecord('material', material_id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const { model: folder, book } = this.modelFor('master.archives.folders');

    this.pageviews.logPageView(folder, book, folder.get('parent'), {
      material: model.get('id'),
    });
  }
}
