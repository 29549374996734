import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class WorkspaceAssignmentsIndexComponent extends Component {
  /**
   * Arguments:
   * onClose (function)
   */

  @service assignmentEvents;

  @service intl;

  @service router;

  @service session;

  @service missionMode;

  @service teacherAssignmentDispatcher;

  @controller('master.contents') contentsController;

  @tracked teacherAssignmentChooseModalIsOpen = false;

  get content() {
    return this.contentsController?.content;
  }

  get collections() {
    return this.contentsController?.categorizedCollections;
  }

  get currentTeacherAssignments() {
    return this.contentsController?.currentTeacherAssignments;
  }

  get noAssignmentsMessage() {
    const isTeacher = this.session?.user?.isTeacher;

    return htmlSafe(
      this.intl.t('components.workspace.assignments.index.noAssignments', {
        isTeacher,
      })
    );
  }

  @action async handleTeacherAssignmentChooseModalChoose(teacherAssignments) {
    await this.teacherAssignmentDispatcher.linkMany(
      teacherAssignments,
      this.content
    );

    const exercises = [];

    teacherAssignments.forEach((teacherAssignment) => {
      exercises.push(teacherAssignment.createAsEntity(this.content));
      this.missionMode.addIncludedTeacherAssignmentIfNeeded(teacherAssignment);
    });

    await this.assignmentEvents.handleTeacherAssignmentsAddedToCollection(
      exercises
    );
  }

  @action openExercise(exercise) {
    return this.router.transitionTo(
      'master.contents.assignments.show',
      exercise.id
    );
  }
}
