import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import config from 'babel/config/environment';
import { computed as overridable } from 'compton/utils/computed-override';

// FIXME
function strip(html) {
  if (!html) {
    return '';
  }
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export default Component.extend({
  classNames: ['word-explanation-box'],

  languages: service(),

  session: service(),

  intl: service(),

  // EXTERNAL PROPERTIES

  word: null,

  wordlist: null,

  wordClass: null,

  wordConstruction: null,

  explanation: null,

  examples: null,

  translation: null,

  canSave: false,

  parentComponentLanguage: null,

  exerciseLanguage: null,

  // COMPUTED PROPERTIES

  htmlSafeWordClass: computed('wordClass', function () {
    return htmlSafe(this.wordClass);
  }),

  htmlSafeWordConstruction: computed('wordConstruction', function () {
    return htmlSafe(this.wordConstruction);
  }),

  wordExplanationLanguage: computed(
    'parentComponentLanguage',
    'exerciseLanguage',
    'wordlist.language',
    'languages.contentLanguage',
    function () {
      const lang =
        this.parentComponentLanguage ||
        this.exerciseLanguage ||
        this.get('wordlist.language') ||
        this.get('languages.contentLanguage');
      return lang;
    }
  ),

  wordLanguage: computed(
    'parentComponentLanguage',
    'exerciseLanguage',
    'wordlist.language',
    'languages.contentLanguage',
    function () {
      const lang =
        this.parentComponentLanguage ||
        this.exerciseLanguage ||
        this.get('wordlist.language') ||
        this.get('languages.contentLanguage');
      return lang;
    }
  ),

  strippedExplanation: computed('explanation', function () {
    return strip(this.explanation);
  }),

  tab: overridable('examples', 'explanation', 'translation', function () {
    let selected_tab = 'examples';

    if (this.strippedExplanation) {
      selected_tab = 'explanation';
    } else if (this.translation) {
      selected_tab = 'translation';
    }

    return selected_tab;
  }),

  onClick(e) {
    e.preventDefault();
  },

  readTextArray: computed(
    'examples',
    'explanation',
    'session.user.language',
    'tab',
    'translation',
    'word',
    'wordClass',
    'wordExplanationLanguage',
    'wordLanguage',
    function () {
      const arr = [];

      arr.push({
        text: this.word + '. ',
        // language: this.get('languages.contentLanguage')
        language: this.wordLanguage,
      });

      if (this.explanation && this.tab === 'explanation') {
        arr.push({
          text: this.intl.t('components.wordExplanationBox.explanation') + ':',
          language: this.get('session.user.language'),
        });

        arr.push({
          text: this.explanation + '. ',
          // language: this.get('languages.contentLanguage')
          language: this.wordExplanationLanguage,
        });
      }

      if (this.examples && this.tab === 'examples') {
        arr.push({
          text: this.intl.t('components.wordExplanationBox.examples') + ':',
          language: this.get('session.user.language'),
        });

        arr.push({
          text: this.examples + ':',
          // language: this.get('languages.contentLanguage')
          language: this.wordExplanationLanguage,
        });
      }

      if (this.translation && this.tab === 'translation') {
        arr.push({
          text: this.intl.t('components.wordExplanationBox.translation') + ':',
          language: this.get('session.user.language'),
        });

        arr.push({
          text: this.translation + '.',
          language: config.defaultLanguage,
        });
      }

      return arr;
    }
  ),
});
