import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.exerciseResults as |exerciseResult|}}\n  <div class=\"flex hover-highlight\">\n    <a\n      href=\"#\"\n      class=\"flex flex-auto flex-center p1\"\n      {{on \"click\" (fn this.handleClick exerciseResult)}}\n    >\n      <AnswerStatusIcon\n        @status={{exerciseResult.answer.computedStatus}}\n        class=\"nr1\"\n      />\n\n      <div class=\"text-ui{{if (not-eq exerciseResult.answer.computedStatus \"not-started\") \" text-color-default\"}}\">\n        {{exerciseResult.exercise.sort}}. {{exerciseResult.exercise.title}}\n      </div>\n    </a>\n  </div>\n{{/each}}\n", {"contents":"{{#each this.exerciseResults as |exerciseResult|}}\n  <div class=\"flex hover-highlight\">\n    <a\n      href=\"#\"\n      class=\"flex flex-auto flex-center p1\"\n      {{on \"click\" (fn this.handleClick exerciseResult)}}\n    >\n      <AnswerStatusIcon\n        @status={{exerciseResult.answer.computedStatus}}\n        class=\"nr1\"\n      />\n\n      <div class=\"text-ui{{if (not-eq exerciseResult.answer.computedStatus \"not-started\") \" text-color-default\"}}\">\n        {{exerciseResult.exercise.sort}}. {{exerciseResult.exercise.title}}\n      </div>\n    </a>\n  </div>\n{{/each}}\n","moduleName":"babel/components/course-audit/diagnosis/exercise/index.hbs","parseOptions":{"srcName":"babel/components/course-audit/diagnosis/exercise/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class CourseAuditDiagnosisExerciseComponent extends Component {
  /**
   * Arguments:
   * exercises (array)
   * results (array)
   * openAuditMode (function)
   */

  get exerciseResults() {
    const exercises = this.args.exercises;
    const answers = this.args.results?.answers;

    const exerciseResults = [];

    exercises.forEach((exercise) => {
      const answer = answers?.find((x) => x.node_id === exercise.id);
      exerciseResults.push({ exercise, answer });
    });

    return exerciseResults;
  }

  @action handleClick(exerciseResult, evt) {
    evt.preventDefault();

    this.args.openAuditMode?.({
      collection: exerciseResult.exercise.parent,
      exercise: exerciseResult.exercise,
      answers: this.args.results.answers,
      student: null,
      mode: 'students',
    });
  }
}
