import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<XPopup\n  @for={{@for}}\n  @minWidth={{240}}\n  @alignment=\"start\"\n  @switchToDialogOnSmallBreakpoint={{false}}\n  @onClose={{@onClose}}\n>\n  <div class=\"px1 pb1\">\n    <div class=\"header-base-switch-course-popup-header position-sticky text-bold py1\">\n      {{t \"components.header.course.switchCoursePopup.switchCourse\"}}\n    </div>\n\n    <SelectList as |list|>\n\n      {{#each this.sortedCourses as |course|}}\n        <list.Item\n          @selected={{eq @activeCourse.id course.id}}\n          @onSelect={{fn @onSelect course.id}}\n          @disabled={{and (gte course.allBooks.length 1) (not course.hasInternalBooks)}}\n        >\n          {{course.name}}\n        </list.Item>\n      {{/each}}\n\n    </SelectList>\n  </div>\n</XPopup>", {"contents":"<XPopup\n  @for={{@for}}\n  @minWidth={{240}}\n  @alignment=\"start\"\n  @switchToDialogOnSmallBreakpoint={{false}}\n  @onClose={{@onClose}}\n>\n  <div class=\"px1 pb1\">\n    <div class=\"header-base-switch-course-popup-header position-sticky text-bold py1\">\n      {{t \"components.header.course.switchCoursePopup.switchCourse\"}}\n    </div>\n\n    <SelectList as |list|>\n\n      {{#each this.sortedCourses as |course|}}\n        <list.Item\n          @selected={{eq @activeCourse.id course.id}}\n          @onSelect={{fn @onSelect course.id}}\n          @disabled={{and (gte course.allBooks.length 1) (not course.hasInternalBooks)}}\n        >\n          {{course.name}}\n        </list.Item>\n      {{/each}}\n\n    </SelectList>\n  </div>\n</XPopup>","moduleName":"babel/components/header/course/switch-course-popup/index.hbs","parseOptions":{"srcName":"babel/components/header/course/switch-course-popup/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class CourseHeaderSwitchCoursePopup extends Component {
  @cached get coursesProxy() {
    let promise = resolve();

    if (this.args.courses?.length > 0) {
      promise = all(this.args.courses.map((course) => resolve(course)));
    }

    return load(promise, this);
  }

  get courses() {
    return this.coursesProxy.isResolved ? this.coursesProxy.value : null;
  }

  get sortedCourses() {
    return (this.courses || []).sort((courseA, courseB) =>
      courseA?.name?.localeCompare(courseB?.name)
    );
  }
}
