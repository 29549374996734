import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';
import combineStyle from 'compton/utils/combine-style';

export default class TbRenderEmbedComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-embed';

  asyncType = 'material';

  get asyncId() {
    return this.args.part?.embed_id;
  }

  get material() {
    return this.asyncData;
  }

  get extraClasses() {
    return [[this.baseClass, this.material?.type].join('--')];
  }

  get aspectRatio() {
    const height = this.material?.metadata?.height;
    const width = this.material?.metadata?.width;

    if (isNaN(height) || isNaN(width)) return 56.25;

    return (height / width) * 100;
  }

  get wrapperStyle() {
    return combineStyle({ paddingTop: this.aspectRatio + '%' });
  }
}
