import digilarPollyRuleSet from 'babel/utils/speaker/text-processing-rule-sets/polly/digilar-polly';
import {
  PollyTextToSpeechProvider,
  SpeakerTextProcessorRuleSet,
} from 'speaker';

import DigilarPollyProxy from './DigilarPollyProxy';

class DigilarPollyProviderFactory {
  static newPollyPlugin({
    pollyProxyParams,
    speechSpeed = 1.0,
    generalDigilarTextProcessingRuleSets,
  }) {
    const { ajax, apiBaseUrl } = pollyProxyParams;

    const pollyProvider = new PollyTextToSpeechProvider({
      // A proxy object needs to be injected to handle all communication with AWS Polly.
      // We use our own backend proxy instead of the "default" PollyFrontendProxy.
      pollyProxy: new DigilarPollyProxy({ ajax, apiBaseUrl }),

      cacheEnabled: false,
      // Injecting a simple "cache function" that can be used either as a cache or for mocking during dev and test.
      // The audioSourceKey received as param is the textToSpeechCacheKey for each audio source config.

      fetchSpeechCache: (audioSourceKey, language, voice, speechSpeed) => {
        return null;
      },

      // URL protocol can be forced by setting for example 'https' here. Not forcing will use protocol relative URLs.
      forcedUrlProtocol: null,

      // The speech speed here is not the playback speed. Instead it is the speed of the speech generated by Acapela. 1.0 = default. Lower value = slower, higher value = faster.
      speechSpeed,

      // Adjust volume to match other providers and/or recorded speech volume.
      // Set to a falsy value to use default volume
      // For all valid values, see https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#prosody-tag
      // Examples of valid values: '+6dB', '-6dB', 'soft', 'x-loud'
      volume: 'x-loud',

      customTextProcessingRuleSets: (language) => [
        ...generalDigilarTextProcessingRuleSets(language),

        // Digilär+Polly specific rules
        new SpeakerTextProcessorRuleSet(digilarPollyRuleSet(language)),
      ],
    });

    return pollyProvider;
  }
}

export default DigilarPollyProviderFactory;
