import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class WorkspaceWordlistsWordsEditComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * onClose (function)
   */

  @service router;

  @service wordDispatcher;

  get word() {
    return this.args.model;
  }

  @action openIndex() {
    return this.router.transitionTo('master.contents.wordlists.show');
  }

  @action handleWordDetailSave() {
    if (this.word.validate()) {
      return this.wordDispatcher
        .save(this.word)
        .then(() => this.router.transitionTo('master.contents.wordlists.show'));
    }
  }

  @action handleWordDetailDelete() {
    return this.wordDispatcher
      .delete(this.word)
      .then(() => this.router.transitionTo('master.contents.wordlists.show'));
  }
}
