import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedRoute extends Route {
  @service intl;

  @service notice;

  @service pusher;

  @service session;

  @service speakerSession;

  async beforeModel(transition) {
    const authenticated = await this.session.requireAuthentication(
      transition,
      'login'
    );

    if (authenticated) {
      this.sessionAuthenticated();
      await this.session.load();
      this.intl.setLocale(this.session.user?.metadata?.language ?? 'sv');
      this.pusher.subscribe();
      this.notice.addListener();
      this.notice.getMessages();
    }
  }

  sessionAuthenticated() {
    if (this.session?.data?.nextURL) {
      const expires = this.session?.data?.nextURLExpires;

      if (expires) {
        const date = new Date();
        const time = date.getTime();

        if (time > expires) {
          this.session.data.nextURL = null;
        }
      }
    }

    // Reset ILT access
    // Access will be checked again the next time user opens an ILT enabled content
    // Note that this same reset is done in when the school changes
    this.speakerSession.reset();
  }
}
