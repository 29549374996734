import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CollectionOptionsPreviewComponent extends Component {
  @service assignmentEvents;

  @cached get answerProxy() {
    const promise = resolve(
      this.assignmentEvents.getOrCreateAnswerForExercise(this.args.exercise, [])
    );

    return load(promise, this);
  }

  get answer() {
    return this.answerProxy.isResolved ? this.answerProxy.value : null;
  }

  get index() {
    return this.args.index + 1;
  }
}
