import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { getScrollParent, scrollToTop } from 'compton/utils/dom';
import { resolve } from 'rsvp';

export default class EntityInspectorGuidanceViewer extends Component {
  /**
   * Arguments:
   * entity (object)
   */

  @tracked _selectedArticle = null;

  @tracked scrollParent = null;

  get selectedArticle() {
    if (
      this._selectedArticle &&
      this.articles?.includes(this._selectedArticle)
    ) {
      return this._selectedArticle;
    }
    if (this.articles?.length === 1) return this.articles.firstObject;
    return null;
  }

  get title() {
    return this.args.entity?.title;
  }

  @cached get articlesProxy() {
    return load(resolve(this.args.entity?.guidances), this);
  }

  get articles() {
    return this.articlesProxy.isResolved ? this.articlesProxy.value : null;
  }

  get isLoading() {
    return this.articlesProxy.isPending ? true : false;
  }

  @action handleIndexSelect(article) {
    this._selectedArticle = article;
    scrollToTop(this.scrollParent);
  }

  @action setScrollParent(element) {
    this.scrollParent = getScrollParent(element);
  }
}
