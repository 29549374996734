import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"diagnosis-result-item{{if this.showBorder \" diagnosis-result-item--border\"}}\" ...attributes>\n  <UiButton\n    @appearance=\"link\"\n    class=\"diagnosis-result-item-link flex flex-center\"\n    @onClick={{this.openExercise}}\n  >\n    <AnswerStatusIcon @status={{@answer.computedStatus}} class=\"mr1\" />\n    {{this.titleToShow}}\n  </UiButton>\n</li>\n", {"contents":"<li class=\"diagnosis-result-item{{if this.showBorder \" diagnosis-result-item--border\"}}\" ...attributes>\n  <UiButton\n    @appearance=\"link\"\n    class=\"diagnosis-result-item-link flex flex-center\"\n    @onClick={{this.openExercise}}\n  >\n    <AnswerStatusIcon @status={{@answer.computedStatus}} class=\"mr1\" />\n    {{this.titleToShow}}\n  </UiButton>\n</li>\n","moduleName":"babel/components/diagnosis-result-item/index.hbs","parseOptions":{"srcName":"babel/components/diagnosis-result-item/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { typeOf } from '@ember/utils';
import Component from '@glimmer/component';

export default class DiagnosisResultItemComponent extends Component {
  /**
   * Arguments:
   * showBorder (bool)
   * index (number)
   * route (string)
   * answer (object)
   * exercise (object)
   */

  @service router;

  get showBorder() {
    return this.args.showBorder === false ? false : true;
  }

  get title() {
    return this.args.exercise?.title;
  }

  get titleToShow() {
    const index =
      typeOf(this.args.index) === 'number' ? `${this.args.index + 1}. ` : '';
    const title = this.title ? htmlSafe(this.title) : '';

    return `${index}${title}`;
  }

  @action openExercise() {
    this.router.transitionTo(this.args.route, {
      queryParams: {
        exercise: this.args.exercise.id,
      },
    });
  }
}
