import Controller from '@ember/controller';

export default class MasterInteractivesIndexController extends Controller {
  queryParams = [
    { missionId: 'mission' },
    { customContentId: 'custom_content' },
    { closeUrl: 'close_url' },
    { returnUrl: 'return_url' },
  ];
}
