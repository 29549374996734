import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class WorkspaceComponent extends Component {
  /**
   * Arguments:
   * route (string)
   * model (object)
   */

  // SETUP

  @service router;

  @service pageviews;

  @tracked isPrint = false;

  constructor() {
    super(...arguments);
    window.addEventListener('beforeprint', this.beforePrint.bind(this));
    window.addEventListener('afterprint', this.afterPrint.bind(this));
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('beforeprint', this.beforePrint);
    window.removeEventListener('afterprint', this.afterPrint);
  }

  // ACTIONS

  @action async handleDrawerClose() {
    await this.router.transitionTo('master.contents');
    const { currentRoute } = this.router;
    const sectionId = currentRoute.queryParams.section;
    const { bookEntity, model, children } = currentRoute.attributes;
    const section = children.findBy('id', sectionId);
    this.pageviews.logPageView(section, bookEntity, model);
  }

  beforePrint() {
    this.isPrint = true;
  }

  afterPrint() {
    this.isPrint = false;
  }
}
