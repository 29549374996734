import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.content}}\n  <XAlert\n    @dismissable={{true}}\n    @onDismiss={{this.onDismiss}}\n    class=\"mb2\"\n    data-test-book-announcement\n  >\n    <ContentPresenter @isContainer={{false}} class=\"ml0\">\n      <TbRenderInline @value={{this.content}} />\n    </ContentPresenter>\n  </XAlert>\n{{/if}}", {"contents":"{{#if this.content}}\n  <XAlert\n    @dismissable={{true}}\n    @onDismiss={{this.onDismiss}}\n    class=\"mb2\"\n    data-test-book-announcement\n  >\n    <ContentPresenter @isContainer={{false}} class=\"ml0\">\n      <TbRenderInline @value={{this.content}} />\n    </ContentPresenter>\n  </XAlert>\n{{/if}}","moduleName":"babel/components/book-announcement/index.hbs","parseOptions":{"srcName":"babel/components/book-announcement/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import sanitizeHtml from 'ember-cli-sanitize-html';
import { reject, resolve } from 'rsvp';

export default class BookAnnouncementComponent extends Component {
  /**
   * Arguments:
   * id (string)
   * announcement (string)
   * updated (number)
   */

  constructor() {
    super(...arguments);
    this.setClosedAnnouncements();
  }

  @service session;

  @tracked closedAnnouncements;

  get announcementClosed() {
    const id = this.args.id;
    const updated = this.args.updated;

    return this.closedAnnouncements.find(
      (x) => x?.id === id && x?.time > updated
    )
      ? true
      : false;
  }

  get content() {
    if (this.announcementClosed) return false;

    const announcement = this.args.announcement ?? '';
    const textContent = sanitizeHtml(announcement, { allowedTags: [] });

    if (textContent.replace(/\s/g, '').length > 0) return announcement;

    return false;
  }

  setClosedAnnouncements() {
    this.closedAnnouncements =
      this.session.user?.metadata?.closed_announcements || [];
  }

  @action onDismiss() {
    const id = this.args.id;
    const time = new Date().getTime();
    const user = this.session.user;
    const metadata = user?.metadata || {};

    if (!metadata.closed_announcements) metadata.closed_announcements = [];

    metadata.closed_announcements.removeObjects(
      metadata.closed_announcements.filterBy('id', id)
    );

    metadata.closed_announcements.addObject({ id, time });

    this.setClosedAnnouncements();

    return resolve(user.roles)
      .then((roles) => {
        if (roles?.findBy('client_id', 'ARCH')) {
          // We don't persist that the announcement was closed
          // for Arch users. See DINO-714.
          return resolve();
        }

        return reject();
      })
      .catch(() => {
        // Either roles could not be resolved or the user does
        // not have Arch access. We should persist that the
        // user closed the announcement.
        return user.save();
      });
  }
}
