import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class AreaViewGridItemComponent extends Component {
  @service router;

  get query() {
    return {
      activeExerciseId: null,
      activeSectionId: null,
      missionId: null,
      customContentId: null,
      closeUrl: this.router.currentUrl,
      returnUrl: null,
    };
  }
}
