import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { resolve } from 'rsvp';

export default class TbRenderComponent extends Component {
  /**
   * Arguments:
   * row (object)
   */

  @service ilt;

  @tracked element;

  get sortedColumns() {
    return this.args.row?.columns?.sortBy('index');
  }

  get rowClassNames() {
    const row = this.args.row;
    const classes = ['tb-render-row'];

    if (row?.column_width === 'widerLeft') {
      classes.push('tb-render-row--wider-left');
    } else if (row?.column_width === 'widerRight') {
      classes.push('tb-render-row--wider-right');
    }

    if (row?.encourage_listening_enabled) {
      classes.push('tb-render-row--listening-enabled');
    }

    return classes.join(' ');
  }

  get encourageListeningEnabled() {
    return this.args.row.encourage_listening_enabled && this.element;
  }

  get onlyIlt() {
    return this.args.row.only_ilt;
  }

  get showRowPromise() {
    if (!this.onlyIlt) return resolve(true);

    return this.ilt
      .getToken()
      .then(() => this.ilt.hasBegreppa)
      .catch(() => false);
  }

  @action
  setElement(element) {
    this.element = element;
  }
}
