import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { all, resolve } from 'rsvp';

export default class MasterCoursesShowAuditMissionsIndexRoute extends Route {
  @service store;

  queryParams = {
    page: {
      refreshModel: true,
    },
  };

  model({ page }) {
    const course = this.modelFor('master.courses.show');

    return this.store.query('mission', {
      filter: {
        scope: 'visible',
        course_id: course?.id,
        'custom_contents <>': '{}',
      },
      page: {
        number: page,
        size: 12,
      },
    });
  }

  afterModel(model) {
    return all([
      all(model.getEach('customContents')).then((parts) =>
        all(parts.map((part) => part.getEach('entity')).flat()).catch(resolve)
      ),
      ...model.getEach('receivers'),
    ]);
  }
}
