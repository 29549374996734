import Component from '@ember/component';
import { get, observer } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  router: service(),
  // SETUP

  classNames: ['preview-page-wrapper'],

  // EXTERNAL PROPERTIES

  backgroundColor: null,

  image: null,

  cropImage: false,

  didInsertElement() {
    this._super(...arguments);

    this.element.style.backgroundColor = this.get('backgroundColor');

    if (get(this, 'image')) {
      this.setBackgroundImage();
    }
  },

  imageChanged: observer('image', function () {
    if (get(this, 'image')) {
      this.setBackgroundImage();
    }
  }),

  setBackgroundImage() {
    let url = `url(${get(this, 'image')})`;
    this.element.style.setProperty('--url', url);
    this.element.style.setProperty(
      '--gradient',
      `linear-gradient(to top, var(--overlay-gradient-start) 20%, var(--overlay-gradient-end) 100%)`
    );
  },

  actions: {
    login() {
      window.location.replace('/' + this.get('model.id'));
    },
  },
});
