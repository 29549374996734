// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import Store from 'ember-data/store';

export default class CustomStore extends Store {
  createRecord() {
    const model = super.createRecord(...arguments);
    const attrs = model?.constructor?.attributes;

    if (attrs) {
      attrs.forEach((attr) => {
        const key = attr?.name;

        if (key && model[key]) {
          // self assign model key to trigger som internal ember-data stuff
          // that solves the defaultValue bugs in DINO-2210
          // eslint-disable-next-line no-self-assign
          model[key] = model[key];
        }
      });
    }

    return model;
  }
}
