import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';

const EDIT = 'edit';
const UNLINK = 'unlink';
const DELETE = 'delete';

export default class NoteBrowserComponent extends Component {
  /**
   * Arguments:
   * note
   * mode
   * range
   * unlinkable
   * showRouteName
   * onPerform
   */

  @service intl;

  get mode() {
    return this.args.mode ?? 'list';
  }

  get performMenuButtonItems() {
    const t = (value) => {
      return this.intl.t(
        [
          'components',
          'noteBrowser',
          'performMenuButtonItems',
          camelize(value),
        ].join('.')
      );
    };

    const items = [];

    items.addObject({ value: EDIT, label: t(EDIT) });

    if (this.args.unlinkable) {
      items.addObject({ value: UNLINK, label: t(UNLINK), variant: 'error' });
    }

    items.addObject({ value: DELETE, label: t(DELETE), variant: 'error' });

    return items;
  }
}
