import Component from '@glimmer/component';
import config from 'babel/config/environment';
import appendQueryParams from 'babel/utils/append-query-params';

export default class BookBrowserItemComponent extends Component {
  get isChecked() {
    const selectedBookIds = this.args.selectedBooks?.mapBy('id');
    return selectedBookIds?.includes(this.args.book.id);
  }

  set isChecked(selected) {
    if (selected) {
      this.args.onSelect?.(this.args.book);
    } else {
      this.args.onDeselect?.(this.args.book);
    }
  }

  get imageURL() {
    return (
      this.args.book?.get('coverImage.imageURL') ||
      this.args.book?.get('image.imageURL')
    );
  }

  get linkTarget() {
    return this.isExternal ? '_blank' : '_self';
  }

  get linkIcon() {
    return this.isExternal ? 'external' : null;
  }

  get isExternal() {
    return (
      this.args.external &&
      !this.args.book?.body?.external_url?.includes(config.domain)
    );
  }

  get href() {
    const target = this.args.external
      ? this.args.book?.body?.external_url
      : `${config.juniorUrl}/books/${this.args.book.id}`;
    const statisticsIsbn = this.args.product?.id;

    return appendQueryParams(target, {
      statisticsIsbn,
    });
  }
}
