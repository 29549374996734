import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"text-gap-select {{this.classNames}}\"\n  {{did-insert this.setElement}}\n  ...attributes\n>\n  <XSelect\n    @disabled={{@disabled}}\n    @value={{this.gapValue}}\n    @items={{this.selectAlternatives}}\n    @placeholder={{this.gapObj.placeholder}}\n    @onChange={{this.onChange}}\n    @onEnter={{@onEnter}}\n  />\n</div>\n", {"contents":"<div\n  class=\"text-gap-select {{this.classNames}}\"\n  {{did-insert this.setElement}}\n  ...attributes\n>\n  <XSelect\n    @disabled={{@disabled}}\n    @value={{this.gapValue}}\n    @items={{this.selectAlternatives}}\n    @placeholder={{this.gapObj.placeholder}}\n    @onChange={{this.onChange}}\n    @onEnter={{@onEnter}}\n  />\n</div>\n","moduleName":"babel/components/exercise-view/assignment/text-gap/select/gap/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/text-gap/select/gap/index.hbs"}});
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import ItemStateComponent from 'babel/components/exercise-view/common/item-state/index';
import shuffle from 'compton/utils/array-shuffle';

export default class ExerciseViewAssignmentTextGapSelectGapComponent extends ItemStateComponent {
  /**
   * Arguments:
   * onChange (function)
   * onEnter (function)
   * node (object)
   * disabled (bool)
   * assignment (object)
   * input (object)
   * showKey (bool)
   * incomplete (bool)
   * showValidation (bool)
   */

  constructor() {
    super(...arguments);

    this.gapValue = this.input?.findBy('uuid', this.uuid)?.value;
  }

  @tracked gapValue;

  get uuid() {
    return this.args.node?.attributes?.findBy('key', 'data-id')?.value;
  }

  @cached
  get gapObj() {
    const gaps = this.assignment?.content?.objects;

    if (gaps && this.assignment?.settings?.random) {
      gaps.forEach((gap) => {
        shuffle(gap.alternatives);
      });
    }

    return gaps?.findBy('uuid', this.uuid);
  }

  get assignment() {
    return this.args.assignment;
  }

  get input() {
    return this.args.input;
  }

  @cached
  get showKey() {
    const showKey = this.args.showKey;

    if (showKey) {
      this.gapValue =
        this.assignment?.content?.objects
          ?.findBy('uuid', this.uuid)
          ?.alternatives?.filterBy('correct')?.firstObject?.value || '';
    } else {
      this.gapValue = this.input?.findBy('uuid', this.uuid)?.value;
    }

    return showKey;
  }

  get showValidation() {
    return this.args.showValidation;
  }

  get selectAlternatives() {
    return this.gapObj?.alternatives?.mapBy('value');
  }

  @cached
  get suffix() {
    let suffix;

    const keys =
      this.assignment?.content?.objects
        ?.findBy('uuid', this.uuid)
        ?.alternatives?.filterBy('correct')
        ?.map((alternative) => alternative.value.trim()) || [];

    const value = this.input?.findBy('uuid', this.uuid)?.value?.trim() || '';

    if (this.showKey) {
      suffix = 'key';
    } else if (this.showValidation) {
      if (!value) {
        suffix = 'error';
      } else {
        suffix = keys.includes(value) ? 'success' : 'error';
      }
    }

    return suffix;
  }

  @action
  setElement(elem) {
    this.element = elem;
  }

  @action
  onChange(value) {
    if (!this.showKey) {
      if (this.input?.findBy('uuid', this.uuid)) {
        this.args.input.findBy('uuid', this.uuid).value = value;
      }
    }

    this.args.onChange?.(value);
  }
}
