import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (or @id (generate-id)) as |id|}}\n\n  <Header::Base::BasicLink\n    id={{id}}\n    class=\"header-base-link\"\n    href={{@href}}\n    aria-label={{@tooltip}}\n    data-wide={{this.wide}}\n    data-active={{this.active}}\n    data-with-icon={{this.withIcon}}\n    data-with-text={{this.withText}}\n    ...attributes\n  >\n\n    {{yield\n      (hash\n        Icon=(component \"header/base/link/icon\")\n        Label=(component \"header/base/link/label\")\n      )\n    }}\n\n  </Header::Base::BasicLink>\n\n  {{#if @tooltip}}\n    <XTooltip @for={{id}}>\n      {{@tooltip}}\n    </XTooltip>\n  {{/if}}\n\n{{/let}}", {"contents":"{{#let (or @id (generate-id)) as |id|}}\n\n  <Header::Base::BasicLink\n    id={{id}}\n    class=\"header-base-link\"\n    href={{@href}}\n    aria-label={{@tooltip}}\n    data-wide={{this.wide}}\n    data-active={{this.active}}\n    data-with-icon={{this.withIcon}}\n    data-with-text={{this.withText}}\n    ...attributes\n  >\n\n    {{yield\n      (hash\n        Icon=(component \"header/base/link/icon\")\n        Label=(component \"header/base/link/label\")\n      )\n    }}\n\n  </Header::Base::BasicLink>\n\n  {{#if @tooltip}}\n    <XTooltip @for={{id}}>\n      {{@tooltip}}\n    </XTooltip>\n  {{/if}}\n\n{{/let}}","moduleName":"babel/components/header/base/link/index.hbs","parseOptions":{"srcName":"babel/components/header/base/link/index.hbs"}});
import Component from '@glimmer/component';

export default class HeaderLink extends Component {
  get wide() {
    return this.args.wide ?? false;
  }

  get startCircle() {
    return this.args.startCircle ?? false;
  }

  get withIcon() {
    return !!this.args.icon;
  }

  get withText() {
    return !!this.args.text;
  }
}
