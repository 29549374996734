import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

const GLOSSARIES = 'glossaries';
const CONCEPTS = 'concepts';

export default Component.extend({
  // SETUP

  router: service(),

  intl: service(),

  classNames: ['wordlist-detail'],

  // PARAMS

  wordlist: null,

  indexRouteName: null,

  showRouteName: null,

  onSave() {},

  onDelete() {},

  // PROPERTIES

  typeRadioButtonGroupItems: computed('intl.locale', function() {
    const t = (value, property) => {
      return this.get('intl').t(
        [
          'components',
          'wordlistDetail',
          'typeRadioButtonGroupItems',
          value,
          property
        ].join('.')
      );
    };

    return [GLOSSARIES, CONCEPTS].map((value) => {
      return {
        value,
        label: t(value, 'label'),
        description: t(value, 'description')
      };
    });
  }),

  actions: {
    handleCancel() {
      const params = this.wordlist.isNew
        ? [this.indexRouteName]
        : [this.showRouteName, this.wordlist.id];

        return this.router.transitionTo(...params);
    }
  }
});
