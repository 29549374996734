import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { all, resolve } from 'rsvp';

export default class MasterMissionsRoute extends Route {
  @service contextHelper;
  @service router;
  @service store;
  @service missionMode;

  async model({ mission_id }, transition) {
    const mission = await this.store.findRecord('mission', mission_id);

    const [customContents] = await all([
      mission.sortedCustomContents,
      mission.course,
      mission.receivers,
    ]);

    const items = await all(
      customContents.map((customContent) =>
        resolve(customContent.entity).then((entity) => ({
          customContent,
          entity,
        }))
      )
    );

    return { mission, items, closeUrl: transition?.to?.queryParams?.close_url };
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.missionMode.deactivate();
    }
  }

  setupController(controller, { mission, closeUrl }) {
    super.setupController(...arguments);
    this.missionMode.activate({ mission, closeUrl });
  }
}
