import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InvalidateRoute extends Route {
  @service session;

  beforeModel() {
    // session.restore() is moved from initializer to here to be able to catch exception
    // in incognito mode, as it tries to access localStorage
    this.session
      .restore()
      .then(() => this.session.invalidateSession())
      .then(() => {
        window.parent.postMessage('session_invalidated', '*');
      })
      .catch(() => {
        window.parent.postMessage('session_invalidation_error', '*');
      });
  }
}
