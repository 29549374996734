import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class UserSelectComponent extends Component {
  /**
   * Arguments:
   * users (array)
   * selected (array)
   */

  @tracked modalSelected;

  @tracked modalOpen = false;

  get sortedSelected() {
    return (this.args.selected || []).sortBy('showname');
  }

  @action openModal() {
    this.modalOpen = true;
    this.modalSelected = (this.args.selected || []).slice(0);
  }

  @action closeModal() {
    this.modalOpen = false;
  }

  @action userChanged(user, checked) {
    if (checked) {
      this.modalSelected.addObject(user);
    } else {
      this.modalSelected.removeObject(user);
    }
  }

  @action remove(item) {
    if (this.args.selected) {
      this.args.selected.removeObject(item);
    }
  }

  @action saveUsers() {
    if (this.args.selected) {
      this.args.selected.clear().addObjects(this.modalSelected.slice(0));
    }

    this.closeModal();
  }
}
