import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { storageFor } from 'ember-local-storage';

const ASSIGNMENTS_ROUTE = 'master.contents.assignments';
const WORDLISTS_ROUTE = 'master.contents.wordlists';
const NOTES_ROUTE = 'master.contents.notes';

export default class ContentTopbarComponent extends Component {
  /**
   * Arguments:
   * content (object|array)
   * collections (array)
   * model (object)
   * template (string)
   * hideWorkflow (bool)
   * hidePlaySettings (bool)
   * hideReadSettings (bool)
   */

  @storageFor('read-setting') readSettings;

  @service router;

  @service session;

  get routeOnSubHeadingClick() {
    return !(
      this.args.template === 'chapter' &&
      this.readSettings.get('mode') === 'scroll'
    );
  }

  get hasNextSection() {
    return !(
      this.args.sections?.indexOf(this.args.activeSection) >=
      this.args.sections?.length - 1
    );
  }

  get hasPreviousSection() {
    return this.args.sections?.indexOf(this.args.activeSection) > 0;
  }

  get shouldDisplayAssignments() {
    const user = this.session.user;

    if (user?.isTeacher) {
      return true;
    }

    return this.args.collections?.length > 0;
  }

  get assignmentsButtonIsActive() {
    return this.router.currentRouteName?.startsWith(ASSIGNMENTS_ROUTE);
  }

  get wordlistsButtonIsActive() {
    return this.router.currentRouteName?.startsWith(WORDLISTS_ROUTE);
  }

  get notesButtonIsActive() {
    return this.router.currentRouteName?.startsWith(NOTES_ROUTE);
  }

  @action
  openAssignments() {
    return this.router.transitionTo(`${ASSIGNMENTS_ROUTE}.index`);
  }

  @action
  openWordlists() {
    return this.router.transitionTo(`${WORDLISTS_ROUTE}.index`);
  }

  @action
  openNotes() {
    return this.router.transitionTo(`${NOTES_ROUTE}.index`);
  }
}
