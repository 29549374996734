export default async function collectionClose(model, contextHelper, router) {
  const { interactive } = model;

  if (contextHelper.inMission && interactive?.children?.length <= 1) {
    return router.transitionTo(
      'master.missions',
      contextHelper.activeMission.id
    );
  }

  if (interactive?.children.length > 1) {
    return router.transitionTo('master.interactives');
  }

  if (contextHelper.hasCloseUrl) {
    return router.transitionTo(contextHelper.closeUrl);
  }

  let parent;

  try {
    parent = await interactive.parent;
  } catch (err) {
    // do nothing
  }

  if (parent) {
    return router.transitionTo('nodes', parent.id);
  }

  return router.transitionTo('master.index');
}
