import Model from '@ember-data/model';
import { attr, belongsTo } from '@ember-data/model';

export default class CanvasStateModel extends Model {
  @attr('date') created;

  @attr('date') updated;

  @attr('string') json;

  @belongsTo('material', { async: true, inverse: null }) material;

  @belongsTo('editor', { async: true, inverse: null }) editor;

  @belongsTo('user', { async: true, inverse: null }) user;

  setJSON(data) {
    this.json = JSON.stringify(data);
  }
}
