import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class ContentCoverComponent extends Component {
  /**
   * Arguments:
   * content (object)
   * sections (array)
   * collections (array)
   * wordlists (array)
   * categories (array)
   * activeCategory (string)
   * template (string)
   * transitionToSection (function)
   */

  @service session;

  @service speaker;

  constructor() {
    super(...arguments);
    this.speaker.resetSpeakerActive();
  }

  get categoryOptions() {
    return this.args.categories?.map((category) => ({
      value: category.id,
      label: category.name,
    }));
  }

  get image() {
    return (
      this.args.content?.image?.imageURL || this.args.content?.featuredImage
    );
  }

  get backgroundColor() {
    return this.args.content?.image?.backgroundColor;
  }

  get imageIsPortrait() {
    return this.args.content?.image?.imageIsPortrait;
  }

  get styleString() {
    let url;

    if (this.imageIsPortrait) {
      url = `url(${this.image}&preset=cover-large)`;
    } else {
      url = `url(${this.image})`;
    }

    return `--content-cover-bg: ${this.backgroundColor}; --url: ${url};`;
  }

  @action handleCategoryChange(id) {
    const bookId = this.args.content?.belongsTo('book')?.id();
    const user = this.session.user;

    if (user) {
      return user.savePreferredTextVersion(bookId, id);
    }
  }
}
