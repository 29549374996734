import { get as proxyGet } from '@ember/object';
import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import sanitizeHtml from 'ember-cli-sanitize-html';

/* eslint-disable ember/no-get */
export default class TeacherAssignmentDispatcherService extends Service {
  @service session;
  @service store;
  @service intl;
  @service snackbar;
  @service confirmManager;

  @tracked highlightedTeacherAssignments = [];

  async save(teacherAssignment, publish = false) {
    try {
      teacherAssignment?.exercise?.assignments?.forEach((assignment) => {
        const pattern = assignment.content?.pattern ?? '';
        const textContent = sanitizeHtml(pattern, { allowedTags: [] });

        if (textContent.replace(/\s/g, '').length > 0) {
          assignment.settings?.set('pattern', true);
        } else {
          assignment.settings?.set('pattern', false);
        }
      });

      teacherAssignment.public = publish;
      const result = await teacherAssignment.save();
      this.#success('save');
      return result;
    } catch (error) {
      this.#error('save');
      throw error;
    }
  }

  delete(teacherAssignment) {
    return this.#confirm('delete', { danger: true }).then(async () => {
      try {
        const result = await teacherAssignment.destroyRecord();
        this.#success('delete');
        return result;
      } catch (error) {
        this.#error('delete');
        throw error;
      }
    });
  }

  async deleteMany(teacherAssignments) {
    return this.#confirm(
      'deleteMany',
      {
        danger: true,
      },
      teacherAssignments.length
    ).then(async () => {
      try {
        const result = await Promise.all(
          teacherAssignments.map((teacherAssignment) =>
            teacherAssignment.destroyRecord()
          )
        );
        this.#success('deleteMany', { count: teacherAssignments.length });
        return result;
      } catch (error) {
        this.#error('delete');
        throw error;
      }
    });
  }

  async link(teacherAssignment, content) {
    try {
      const result = await this.#link(teacherAssignment, content);
      this.#success('link');
      return result;
    } catch (error) {
      this.#error('link');
      throw error;
    }
  }

  async linkMany(teacherAssignments, content) {
    try {
      const result = await Promise.all(
        teacherAssignments.map((teacherAssignment) =>
          this.#link(teacherAssignment, content)
        )
      );
      this.#success('linkMany', { count: teacherAssignments.length });
      return result;
    } catch (error) {
      this.#error('linkMany');
      throw error;
    }
  }

  unlink(teacherAssignment, content) {
    return this.#confirm('unlink', { danger: true }).then(async () => {
      try {
        teacherAssignment.rollback();
        teacherAssignment.get('contents').removeObject(content);
        const result = await teacherAssignment.save();
        this.#success('unlink');
        return result;
      } catch (error) {
        this.#error('unlink');
        throw error;
      }
    });
  }

  highlight(teacherAssignment) {
    this.highlightMany([teacherAssignment]);
  }

  highlightMany(teacherAssignments) {
    this.highlightedTeacherAssignments.addObjects(teacherAssignments);

    later(() => {
      this.highlightedTeacherAssignments.removeObjects(teacherAssignments);
    }, 3000);
  }

  #confirm(method, options, count = 1) {
    return this.confirmManager.perform(
      this.intl.t(`services.teacherAssignmentDispatcher.${method}.confirm`, {
        count,
      }),
      options
    );
  }

  #success(method, { count = 1 } = {}) {
    this.snackbar.enqueue(
      this.intl.t(
        `services.teacherAssignmentDispatcher.${method}.snackbar.success`,
        {
          count,
        }
      ),
      {
        variant: 'success',
        autoDismiss: true,
      }
    );
  }

  #error(method, count = 1) {
    this.snackbar.enqueue(
      this.intl.t(
        `services.teacherAssignmentDispatcher.${method}.snackbar.error`
      ),
      {
        variant: 'error',
      },
      { count }
    );
  }

  async #link(teacherAssignment, content) {
    const book = await proxyGet(content, 'book');

    teacherAssignment.rollback();
    proxyGet(teacherAssignment, 'books').addObject(book);
    proxyGet(teacherAssignment, 'contents').addObject(content);

    return teacherAssignment.save();
  }
}
