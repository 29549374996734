import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['word-browser'],

  // PARAMS

  words: null,

  onPerform() {}
});
