import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class UserSelectListComponent extends Component {
  /**
   * Arguments:
   * users (array)
   * userChanged (function)
   * modalSelected (array)
   */

  @tracked searchVal;

  @tracked search;

  @tracked searchArr;

  get sortedUsers() {
    return (this.args.users || []).sortBy('lastname', 'firstname', 'username');
  }

  get filteredUsers() {
    return this.sortedUsers.filter((user) => {
      if (!this.search) return true;

      if (
        (user.lastname &&
          user.lastname.toLowerCase().indexOf(this.search) !== -1) ||
        (user.firstname &&
          user.firstname.toLowerCase().indexOf(this.search) !== -1) ||
        user.username.toLowerCase().indexOf(this.search) !== -1 ||
        user.showname.toLowerCase().indexOf(this.search) !== -1 ||
        (typeof user.listname === 'string' &&
          user.listname.toLowerCase().indexOf(this.search) !== -1)
      ) {
        return true;
      }

      return false;
    });
  }

  get numMatchingUsers() {
    return this.filteredUsers?.length ?? 0;
  }

  @action doSearch() {
    let search = this.searchVal;

    if (search && search.length > 0) {
      search = search.trim().toLowerCase();

      this.search = search;
      this.searchArr = search.split(' ');
    } else {
      this.search = null;
      this.searchArr = null;
    }
  }
}
