import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PreviewIcon extends Component {
  @tracked isMoving = false;
  @tracked previewIconRef = null;
  @tracked initialX = 0;
  @tracked initialY = 0;

  @service session;

  @action
  togglePreview() {
    if (this.isMoving) return (this.isMoving = false);

    const urlSearchParams = new URLSearchParams(window.location.search);

    if (this.session.inPreviewMode) {
      this.session.inPreviewMode = false;
      urlSearchParams.delete('preview');
    } else {
      urlSearchParams.set('preview', 'true');
    }

    window.location.href = `//${window.location.host}${
      window.location.pathname
    }?${urlSearchParams.toString()}`;
  }

  @action
  mouseDown(event) {
    event.preventDefault();

    this.initialX = event.clientX;
    this.initialY = event.clientY;

    window.addEventListener('mousemove', this.mouseMove);
  }

  @action
  mouseUp(event) {
    event.preventDefault();

    this.previewIconRef.style.removeProperty('transform');
    this.previewIconRef.style.removeProperty('left');
    this.previewIconRef.style.removeProperty('top');

    window.removeEventListener('mousemove', this.mouseMove);
  }

  @action
  mouseMove(event) {
    const currentX = event.clientX;
    const currentY = event.clientY;

    const right = currentX - this.initialX;
    const left = this.initialX - currentX;
    const up = this.initialY - currentY;
    const down = currentY - this.initialY;

    if (right > 20 || left > 20 || up > 20 || down > 20) {
      this.isMoving = true;
    }

    if (this.isMoving) {
      this.previewIconRef.style.transform = 'translate(-50%, -50%)';
      this.previewIconRef.style.left = currentX + 'px';
      this.previewIconRef.style.top = currentY + 'px';

      let moved = false;
      const positionY = up > 50 ? 'top' : down > 50 ? 'bottom' : '';
      const positionX = right > 50 ? 'right' : left > 50 ? 'left' : '';

      if (right > 150 || left > 150 || up > 150 || down > 150) {
        this._move(positionX, positionY);
        moved = true;
      }

      if (moved) {
        window.removeEventListener('mousemove', this.mouseMove);
        this.previewIconRef.style.removeProperty('transform');
        this.previewIconRef.style.removeProperty('left');
        this.previewIconRef.style.removeProperty('top');
        this.isMoving = false;
      }
    }
  }

  _move(x, y) {
    if (x === 'left') {
      this.previewIconRef.classList.remove('positioned-right');
      this.previewIconRef.classList.add('positioned-left');
    } else if (x === 'right') {
      this.previewIconRef.classList.remove('positioned-left');
      this.previewIconRef.classList.add('positioned-right');
    }

    if (y === 'top') {
      this.previewIconRef.classList.remove('positioned-bottom');
      this.previewIconRef.classList.add('positioned-top');
    } else if (y === 'bottom') {
      this.previewIconRef.classList.remove('positioned-top');
      this.previewIconRef.classList.add('positioned-bottom');
    }
  }
}
