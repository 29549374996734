import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @assignedAlternative}}\n  <ExerciseView::Assignment::PlaceImages::Item\n    @marker={{@marker}}\n    @assignment={{@assignment}}\n    @showKey={{@showKey}}\n    @assignmentAnswer={{@assignmentAnswer}}\n    @assignedAlternative={{@assignedAlternative}}\n    @showValidation={{@showValidation}}\n    @alternatives={{@alternatives}}\n    @onRemove={{@onRemove}}\n    data-test-attached-alternative\n    >\n    <XImage\n      class=\"place-images-image\"\n      @source={{this.imageUrl}}\n      @width={{this.image.imageWidth}}\n      @height={{this.image.imageHeight}}\n    />\n  </ExerciseView::Assignment::PlaceImages::Item>\n{{/if}}", {"contents":"{{#if @assignedAlternative}}\n  <ExerciseView::Assignment::PlaceImages::Item\n    @marker={{@marker}}\n    @assignment={{@assignment}}\n    @showKey={{@showKey}}\n    @assignmentAnswer={{@assignmentAnswer}}\n    @assignedAlternative={{@assignedAlternative}}\n    @showValidation={{@showValidation}}\n    @alternatives={{@alternatives}}\n    @onRemove={{@onRemove}}\n    data-test-attached-alternative\n    >\n    <XImage\n      class=\"place-images-image\"\n      @source={{this.imageUrl}}\n      @width={{this.image.imageWidth}}\n      @height={{this.image.imageHeight}}\n    />\n  </ExerciseView::Assignment::PlaceImages::Item>\n{{/if}}","moduleName":"babel/components/exercise-view/assignment/place-images/marker-image/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/place-images/marker-image/index.hbs"}});
import Component from '@glimmer/component';

export default class ExerciseViewAssignmentPlaceImagesMarkerImageComponent extends Component {
  get image() {
    return this.args.alternativeImages?.findBy('id', this.args.imageId);
  }

  get imageUrl() {
    return `${this.image.imageUrl}&preserve-transparency=true`;
  }
}
