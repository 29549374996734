import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import getSettingClassesForPart from 'babel/utils/get-setting-classes-for-part';
import loadDataFromStore from 'babel/utils/load-data-from-store';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class TbRenderBlockComponent extends Component {
  @service store;

  get classes() {
    const classes = getSettingClassesForPart(this.baseClass, this.args.part);

    if (this.extraClasses) {
      classes.push(...this.extraClasses);
    }

    return classes.join(' ');
  }

  @cached get asyncProxy() {
    const promise = this.asyncId
      ? loadDataFromStore(this.asyncType, this.asyncId, this.store)
      : resolve();

    return load(promise, this);
  }

  get asyncData() {
    return this.asyncProxy.isResolved ? this.asyncProxy.value : null;
  }
}
