import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class UserSelectTableComponent extends Component {
  /**
   * Arguments:
   * users (array)
   * searchArr (array)
   * userChanged (function)
   * modalSelected (array)
   */

  @action toggle(id) {
    const checkbox = document
      .getElementById(id)
      ?.querySelector('input[type="checkbox"]');

    if (checkbox) {
      const event = new Event('change');

      checkbox.checked = !checkbox.checked;
      checkbox.dispatchEvent(event);
    }
  }
}
