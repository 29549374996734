import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  connectionStatus: service(),

  intl: service(),

  model: null,

  message: computed('connectionStatus', 'model', function () {
    const online = this.get('connectionStatus.online');

    if (!online) {
      return this.get('intl').t(
        'components.modelRejection.noInternetConnection'
      );
    } else {
      return this.get('intl').t('components.modelRejection.other');
    }
  }),
});
