import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  // SETUP

  router: service(),

  classNames: ['wordlist-view'],

  // PARAMS

  wordlist: null,

  hasBreadcrumb: true,

  showTitle: true,

  onWordPerform() {},

  // PROPERTIES

  quiz: false,

  sortedWords: computed(
    'wordlist.{isTransformed,words.@each.sort,word}',
    function() {
      const wordlist = this.get('wordlist');

      const words = wordlist.get('words').toArray();

      if (wordlist.get('isTransformed')) {
        return words.sortBy('sort');
      }
      return words.sortBy('word');
    }
  ),

  // ACTIONS

  actions: {
    handleWordlistQuizModalClose() {
      this.router.transitionTo({ queryParams: { quiz: null } });
    },

    openQuiz() {
      this.router.transitionTo({ queryParams: { quiz: true } });
    }
  }
});
