import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { scrollIntoViewIfNeeded } from 'compton/utils/dom';

export default Component.extend({
  // SETUP

  noteDispatcher: service(),

  classNames: ['note-browser-grid-item'],

  classNameBindings: [
    'isHighlighted:note-browser-grid-item--highlighted',
    'isDisabled:note-browser-grid-item--disabled',
  ],

  // PARAMS

  note: null,

  showRouteName: null,

  performMenuButtonItems: null,

  onPerform: null,

  // PROPERTIES

  isHighlighted: computed(
    'noteDispatcher.highlightedNotes.[]',
    'note',
    function () {
      return this.noteDispatcher.highlightedNotes.includes(this.note);
    }
  ),

  isDisabled: readOnly('note.isDeleted'),

  // HOOKS

  didRender() {
    this._super(...arguments);
    if (this.isHighlighted) {
      scrollIntoViewIfNeeded(this.element);
    }
  },
});
