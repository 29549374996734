const DigilarDomElements = {
  CONTENT_TITLE: {
    elementName: 'h1',
    className: 'content-title',
  },
  IMAGE_GALLERY: {
    elementName: 'div',
    className: 'tb-render-gallery',
  },
  IMAGES: {
    elementName: 'figure',
    className: 'tb-render-image',
  },
  IMAGE_INNER: {
    elementName: 'img',
    className: 'tb-render-image',
  },
  IMAGE_CAPTIONS: {
    elementName: 'div',
    className: 'tb-render-image-caption',
  },
  INTERACTIVE_IMAGE_CAPTIONS: {
    elementName: 'div',
    className: 'tb-render-interactive-image-caption',
  },
  AUDIO_CAPTIONS: {
    elementName: 'div',
    className: 'tb-render-audio-caption',
  },
  COLLECTION_EXERCISE_INDEX: {
    elementName: 'div',
    className: 'collection-exercise-index',
  },
  VIDEO_CAPTIONS: {
    elementName: 'div',
    className: 'tb-render-video-caption',
  },
  IMAGE_SOURCES: {
    elementName: 'div',
    className: 'tb-render-image-source',
  },
  TIPS: {
    elementName: 'div',
    className: 'tb-render-group--recommendation',
  },
  SECTION_HEADERS: {
    elementName: 'div',
    className: 'section-number',
  },
  HEADERS_LEVEL_2: {
    elementName: 'h2',
    className: 'tb-render-heading',
  },
  HEADERS_LEVEL_3: {
    elementName: 'h3',
    className: 'tb-render-heading',
  },
  LIST: {
    elementName: 'ol',
    className: 'tb-render-list',
  },
  LIST_ITEMS: {
    elementName: 'li',
    className: 'tb-render-list-item',
  },
  POPOVER_DIALOGS: {
    elementName: 'div',
    className: 'x-popover-dialog',
  },
  SECTION_HEADING: {
    elementName: 'h1',
    className: '',
  },
  MODAL_DIALOG_TITLE: {
    elementName: 'div',
    className: 'x-dialog-header',
  },
  STORY_SECTION_TITLE: {
    elementName: 'h2',
    classNames: 'story-section-title',
  },
  STORY_SECTION_CONTENT: {
    elementName: 'div',
    className: 'story-section-content-text-block',
  },
  STORY_IMAGE_CONTAINER: {
    elementName: 'figure',
    className: 'story-section-image-container',
  },
  STORY_IMAGE: {
    elementName: 'div',
    className: 'story-section-image',
  },
  VIDEO: {
    elementName: 'div',
    className: 'tb-render-video',
  },
  AUDIO: {
    elementName: 'div',
    className: 'tb-render-audio',
  },
  MATHLIVE: {
    elementName: 'span',
    className: 'mathlive',
  },
  WORKSPACE: {
    elementName: 'div',
    className: 'toolbar-sticky',
  },
  EXERCISE_VIEW: {
    elementName: 'div',
    className: 'collection-view-body',
  },
  DATA_TABLE: {
    elementName: 'div',
    className: 'tb-render-table',
  },
  DATA_TABLE_CELLS: {
    elementName: 'td',
    className: 'tb-render-table-column',
  },
  DATA_TABLE_HEADER_CELLS: {
    elementName: 'th',
    className: 'tb-render-table-column',
  },
  INTERACTIVE_IMAGES: {
    elementName: 'figure',
    className: 'tb-render-interactive-image',
  },
  CANVAS: {
    elementName: 'div',
    className: 'tb-render-canvas',
  },
  CANVAS_CAPTION: {
    elementName: 'div',
    className: 'tb-render-canvas-caption',
  },
  VR_IMAGE: {
    elementName: 'figure',
    className: 'tb-render-vr-image',
  },
  VR_IMAGE_CAPTION: {
    elementName: 'div',
    className: 'tb-render-vr-image-caption',
  },
  WORKFLOW_INDEX: {
    elementName: 'div',
    className: 'workflow-index',
  },
  TOOLBAR_WIDGETS: {
    elementName: 'div',
    className: 'toolbar-widgets',
  },
  EXERCISE_MAIN_ASSIGNMENT: {
    elementName: 'div',
    className: 'exercise-main-assignment',
  },
  ENTITY_THUMBNAILS: {
    elementName: 'div',
    className: 'entity-thumbnail',
  },
  CODE_BLOCK: {
    elementName: 'div',
    className: 'tb-render-code',
  },
  CODE_BLOCK_CAPTION: {
    elementName: 'div',
    className: 'tb-render-code-caption',
  },
  ASSIGNMENT_LINK: {
    elementName: 'div',
    className: 'tb-render-assignment-link',
  },
};

export default DigilarDomElements;
