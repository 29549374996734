import Component from '@glimmer/component';
import imageSource from 'compton/utils/image-source';

export default class AreaViewCoverCardComponent extends Component {
  /**
   * Arguments:
   * entity (object)
   * index (integer)
   */

  get image() {
    return this.args.entity?.image;
  }

  get imageSource() {
    const url = this.image?.imageUrl || this.args.entity?.featuredImage;

    const settings = {
      width: 132,
      height: 132,
      embed: true,
    };

    if (this.image?.metadata?.focus_point) {
      settings.x = this.image.metadata.focus_point.x;
      settings.y = this.image.metadata.focus_point.y;
    }

    return imageSource(url, settings);
  }

  get backgroundColor() {
    return this.image?.backgroundColor;
  }

  get imageClass() {
    const classes = ['cover-card-image'];

    if (!this.image) {
      classes.push('cover-card-image--fallback');
    }

    return classes.join(' ');
  }
}
