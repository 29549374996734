import Service from '@ember/service';
import { inject as service } from '@ember/service';
import {
  getValueFromObject,
  removeObject,
  storeInObject,
} from 'babel/utils/session-storage';
import { resolve } from 'rsvp';

const SESSION_STORAGE_KEY = 'product_context_dir';

export default class ProductContextService extends Service {
  @service session;
  @service contextHelper;

  getAvailableProducts(book) {
    return this._products(book);
  }

  async setActiveProduct(product) {
    product = await resolve(product);

    if (product) {
      const books = await resolve(product.userBooks);

      if (books) {
        books.forEach((book) => {
          this._storeActiveProductForBook(book.id, product.id);
        });
      }
    }

    this.contextHelper.setActive('activeProduct', product);
  }

  removeAllContext() {
    this.contextHelper.setActive('activeProduct', null);
    removeObject(SESSION_STORAGE_KEY);
  }

  // Private

  _getStoredProductForBook(book) {
    const userProductIds = (this.session.get('user.licenses') || []).mapBy(
      'product.id'
    );

    const activeProductId = this._getActiveProductForBook(book?.get('id'));

    if (activeProductId && userProductIds?.includes(activeProductId)) {
      return book.get('products').findBy('id', activeProductId);
    }
  }

  _products(book) {
    if (!book) {
      return [];
    }

    const userProductIds = this.session
      .get('user.licenses')
      .map((license) => license.belongsTo('product').id());

    const storedProduct = this._getStoredProductForBook(book);

    if (storedProduct) {
      return [storedProduct];
    }

    const entityProductIds = (book.get('products') || []).mapBy('id');

    const validProductIds = entityProductIds.filter(
      (id) => userProductIds.indexOf(id) !== -1
    );

    if (validProductIds.length === 1) {
      const index = entityProductIds.indexOf(validProductIds[0]);
      return [book.get('products').objectAt(index)];
    } else {
      return book.get('products').filter((product) => {
        return validProductIds.includes(product.get('id'));
      });
    }
  }

  _getActiveProductForBook(bookId) {
    return getValueFromObject(SESSION_STORAGE_KEY, bookId);
  }

  _storeActiveProductForBook(bookId, productId) {
    storeInObject(SESSION_STORAGE_KEY, bookId, productId);
  }
}
