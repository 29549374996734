import { action } from '@ember/object';
import { cancel, later, next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import MicRecorder from 'mic-recorder-to-mp3';

export default class RecordAudioService extends Service {
  @tracked recorder;
  @tracked audio = null;
  @tracked uuid = null;
  @tracked recordingsArray = [];

  @action async startRecording() {
    this.recorder = await this.handleStartRecord();
    this.recorder.start();
  }

  @action async stopRecording() {
    this.audio = await this.recorder.stop();
    const index = this.recordingsArray.findIndex((object) => {
      return object.uuid === this.uuid;
    });

    if (index != -1) {
      this.recordingsArray[index].audio = this.audio;
    } else {
      this.recordingsArray.push({ audio: this.audio, uuid: this.uuid });
    }
    return;
  }

  returnRecording(searchId) {
    const result = this.recordingsArray.find(({ uuid }) => uuid === searchId);
    return this.recordingsArray.length > 1 ? result.audio : this.audio;
  }

  handleStartRecord() {
    return new Promise((resolve) => {
      const recorder = new MicRecorder({
        bitRate: 128,
      });

      const start = () => {
        recorder.start().catch((e) => {
          console.error(e);
        });
      };

      const stop = () => {
        return new Promise((resolve) => {
          recorder
            .stop()
            .getMp3()
            .then(([buffer, audioBlob]) => {
              const file = new File(buffer, 'music.mp3', {
                type: audioBlob.type,
                lastModified: Date.now(),
              });
              const audioUrl = new Audio(URL.createObjectURL(file));

              resolve({ audioBlob, audioUrl });
            })
            .catch((e) => {
              console.error(e);
            });
        });
      };

      resolve({ start, stop });
    });
  }
}
