import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * Arguments:
 * activeProduct
 * currentCourse
 * disabled
 * displayArrowIcon
 * entity
 * exercise
 * items
 * shape
 * variant
 * opacity (int)
 * onChange
 * onClose
 * onOpen
 */

export default class ShareMetaMenu extends Component {
  @tracked menuIsOpen = false;

  @tracked menuMouseDragMode = false;

  // ACTIONS

  @action
  handleButtonLongPress(evt) {
    this._openMenu(true, evt);
  }

  @action handleButtonClick(evt) {
    this._openMenu(false, evt);
  }

  @action handleMenuClose(evt) {
    this._closeMenu(evt);
  }

  // PRIVATE

  _change() {
    this.args.onChange(...arguments);
  }

  _openMenu(menuMouseDragMode, evt) {
    this.menuMouseDragMode = true;
    this.menuIsOpen = true;

    this.args.onOpen(evt);
  }

  _closeMenu(evt) {
    this.menuMouseDragMode = false;
    this.menuIsOpen = false;

    this.args.onClose(evt);
  }
}
