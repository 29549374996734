import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class TbRenderWrapperComponent extends Component {
  /**
   * Arguments:
   * editorId (string)
   * model (object)
   */

  @cached get editorProxy() {
    let promise = resolve();

    if (this.args.model && this.args.editorId) {
      promise = resolve(this.args.model.editors).then((editors) =>
        editors?.findBy('id', this.args.editorId)
      );
    }

    return load(promise, this);
  }

  get editor() {
    return this.editorProxy.isResolved ? this.editorProxy.value : null;
  }
}
