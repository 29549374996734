import Route from '@ember/routing/route';
import { redirectToNokportal } from 'babel/utils/redirect';

export default class CourseSettings extends Route {
  beforeModel(transition) {
    redirectToNokportal(transition);
  }

  model() {}
}
