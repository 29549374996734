import { helper } from '@ember/component/helper';

const MATERIAL_TYPE_TO_POPOVER_COMPONENT_NAME_MAPPING = {
  articles: 'article-popup',
  images: 'image-popup',
};

const MATERIAL_TYPE_TO_MODAL_COMPONENT_NAME_MAPPING = {
  articles: 'article-dialog',
  attachments: 'attachment-dialog',
  embeds: 'embed-lightbox',
  guidances: 'article-dialog',
  images: 'image-lightbox',
  interactive_images: 'interactive-image-lightbox',
  links: 'link-dialog',
  videos: 'video-lightbox',
  vr_images: 'vr-image-lightbox',
  audios: 'audio-lightbox',
  canvas: 'canvas',
};

const COMPONENT_NAME_PREFIX = 'material-viewer';

export function materialViewerComponentName(
  [material],
  { behavior = 'modal' }
) {
  if (
    behavior === 'popover' &&
    Object.keys(MATERIAL_TYPE_TO_POPOVER_COMPONENT_NAME_MAPPING).includes(
      material.type
    )
  ) {
    return [
      COMPONENT_NAME_PREFIX,
      MATERIAL_TYPE_TO_POPOVER_COMPONENT_NAME_MAPPING[material.type],
    ].join('/');
  }

  return [
    COMPONENT_NAME_PREFIX,
    MATERIAL_TYPE_TO_MODAL_COMPONENT_NAME_MAPPING[material.type],
  ].join('/');
}

export default helper(materialViewerComponentName);
