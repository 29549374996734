import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { encode } from 'compton/utils/punctuation';

export default class TbRenderInlineTextComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */

  get content() {
    return htmlSafe(
      encode(
        this.args.node?.content?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>')
      )
    );
  }
}
