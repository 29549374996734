import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class AdditionCustomizerGroupSectionTreeItemComponent extends Component {
  /**
   * Arguments:
   * addition (object)
   * includedAdditions (array)
   * onInclude (function)
   * onExclude (function)
   */

  get isIncluded() {
    return (this.args.includedAdditions || []).includes(this.args.addition);
  }

  @action handleCheckboxChange() {
    if (this.isIncluded) {
      this.#exclude();
    } else {
      this.#include();
    }
  }

  #include() {
    this.args.onInclude?.(this.args.addition);
  }

  #exclude() {
    this.args.onExclude?.(this.args.addition);
  }
}
