import { action } from '@ember/object';
import { cancel, later, scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { customCanvasKeys } from '@naturochkultur/detroit/utils/fabric';
import { resolve } from 'rsvp';

const SAVE_DELAY = 1000;
const SAVE_STATUS_RESET_DELAY = 3000;

export default class MaterialViewerCanvas extends Component {
  /**
   * Arguments:
   * material (object)
   * editor (object)
   * user (object)
   */

  @service confirmManager;

  @service session;

  @service store;

  @tracked element;

  @tracked isFullscreen;

  @tracked confirm;

  @tracked isLoading = true;

  @tracked savingState = null;

  @tracked saveTimeout;

  @tracked canvasState;

  @tracked json = new Promise((resolve) => resolve);

  @tracked changingUser = false;

  get classNames() {
    const classes = ['canvas-presentor'];

    if (this.isFullscreen) {
      classes.push('canvas-presentor-fullscreen');
    }

    return classes.join(' ');
  }

  get isFullscreenEnabled() {
    return Boolean(
      document.fullscreenElement || document.webkitFullscreenElement
    );
  }

  @action async changeUser() {
    this.changingUser = true;

    await this.setCanvasState();

    this.changingUser = false;
  }

  @action toggleFullscreen() {
    if (!this.isFullscreenEnabled) {
      this.isFullscreen = true;
      this.element.requestFullscreen
        ? this.element.requestFullscreen()
        : this.element.webkitRequestFullscreen?.();
    } else {
      this.isFullscreen = false;
      document.exitFullscreen
        ? document.exitFullscreen()
        : document.webkitExitFullscreen?.();
    }
  }

  @action setElementAndCanvasState(elem) {
    this.setElement(elem);
    this.setCanvasState();
  }

  @action handleConfirmActivated() {
    if (this.isFullscreen) {
      scheduleOnce('afterRender', this, this.moveModalToElement);
    }
  }

  @action handleConfirmReset() {
    if (this.confirm) {
      if (this.element.contains(this.confirm)) {
        this.element.removeChild(this.confirm);
      } else {
        this.confirm = null;
      }
    }
  }

  @action async handleSave(canvas) {
    cancel(this.saveTimeout);
    this.saveTimeout = later(this, this.performSave, canvas, SAVE_DELAY);
  }

  async performSave(canvas) {
    const stateUser = await resolve(this.canvasState.user);

    if (stateUser.id !== this.session.user.id) return;

    const json = canvas.toJSON(customCanvasKeys.map((item) => item.name));

    if (JSON.stringify(json) === this.canvasState.json) return;

    this.savingState = 'saving';

    this.canvasState.setJSON(json);

    await this.canvasState.save();

    this.savingState = 'saved';

    later(this, this.resetSaveState, SAVE_STATUS_RESET_DELAY);
  }

  resetSaveState() {
    if (this.savingState === 'saved') {
      this.savingState = null;
    }
  }

  onFullScreenChange() {
    this.isFullscreen = this.isFullscreenEnabled;
  }

  moveModalToElement() {
    const confirm = document.getElementById(this.confirmManager.id);

    if (confirm) {
      this.confirm = confirm;
      this.element.appendChild(confirm);
    }
  }

  setElement(elem) {
    elem.addEventListener(
      'fullscreenchange',
      this.onFullScreenChange.bind(this)
    );

    elem.addEventListener(
      'webkitfullscreenchange',
      this.onFullScreenChange.bind(this)
    );

    this.element = elem;
  }

  async setCanvasState() {
    this.isLoading = true;
    this.canvasState = null;

    let states, state;

    try {
      let user_id = this.session.user.id;

      if (this.args.user) {
        const user = await resolve(this.args.user);
        user_id = user.id;
      }

      states = await this.store.query('canvas-state', {
        user_id,
        material_id: this.args.material.id,
        editor_id: this.args.editor?.id ?? null,
      });

      if (states?.length > 0) state = states[0];
    } catch (err) {
      // do nothing
    }

    if (!state) {
      state = this.store.createRecord('canvas-state', {
        material: this.args.material,
        editor: this.args.editor,
        user: this.session.user,
      });

      state.setJSON(this.args.material?.metadata?.json);
    }

    this.canvasState = state;
    this.json = state.json;
    this.isLoading = false;
  }
}
