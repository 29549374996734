import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class MasterProductsIndexController extends Controller {
  @service router;

  @action routeToCourse(course) {
    return this.router.replaceWith('master.courses.show.index', course.id);
  }
}
