import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<XPopup\n  @for={{@for}}\n  @switchToDialogOnSmallBreakpoint={{false}}\n  @onClose={{@onClose}}\n>\n  <div\n    class=\"action-menu\"\n    role=\"menu\"\n    inert={{this.isInert}}\n    ...attributes\n    data-test-action-menu\n    data-width={{this.width}}\n    tabindex=\"-1\"\n    {{auto-focus}}\n    {{step-focus orientation=\"vertical\" searchable=true}}\n    {{ref (set this \"element\")}}\n  >\n    {{yield\n      (hash\n        Item=(component\n          \"action-menu/item\"\n          onSelect=this.handleItemSelect\n          wrapperElement=this.element\n          onHighlightChange=(set this \"isInert\")\n        )\n        Divider=(component \"action-menu/divider\")\n      )\n    }}\n  </div>\n</XPopup>\n{{on-document \"keydown\" this.handleDocumentKeyDown capture=true}}\n{{on-window \"resize\" @onClose}}\n{{on-window \"blur\" @onClose}}", {"contents":"<XPopup\n  @for={{@for}}\n  @switchToDialogOnSmallBreakpoint={{false}}\n  @onClose={{@onClose}}\n>\n  <div\n    class=\"action-menu\"\n    role=\"menu\"\n    inert={{this.isInert}}\n    ...attributes\n    data-test-action-menu\n    data-width={{this.width}}\n    tabindex=\"-1\"\n    {{auto-focus}}\n    {{step-focus orientation=\"vertical\" searchable=true}}\n    {{ref (set this \"element\")}}\n  >\n    {{yield\n      (hash\n        Item=(component\n          \"action-menu/item\"\n          onSelect=this.handleItemSelect\n          wrapperElement=this.element\n          onHighlightChange=(set this \"isInert\")\n        )\n        Divider=(component \"action-menu/divider\")\n      )\n    }}\n  </div>\n</XPopup>\n{{on-document \"keydown\" this.handleDocumentKeyDown capture=true}}\n{{on-window \"resize\" @onClose}}\n{{on-window \"blur\" @onClose}}","moduleName":"babel/components/action-menu/index.hbs","parseOptions":{"srcName":"babel/components/action-menu/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { KEY } from 'compton/utils/event';

export default class ActionMenu extends Component {
  element = null;

  @tracked isInert = false;

  get width() {
    return this.args.width || 'auto';
  }

  @action
  handleItemSelect(value) {
    this.args.onSelect?.(value);
    this.args.onClose?.();
  }

  @action
  handleDocumentKeyDown(evt) {
    if (evt.key === KEY.tab) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
    }
  }
}
