import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { defaultValidator } from 'ember-a11y-refocus';
import { storageFor } from 'ember-local-storage';
import moment from 'moment';

export default class ApplicationController extends Controller {
  // SETUP

  constructor() {
    super(...arguments);
    this.languageChanged();
  }

  @service contextHelper;

  @service intl;

  @service session;

  @service colorTheme;

  @service router;

  @storageFor('read-setting') readSettings;

  // PROPERTIES

  @tracked provider;

  @tracked idp;

  @tracked isLoading = false;

  @tracked error;

  @action
  shouldScreenReaderRefocus(transition) {
    const to = transition.to;
    const from = transition.from;

    const isScroll = this.readSettings.get('mode') === 'scroll';

    const fromSection = from?.queryParams?.section;
    const toSection = to?.queryParams?.section;

    const isWorkspaceOpen = () => {
      const currentRouteName = this.router.currentRouteName;

      return (
        currentRouteName.includes('master.contents.assignments') ||
        currentRouteName.includes('master.contents.wordlists') ||
        currentRouteName.includes('master.contents.notes')
      );
    };

    if (
      (from.name.startsWith('master.contents.assignments') ||
        from.name.startsWith('master.contents.wordlists') ||
        from.name.startsWith('master.contents.notes')) &&
      to.name === 'master.contents.index'
    ) {
      // If we're closing the workspace, we should not refocus (this causes DINO-4041)
      return false;
    }

    if (isWorkspaceOpen()) {
      // If workspace is open only refocus if section changes, and not in scroll mode
      return fromSection !== toSection && !isScroll;
    }

    const MASTER_CONTENTS_INDEX = 'master.contents.index';

    if (to.name === MASTER_CONTENTS_INDEX && !toSection) {
      // If we're navigation to master content and there is no toSection, we're probably going to the overview page. Refocus!
      return true;
    }

    if (
      to.name === MASTER_CONTENTS_INDEX &&
      from.name === MASTER_CONTENTS_INDEX &&
      fromSection &&
      isScroll
    ) {
      // Don't refocus if we're navigating in content when mode is 'scroll'
      return false;
    }

    // otherwise fallback to default
    return defaultValidator(transition);
  }

  // ACTIONS

  @action
  languageChanged() {
    const currentLocale = (this.intl.locale || [])[0];
    const courseLocale = this.contextHelper.activeCourse?.language;
    const userLocale = this.session.user?.language;
    const locale = courseLocale || userLocale || 'sv';

    if (locale !== currentLocale) {
      this.intl.locale = locale;
      moment.locale(locale);
    }
  }
}
