import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ArchiveFormComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * routed (bool)
   * cancelRouteParams (array)
   * onUnlink (function)
   * onDelete (function)
   * onCancel (function)
   * onSave (function)
   */

  @service router;

  @action onCancel() {
    if (this.args.routed) {
      return this.router.transitionTo(...this.args.cancelRouteParams);
    }

    return this.args.onCancel?.();
  }
}
