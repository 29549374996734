import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  textformats={{this.settings.textformats}}\n  textsize={{this.textSize}}\n  textonly={{this.settings.textonly}}\n  style={{this.settings.style}}\n  lineheight={{this.settings.lineHeight}}\n  class=\"content-presenter{{if this.isContainer \" content-presenter-container\"}} {{this.settings.fontfamily}}\"\n  ...attributes>\n  {{yield}}\n</div>\n", {"contents":"<div\n  textformats={{this.settings.textformats}}\n  textsize={{this.textSize}}\n  textonly={{this.settings.textonly}}\n  style={{this.settings.style}}\n  lineheight={{this.settings.lineHeight}}\n  class=\"content-presenter{{if this.isContainer \" content-presenter-container\"}} {{this.settings.fontfamily}}\"\n  ...attributes>\n  {{yield}}\n</div>\n","moduleName":"babel/components/content-presenter/index.hbs","parseOptions":{"srcName":"babel/components/content-presenter/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { storageFor } from 'ember-local-storage';

export default class ContentPresenter extends Component {
  @service contextHelper;

  @storageFor('read-setting') settings;

  get textSize() {
    const adaptUiFor = this.contextHelper.activeBook?.adaptUiFor;
    const saved = this.settings.get('textsize');

    if (adaptUiFor === 1 || saved === '3') {
      return '3';
    }

    return '2';
  }

  get isContainer() {
    if (this.args.isContainer === false) {
      return false;
    } else {
      return true;
    }
  }
}
