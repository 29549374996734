import { action } from '@ember/object';
import { throttle } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CourseAdaptSettingsComponent extends Component {
  /**
   * Arguments:
   * course (object)
   */

  @service intl;

  @service ajax;

  @cached
  get settingProxy() {
    const promise = resolve(this.args.course).then((course) => course.setting);

    return load(promise, this);
  }

  get setting() {
    return this.settingProxy.isResolved ? this.settingProxy.value : null;
  }

  @tracked viewExternalProducts = false;

  get languages() {
    let langCodes = ['sv', 'en'];

    langCodes = langCodes.map((langCode) => {
      const langName = this.intl.t(
        `components.courseAdaptSettings.language.${langCode}`
      );
      return { label: langName, value: langCode };
    });

    langCodes.unshift({
      label: this.intl.t(
        'components.courseAdaptSettings.language.noSelectedLanguage'
      ),
      value: null,
    });

    return langCodes;
  }

  get overviewSortSetting() {
    let settings = ['shortcuts', 'missions'];

    return settings.map((setting) => {
      const label = this.intl.t(
        `components.courseAdaptSettings.sort.${setting}`
      );
      return { label: label, value: setting };
    });
  }

  get hideDiagnosis() {
    return !this.setting?.showDiagnosis;
  }

  get hideKey() {
    return !this.setting?.showKey;
  }

  get externalProducts() {
    return this.args.course.books.filter((product) => {
      return product.type === 'books_external';
    });
  }

  @action
  setDiagnosis(val) {
    if (!this.setting) return;
    this.setting.showDiagnosis = !val;
    this.setting.save();
  }

  @action
  setKey(val) {
    if (!this.setting) return;
    this.setting.showKey = !val;
    this.setting.save();
  }

  @action
  saveSettings() {
    throttle(() => this.setting.save(), 1000, false);
  }

  @action
  handleImageUpload(file) {
    return this.ajax
      .request(`${config.endpoint}/api/v1/meta/settings/upload-url`, true, {
        type: 'POST',
        data: {
          id: this.args.course.id,
          name: this.args.course.name,
          contentType: file.type,
        },
      })
      .then((response) => {
        return this.ajax
          .request(response.url, false, {
            type: 'PUT',
            contentType: file.type,
            processData: false,
            data: file,
          })
          .then(() => {
            this.args.course.set('imageUploaded', true);
            return this.args.course.save();
          });
      });
  }

  @action
  removeImage() {
    this.args.course.set('imageUploaded', false);
    return this.args.course.save();
  }
}
