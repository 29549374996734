import { later } from '@ember/runloop';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import combineStyle from 'compton/utils/combine-style';

// The entire path must exist for each file
const FILES = [
  { name: 'error', path: '/assets/audios/error.mp3' },
  { name: 'success', path: '/assets/audios/success.mp3' },
  { name: 'swoosch', path: '/assets/audios/turned_in.mp3' },
  { name: 'correct', path: '/assets/images/response/correct.svg' },
  { name: 'turned-in', path: '/assets/images/response/turned-in.svg' },
  { name: 'done', path: '/assets/images/response/done.svg' },
];

export default class ShowResponseService extends Service {
  @tracked mode;

  @tracked audioSrc;

  @tracked imgSrc;

  @tracked imgStyle;

  handleResponse(id, mode) {
    switch (mode) {
      case 'wrong':
        this.mode = 'error';
        this.playSound(id, 'error');
        break;
      case 'correct':
        this.mode = 'success';
        this.playSound(id, 'success');
        this.showImage(id, 'correct');
        break;
      case 'done':
        this.mode = 'done';
        this.playSound(id, 'success');
        this.showImage(id, 'done');
        break;
      default:
        this.mode = 'turned-in';
        this.playSound(id, 'swoosch');
        this.showImage(id, 'turned-in');
    }

    later(() => {
      this.reset();
    }, 2100);
  }

  playSound(id, name) {
    const pathObj = FILES.findBy('name', name);

    this.audioSrc = pathObj?.path;

    const elem = document.querySelector(`#${id}`);
    const audio = elem.querySelector('.show-response-audio');

    if (audio) {
      audio.onload = () => {
        audio.play();
      };
    }
  }

  showImage(id, name) {
    const elem = document.querySelector(`#${id}`);
    const parent = elem?.parentElement;
    const rect = parent?.getBoundingClientRect();
    const left = Math.floor(rect?.left + rect?.width / 2 - 144 / 2);
    const height = 144;
    const width = 144;
    const pathObj = FILES.findBy('name', name);

    this.imgSrc = pathObj?.path;
    this.imgStyle = combineStyle({ left, height, width });
  }

  reset() {
    this.audioSrc = null;
    this.imgSrc = null;
  }
}
