const MATOMO_CATEGORIES = {
  presentation: 'Presentation',
  play: 'Uppläsning',
  readingBuddy: 'Läskompis',
  helplist: 'Hjälplista',
  mission: 'Uppdrag',
};
/**
 * Track an event in Matomo
 * @param {Event} event
 * @example
 * **När användaren slutför en inbjudan via a-post där hen har valt klass och skickar med 30 elever**
 * ```javascript
 * trackEvent('Användare', 'Bjud in elev - Via e-post - Slutför', 'Med klass', 30);
 * ```
 */
export function trackEvent({ category, action, name, value }) {
  if (!category || !action) {
    console.error('Missing category or action for tracking event', {
      category,
      action,
    });

    return;
  }

  // Make sure window._paq exists
  window._paq ??= [];

  window._paq.push(['trackEvent', category, action, name, value?.toString()]);
}

/**
 * Track a presentation event in Matomo.
 *
 * @param {string} action The action performed
 * @param {string} [name] Additional details
 */
export function trackPresentationEvent(action, name) {
  trackEvent({
    category: MATOMO_CATEGORIES.presentation,
    action,
    name,
  });
}

/**
 * Track a play setting event in Matomo.
 *
 * @param {string} action The action performed
 * @param {string} [name] Additional details
 * @param {number} [value] The new settings value
 */
export function trackPlaySettingEvent(action, name, value) {
  trackEvent({
    category: MATOMO_CATEGORIES.play,
    action,
    name,
    value,
  });
}

/**
 * Track a reading buddy event in Matomo.
 *
 * @param {string} action The action performed
 */
export function trackReadingBuddyEvent(action) {
  trackEvent({
    category: MATOMO_CATEGORIES.readingBuddy,
    action,
  });
}

/**
 * Track a helplist event in Matomo.
 *
 * @param {string} action The action performed
 */
export function trackHelplistEvent(action) {
  trackEvent({
    category: MATOMO_CATEGORIES.helplist,
    action,
  });
}

/**
 * Track a mission event in Matomo.
 *
 * @param {string} action The action performed
 */
export function trackMissionEvent(action) {
  trackEvent({
    category: MATOMO_CATEGORIES.mission,
    action,
  });
}
