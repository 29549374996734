import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';
import { resolve } from 'rsvp';

export default Service.extend({
  // SETUP

  ilt: service(),

  session: service(),

  speakerSession: storageFor('speaker-session'),

  // PROPERTIES

  iltJwt: alias('speakerSession.iltJwt'),

  iltEnabled: alias('speakerSession.iltEnabled'),

  // METHODS

  reset() {
    this.set('iltJwt', null);
    this.set('iltEnabled', null);
  },

  iltError() {
    this.set('iltJwt', null);
    this.set('iltEnabled', false);
  },

  // Fetch token through Digilär's backend and return a Promise that resolves with the new token as a string
  // Should resolve with null payload instead of rejecting if a token could not be fetched.
  async getJwt() {
    // Check if ILT is enabled before doing anything else
    // Note! The initial state of iltEnabled is null, which means that we don't know yet if ILT should be enabled or disabled.
    // In this case we consider iltEnabled==null to mean "enabled", to allow the initial ILT authentication attempt.
    if (this.get('iltEnabled') === false) {
      return resolve();
    }

    await this.ilt.getToken().catch(() => {});

    if (this.ilt.hasNarratedStudyMaterial) {
      this.set('iltJwt', this.ilt.token);
      this.set('iltEnabled', true);

      return this.ilt.token;
    } else {
      this.set('iltJwt', null);
      this.set('iltEnabled', false);
    }
  },
});
