import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { all, hash, resolve } from 'rsvp';

export default class MasterCoursesShowAuditRoute extends Route {
  @service router;

  @service store;

  @service session;

  async beforeModel() {
    super.beforeModel(...arguments);

    if (!this.session.user?.isTeacher) {
      return this.router.transitionTo('master.courses.show');
    }
  }

  async model() {
    const course = this.modelFor('master.courses.show');

    let [groups, teachers, teacherAssignments] = await all([
      course.groups,
      course.teachers,
      this.store.findAll('teacher-assignment'),
      course.books,
    ]);

    groups = groups.sortBy('name');

    const teacherIds = teachers.map((teacher) => teacher.id);

    teacherAssignments = teacherAssignments
      .filter((teacherAssignment) =>
        teacherIds.includes(teacherAssignment.userId)
      )
      .sortBy('title');

    const students = [];

    await all(
      groups.map((group) =>
        resolve(group.users).then((users) =>
          students.push(
            ...users
              .filter(
                (user) => user.role === 'student' && !students.includes(user.id)
              )
              .map((user) => user.id)
          )
        )
      )
    );

    return hash({ course, groups, students, teacherAssignments });
  }
}
