import config from 'babel/config/environment';
import { DefaultSpeakerConfig, WebPageTranslationDetector } from 'speaker';

import DigilarLanguageUtil from './DigilarLanguageUtil';
import allSpeakerVoices from './speaker-voices';

class DigilarSpeakerConfig extends DefaultSpeakerConfig {
  constructor({ getContextData, getSpeakerSettings } = {}) {
    super();
    this.getContextData = getContextData;
    this.getSpeakerSettings = getSpeakerSettings;

    // Default case, we want to use all voices configured in speaker-voices.js
    this._voices = allSpeakerVoices;

    this.recordedSpeechProviderPriority =
      this.recordedSpeechProviderPriority.bind(this);
  }

  onDemandLoadingNumberOfAudioSourcesToLoad() {
    // If the page is translated don't load audio sources on demand, since that
    // will cause the speaker to replace the translated text with the original text.
    //
    // The user will get more loading spinners but hopefully will only be shown translated text.
    if (WebPageTranslationDetector.isTranslated()) {
      return 0;
    }

    return super.onDemandLoadingNumberOfAudioSourcesToLoad();
  }

  //////////////////////////////////////////////////////////////////
  //
  // BEGIN: Overridden config
  //
  recordedSpeechBaseUrl() {
    return config.recordedSpeechEndpoint;
  }

  preferredVoice(language, isTranslationLanguage = false) {
    const defaultLanguage = isTranslationLanguage ? null : 'sv';
    language = this._toTtsLanguageCode(
      DigilarLanguageUtil.parseLanguage(language),
      defaultLanguage
    );

    // This can only happen when isTranslationLanguage is true
    // In that case, we don't want to default to Swedish voice, so we return null here
    if (!language) {
      return null;
    }

    let selectedVoice = this.getSpeakerSettings().get('voices')[language];

    // If user has already selected a voice, make sure that it is still a valid voice
    const hasValidSelectedVoice =
      selectedVoice && this.isValidVoice(language, selectedVoice);

    if (!hasValidSelectedVoice) {
      // Get default voice in case of no valid voice selected
      selectedVoice = this.supportedVoices(language)[0].id;
    }

    return selectedVoice;
  }

  isRecordedSpeechEnabled(speakerType) {
    // Recorded speech can be completely disabled, to prevent looking up audio source URLs that we won't use or that we know don't exist anyway
    return (
      // Never use recorded speech for translated web pages
      !WebPageTranslationDetector.isTranslated() &&
      // No need to check recorded speech if user prefers synthetic speech
      !this.getSpeakerSettings().get('preferSpeechSynthesis')
    );
  }

  preferRecordedSpeech() {
    return (
      this.isRecordedSpeechEnabled() &&
      !this.getSpeakerSettings().get('preferSpeechSynthesis')
    );
  }

  recordedSpeechProviderPriority() {
    const priority = [];

    // console.log('getContextData()', this.getContextData());

    if (this.getContextData().digilarRecordedSpeechEnabled) {
      priority.push('digilar');
    }
    if (this.getContextData().iltRecordedSpeechEnabled) {
      priority.push('ilt');
    }

    // console.debug('recordedSpeechProviderPriority =', priority);

    return priority;
  }

  allowTtsWithRecordedSpeech() {
    return true;
  }

  allowMixOfRecordedSpeechProviders() {
    return false;
  }

  //
  // END: Overridden config
  //
  //////////////////////////////////////////////////////////////////
  //
  // BEGIN: Additional Digilär specific config
  //

  /**
   * Helper function to handle content of unsupported languages
   * @param {*} languageCode
   */
  _toTtsLanguageCode(languageCode, defaultLanguage = null) {
    if (Object.keys(this.supportedVoices()).includes(languageCode)) {
      return languageCode;
    } else {
      return defaultLanguage;
    }
  }

  isValidVoice(language, voiceId) {
    return this.supportedVoices(language).some((voice) => voice.id === voiceId);
  }

  supportedVoices(language) {
    if (language) {
      if (!this._voices[language] || this._voices[language].length === 0) {
        throw `No TTS voices configured for language ${language}. Check file speaker-voices.js.`;
      }
      return this._voices[language];
    } else {
      // Return all voices if no language is specified as param
      return this._voices;
    }
  }

  /**
   * @param providerId The ID of the provider to get speech speed values for. Must be one of the implemented providers: { 'digilar', 'ilt', 'acapela', 'polly' }
   * @return An array of three speech speed values where 1.0 means 100%, each array index corresponding to selected option in UI component (Veryslow/Slow/Standard/Fast).
   */
  speechSpeedValues(providerId) {
    const DEFAULT = [0.5, 0.7, 1.0, 1.2];

    const speedByProvider = {
      digilar: [0.5, 0.7, 1.0, 1.2],
      ilt: [0.5, 0.7, 1.0, 1.2],
      acapela: [0.5, 0.7, 1.0, 1.2],
      acapela_cloud: [0.5, 0.7, 1.0, 1.2],
      polly: [0.5, 0.7, 1.0, 1.2],
    };

    return speedByProvider[providerId] || DEFAULT;
  }

  // true will use the selected TTS provider's speech speed instead of using the playback speed functionality of the local sound lib (Howler)
  // false will always request speech from TTS provider with a speed of 1.0 (100%) and the local sound lib will then be used to adjust playback speed.
  useTtsSpeechSpeed() {
    return true;
  }

  // Autoplay of first text block can be disabled for some content types.
  autoplayFirstTextBlockOnLoad(speakerType) {
    return !['practice'].includes(this.getContextData().contentType);
  }

  // This is a setting to handle the legacy of stories (bildberättelser) where each story has a single recorded speech file.
  // Setting this to true will always use that legacy file if found, while false will also check for recorded speech on component level.
  alwaysUseSingleRecordedSpeechFileForStory() {
    return true;
  }

  autoSelectFirstTextBlockOnInit(speakerType, initializedAudioSources) {
    return ['chapter', 'story', 'workflow', 'practice'].includes(
      this.getContextData().contentType
    );
  }

  // Change to a larger value if DOM poller seems to consume too much CPU
  domPollerIntervalMs() {
    return 500;
  }

  // Below: Sub types of configuration are grouped in getters

  get UI() {
    return {
      Images: {
        // Configures if the images and captions should be part of the clickable speaker controls or not.
        // Captions can only be excluded when images are.
        // true --> The image/caption is not clickable to play/pause
        // false --> Make image/caption clickable to play/pause
        excludeImageFromSpeakerControls: () => true,
        excludeCaptionsFromSpeakerControls: () => {
          return (
            this.getContextData().digilarRecordedSpeechEnabled &&
            !this.getSpeakerSettings().get('preferSpeechSynthesis')
          );
        },
      },
    };
  }

  get Activation() {
    return {
      // true --> save state (permantently) in local storage, false --> save state (temporarily) in speaker service
      alwaysRememberActiveState: () => false,

      // true --> allow the active state stored in service to be overridden by "active setting" from local storage
      // Only relevant if alwaysRememberActiveState === false.
      overrideActiveStateWithSetting: () => true,
    };
  }

  get RecordedSpeech() {
    return {
      // Change this to true to search ancestors in the DOM for data-id attribute when a text block element does not have the data-id itself.
      // This should make it possible to have recorded speech for images (ILT) even if only the text caption is selected from the DOM.
      // (The caption does not have a data-id attribute but the figure ancestor does)
      searchAncestorsForDataId: () => true,
    };
  }

  // The TextProcessing group contains function related to processing texts for TTS.
  get TextProcessing() {
    return {
      shouldExcludeImageGalleries: () => true,
      shouldExcludeImages: () => false, // Excludes images (and captions) completely from TTS text processing
      shouldExcludeImageSources: () => true, // Only relevant if shouldExcludeImages()===false
      shouldExcludeTips: () => false,
      shouldExcludeModalDialogTitle: () => true,
      shouldExcludeTextsContainingMathCode: () => false, // Don't exclude entire text blocks for TTS because they contain math
      shouldExcludeMathCode: () => false, // Set to true to exclude just the math code. (Right now it's being replaced with alternative text instead)
    };
  }
}

export default DigilarSpeakerConfig;
