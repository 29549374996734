import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"editor-assignment {{this.presetClass}}\" ...attributes>\n  <div class=\"editor-wrapper\" {{did-update this.inputChanged this.input}}>\n    {{#if this.ready}}\n      <XCkeditor\n        @value={{this.textValue}}\n        @toolbarOptions={{this.toolbarOptions}}\n        @readOnly={{@disabled}}\n        @version={{this.version}}\n        @answerId={{@answer.id}}\n        @onChange={{this.updateText}}\n      />\n    {{/if}}\n  </div>\n\n  {{#if this.isIncomplete}}\n    <div class=\"incomplete-message\">\n      {{t \"components.exerciseView.assignment.editor.incompleteMessage\"}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"editor-assignment {{this.presetClass}}\" ...attributes>\n  <div class=\"editor-wrapper\" {{did-update this.inputChanged this.input}}>\n    {{#if this.ready}}\n      <XCkeditor\n        @value={{this.textValue}}\n        @toolbarOptions={{this.toolbarOptions}}\n        @readOnly={{@disabled}}\n        @version={{this.version}}\n        @answerId={{@answer.id}}\n        @onChange={{this.updateText}}\n      />\n    {{/if}}\n  </div>\n\n  {{#if this.isIncomplete}}\n    <div class=\"incomplete-message\">\n      {{t \"components.exerciseView.assignment.editor.incompleteMessage\"}}\n    </div>\n  {{/if}}\n</div>","moduleName":"babel/components/exercise-view/assignment/editor/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/editor/index.hbs"}});
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

export default class ExerciseViewAssignmentEditorComponent extends Component {
  /**
   * Arguments:
   * assignment (object)
   * canHaveAttachments (bool)
   * assignmentAnswer (object)
   * answer (object)
   * handleOnChange (function)
   * showValidation (bool)
   * showKey (bool)
   * disabled (bool)
   * showSuggestion (bool)
   * incomplete (bool)
   * image (object)
   */

  constructor() {
    super(...arguments);

    this.textValue = this.input;
  }

  @tracked version = 1;

  @tracked textValue;

  get input() {
    return this.args.assignmentAnswer?.input;
  }

  get ready() {
    return (
      (this.input !== undefined && this.input !== false) || this.args.disabled
    );
  }

  get isIncomplete() {
    if (this.args.incomplete) {
      return (
        isEmpty(this.input) &&
        this.args.canHaveAttachments &&
        this.args.answer?.files?.length === this.args.numberOfMediaAssignments
      );
    }

    return false;
  }

  get presetClass() {
    return ['preset', this.args.assignment?.settings?.preset].join('-');
  }

  @cached get toolbarOptions() {
    const preset = this.args.assignment?.settings?.preset;

    switch (preset) {
      case 'minimal':
        return [];
      case 'basic':
        return ['bold', 'italic', 'underline'];
      default:
        return [
          'heading',
          'bold',
          'italic',
          'strikethrough',
          'underline',
          'subscript',
          'superscript',
          'bulletedList',
          'numberedList',
          'insertTable',
        ];
    }
  }

  @action updateText(value) {
    if (!this.args.disabled) {
      if (this.textValue !== value) {
        this.textValue = value;

        if (this.args.assignmentAnswer) {
          this.args.assignmentAnswer.input = value;
        }

        this.args.handleOnChange?.(value);
      }
    }
  }

  @action inputChanged() {
    // Update editor version if input is cleared or
    // equal to pattern / answer template
    if (
      this.input === this.args.assignment?.content?.pattern ||
      this.input == null ||
      this.input == undefined ||
      this.input == ''
    ) {
      this.textValue = this.input;
      this.version++;
    }
  }
}
