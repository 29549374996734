import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CourseMainComponent extends Component {
  /**
   * Arguments:
   * course (object)
   */

  constructor() {
    super(...arguments);

    if (this.missingLicenses.length > 0 && this.licensesReloaded === false) {
      this.session.refreshUserLicenses().then(() => {
        this.licensesReloaded = true;
      });
    }
  }

  @service session;

  @service intl;

  @tracked licensesReloaded = false;

  @tracked activeProduct = null;

  @tracked modalOpen = false;

  @cached
  get schoolProxy() {
    return load(resolve(this.session.user?.school), this);
  }

  get school() {
    return this.schoolProxy.isResolved ? this.schoolProxy.value : null;
  }

  get missingLicenses() {
    let missingLicenses = [];

    const licenses = this.session.user?.licenses;
    const courseProducts = this.args.course?.products;

    if (licenses && courseProducts) {
      const activeLicenseISBNs = licenses
        .filter((x) => x.isActive)
        .map((x) => x.isbn);
      const licenseISBNs = licenses.map((x) => x.isbn);

      courseProducts.forEach((product) => {
        if (activeLicenseISBNs.indexOf(product.id) === -1) {
          const index = licenseISBNs.indexOf(product.id);
          const license = index !== -1 ? licenses.objectAt(index) : null;

          missingLicenses.push({
            product: product,
            license: license,
          });
        }
      });
    }

    return missingLicenses;
  }

  get courseHasGroups() {
    if (this.args.course?.groups?.length > 0) return true;
    return false;
  }

  get courseHasBooks() {
    if (this.args.course?.books?.length > 0) return true;
    return false;
  }

  get hasStudents() {
    return this.args.course?.students?.length > 0;
  }

  get courseMenuItems() {
    const menu_items = [
      'overview',
      'books',
      'myResults',
      'audit',
      'missions',
      'settings',
    ];
    const menu_teacher_items = ['audit', 'missions', 'settings'];

    const menu_icons = {
      overview: 'home',
      books: 'book',
      missions: 'mission',
      myResults: 'log',
      settings: 'settings',
      audit: 'results',
    };

    const menu_routes = {
      overview: 'master.courses.show.index',
      books: 'master.courses.show.books',
      missions: 'master.courses.show.missions',
      myResults: 'master.courses.show.my-results',
      settings: 'master.courses.show.settings',
      audit: 'master.courses.show.audit',
    };

    const require_books = ['myResults', 'audit'];

    let items = [];

    for (let i = 0; i < menu_items.length; i++) {
      items.addObject({
        title: this.intl.t('components.courseMain.menu.' + menu_items[i]),
        icon: menu_icons[menu_items[i]],
        route: menu_routes[menu_items[i]],
        disabled:
          (require_books.includes(menu_items[i]) && !this.courseHasBooks) ||
          (menu_items[i] === 'audit' && !this.hasStudents),
        teacherMenuItems: menu_teacher_items.includes(menu_items[i]),
      });
    }

    return items;
  }

  @action
  saveCourse() {
    return this.args.course.save();
  }

  @action
  restore(course) {
    course.active = true;
    return course.save();
  }

  @action
  openLicenseModal(product) {
    this.activeProduct = product;
    this.modalOpen = true;
  }
}
