import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"drag-alternatives{{if this.empty ' drag-alternatives--empty'}}\"\n  ...attributes\n>\n  {{#if this.empty}}\n    <span class=\"text-small text-ui\">\n      {{this.emptyLabel}}\n    </span>\n  {{else}}\n    {{#each @alternatives as |alternative|}}\n      {{yield (hash alternative=alternative)}}\n    {{/each}}\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"drag-alternatives{{if this.empty ' drag-alternatives--empty'}}\"\n  ...attributes\n>\n  {{#if this.empty}}\n    <span class=\"text-small text-ui\">\n      {{this.emptyLabel}}\n    </span>\n  {{else}}\n    {{#each @alternatives as |alternative|}}\n      {{yield (hash alternative=alternative)}}\n    {{/each}}\n  {{/if}}\n</div>","moduleName":"babel/components/exercise-view/common/drag-alternatives/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/common/drag-alternatives/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ExerciseViewCommonDragAlternativesComponent extends Component {
  /**
   * Arguments:
   * alternatives (object)
   */

  @service intl;

  get empty() {
    return this.args.alternatives?.length === 0;
  }

  get emptyLabel() {
    return this.args.behaviour === 'images'
      ? this.intl.t(
          'components.exerciseView.common.dragAlternatives.imageDragEmpty'
        )
      : this.intl.t(
          'components.exerciseView.common.dragAlternatives.textGapDragEmpty'
        );
  }
}
