import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import sortBy from 'babel/utils/sort-by';
import { task } from 'ember-concurrency';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';

const { PromiseArray } = DS;

export default Component.extend({
  // SETUP

  intl: service(),

  router: service(),

  store: service(),

  classNames: ['archive-view'],

  // INTERNAL PROPERTIES

  implicitSearchResults: null,

  isLoading: false,

  isLoadingImplicit: false,

  searchResults: null,

  searchVal: null,

  searchValue: '',

  sortValue: null,

  // EXTERNAL PROPERTIES

  archive: null,

  folder: null,

  materials: null,

  init() {
    this._super(...arguments);
    this.set('sortValue', 'standard');
  },

  // COMPUTED PROPERTIES

  noResults: computed('searchValue', 'searchResults', 'isLoading', function () {
    return (
      this.get('searchValue.length') > 0 &&
      this.get('searchResults.length') === 0 &&
      !this.get('isLoading')
    );
  }),

  showSearchBar: computed('archive.body.show_search_field', function () {
    return this.get('archive.body.show_search_field') !== false;
  }),

  isPaginated: computed('archive.body.show_pagination', function () {
    return this.get('archive.body.show_pagination') === true;
  }),

  hasFilters: computed('archive.body.show_filters', function () {
    return this.get('archive.body.show_filters') === true;
  }),

  hasOrder: computed('archive.body.show_order', function () {
    return this.get('archive.body.show_order') === true;
  }),

  hasRandom: computed('archive.body.show_random', function () {
    return this.get('archive.body.show_random') === true;
  }),

  hasCategorySelect: computed(
    'hasFilters',
    'archive.children.length',
    function () {
      if (!this.get('hasFilters')) return false;
      if (this.get('archive.children.length') < 2) return false;

      return true;
    }
  ),

  hasSortSelect: computed('hasOrder', 'archive.body.show_titles', function () {
    if (!this.get('hasOrder')) return false;
    if (this.get('archive.body.show_titles') === false) return false;

    return true;
  }),

  categoryOptions: computed(
    'hasCategorySelect',
    'archive.children.[]',
    function () {
      if (!this.get('hasCategorySelect')) return [];

      const options = this.get('archive.children').map((x) => {
        return { label: x.get('title'), value: x.get('id') };
      });

      options.unshift({
        label: this.get('intl').t('components.archiveView.allCategories'),
        value: null,
      });

      return options;
    }
  ),

  sortOptions: computed('hasSortSelect', function () {
    if (!this.get('hasSortSelect')) return [];

    return [
      {
        label: this.get('intl').t('components.archiveView.sort.standard'),
        value: 'standard',
      },
      {
        label: this.get('intl').t('components.archiveView.sort.name_asc'),
        value: 'name_asc',
      },
      {
        label: this.get('intl').t('components.archiveView.sort.name_desc'),
        value: 'name_desc',
      },
    ];
  }),

  folderId: computed('folder.id', {
    get() {
      return this.get('folder.id') ? this.get('folder.id') : null;
    },
    set(key, val) {
      if (!val) {
        this.get('router').transitionTo(
          'master.archives',
          this.get('archive.id'),
          { queryParams: { page: 1 } }
        );
      } else {
        this.get('router').transitionTo('master.archives.folders', val, {
          queryParams: { page: 1 },
        });
      }

      return val;
    },
  }),

  sortedCategories: computed(
    'archive.{body.archive_sort,children.[]}',
    function () {
      let sort = this.get('archive.body.archive_sort');

      if (sort) {
        if (sort === 'name_asc') sort = 'title_asc';
        else if (sort === 'name_desc') sort = 'title_desc';

        return sortBy(this.get('archive.children'), sort);
      } else {
        return this.get('archive.children');
      }
    }
  ),

  // PRIVATE METHODS

  _search() {
    this.set('isLoading', true);
    this.set('pageNumber', 1);

    return this.get('store')
      .query('material', {
        entity_id: this.get('archive.id'),
        search: this.get('searchValue'),
        action: 'explicit',
      })
      .then((hits) => {
        const folders = this.get('archive.children');

        if (folders) {
          hits.forEach((hit) => {
            folders.forEach((folder) => {
              if (folder.get('materials').indexOf(hit) !== -1) {
                hit.set('folder', folder);
              }
            });
          });
        }

        if (hits.get('length') === 0) {
          this.set('isLoadingImplicit', true);
          this.set('searchResults', []);

          this.get('store')
            .query('material', {
              entity_id: this.get('archive.id'),
              search: this.get('searchValue'),
              action: 'implicit',
            })
            .then((hits) => {
              this.set('implicitSearchResults', hits.sortBy('rank').reverse());
              this.set('isLoadingImplicit', false);
              this.set('isLoading', false);
            });
        } else {
          this.set('implicitSearchResults', null);
          this.set('searchResults', hits.sortBy('rank').reverse());
          this.set('isLoading', false);
        }
      });
  },

  // TASKS

  search: task(function* () {
    yield this._search();
  }).keepLatest(),

  doSearch: task(function* () {
    const searchVal = this.get('searchVal');
    const searchValue = this.get('searchValue');

    if (searchValue === searchVal) {
      return;
    }

    this.set('searchValue', searchVal);

    this.set('isLoading', true);

    if (this.get('folder')) {
      this.get('router').transitionTo('nodes', this.get('archive'));
    }

    yield this.get('search').perform();
  }).restartable(),

  // ACTIONS

  actions: {
    openRandom() {
      const materials = this.get('materials');

      if (materials && materials.length > 0) {
        const rand = Math.floor(Math.random() * materials.length);
        const material = materials.objectAt(rand);
        const materialId = material.get('id');
        const folderId = this.get('folder')
          ? this.get('folder.id')
          : material.get('folder.id');
        const archiveId = this.get('archive.id');

        return this.get('router').transitionTo(
          'master.archives.folders.materials',
          archiveId,
          folderId,
          materialId
        );
      }
    },
    changeSortValue(value) {
      this.set('sortValue', value);
    },
  },
});
