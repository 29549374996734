import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (generate-id) (t \"components.missionOptionsButton.label\") as |id label|}}\n\n  <UiButton\n    id={{id}}\n    class=\"mission-share-button\"\n    aria-label={{label}}\n    @appearance=\"link\"\n    @onClick={{set this \"isShareLinkModalOpen\" true}}\n    ...attributes\n  >\n    <DcIcon @name=\"share\" />\n  </UiButton>\n\n  <XTooltip @for={{id}}>\n    {{label}}\n  </XTooltip>\n\n{{/let}}\n\n{{#if this.isShareLinkModalOpen}}\n  <ShareLinkModal\n    @onClose={{set this \"isShareLinkModalOpen\" false}}\n    @link={{this.link}}\n    @modalTitle={{t \"components.missionShareLinkModal.title\"}}\n  />\n{{/if}}", {"contents":"{{#let (generate-id) (t \"components.missionOptionsButton.label\") as |id label|}}\n\n  <UiButton\n    id={{id}}\n    class=\"mission-share-button\"\n    aria-label={{label}}\n    @appearance=\"link\"\n    @onClick={{set this \"isShareLinkModalOpen\" true}}\n    ...attributes\n  >\n    <DcIcon @name=\"share\" />\n  </UiButton>\n\n  <XTooltip @for={{id}}>\n    {{label}}\n  </XTooltip>\n\n{{/let}}\n\n{{#if this.isShareLinkModalOpen}}\n  <ShareLinkModal\n    @onClose={{set this \"isShareLinkModalOpen\" false}}\n    @link={{this.link}}\n    @modalTitle={{t \"components.missionShareLinkModal.title\"}}\n  />\n{{/if}}","moduleName":"babel/components/mission-share-button/index.hbs","parseOptions":{"srcName":"babel/components/mission-share-button/index.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class MissionShareButtonComponent extends Component {
  @service router;

  @tracked isShareLinkModalOpen = false;

  get link() {
    const url = this.router.urlFor('master.missions', this.args.mission.id);
    return window.location.origin + url;
  }
}
