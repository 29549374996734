import { getRect } from 'compton/utils/dom';
import { modifier } from 'ember-modifier';

function fitWithinWindow(element, positional, named) {
  const margin = named.margin ?? 0;

  const initialRect = getRect(element);

  function resize() {
    const windowRect = getRect(window);

    const widthPercent = windowRect.width / initialRect.width;
    const heightPercent = windowRect.height / initialRect.height;
    const percent = Math.min(widthPercent, heightPercent);

    element.style.width = initialRect.width * percent - margin * 2 + 'px';
    element.style.maxHeight = initialRect.height * percent - margin * 2 + 'px';
  }

  resize();

  window.addEventListener('resize', resize);

  return () => {
    window.removeEventListener('resize', resize);
  };
}

export default modifier(fitWithinWindow, { eager: false });
