import Service from '@ember/service';

export default Service.extend({
  transformMaterialWord(material, sort) {
    return {
      word: material.get('name'),
      translation: material.get('metadata.translation'),
      explanation: material.get('metadata.explanation'),
      examples: material.get('metadata.examples'),
      word_class: material.get('metadata.word_class'),
      word_construction: material.get('metadata.word_construction'),
      sort: sort
    };
  }
});
