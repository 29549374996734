import Route from '@ember/routing/route';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { NotFoundError } from '@ember-data/adapter/error';
import { MissingContentError } from 'babel/utils/errors';
import getCollectionData from 'babel/utils/get-collection-data';
import { reject, resolve } from 'rsvp';

export default class MasterContentsAssignmentsShowRoute extends Route {
  // SETUP

  @service assignmentEvents;

  @service missionMode;

  @service pageviews;

  @service router;

  @service store;

  @service entityContext;

  // HOOKS

  async model({ assignment_id }) {
    let activeExercise, collection;

    try {
      activeExercise = await this.store.findRecord('entity', assignment_id);
      collection = await resolve(activeExercise?.parent);
    } catch (err) {
      return reject(new MissingContentError());
    }

    if (collection.template === 'carousel') {
      // ignore passed in assignment_id for carousels -
      // the interactive's current exercise should always win.
      activeExercise = null;
    }

    let {
      parent: content,
      exercises,
      book,
      editor,
    } = await getCollectionData(this.store, collection);

    exercises = await this.missionMode.allowedEntities(exercises);

    const {
      interactive,
      activeAnswer,
      activeExercise: exerciseOverride,
    } = await this.assignmentEvents.getOrCreateInteractiveForCollection(
      collection,
      activeExercise
    );

    activeExercise = exerciseOverride;

    return {
      book,
      content,
      collection,
      exercises,
      activeExercise,
      editor,
      answers: interactive.answers,
      activeAnswer,
    };
  }

  afterModel(
    { activeExercise, book, collection, content, exercises },
    transition
  ) {
    if (activeExercise.id !== transition.to.params.assignment_id) {
      // if the interactive's current exercise is different than
      // the current route we redirect to the correct exercise.

      return this.router.replaceWith(
        'master.contents.assignments.show',
        activeExercise.id
      );
    }

    const { model: contentModel } = this.modelFor('master.contents');

    if (!exercises.includes(activeExercise) && content.id !== contentModel.id) {
      return reject(NotFoundError);
    }

    const contentsRoute = transition.to.find(
      (route) => route.name === 'master.contents'
    );

    this.pageviews.logPageView(activeExercise, book, collection, {
      section: contentsRoute.queryParams.section,
    });

    // When switching between collections while the workspace is left open
    // (using links in nav menu or done box) the interactive needs to be
    // updated. Triggering an interactive-updated event allows us to catch
    // this case in the entity-progress component and load the data as needed.
    scheduleOnce(
      'afterRender',
      this,
      this.triggerInteractiveUpdated,
      collection.id
    );
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    this.entityContext.changeWorkspaceExercise(model.activeExercise);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.entityContext.changeWorkspaceExercise();
    }
  }

  triggerInteractiveUpdated(collectionId) {
    this.assignmentEvents.trigger('interactive-updated', collectionId);
  }
}
