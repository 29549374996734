class IltDataParser {
  _findSentenceGroup(article, sentenceGroupId) {
    if (!article) {
      return null;
    }

    let sentenceGroup = null;

    // Find sentence group in article
    if (article.SentenceGroups && article.SentenceGroups.length > 0) {
      sentenceGroup = article.SentenceGroups.find(
        (group) => group.Id === sentenceGroupId
      );
    }

    return sentenceGroup;
  }

  _findArticle(articles, sentenceGroupId) {
    if (!articles) {
      return null;
    }

    let articleContainingSentenceGroup = null;

    // Find article containing the sentence group
    for (let article of articles) {
      if (article.SentenceGroups && article.SentenceGroups.length > 0) {
        if (
          article.SentenceGroups.some((group) => group.Id === sentenceGroupId)
        ) {
          articleContainingSentenceGroup = article;
          break;
        }
      }
    }

    return articleContainingSentenceGroup || null;
  }

  _createTextMetaData(sentence, sentenceIndex, textBlockHtml) {
    // For ILT, there can be no support for word highlighting since we have no info about individual words
    const words = [];

    // Each audio source is always a single sentence
    const sentences = [
      {
        // The following properties are required for 'sentence' events to be emitted at correct times from the Speaker class.
        sentenceIndex,
        startTimeMs: 0,
        durationMs: 0, // 0 means that duration is unknown at the moment and equal to length of audio source

        // Required for sentence highlighting to be possible. Note that sentenceIndex is also required.
        sentenceText: sentence.Content,
      },
    ];

    return { words, sentences };
  }

  //////////////////////////////////////////////////////////////////////////////
  // PUBLIC METHODS

  getAudioSources(articles, sentenceGroupId, textBlockHtml) {
    const article = this._findArticle(articles, sentenceGroupId);
    const sentenceGroup = this._findSentenceGroup(article, sentenceGroupId);

    if (
      !sentenceGroup ||
      !sentenceGroup.Sentences ||
      sentenceGroup.Sentences.length === 0
    ) {
      return [];
    }

    // Parse audio sources from sentence group within the article data
    const audioSources = sentenceGroup.Sentences.map((sentence, index) => {
      return {
        // The url is required
        url: sentence.RecordingUrl.Url,
        // Text meta data is required to be able to support sentence highlighting
        textMetaData: this._createTextMetaData(sentence, index, textBlockHtml),
        // The data is optional and may contain whatever data necessary for custom UI solutions
        // It will be included in events emitted by the speaker as property "recordedSpeechProviderData"
        // For ILT we need recordingId+article.Id to be able to call the "royalty API" for reporting back listenings to ILT.
        data: {
          text: sentence.Content,
          recordingId: sentence.RecordingId,
          articleId: article.Id,
        },
      };
    });

    // console.debug(`audio sources returned from IltDataParser:`, audioSources);

    return audioSources;
  }

  isArticleExpired(article) {
    // To decide if an article has expired, we must check EVERY sentence, because Created+TTL is individual for each sentence...
    // It's enough to find one sentence that has expired, because that means that the entire article must be fetched again.
    const isExpired = article.SentenceGroups.some((sentenceGroup) => {
      return sentenceGroup.Sentences.some((sentence) => {
        const createdDate = new Date(sentence.RecordingUrl.Created);
        const expirationTimeInSeconds = Math.floor(
          createdDate.getTime() / 1000 + sentence.RecordingUrl.TimeLeftSeconds
        );
        const nowInSeconds = Math.floor(new Date().getTime() / 1000);

        // Sentence will be considered expired if it expires within this margin (in minutes)
        const expirationMarginMinutes = 10;

        return (
          nowInSeconds > expirationTimeInSeconds - expirationMarginMinutes * 60
        );
      });
    });

    // console.debug(`Is article ${article.Id} expired? ${isExpired}`);

    return isExpired;
  }
}

export default IltDataParser;
