import { isArray } from '@ember/array';
import Component from '@ember/component';
import { observer } from '@ember/object';
import { on } from '@ember/object/evented';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),

  classNames: ['video-player-component'],

  classNameBindings: ['videoReady::video-placeholder'],

  elem: null,
  plyr: null,

  type: null,
  subtitles: null,
  name: null,
  posterUrl: null,
  source: null,
  videoReady: false,

  setupPlayer: on(
    'didInsertElement',
    observer('source', function () {
      const source = this.get('source');
      const plyr = this.get('plyr');

      if (source) {
        if (!plyr) {
          later(this, this._initPlyr);
        } else {
          this._updatePlyr();
        }
      }
    })
  ),

  _initPlyr() {
    const mediaElem = this.element.querySelectorAll(
      this.get('type') === 'videos' ? 'video' : 'audio'
    )[0];

    const plyr = new Plyr(mediaElem, {
      fullscreen: { enabled: true, fallback: true, iosNative: true },
      intl: {
        restart: this.intl.t('components.videoPlayer.restart').toString(),
        rewind: this.intl
          .t('components.videoPlayer.rewind', { seektime: 'seektime' })
          .toString(),
        play: this.intl.t('components.videoPlayer.play').toString(),
        pause: this.intl.t('components.videoPlayer.pause').toString(),
        fastForward: this.intl
          .t('components.videoPlayer.fastForward', { seektime: 'seektime' })
          .toString(),
        seek: this.intl.t('components.videoPlayer.seek').toString(),
        seekLabel: this.intl
          .t('components.videoPlayer.seekLabel', {
            currentTime: 'currentTime',
            duration: 'duration',
          })
          .toString(),
        played: this.intl.t('components.videoPlayer.played').toString(),
        buffered: this.intl.t('components.videoPlayer.buffered').toString(),
        currentTime: this.intl
          .t('components.videoPlayer.currentTime')
          .toString(),
        duration: this.intl.t('components.videoPlayer.duration').toString(),
        volume: this.intl.t('components.videoPlayer.volume').toString(),
        mute: this.intl.t('components.videoPlayer.mute').toString(),
        unmute: this.intl.t('components.videoPlayer.unmute').toString(),
        enableCaptions: this.intl
          .t('components.videoPlayer.enableCaptions')
          .toString(),
        disableCaptions: this.intl
          .t('components.videoPlayer.disableCaptions')
          .toString(),
        enterFullscreen: this.intl
          .t('components.videoPlayer.enterFullscreen')
          .toString(),
        exitFullscreen: this.intl
          .t('components.videoPlayer.exitFullscreen')
          .toString(),
        frameTitle: this.intl
          .t('components.videoPlayer.frameTitle', { title: 'title' })
          .toString(),
        captions: this.intl.t('components.videoPlayer.captions').toString(),
        settings: this.intl.t('components.videoPlayer.settings').toString(),
        menuBack: this.intl.t('components.videoPlayer.menuBack').toString(),
        speed: this.intl.t('components.videoPlayer.speed').toString(),
        normal: this.intl.t('components.videoPlayer.normal').toString(),
        quality: this.intl.t('components.videoPlayer.quality').toString(),
        loop: this.intl.t('components.videoPlayer.loop').toString(),
        start: this.intl.t('components.videoPlayer.start').toString(),
        end: this.intl.t('components.videoPlayer.end').toString(),
        all: this.intl.t('components.videoPlayer.all').toString(),
        reset: this.intl.t('components.videoPlayer.reset').toString(),
        disabled: this.intl.t('components.videoPlayer.disabled').toString(),
        enabled: this.intl.t('components.videoPlayer.enabled').toString(),
        advertisement: this.intl
          .t('components.videoPlayer.advertisement')
          .toString(),
        qualityBadge: {
          2160: this.intl
            .t('components.videoPlayer.qualityBadge.2160')
            .toString(),
          1440: this.intl
            .t('components.videoPlayer.qualityBadge.1440')
            .toString(),
          1080: this.intl
            .t('components.videoPlayer.qualityBadge.1080')
            .toString(),
          720: this.intl
            .t('components.videoPlayer.qualityBadge.720')
            .toString(),
          576: this.intl
            .t('components.videoPlayer.qualityBadge.576')
            .toString(),
          480: this.intl
            .t('components.videoPlayer.qualityBadge.480')
            .toString(),
        },
      },
      speed: {
        selected: 1,
        options: [0.75, 1, 1.25, 1.5],
      },
      blankVideo: '',
    });

    this.set('plyr', plyr);
    this.set('videoReady', true);

    this._updatePlyr();
  },

  _updatePlyr() {
    if (this.get('type') === 'videos') {
      this._plyrVideo();
    }
  },

  _plyrVideo() {
    const subtitles = this.get('subtitles');
    const plyr = this.get('plyr');

    plyr.on('enterfullscreen', (event) => {
      document.body.classList.add('plyr-fullscreen');
    });

    plyr.on('exitfullscreen', (event) => {
      document.body.classList.remove('plyr-fullscreen');
    });

    let tracks = null;
    let activateSubs = false;

    if (isArray(subtitles)) {
      const len = subtitles.length;

      tracks = [];

      for (let i = 0; i < len; i++) {
        let obj = subtitles[i];

        //if (obj.default_state === true) {
        activateSubs = true;
        //}

        tracks.push({
          kind: 'subtitles',
          label: obj.label,
          srclang: obj.language,
          src: obj.subtitle_url,
          default: true, // obj.default_state
        });
      }
    }

    let name = this.get('name') || '';

    plyr.source = {
      type: 'video',
      title: name,
      sources: [{ src: this.get('source'), type: 'video/mp4' }],
      poster: this.get('posterUrl'),
      tracks: tracks,
    };

    if (activateSubs) {
      plyr.captions.active = true;
    }
  },
});
