import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import generateId from 'compton/utils/generate-id';
import { storageFor } from 'ember-local-storage';

export default class ContentTopbarSettingsComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * speakerProvider (object)
   * hidePlaySettings (bool)
   * hideReadSettings (bool)
   * template (string)
   */

  @storageFor('read-setting') settings;

  @service session;

  @service speaker;

  @service colorTheme;

  @tracked speakerInfoModalForced = false;

  @tracked isShowingReadSettings = false;

  @tracked isCategoryPopupOpen = false;

  @tracked activePane =
    this.speaker.active && !this.args.speakerProvider.disabled
      ? 'playSettings'
      : 'readSettings';

  constructor() {
    super(...arguments);

    this.colorTheme.changeTheme(this.settings.get('theme'));
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.colorTheme.removeTheme();
  }

  get hasPlaySettings() {
    return !this.args.hidePlaySettings && !this.args.speakerProvider.disabled;
  }

  get speakerButtonClass() {
    const classes = [
      'content-topbar-option',
      'topbar-option-icon',
      'content-topbar-button',
    ];

    if (this.speaker.active && !this.args.speakerProvider.disabled) {
      classes.push('speaker--activated');
    } else {
      classes.push('speaker--deactivated');
    }

    return classes.join(' ');
  }

  get speakerButtonId() {
    return generateId('toggle-speaker');
  }

  get showSpeakerInfoModal() {
    const isSpeakerActive = !!this.speaker?.active;
    const hasSeenSpeakerInfo =
      !!this.session.user?.metadata?.has_seen_speaker_info;

    const autoShow = isSpeakerActive && !hasSeenSpeakerInfo;
    const forceShow = this.speakerInfoModalForced;

    return autoShow || forceShow;
  }

  get settingsButtonClass() {
    const classes = ['content-topbar-option', 'content-topbar-button', 'mr1'];

    if (this.isShowingReadSettings) {
      classes.push('settings--activated');
    }

    return classes.join(' ');
  }

  @action toggleSpeakerActive() {
    // Set value in service
    this.speaker.toggleSpeakerActive();
  }

  @action closeSpeakerInfoModal() {
    // Reset the 'force show' flag in case the modal was opened manually from the settings menu
    this.speakerInfoModalForced = false;

    // Save to db to prevent modal from being auto-shown again
    const user = this.session.user;

    if (!user) return;
    if (!user.metadata) user.metadata = {};

    user.metadata.has_seen_speaker_info = true;

    user.save();
  }

  @action forceShowSpeakerInfoModal() {
    this.speakerInfoModalForced = true;
  }

  @action toggleReadSettings() {
    this.isShowingReadSettings = !this.isShowingReadSettings;
  }

  @action handleSettingsChange(setting) {
    if (setting == 'readRuler' || setting == 'focus') {
      this.isShowingReadSettings = false;
    }
  }
}
