class DigilarLanguageUtil {
  /**
   * Parses the actual language from different language code formats.
   * Examples:
   * 'sv_SE' => 'sv'
   * 'en-UD' => 'en'
   * @param {*} languageCode
   * @return The language part from the language/country code.
   */
  static parseLanguage(languageCode) {
    // Replace '-' with '_' in case language codes are of format 'sv-SE' instead of 'sv_SE'
    let language = languageCode.replace('-', '_');
    const separatorIndex = language.indexOf('_');

    if (separatorIndex >= 0) {
      // Get first part of language code
      language = language.substring(0, separatorIndex);
    }

    // console.debug(`langageCode "${languageCode}" was parsed into language "${language}"`);

    return language;
  }
}

export default DigilarLanguageUtil;
