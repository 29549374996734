import Service, { service } from '@ember/service';

export default class EntityContextService extends Service {
  @service contextHelper;

  async setEntityContext(entity) {
    await this.setEntityContextRecursively(entity);
  }

  async changeExercise(entity) {
    this.contextHelper.setActive('activeExercise', entity);
  }

  async changeWorkspaceExercise(entity) {
    const parent = await this.getParent(entity);

    this.contextHelper.setActive('activeWorkspaceExercise', entity);
    this.contextHelper.setActive('activeWorkspaceSection', parent);
  }

  resetEntityContext() {
    this.contextHelper.setActive('activeBook', null);
    this.contextHelper.setActive('activeArea', null);
    this.contextHelper.setActive('activeContent', null);
    this.contextHelper.setActive('activeSection', null);
    this.contextHelper.setActive('activeExercise', null);
    this.contextHelper.setActive('activeWorkspaceSection', null);
    this.contextHelper.setActive('activeWorkspaceExercise', null);
    this.contextHelper.setCloseUrl(null);
    this.contextHelper.setReturnUrl(null);
  }

  async getParent(entity) {
    let parent;

    try {
      parent = await entity.parent;
    } catch (err) {
      // do nothing
    }

    return parent;
  }

  async setEntityContextRecursively(entity) {
    const parent = await this.getParent(entity);

    switch (entity.type) {
      case 'books':
        this.contextHelper.setActive('activeBook', entity);
        return;
      case 'areas':
      case 'archives':
        this.contextHelper.setActive('activeArea', entity);
        break;
      case 'contents':
      case 'interactives':
      case 'materials':
        this.contextHelper.setActive('activeContent', entity);
        break;
      case 'collections':
      case 'sections':
        if (entity.space === 'workspace') {
          this.contextHelper.setActive('activeWorkspaceSection', entity);
        } else {
          this.contextHelper.setActive('activeSection', entity);
        }
        break;
      case 'studies':
      case 'exercises':
      case 'assignments':
        if (parent?.space === 'workspace') {
          this.contextHelper.setActive('activeWorkspaceExercise', entity);
        } else {
          this.contextHelper.setActive('activeExercise', entity);
        }
        break;
      default:
        return;
    }

    if (parent) {
      return this.setEntityContextRecursively(parent);
    }
  }
}
