import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Header::Base::BasicButton\n  class=\"header-base-combo-button-button\"\n  ...attributes\n  data-wide={{this.wide}}\n  data-active={{this.active}}\n  @onClick={{@onClick}}\n>\n\n  {{yield\n    (hash\n      Icon=(component \"header/base/combo-button/button/icon\")\n      Label=(component \"header/base/combo-button/button/label\")\n    )\n  }}\n\n</Header::Base::BasicButton>", {"contents":"<Header::Base::BasicButton\n  class=\"header-base-combo-button-button\"\n  ...attributes\n  data-wide={{this.wide}}\n  data-active={{this.active}}\n  @onClick={{@onClick}}\n>\n\n  {{yield\n    (hash\n      Icon=(component \"header/base/combo-button/button/icon\")\n      Label=(component \"header/base/combo-button/button/label\")\n    )\n  }}\n\n</Header::Base::BasicButton>","moduleName":"babel/components/header/base/combo-button/button/index.hbs","parseOptions":{"srcName":"babel/components/header/base/combo-button/button/index.hbs"}});
import Component from '@glimmer/component';

export default class HeaderBaseComboButtonButton extends Component {
  get wide() {
    return this.args.wide ?? false;
  }

  get active() {
    return this.args.active ?? false;
  }
}
