const exponents = {
  /**
   * Get rid of all html elements representing exponents and replace with specific placeholders
   */
  replaceHtmlWithPlaceholders: (textProcessor) => {
    // Note! Important here to replace entire html element instead of just using replaceHtmlElementContent.
    // Otherwise we can't later add a word meta data element around both the base unit and the exponent since the html will be "broken" after being replaced/removed in several steps.
    // By replacing the entire html element in one step we avoid that problem.
    textProcessor.replaceHtmlElementByClass(
      {
        elementName: 'sup',
        className: 'tb-render-inline-element-superscript',
      },
      (elementMetaData) => {
        const { content } = elementMetaData;
        const replacement = `___sup_${content}___`;
        return replacement;
      }
    );

    textProcessor.replaceHtmlElementByClass(
      {
        elementName: 'sub',
        className: 'tb-render-inline-element-subscript',
      },
      (elementMetaData) => {
        const { content } = elementMetaData;
        const replacement = `___sub_${content}___`;
        return replacement;
      }
    );
  },

  /**
   * Replace the placeholders created by replaceHtmlWithPlaceholders() with plain text that depends on textual context
   */
  replacePlaceholdersWithText: (textProcessor, syntax) => {
    // Replace superscript placeholders
    textProcessor.replaceRegex(/(\w*?)___sup_(.*?)___/, (match) => {
      // console.log('** Matched pow() =', match);
      const base = match[1];
      const sup = match[2];

      let plainText = `${base} ${syntax.pause(200)} upphöjt till ${sup}`;

      return ` ${plainText}`;
    });

    // Replace subscript placeholders
    textProcessor.replaceRegex(/(\w*?)___sub_(.*?)___/, (match) => {
      const base = match[1];
      const sub = match[2];

      let plainText = `${base} ${syntax.pause(200)} nedsänkt till ${sub}`;

      return ` ${plainText}`;
    });
  },

  /**
   * Replace emoji symbols with empty string
   * Note: The regex may not 100% cover all current and future emojis
   *       but seems to cover all listed emojis on https://getemoji.com/
   */
  removeEmojis: (textProcessor) => {
    textProcessor.replaceRegex(
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/,
      () => ''
    );
  },
};

export default exponents;
