/////////////////////////////////////////////////////////////////////////////////////
// Note: This Polly specific rule set is empty right now... add functionality when needed

const ruleSet = (language) => {
  return {
    initialCleanUp: (textProcessor, syntax, { withPlaceholder }) => {},

    beforeHtmlRemoved: (textProcessor, syntax, { withPlaceholder }) => {},

    afterHtmlRemoved: (textProcessor, syntax, { withPlaceholder }) => {},

    finalCleanUp: (textProcessor, syntax, { withPlaceholder }) => {},
  };
};

export default ruleSet;
