import Service, { inject as service } from '@ember/service';
import { cached } from '@glimmer/tracking';
import { entityIsAllowedInCustomContent } from 'babel/utils/entity-is-allowed-in-custom-content';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class MissionModeService extends Service {
  // SERVICES

  @service session;

  @service contextHelper;

  @service courseContext;

  // PROPERTIES

  @cached get customContentEntityProxy() {
    let promise = resolve();

    if (this.contextHelper.activeCustomContent) {
      promise = resolve(this.contextHelper.activeCustomContent.entity);
    }

    return load(promise, this);
  }

  get customContentEntity() {
    return this.customContentEntityProxy.isResolved
      ? this.customContentEntityProxy.value
      : null;
  }

  @cached get includedEntitiesProxy() {
    let promise = resolve([]);

    if (
      this.customContentEntity &&
      this.contextHelper.activeContent &&
      this.customContentEntity === this.contextHelper.activeContent
    ) {
      promise = resolve(
        this.contextHelper.activeCustomContent.includedEntities
      ).then((includedEntities) => includedEntities.toArray());
    }

    return load(promise, this);
  }

  get includedEntities() {
    return this.includedEntitiesProxy.isResolved
      ? this.includedEntitiesProxy.value
      : [];
  }

  @cached get includedTeacherAssignmentsProxy() {
    let promise = resolve([]);

    if (
      this.customContentEntity &&
      this.contextHelper.activeContent &&
      this.customContentEntity === this.contextHelper.activeContent
    ) {
      promise = resolve(
        this.contextHelper.activeCustomContent.includedTeacherAssignments
      ).then((includedTeacherAssignments) =>
        includedTeacherAssignments.toArray()
      );
    }

    return load(promise, this);
  }

  get includedTeacherAssignments() {
    return this.includedTeacherAssignmentsProxy.isResolved
      ? this.includedTeacherAssignmentsProxy.value
      : [];
  }

  @cached get includedWordlistsProxy() {
    let promise = resolve([]);

    if (
      this.customContentEntity &&
      this.contextHelper.activeContent &&
      this.customContentEntity === this.contextHelper.activeContent
    ) {
      promise = resolve(
        this.contextHelper.activeCustomContent.includedWordlists
      ).then((includedWordlists) => includedWordlists.toArray());
    }

    return load(promise, this);
  }

  get includedWordlists() {
    return this.includedWordlistsProxy.isResolved
      ? this.includedWordlistsProxy.value
      : [];
  }

  // METHODS

  activate({ mission = null, customContent = null, closeUrl = null }) {
    this.contextHelper.setActive('activeMission', mission);
    this.contextHelper.setActive('activeCustomContent', customContent);
    this.contextHelper.setCloseUrl(closeUrl);

    this.courseContext.setCurrentCourse(mission.course);
  }

  deactivate() {
    this.contextHelper.setActive('activeMission', null);
    this.contextHelper.setActive('activeCustomContent', null);
    this.contextHelper.setCloseUrl(null);
  }

  addIncludedTeacherAssignmentIfNeeded(teacherAssignment) {
    const user = this.session.user;

    if (
      this.contextHelper.inMission &&
      this.contextHelper.activeCustomContent?.isUser(user) &&
      this.contextHelper.activeCustomContent?.populated &&
      teacherAssignment.isPublished
    ) {
      this.contextHelper.activeCustomContent.includedTeacherAssignments.addObject(
        teacherAssignment
      );

      return this.contextHelper.activeCustomContent.save();
    }

    return resolve();
  }

  addIncludedWordlistIfNeeded(wordlist) {
    const user = this.session.user;

    if (
      this.contextHelper.inMission &&
      this.contextHelper.activeCustomContent?.isUser(user) &&
      this.contextHelper.activeCustomContent?.populated &&
      wordlist.isPublished
    ) {
      this.contextHelper.activeCustomContent.includedWordlists.addObject(
        wordlist
      );

      return this.contextHelper.activeCustomContent.save();
    }

    return resolve();
  }

  teacherAssignmentIsAllowed(teacherAssignment) {
    if (this.contextHelper.inMission) {
      return (
        (this.contextHelper.activeCustomContent?.populated &&
          this.includedTeacherAssignments.length === 0) ||
        this.includedTeacherAssignments.includes(teacherAssignment)
      );
    }

    return true;
  }

  async allowedEntities(entities) {
    if (!Array.isArray(entities)) return entities;

    await resolve(this.contextHelper.activeCustomContent?.includedEntities);

    const allowed = await all(
      entities.map(async (entity) => {
        const parent = await resolve(entity.parent);

        if (
          parent?.isDiagnosis ||
          parent?.isCarousel ||
          this.entityIsAllowed(entity)
        ) {
          return entity;
        }

        return null;
      })
    );

    return allowed.filter((entity) => entity);
  }

  entityIsAllowed(entity) {
    const user = this.session.user;

    if (entity.isTeacherAssignment) {
      const teacherAssignment = entity.teacherAssignment;

      // NOTE The teacher owner should always have access to own unpublished teacher assignments.
      if (
        user.isTeacher &&
        teacherAssignment.isUser(user) &&
        !teacherAssignment.isPublished
      ) {
        return true;
      }
    }

    return entityIsAllowedInCustomContent(
      entity,
      this.contextHelper.activeCustomContent,
      this.contextHelper.inMission
    );
  }

  wordlistIsAllowed(wordlist) {
    if (wordlist.isTransformed) {
      return this.entityIsAllowed(wordlist);
    }

    const user = this.session.user;

    // NOTE A student should always have access to own wordlists.
    if (user.isStudent && wordlist.isUser(user)) {
      return true;
    }

    // NOTE The teacher owner should always have access to own unpublished wordlists.
    if (user.isTeacher && wordlist.isUser(user) && !wordlist.isPublished) {
      return true;
    }

    const entries = this.includedWordlists;

    if (!this.contextHelper.inMission || !entries) {
      return true;
    }

    if (
      !this.contextHelper.activeCustomContent?.populated &&
      entries.length === 0
    ) {
      return true;
    }

    return entries.mapBy('id').includes(wordlist.id);
  }
}
