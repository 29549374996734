import EmberObject from '@ember/object';
import DS from 'ember-data';

import Model from './model';

const { attr } = DS;

export default Model.extend({
  service: attr('string'),

  identifier: attr('string'),

  metadata: attr('object', {
    defaultValue() {
      return EmberObject.create({ sortedCustomContentIds: [] });
    },
    camelizeDeserializedKeys: true,
  }),
});
