import { action, get } from '@ember/object';
import Service from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class ColorThemeService extends Service {
  @storageFor('read-setting') settings;

  get currentTheme() {
    return this.settings.get('theme');
  }

  @action changeTheme(theme) {
    this.removeTheme();

    get(this, 'settings').set('theme', theme);

    if (theme) {
      const themeClass = ['theme', theme].join('-');
      document.body.classList.add(themeClass);
    }
  }

  @action removeTheme() {
    const body = document.body;
    const currentThemes = [...body.classList].filter((item) =>
      /^theme-\w+$/.test(item)
    );

    currentThemes.forEach((theme) => {
      body.classList.remove(theme);
    });
  }
}
