import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterContentsNotesNewRoute extends Route {
  @service session;

  @service store;

  model() {
    const user = this.session.user;

    const { model: content, book } = this.modelFor('master.contents');

    return this.store.createRecord('note', {
      user,
      books: [book],
      contents: [content],
    });
  }

  resetController(controller) {
    controller.model?.rollback();
  }
}
