import Component from '@glimmer/component';

export default class ContentPaginatinSummaryComponent extends Component {
  get summaryMode() {
    return this.args.summaryMode ?? 'grid';
  }

  get showContentMissionNextButton() {
    if (!this.args.nextMissionContent) return false;
    return (
      (!this.args.showNextButton && !this.args.showDoneButton) ||
      this.args.showSummary
    );
  }
}
