import { notifyPropertyChange } from '@ember/object';

export default async function refreshRouteAndNotifyExerciseChange(
  router,
  contextHelper,
  route
) {
  await router.refresh(route);

  const children = await contextHelper.activeContent.children;
  const collections = children.filter((child) => child.type === 'collections');

  collections.forEach((collection) =>
    notifyPropertyChange(collection, 'children')
  );
}
