import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import getCollectionData from 'babel/utils/get-collection-data';
import { all } from 'rsvp';

export default class MasterInteractivesDiagnosesDoneRoute extends Route {
  @service router;

  @service session;

  @service store;

  async model({ result_id }) {
    const attempt = await this.store.findRecord('interactive', result_id);
    const [collection] = await all([attempt.entity, attempt.entityProxy]);

    const attempts = await this.store.query('interactive', {
      filter: {
        node_id: collection.id,
        user_id: this.session.user.id,
        all: true,
        type: 'diagnosis_part',
      },
    });

    const answers = await this.store.query('answer', {
      filter: {
        id: attempt.hasMany('answers').ids(),
      },
    });

    if (attempts.findBy('isActive')) {
      return this.router.replaceWith('master.interactives.index');
    }

    const {
      parent: interactive,
      exercises,
      book,
      editor,
    } = await getCollectionData(this.store, collection);

    return {
      attempt,
      book,
      interactive,
      collection,
      exercises,
      answers,
      editor,
    };
  }
}
