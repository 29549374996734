import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { all, hash } from 'rsvp';

export default class CoursesRoute extends Route {
  @service store;

  model() {
    return hash({
      teacherAssignments: this.store
        .findAll('teacher-assignment')
        .then((teacherAssignments) =>
          all(
            teacherAssignments.map((teacherAssignment) =>
              all([
                teacherAssignment.get('books'),
                teacherAssignment.get('contents'),
              ])
            )
          ).then(() => teacherAssignments)
        ),
      wordlists: this.store
        .findAll('wordlist')
        .then((wordlists) =>
          all(
            wordlists.map((wordlist) =>
              all([wordlist.get('books'), wordlist.get('contents')])
            )
          ).then(() => wordlists)
        ),
    });
  }
}
