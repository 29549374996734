import ApplicationAdapter from 'babel/adapters/application';
import config from 'babel/config/environment';

export default class GroupAdapter extends ApplicationAdapter {
  host = config.userApiEndpoint;

  namespace = 'api';

  shouldBackgroundReloadRecord() {
    return true;
  }

  shouldBackgroundReloadAll() {
    return true;
  }
}
