import Route from '@ember/routing/route';

export default class MasterContentsWordlistsShowIndexRoute extends Route {
  queryParams = {
    quiz: {
      refreshModel: true,
    },
  };

  model({ quiz }) {
    const wordlist = this.modelFor('master.contents.wordlists.show');
    return { wordlist, quiz };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.quiz = null;
    }
  }
}
