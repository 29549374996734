import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-center hover-highlight\">\n  <UiButton\n    class=\"p1 flex-auto\"\n    @appearance=\"link\"\n    @variant=\"inherit\"\n    @onClick={{fn @openAuditMode (hash\n      collection=(or @collection @entity.parent)\n      exercise=@entity\n      answers=@answers\n      student=null\n      mode=\"students\"\n    )}}\n  >\n    <AnswerStatusIcon @status={{this.status}} class=\"nr1\" />\n\n    <div class=\"text-ui{{if (not-eq this.status \"not-started\") \" text-color-default\"}}\">\n      {{or @index @entity.sort}}. {{@entity.title}}\n    </div>\n  </UiButton>\n</div>\n", {"contents":"<div class=\"flex flex-center hover-highlight\">\n  <UiButton\n    class=\"p1 flex-auto\"\n    @appearance=\"link\"\n    @variant=\"inherit\"\n    @onClick={{fn @openAuditMode (hash\n      collection=(or @collection @entity.parent)\n      exercise=@entity\n      answers=@answers\n      student=null\n      mode=\"students\"\n    )}}\n  >\n    <AnswerStatusIcon @status={{this.status}} class=\"nr1\" />\n\n    <div class=\"text-ui{{if (not-eq this.status \"not-started\") \" text-color-default\"}}\">\n      {{or @index @entity.sort}}. {{@entity.title}}\n    </div>\n  </UiButton>\n</div>\n","moduleName":"babel/components/course-audit/standard/exercise-single/index.hbs","parseOptions":{"srcName":"babel/components/course-audit/standard/exercise-single/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { getLatestVersion } from 'babel/utils/get-answer';

export default class CourseAuditStandardExerciseSingleComponent extends Component {
  /**
   * Arguments:
   * entity (object)
   * index (number)
   * collection (object)
   * answers (array)
   * openAuditMode (function)
   */

  @cached get answer() {
    return getLatestVersion(this.args.entity, this.args.answers);
  }

  get status() {
    return this.answer?.computedStatus || 'not-started';
  }
}
