import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CollectionTopbarComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * title (string)
   * exercises (array)
   * activeExercise (object)
   * onExerciseChange (function)
   * hasPagination (bool)
   */

  @service media;

  get activePage() {
    const index = this.args.exercises?.indexOf(this.args.activeExercise);

    if (index >= 0) {
      return index + 1;
    }

    return 1;
  }

  get numExercises() {
    return this.args.exercises?.length;
  }

  get hasPagination() {
    return this.args.hasPagination && this.numExercises >= 2;
  }

  get hasPopupIndex() {
    return this.hasPagination;
  }

  @action handleIndexGoTo(index) {
    this.args.onExerciseChange?.(index);
  }
}
