import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import copyToClipboard from 'babel/utils/copy-to-clipboard';

export default class ShareMetaComponent extends Component {
  @service intl;

  @service snackbar;

  @tracked shareModalOpen = false;

  onOpen() {}

  onClose() {}

  get shareMetaItems() {
    const returnArray = [
      {
        icon: 'link',
        value: 'copyLink',
        label: this.intl.t('components.shareMeta.menu.copyLink'),
      },
      {
        icon: 'share',
        value: 'share',
        label: this.intl.t('components.shareMeta.menu.share'),
      },
    ];

    return returnArray;
  }

  get currentUrl() {
    return window.location.href;
  }

  @action handleOpenInspector(value) {
    if (value === 'copyLink') {
      copyToClipboard(this.currentUrl)
        .then(() => {
          this.snackbar.enqueue(
            this.intl.t('components.shareMeta.locationCopiedToClipboard'),
            {
              variant: 'success',
              autoDismiss: true,
            }
          );
        })
        .catch(() => {
          this.snackbar.enqueue(
            this.intl.t('components.shareMeta.locationFailedToCopyToClipboard'),
            {
              variant: 'error',
            }
          );
        });
    } else {
      this.shareModalOpen = true;
    }
  }
}
