import { attr, belongsTo } from '@ember-data/model';

import Model from './model';

export default Model.extend({
  // ATTRIBUTES

  type: attr('string'),

  comment: attr('string'),

  public: attr('boolean'),

  version: attr('number'),

  metadata: attr('object', {
    defaultValue() {
      return {};
    },
  }),

  answer: belongsTo('answer', {
    async: true,
    inverse: 'comments',
  }),

  user: belongsTo('user', {
    async: true,
    inverse: null,
  }),

  assignment: belongsTo('entity', {
    async: true,
    inverse: null,
  }),
});
