import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { resolve } from 'rsvp';

export default class WorkspaceWordlistsWordsEditComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * onClose (function)
   */

  @service router;

  @service wordlistDispatcher;

  @service missionMode;

  get wordlist() {
    return this.args.model;
  }

  @action openIndex() {
    return this.router.transitionTo('master.contents.wordlists.index');
  }

  @action handleWordlistDetailSave(publish) {
    if (this.wordlist.validate()) {
      const isNew = this.wordlist.isNew;

      return this.wordlistDispatcher
        .save(this.wordlist, publish)
        .then(() => {
          if (isNew) {
            return this.missionMode.addIncludedWordlistIfNeeded(this.wordlist);
          }

          return resolve();
        })
        .then(() =>
          this.router.transitionTo(
            'master.contents.wordlists.show',
            this.wordlist.id
          )
        )
        .then(() => this.wordlistDispatcher.highlight(this.wordlist));
    }
  }

  @action handleWordlistDetailDelete() {
    return this.wordlistDispatcher
      .delete(this.wordlist)
      .then(() => this.router.transitionTo('master.contents.wordlists.index'));
  }
}
