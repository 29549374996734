/**
 * This is a work in progress with the idea of refactoring
 * the contents controller in steps. Please move other parts
 * of the controller here when refactoring.
 */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class ContentsController extends Controller {
  // SERVICES

  @service assignmentEvents;

  @service session;

  @service contextHelper;

  @service missionMode;

  // PROPERTIES

  @tracked notes = [];

  @tracked wordlists = [];

  @tracked activeExerciseId = null;

  // NOTES

  get restrictedNotes() {
    return this.notes.rejectBy('isDeleted');
  }

  get sortedNotes() {
    return this.restrictedNotes.sortBy('created');
  }

  get bookNotes() {
    return this.sortedNotes.filter((note) =>
      note.bookIds.includes(this.bookId)
    );
  }

  get contentNotes() {
    return this.sortedNotes.filter((note) =>
      note.contentIds.includes(this.contentId)
    );
  }

  get currentNotes() {
    return this.contentNotes;
  }

  get relatedNotes() {
    return this.bookNotes.filter((note) => !this.contentNotes.includes(note));
  }

  // WORDLISTS

  get restrictedWordlists() {
    return this.wordlists
      .rejectBy('isDeleted')
      .filterBy('isLoaded')
      .filter((wordlist) => this.missionMode.wordlistIsAllowed(wordlist))
      .filter((wordlist) => {
        const activeCourse = this.contextHelper.activeCourse;

        if (wordlist.isOwner || wordlist.isTransformed) {
          return true;
        } else if (activeCourse) {
          return activeCourse
            .hasMany('teachers')
            .ids()
            .includes(wordlist.userId);
        } else {
          return false;
        }
      });
  }

  get sortedWordlists() {
    const contentWordlists = this.restrictedWordlists
      ?.filter((l) => l?.entity)
      ?.sort((a, b) => (a?.entity?.sort > b?.entity?.sort ? 1 : -1));

    const teacherWordlists = this.restrictedWordlists
      ?.filter((l) => l?.user?.get('isTeacher'))
      ?.sort((a, b) => a?.title?.localeCompare(b?.title));

    const studentWordlists = this.restrictedWordlists
      ?.filter((l) => l?.user?.get('isStudent'))
      ?.sort((a, b) => a?.title?.localeCompare(b?.title));

    const privateUserWordlists = this.restrictedWordlists
      ?.filter((l) => l?.user?.get('isPrivateUser'))
      ?.sort((a, b) => a?.title?.localeCompare(b?.title));

    return [
      ...contentWordlists,
      ...teacherWordlists,
      ...studentWordlists,
      ...privateUserWordlists,
    ];
  }

  get bookWordlists() {
    return this.sortedWordlists.filter((wordlist) =>
      wordlist.bookIds.includes(this.bookId)
    );
  }

  get contentWordlists() {
    return this.sortedWordlists.filter((wordlist) =>
      wordlist.contentIds.includes(this.contentId)
    );
  }

  get categorizedWordlists() {
    return this.contentWordlists.filter((wordlist) => {
      if (wordlist.category) {
        return wordlist.category === this.activeCategory;
      }

      return true;
    });
  }

  get currentWordlists() {
    return this.categorizedWordlists;
  }

  get coverWordlists() {
    const categorizedWordlists =
      this.categorizedWordlists.filterBy('isPublished');

    const user = this.session.user;

    if (user.isStudent) {
      return categorizedWordlists.rejectBy('userId', user.id);
    }

    return categorizedWordlists;
  }

  get relatedWordlists() {
    return this.bookWordlists
      .reject((wordlist) => this.categorizedWordlists.includes(wordlist))
      .filterBy('userId', this.session.user.id);
  }

  // EXERCISE

  @cached
  get exercisesProxy() {
    const promise = resolve(this.activeSection?.children).then((children) =>
      this.missionMode.allowedEntities(children)
    );

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : null;
  }

  @cached
  get interactiveProxy() {
    const activeExercise = this.exercises?.findBy('id', this.activeExerciseId);

    const promise = this.assignmentEvents.getOrCreateInteractiveForCollection(
      this.activeSection,
      activeExercise
    );

    return load(promise, this);
  }

  get answers() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.interactive?.answers
      : [];
  }

  get activeExercise() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.activeExercise
      : null;
  }

  get activeAnswer() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.activeAnswer
      : null;
  }

  // CATEGORIES

  get categories() {
    return this.restrictedSections
      ?.mapBy('category')
      ?.compact()
      ?.uniqBy('id')
      ?.sortBy('sort');
  }

  get unrestrictedCategories() {
    return this.sections
      ?.mapBy('category')
      ?.compact()
      ?.uniqBy('id')
      ?.sortBy('sort');
  }

  get preferredCategory() {
    const preferredId = this.session.user?.getPreferredTextVersion(this.bookId);

    if (preferredId) {
      const category = this.categories?.findBy('id', preferredId);
      if (category) return category;
    }

    return null;
  }

  get activeCategory() {
    const activeSectionCategoryId = this.activeSection?.belongsTo('cat')?.id();

    if (activeSectionCategoryId) {
      const categories = this.contextHelper.inMission
        ? this.unrestrictedCategories
        : this.categories;

      const category = categories?.findBy('id', activeSectionCategoryId);
      if (category) return category;
    }

    return this.preferredCategory ?? this.categories?.firstObject;
  }

  // SECTIONS

  get categorizedSections() {
    return this.restrictedSections.filterBy(
      'category.id',
      this.activeCategory?.id
    );
  }

  // COLLECTIONS

  get categorizedCollections() {
    return this.sortedCollections.filter((collection) => {
      if (collection.isTeacherCollection) {
        return true;
      }

      if (!collection.category) {
        return true;
      }

      if (this.unrestrictedCategories.length === 1) {
        return collection.category.id === this.unrestrictedCategories[0].id;
      }

      return collection.category.id === this.activeCategory?.id;
    });
  }

  get coverCollections() {
    let categorizedCollections = this.categorizedCollections.reject(
      (collection) => collection.children?.isEvery('isPublished', false)
    );

    const user = this.session.user;

    if (user?.isStudent) {
      return categorizedCollections.rejectBy('userId', user.id);
    }

    return categorizedCollections;
  }

  get isEverythingHidden() {
    return (
      this.categorizedSections.length === 0 &&
      this.categorizedCollections.length === 0 &&
      this.categorizedWordlists.length === 0
    );
  }
}
