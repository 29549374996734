import Component from '@ember/component';
import { computed } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { flatten } from 'compton/utils/array';
import DS from 'ember-data';
import { all } from 'rsvp';

const { PromiseArray } = DS;

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}

export default Component.extend({
  intl: service(),

  value: null,

  groups: null,

  disabled: false,

  missionMode: false,

  canSelectAll: true,

  tagName: '',

  groupsUsers: mapBy('groups', 'users'),

  students: computed('groupsUsers.@each.{role,showname}', function () {
    const promise = all(this.get('groupsUsers')).then((t) => {
      return flatten(t.invoke('toArray'))
        .uniq()
        .filterBy('role', 'student')
        .sortBy('showname');
    });

    return createPromiseArray(promise);
  }),

  items: computed('students.@each', 'canSelectAll', 'missionMode', function () {
    const options = [];

    if (this.get('canSelectAll')) {
      if (this.get('missionMode')) {
        options.addObject({
          label: this.get('intl').t(
            'components.studentSelect.allStudentsInMission'
          ),
          value: null,
        });
      } else {
        options.addObject({
          label: this.get('intl').t('components.studentSelect.allStudents'),
          value: null,
        });
      }
    }

    this.get('students').forEach((user) => {
      options.addObject({
        label: user.get('showname'),
        value: user.get('id'),
        meta: { user },
        metadata: user.get('metadata'),
        isTeacher: user.get('isTeacher'),
        initials: user.get('initials'),
      });
    });

    return options;
  }),
});
