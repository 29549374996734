import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';

export default class ContentsChapterScrollComponent extends Component {
  /**
   * Arguments:
   * section (object)
   * sections (array)
   * activeExercise (object)
   * editor (object)
   * openExercise (function)
   * answers (array)
   * activeAnswer (object)
   */

  @service('footnotes') footnotesService;

  constructor() {
    super(...arguments);

    this.footnotesService.createIndexContext(this.args.sections);
  }

  @cached get sectionProxy() {
    return load(this.args.section, this);
  }

  get heading() {
    const section = this.sectionProxy.isResolved
      ? this.sectionProxy.value
      : null;

    if (section?.body?.hide_heading) return null;

    return section ? section.heading : null;
  }
}
