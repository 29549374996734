import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class\n    \"teacher-assignment-picker-item\"\n    (if this.isDisabled \"teacher-assignment-picker-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @teacherAssignment}}\n  >\n\n    {{@teacherAssignment.titleWithFallback}}\n\n  </XCheckbox>\n\n  {{#unless @teacherAssignment.isPublished}}\n\n    <XBadge class=\"ml1\" @size=\"small\">\n      {{t \"components.teacherAssignmentBrowser.item.draftBadgeLabel\"}}\n    </XBadge>\n\n  {{/unless}}\n</label>", {"contents":"<label\n  class={{class\n    \"teacher-assignment-picker-item\"\n    (if this.isDisabled \"teacher-assignment-picker-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @teacherAssignment}}\n  >\n\n    {{@teacherAssignment.titleWithFallback}}\n\n  </XCheckbox>\n\n  {{#unless @teacherAssignment.isPublished}}\n\n    <XBadge class=\"ml1\" @size=\"small\">\n      {{t \"components.teacherAssignmentBrowser.item.draftBadgeLabel\"}}\n    </XBadge>\n\n  {{/unless}}\n</label>","moduleName":"babel/components/teacher-assignment-picker/item/index.hbs","parseOptions":{"srcName":"babel/components/teacher-assignment-picker/item/index.hbs"}});
import Component from '@glimmer/component';

/*

import TeacherAssignmentModel from 'babel/models/teacher-assignment';

export interface TeacherAssignmentPickerItemComponentArgs {
  teacherAssignment: TeacherAssignmentModel;
  pickedTeacherAssignments: TeacherAssignmentModel[];
  disabledTeacherAssignments: TeacherAssignmentModel[];
  onPick(teacherAssignment: TeacherAssignmentModel): void;
  onUnpick(teacherAssignment: TeacherAssignmentModel): void;
}

*/

export default class TeacherAssignmentPickerItemComponent extends Component {
  get isPicked() {
    return this.args.pickedTeacherAssignments.includes(
      this.args.teacherAssignment
    );
  }

  get isDisabled() {
    const disabledTeacherAssignments = this.args.disabledTeacherAssignments;

    return (
      disabledTeacherAssignments &&
      disabledTeacherAssignments.includes(this.args.teacherAssignment)
    );
  }
}
