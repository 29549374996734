import { assert } from '@ember/debug';
import { modifier } from 'ember-modifier';

function collectionIndex(element, [collection]) {
  assert('You must pass a collection', collection);

  const id = element.getAttribute('data-id');

  if (id !== collection.id) {
    if (id) element.parentNode.scrollTo(0, 0);

    element.setAttribute('data-id', collection.id);
  }
}

export default modifier(collectionIndex, { eager: false });
