import { service } from '@ember/service';
import EntityContextRoute from 'babel/routes/entity-context-route';
import { MissingContentError } from 'babel/utils/errors';
import getCollectionData from 'babel/utils/get-collection-data';
import { reject } from 'rsvp';

export default class MasterInteractivesDiagnosesRoute extends EntityContextRoute {
  @service contextHelper;

  @service pageviews;

  @service store;

  async model({ diagnosis_id }, transition) {
    let collection;

    try {
      collection = await this.store.findRecord('entity', diagnosis_id);
    } catch (err) {
      return reject(
        new MissingContentError(err.message, 'master.interactives')
      );
    }

    const { parent: interactive, book } = await getCollectionData(
      this.store,
      collection
    );

    return {
      collection,
      interactive,
      book,
      closeUrl: transition?.to?.queryParams?.close_url,
    };
  }

  afterModel(model) {
    this.pageviews.logPageView(model.collection, model.book, model.interactive);
  }

  setupController(controller, model) {
    super.setupController(controller, {
      model: model.collection,
      closeUrl: model.closeUrl ?? this.contextHelper.closeUrl,
    });

    controller.model = model;
  }
}
