import { underscore } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelizeKeys } from 'compton/utils/object';

export default class ApplicationSerializer extends JSONAPISerializer {
  serializeAttribute(snapshot, json, key, attribute) {
    // do not serialize the attribute!
    if (attribute.options && attribute.options.readOnly) {
      return;
    }

    return super.serializeAttribute(...arguments);
  }

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(attr) {
    return underscore(attr);
  }

  normalizeArrayResponse() {
    const response = super.normalizeArrayResponse(...arguments);

    if (response.meta) {
      // NOTE Just do an assign as we want to keep the old
      // underscored keys for backward compibitlity.
      Object.assign(response.meta, camelizeKeys(response.meta));
    }

    return response;
  }
}
