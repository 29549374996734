import { action } from '@ember/object';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderPlaceholderComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-placeholder';

  @action forceMedia() {
    this.args.part.mediaForced = true;
  }
}
