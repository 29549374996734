import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"table-of-contents p1\">\n  <TableOfContentsList\n    @activeSubHeading={{@activeSubHeading}}\n    @categorizedSections={{this.categorizedSections}}\n    @categorizedCollections={{this.categorizedCollections}}\n    @wordlists={{this.categorizedWordlists}}\n    @activeCategory={{@activeCategory}}\n    @routeOnSubHeadingClick={{@routeOnSubHeadingClick}}\n    @goToSection={{@goToSection}}\n    @isInteractive={{@isInteractive}}\n    @closeIndex={{@closeIndex}}\n  />\n</div>", {"contents":"<div class=\"table-of-contents p1\">\n  <TableOfContentsList\n    @activeSubHeading={{@activeSubHeading}}\n    @categorizedSections={{this.categorizedSections}}\n    @categorizedCollections={{this.categorizedCollections}}\n    @wordlists={{this.categorizedWordlists}}\n    @activeCategory={{@activeCategory}}\n    @routeOnSubHeadingClick={{@routeOnSubHeadingClick}}\n    @goToSection={{@goToSection}}\n    @isInteractive={{@isInteractive}}\n    @closeIndex={{@closeIndex}}\n  />\n</div>","moduleName":"babel/components/table-of-contents/index.hbs","parseOptions":{"srcName":"babel/components/table-of-contents/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TableOfContentsComponent extends Component {
  /**
   * Arguments:
   * activeCategory
   * sections
   * collections
   * wordlists
   * expandEntity
   * activeSubHeading
   * routeOnSubHeadingClick
   * closeIndex
   */

  @service session;

  get categorizedSections() {
    if (this.args.activeCategory) {
      return this.args.sections?.filterBy(
        'category.id',
        this.args.activeCategory?.id
      );
    } else {
      return this.args.sections;
    }
  }

  get categorizedWordlists() {
    if (!this.args.wordlists) {
      return null;
    }
    return this.args.wordlists?.filter((wordlist) => {
      if (!wordlist?.category) {
        return true;
      }
      if (wordlist?.category?.id === this.args.activeCategory?.id) {
        return true;
      }
      return false;
    });
  }

  get categorizedCollections() {
    if (!this.args.collections) {
      return null;
    }
    let filteredCollections = this.args.collections?.filter((collection) => {
      if (collection?.category?.id === this.args.activeCategory?.id) {
        return true;
      }
      if (collection.isTeacherCollection) {
        return true;
      }
      return false;
    });
    filteredCollections = filteredCollections.reject((collection) =>
      collection.get('children').isEvery('isPublished', false)
    );

    const sorted = filteredCollections.sort((a, b) => {
      if (a.isTeacherCollection) {
        if (b.isTeacherCollection) {
          if (a.title) {
            if (b.title) {
              return a.title.localeCompare(b.title);
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      } else {
        if (b.isTeacherCollection) {
          return 1;
        } else {
          return a.sort > b.sort ? 1 : -1;
        }
      }
    });

    if (this.session?.user?.isStudent) {
      return sorted.rejectBy('userId', this.session?.user?.id);
    }

    return sorted;
  }
}
