import Component from '@glimmer/component';

export default class TBRenderInlineElementFormatTextColorComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */

  get color() {
    return this.args.node?.attributes?.findBy('key', 'data-text-color')?.value;
  }
}
