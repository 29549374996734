import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  courseContext: service(),

  store: service(),

  entities: null,

  pageViews: null,

  course: null,

  isLoaded: false,

  limit: 3,

  didReceiveAttrs() {
    this._super(...arguments);

    const productViews = this.get('pageViews');

    if (productViews) {
      this.set('entities', []);
      productViews.forEach((item, index) => {
        this.store
          .findRecord('entity', item.attributes.entity_id)
          .then((entity) => {
            // DINO-3717: First item is already shown as continue visited
            if (index !== 0) {
              this.get('entities').pushObject({
                id: item.attributes.entity_id,
                title: item.attributes.title,
                selectedMaterial: entity.selectedMaterial?.content,
              });
            }
          });
      });

      this.set('isLoaded', true);
    } else {
      let courseIds = this.get('courseContext').getVisitedContentIds(
        this.get('course.id')
      );

      this.set('entities', []);

      if (courseIds && courseIds.length > 0) {
        courseIds = courseIds.slice(0, this.get('limit'));
        courseIds.forEach((id) => {
          this.get('store')
            .findRecord('entity', id)
            .then((entity) => {
              this.get('entities').pushObject(entity);
              this.set('isLoaded', true);
            });
        });
      }
    }
  },
});
