import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DcIcon\n  @size=\"lg\"\n  @name={{this.iconName}}\n  id={{this.id}}\n  class={{this.classNames}}\n  data-test-answer-status-icon\n  ...attributes\n/>\n\n{{~trim~}}\n\n<XTooltip @for={{this.id}}>{{this.statusText}}</XTooltip>\n\n{{~trim~}}\n", {"contents":"<DcIcon\n  @size=\"lg\"\n  @name={{this.iconName}}\n  id={{this.id}}\n  class={{this.classNames}}\n  data-test-answer-status-icon\n  ...attributes\n/>\n\n{{~trim~}}\n\n<XTooltip @for={{this.id}}>{{this.statusText}}</XTooltip>\n\n{{~trim~}}\n","moduleName":"babel/components/answer-status-icon/index.hbs","parseOptions":{"srcName":"babel/components/answer-status-icon/index.hbs"}});
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';
import generateId from 'compton/utils/generate-id';

export default class AnswerStatusIconComponent extends Component {
  /**
   * Arguments:
   * status (string)
   */

  @service intl;

  get id() {
    return generateId('answer-status-icon');
  }

  get iconName() {
    return `assignment-${this.args.status}`;
  }

  get classNames() {
    return `answer-status-icon status-icon status-icon--${this.args.status}`;
  }

  get statusText() {
    return this.intl.t(
      `components.answerStatusIcon.exercise.status.${camelize(
        this.args.status
      )}`
    );
  }
}
