import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderHeadingComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-heading';

  get extraClasses() {
    const part = this.args.part;
    const classes = [];

    if (part?.settings?.alignment) {
      classes.push(`${this.baseClass}--${part.settings.alignment}`);
    }

    return classes;
  }

  get size() {
    return this.args.part?.settings?.size;
  }

  get isH3() {
    return this.size === 'large';
  }

  get isH4() {
    return this.size === 'medium';
  }

  get isH5() {
    return this.size === 'small';
  }
}
