import { computed, get } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from 'babel/models/model';
import moment from 'moment';

export default class AnswerModel extends Model {
  @service intl;

  // ATTRIBUTES

  @attr('array', {
    defaultValue() {
      return [];
    },
  })
  assignments;

  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  created;

  @attr('string') document_id;

  @attr('string') assignment_title;

  @attr('string', { defaultValue: 'not-started' }) status;

  // assignment, study, exercise, survey are automaticly set
  // diagnosis_part, quiz are set from outside
  @attr('string') type;

  @attr('number', { defaultValue: null }) version;

  get node_id() {
    // eslint-disable-next-line ember/no-get
    return get(this, 'entity.id');
  }

  get teacher_assignment_id() {
    // eslint-disable-next-line ember/no-get
    return get(this, 'teacher-assignment.id');
  }

  get computedStatus() {
    // eslint-disable-next-line ember/no-get
    if (get(this, 'entity.type') === 'studies' && this.status === 'turned-in') {
      return 'done';
    } else if (this.status) {
      return this.status;
    }

    return 'not-started';
  }

  get createdDate() {
    let date = this.updated,
      createdDate;

    if (!date) {
      date = this.created;
    }

    if (date) {
      let locale = this.intl.locale;

      if (!locale || locale.length !== 1) {
        locale = 'sv';
      } else {
        locale = locale[0];
      }

      moment.locale(locale);

      createdDate = moment(date)
        .locale(locale)
        .calendar(null, {
          sameDay: `[${this.intl.t('models.answer.calendar.today')}]`,
          lastDay: `[${this.intl.t('models.answer.calendar.yesterday')}]`,
          lastWeek: `[${this.intl.t('models.answer.calendar.lastWeek')}]`,
          sameElse: 'LL',
        });
    }

    return createdDate || this.intl.t('models.answer.unknownDate');
  }

  // OBSERVERS

  @computed('assignments.@each.correct')
  get correct() {
    return this.assignments.isEvery('correct');
  }

  @computed('assignments.@each.score')
  get score() {
    return this.assignments.mapBy('score').reduce((a, b) => a + b, 0);
  }

  @computed('assignments.@each.scoreMax')
  get scoreMax() {
    return this.assignments.mapBy('scoreMax').reduce((a, b) => a + b, 0);
  }

  // RELATIONS

  @hasMany('file', { async: true, inverse: null }) files;

  @hasMany('answer-comment', { async: true, inverse: 'answer' }) comments;

  @belongsTo('entity', {
    async: true,
    inverse: null,
  })
  entity;

  @belongsTo('entity', {
    async: true,
    inverse: null,
  })
  content;

  @belongsTo('answer', {
    async: true,
    inverse: null,
  })
  originalAnswer;

  @belongsTo('entity', {
    async: true,
    inverse: null,
  })
  area;

  @belongsTo('entity', {
    async: true,
    inverse: null,
  })
  collection;

  @belongsTo('entity', {
    async: true,
    inverse: null,
  })
  book;

  @belongsTo('teacher-assignment', {
    async: true,
    inverse: null,
  })
  teacherAssignment;

  @belongsTo('user', {
    async: true,
    inverse: null,
  })
  user;

  @hasMany('answer', {
    async: true,
    inverse: null,
  })
  versions;

  commentSortDefinition = ['created:desc'];
  @sort('comments', 'commentSortDefinition') sortedComments;
}
