import { service } from '@ember/service';
import ApplicationAdapter from 'babel/adapters/application';
import config from 'babel/config/environment';

export default class UserAdapter extends ApplicationAdapter {
  host = config.userApiEndpoint;

  namespace = 'api';

  @service('ajax') ajaxService;

  queryRecord(modelName, query, options) {
    if (options.me === true) {
      return this.ajaxService.request(
        `${config.userApiEndpoint}/api/users/me`,
        true,
        {
          type: 'GET',
        }
      );
    } else {
      return super.queryRecord(...arguments);
    }
  }
}
