import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class AttachmentPreviewExternalComponent extends Component {
  @tracked externalLinkDialogIsOpen = false;

  get iconName() {
    let type;
    switch (this.args.file?.type) {
      case 'video':
        return 'play-square';
      case 'audio':
        return 'audio';
      case 'external':
        type = this.args.file?.externalType;
        if (type === 'google-docs') {
          return 'google';
        }
        if (type === 'office365') {
          return 'office';
        }
        if (type === 'youtube' || type === 'vimeo') {
          return 'play-square';
        }
        return 'link';
      default:
        return 'content';
    }
  }
}
