import Component from '@ember/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import { scrollIntoViewIfNeeded } from 'compton/utils/dom';

export default Component.extend({
  // SETUP

  noteDispatcher: service(),
  classNames: ['note-browser-list-item'],

  classNameBindings: ['isHighlighted:note-browser-list-item--highlighted'],

  // PARAMS

  note: null,

  highlightedNote: null,

  showRouteName: null,

  performMenuButtonItems: null,

  onPerform: null,

  // PROPERTIES

  isHighlighted: computed(
    'noteDispatcher.highlightedNotes.[]',
    'note',
    function () {
      return this.noteDispatcher.highlightedNotes.includes(this.note);
    }
  ),

  // HOOKS

  didRender() {
    this._super(...arguments);
    if (this.isHighlighted) {
      scrollIntoViewIfNeeded(this.element);
    }
  },
});
