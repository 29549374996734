import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class\n    \"file-picker-list-item\"\n    (if this.isDisabled \"file-picker-list-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @file}}\n  >\n\n    {{@file.titleWithFallback}}\n\n  </XCheckbox>\n\n</label>", {"contents":"<label\n  class={{class\n    \"file-picker-list-item\"\n    (if this.isDisabled \"file-picker-list-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @file}}\n  >\n\n    {{@file.titleWithFallback}}\n\n  </XCheckbox>\n\n</label>","moduleName":"babel/components/file-picker/list/item/index.hbs","parseOptions":{"srcName":"babel/components/file-picker/list/item/index.hbs"}});
import Component from '@glimmer/component';

/*

import FileModel from 'babel/models/file';

export interface FilePickerGridItemComponentArgs {
  file: FileModel;
  pickedFiles: FileModel[];
  disabledFiles: FileModel[];
  onPick(file: FileModel): void;
}

*/

export default class FilePickerGridItemComponent extends Component {
  get isPicked() {
    return this.args.pickedFiles.includes(this.args.file);
  }

  get isDisabled() {
    const disabledFiles = this.args.disabledFiles;

    return disabledFiles && disabledFiles.includes(this.args.file);
  }
}
