import IltDataParser from './IltDataParser';

class IltMockDataParser {
  constructor({ sentenceGroupId }) {
    this._sentenceGroupId = sentenceGroupId;

    // Wrap the "real" data parser to use it's data parsing algorithm
    this._dataParser = new IltDataParser();
  }

  getAudioSources(articles, sentenceGroupId, textBlockHtml) {
    // Ignore the sentenceGroupId param since that will not exist in our mock article data.
    // Instead use the group IDs injected to the constructor, and call the actual data parsing algorithm with that value
    return this._dataParser.getAudioSources(
      articles,
      this._sentenceGroupId,
      textBlockHtml
    );
  }

  isArticleExpired(article) {
    return false;
  }
}

export default IltMockDataParser;
