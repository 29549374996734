import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class=\"course-choose-list-item\"\n  data-test-course-choose-list-item\n  ...attributes\n>\n  <XCheckbox\n    data-test-course-choose-list-item-checkbox\n    @checked={{this.isSelected}}\n    @twoWay={{false}}\n    @onChange={{this.handleCheckboxChange}}\n  >\n    {{@course.name}}\n  </XCheckbox>\n</label>", {"contents":"<label\n  class=\"course-choose-list-item\"\n  data-test-course-choose-list-item\n  ...attributes\n>\n  <XCheckbox\n    data-test-course-choose-list-item-checkbox\n    @checked={{this.isSelected}}\n    @twoWay={{false}}\n    @onChange={{this.handleCheckboxChange}}\n  >\n    {{@course.name}}\n  </XCheckbox>\n</label>","moduleName":"babel/components/course-choose-list/item/index.hbs","parseOptions":{"srcName":"babel/components/course-choose-list/item/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class CourseChooseListItem extends Component {
  get isSelected() {
    return this.args.selectedCourses.includes(this.args.course);
  }

  @action
  handleCheckboxChange() {
    this.#toggle();
  }

  #toggle() {
    if (this.isSelected) {
      this.args.onDeselect(this.args.course);
    } else {
      this.args.onSelect(this.args.course);
    }
  }
}
