/**
 * Utility for shake element(s)
 *
 * @method shakeElement
 * @param {HTMLElement|String} element Element or selector for element(s) to shake
 * @param {HTMLElement|String} [parentElement=document] If element is a String, search for element(s) in parentElement. parentElement can be a selector.
 * @param {Boolean} [multipleElements=false] Only available if element is a String. If true, shake all matching elements. If false, only shake first found element.
 * @namespace utils
 *
 * @example
 * import shakeElement from 'babel/utils/shake-element';
 *
 * shakeElement(this.element);
 * shakeElement('.shake-me', this.element, true);
 */

import { later } from '@ember/runloop';
import getElement from 'babel/utils/get-element';

const shake = (element) => {
  later(
    (shakeElement) => {
      shakeElement && shakeElement.classList.add('shake');
    },
    element,
    1
  );

  later(
    (shakeElement) => {
      shakeElement && shakeElement.classList.remove('shake');
    },
    element,
    1000
  );
};

export default function(...params) {
  const element = getElement(...params);

  if (element instanceof HTMLElement) {
    shake(element);
  } else if (element instanceof NodeList) {
    [].slice.call(element).forEach((elem) => shake(elem));
  }
}
