import Component from '@ember/component';

export default Component.extend({
  // SETUP

  tagName: '',

  // EXTERNAL

  title: null,

  percentCorrect: 0,

  totalCorrectAnswers: 0,

  totalWords: 0
});
