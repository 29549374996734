// This rule set is specific for Swedish language (sv) and has been added here as an example without any actual rules
// It is used from component speaker-context in function _getDigilarTextProcessingRuleSets.
// To add a rule set like this for another language, just copy this file, rename it, add the rules, and use it from speaker-context:_getDigilarTextProcessingRuleSets().
//
// NOTE! Language specific rule sets like this one are only intended for more complex rules that can differ between languages.
// For simpler translations, use digilar-general.js instead and add translations to translation/languages/<language>.js
// See digilar-general.js for example use of the translate() function.
//
const ruleSet = () => {
  return {
    initialCleanUp: (textProcessor, syntax, { withPlaceholder }) => {},

    beforeHtmlRemoved: (textProcessor, syntax, { withPlaceholder }) => {},

    afterHtmlRemoved: (textProcessor, syntax, { withPlaceholder }) => {},

    finalCleanUp: (textProcessor, syntax, { withPlaceholder }) => {},
  };
};

export default ruleSet;
