import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class MissionContentComponent extends Component {
  /**
   * Arguments:
   * mission (object)
   * onEditCustomContent (function)
   */

  @service session;

  get hasContent() {
    const mission = this.args.mission;

    if (mission.hasDescription) return true;
    if (mission.linkedEntities.length > 0) return true;
    if (mission.files.length > 0) return true;

    return false;
  }

  get isEmpty() {
    if (this.hasContent) return false;
    if (this.args.mission.customContents.length > 0) return false;

    return true;
  }

  get showContent() {
    return this.hasContent || this.isEmpty;
  }
}
