import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { isWorkspaceCollectionActive } from 'babel/utils/is-workspace-collection-active';
import { load } from 'ember-async-data';
import { all } from 'rsvp';

export default class ContentSummaryComponent extends Component {
  @service router;

  @service assignmentEvents;

  @tracked isSummaryOpen = false;

  @cached get collectionItemsProxy() {
    const collections = this.args.collections || [];

    const promise = all(
      collections.map(async (collection) => {
        const classNames = ['content-summary-grid-item'];

        if (isWorkspaceCollectionActive(this.router, collection)) {
          classNames.push('content-summary-grid-item--active');
        }

        const classes = classNames.join(' ');

        const interactive =
          await this.assignmentEvents.getOrCreateInteractiveForCollection(
            collection
          );

        return {
          collection,
          classes,
          activeExercise: interactive?.activeExercise,
        };
      })
    );

    return load(promise, this);
  }

  get collectionItems() {
    return this.collectionItemsProxy.isResolved
      ? this.collectionItemsProxy.value
      : null;
  }

  @action handleDone() {
    this.isSummaryOpen = true;
  }
}
