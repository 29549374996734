import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{class \"addition-grid\" (concat \"addition-grid--range-\" this.range)}}\n  ...attributes\n>\n  {{yield}}\n</div>", {"contents":"<div\n  class={{class \"addition-grid\" (concat \"addition-grid--range-\" this.range)}}\n  ...attributes\n>\n  {{yield}}\n</div>","moduleName":"babel/components/addition-grid/index.hbs","parseOptions":{"srcName":"babel/components/addition-grid/index.hbs"}});
import { assert } from '@ember/debug';
import Component from '@glimmer/component';

// export interface AdditionGridComponentArgs {
//   range: 'small' | 'medium' | 'large';
// }

export default class AdditionGridComponent extends Component {
  get range() {
    const range = this.args.range ?? 'medium';

    assert(
      "The @range arg passed to <AdditionGrid> is not valid. Valid values are 'small', 'medium' and 'large'.",
      ['small', 'medium', 'large'].includes(range)
    );

    return range;
  }
}
