import { get } from '@ember/object';
import { isNone } from '@ember/utils';

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  namespace: 'api/v1/content',

  urlForFindRecord(id, modelName, snapshot) {
    const url = this._super(...arguments);
    const adapterOptionsInclude = 'adapterOptions.include';
    const include = get(snapshot, adapterOptionsInclude) || [];

    if (isNone(snapshot) || include.length === 0) {
      return url;
    }

    return `${url}${url.indexOf('?') > -1 ? '&' : '?'}include=${include.join(
      ','
    )}`;
  },
});
