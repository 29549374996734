import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import getCollectionData from 'babel/utils/get-collection-data';
import { all } from 'rsvp';

export default class MasterInteractivesDiagnosesShowRoute extends Route {
  @service diagnosis;

  @service router;

  @service session;

  @service store;

  async model({ result_id }) {
    const attempt = await this.store.findRecord('interactive', result_id);

    const [answers] = await all([
      attempt.answers,
      attempt.entity,
      attempt.entityProxy,
    ]);

    if (!attempt.isActive) {
      return this.router.replaceWith('master.interactives.index');
    }

    const { collection } = this.modelFor('master.interactives.diagnoses');

    const {
      parent: interactive,
      exercises,
      book,
      editor,
    } = await getCollectionData(this.store, collection);

    return {
      attempt,
      book,
      interactive,
      collection,
      exercises,
      editor,
      answers,
    };
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.diagnosis.endDiagnosis();
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    this.diagnosis.startDiagnosis(model.attempt);
  }
}
