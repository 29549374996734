import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n\n  <WaitFor @resource={{this.resource}} as |data|>\n\n    <XMarkerScope>\n\n      <:content>\n\n        <XImage\n          @source={{data.image.imageUrl}}\n          @color={{data.image.imageColor}}\n          @width={{data.image.imageWidth}}\n          @height={{data.image.imageHeight}}\n        />\n\n      </:content>\n\n      <:default as |scope|>\n\n        {{yield scope}}\n\n      </:default>\n\n    </XMarkerScope>\n\n  </WaitFor>\n\n</div>", {"contents":"<div ...attributes>\n\n  <WaitFor @resource={{this.resource}} as |data|>\n\n    <XMarkerScope>\n\n      <:content>\n\n        <XImage\n          @source={{data.image.imageUrl}}\n          @color={{data.image.imageColor}}\n          @width={{data.image.imageWidth}}\n          @height={{data.image.imageHeight}}\n        />\n\n      </:content>\n\n      <:default as |scope|>\n\n        {{yield scope}}\n\n      </:default>\n\n    </XMarkerScope>\n\n  </WaitFor>\n\n</div>","moduleName":"babel/components/exercise-view/common/marker-scope/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/common/marker-scope/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Resource from 'babel/utils/resource';

export default class ExerciseViewCommonMarkerScopeComponent extends Component {
  @service store;

  resource = new Resource(async () => {
    let imageId = this.args.assignment?.content?.image;

    if (imageId) {
      const image = await this.store.findRecord('material', imageId);
      return {
        image,
      };
    }

    return {
      image: null,
    };
  });
}
