import { action } from '@ember/object';
import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import { trackHelplistEvent } from 'babel/utils/matamo-events';
import { resolve } from 'rsvp';

export default class HelpListService extends Service {
  @service router;
  @service store;
  @service contextHelper;
  @service ajax;

  @tracked isOpen =
    this.router.currentRoute?.queryParams?.help_list === 'true' ?? false;

  @action
  openModal() {
    this.isOpen = true;
    if (this.router.currentRoute?.queryParams?.help_list === 'false') {
      this.router.replaceWith({ queryParams: { help_list: true } });
    }
  }

  @action
  closeDrawer() {
    this.isOpen = false;
    if (this.router.currentRoute?.queryParams?.help_list === 'true') {
      this.router.replaceWith({ queryParams: { help_list: false } });
    }
  }

  @action
  async activateHelpList() {
    const course = await resolve(this.contextHelper.activeCourse);
    const setting = await resolve(course.setting);

    if (!setting) return;

    const helpList = this.store.createRecord('help-list', { setting });

    trackHelplistEvent('Aktivera');
    return helpList.save({ adapterOptions: { ignoreResponsePayload: true } });
  }

  @action
  deactivateHelpList(helplist) {
    trackHelplistEvent('Deaktivera');
    return helplist.destroyRecord();
  }

  @action
  async raiseHand() {
    try {
      trackHelplistEvent('Räcker upp handen');
      const payload = await this.ajax.request(
        `${config.endpoint}/api/v1/meta/help-lists/${this.contextHelper.activeCourse.id}/raise-hand`,
        true,
        { type: 'POST', data: {} }
      );

      this.store.pushPayload(payload);
      return payload;
    } catch (err) {
      //
    }
  }

  @action
  async lowerHand(data) {
    let body = {};

    if (data) {
      body.user_id = data.id;
    }

    try {
      trackHelplistEvent('Tar ned handen');
      const payload = await this.ajax.request(
        `${config.endpoint}/api/v1/meta/help-lists/${this.contextHelper.activeCourse.id}/lower-hand`,
        true,
        { type: 'POST', data: body }
      );

      this.store.pushPayload(payload);
      return payload;
    } catch (err) {
      //
    }
  }
}
