import ApplicationAdapter from 'babel/adapters/application';
import { handleReceiversRelationship } from 'babel/utils/handle-dirty-relationship-data';

export default class MissionAdapter extends ApplicationAdapter {
  namespace = 'api/v1/meta';

  findHasMany(store, snapshot, url, relationship) {
    if (relationship?.meta?.name === 'receivers') {
      return handleReceiversRelationship(
        store,
        snapshot,
        url,
        relationship?.meta?.type
      );
    }

    return super.findHasMany(...arguments).then((response) => {
      if (relationship?.meta?.name === 'linkedEntities') {
        const order = snapshot?.record
          ?.hasMany('linkedEntities')
          ?.meta()?.order;

        const data = response?.data;

        if (order && data && order.length === data.length) {
          response.data = order.map((id) =>
            data.find((item) => item.id === id)
          );
        }
      }

      return response;
    });
  }
}
