import { action } from '@ember/object';
import { later } from '@ember/runloop';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { scrollIntoView } from 'compton/utils/dom';
import { all, resolve } from 'rsvp';

const OFFSET = 150;
const HEADING_ATTENTIVE_CLASS_NAME = 'tb-render-heading--attentive';

function queryByDataAttribute(element, key, value) {
  return element.querySelector('[data-' + key + '="' + value + '"]');
}

export default class EntityInspectorDetailArticleViewerShowComponent extends Component {
  /**
   * Arguments:
   * entity (object)
   * article (object)
   * articles (array)
   * title (string)
   * onSelect (function)
   */

  @tracked menuValue = null;

  get previousArticle() {
    const index = this.args.articles?.indexOf(this.args.article);

    if (index < 1) return null;

    return this.args.articles.objectAt(index - 1);
  }

  get nextArticle() {
    const index = this.args.articles?.indexOf(this.args.article);
    const last = this.args.articles?.length - 1;

    if (index >= last) return null;

    return this.args.articles.objectAt(index + 1);
  }

  @cached get editorsProxy() {
    const promise = resolve(this.args.article?.editors).then((editors) =>
      all(editors.map((editor) => resolve(editor.materials))).then(
        () => editors
      )
    );

    return load(promise, this);
  }

  get editors() {
    return this.editorsProxy.isResolved ? this.editorsProxy.value : [];
  }

  get titles() {
    const first = this.editors?.firstObject;

    if (!first) return [];

    return first.components
      .sortBy('index')
      .filterBy('component', 'heading')
      .filterBy('settings.size', 'large')
      .map((component) => {
        if (Array.isArray(component.value) && component.value.length > 0) {
          return { ...component, value: component.value[0].content };
        }

        return component;
      });
  }

  get tableOfContentsMenuButtonItems() {
    return this.titles.map((title) => ({
      label: title.value,
      value: title.uuid,
    }));
  }

  @action handleTableOfContentsMenuButtonChange(value) {
    // NOTE Query on body since this component don't have an element.
    const element = queryByDataAttribute(document.body, 'id', value);

    if (element) {
      scrollIntoView(element, {
        block: 'start',
        // NOTE Pass a custom behavior function to apply a top offset.
        // If need offset functionality elsewhere we should probably
        // move it the scrollIntoView function itself.
        behavior(instructions) {
          instructions.forEach(({ el, left, top }) => {
            el.scrollTo({
              left,
              top: top - OFFSET,
              behavior: 'smooth',
            });
          });
        },
      });

      element.classList.add(HEADING_ATTENTIVE_CLASS_NAME);

      later(() => {
        element.classList.remove(HEADING_ATTENTIVE_CLASS_NAME);
        this.menuValue = null;
      }, 1200);
    }
  }
}
