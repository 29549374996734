import { ForbiddenError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { reject, resolve } from 'rsvp';

export default class MasterContentsWordlistsShowWordsNewRoute extends Route {
  @service session;

  @service store;

  model() {
    const wordlist = this.modelFor('master.contents.wordlists.show');
    return this.store.createRecord('word', { wordlist });
  }

  afterModel(model) {
    return resolve(model.wordlist).then((wordlist) => {
      if (!wordlist.isUser(this.session.user)) {
        return reject(ForbiddenError);
      }
    });
  }

  resetController(controller) {
    controller.model.rollback();
  }
}
