import { A } from '@ember/array';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all } from 'rsvp';

export default class ContextSelectComponent extends Component {
  @service contextHelper;
  @service courseContext;
  @service productContext;
  @service router;
  @service intl;

  @tracked courses = [];

  @tracked products = [];

  get showCourses() {
    return !this.contextHelper.inCourse && this.courses?.length > 1;
  }

  get showProducts() {
    return (
      !this.contextHelper.inCourse &&
      !this.contextHelper.inProduct &&
      this.products?.length > 1
    );
  }

  get showModal() {
    return (
      this.showCourses || (this.showProducts && !this.contextHelper.inCourse)
    );
  }

  get courseProxy() {
    const courses = this.courses || A();

    return all(
      courses?.map((course) =>
        course
          .get('products')
          ?.then((products) =>
            all([products.getEach('image'), products.getEach('cover')])
          )
          .then(() => course)
      )
    );
  }

  get modalHeader() {
    if (this.showCourses) {
      return this.intl.t(
        'components.contextSelect.selectCourseContext.heading'
      );
    } else {
      return this.intl.t(
        'components.contextSelect.selectProductContext.heading'
      );
    }
  }

  @action async fetchCoursesAndProducts() {
    if (this.contextHelper.inCourse) return;

    if (this.args.book) {
      this.courses = await this.courseContext.fetchCoursesForBook(
        this.args.book,
        this.args.isTeacher
      );
    } else if (this.args.product) {
      this.courses = await this.courseContext.fetchCoursesForProduct(
        this.args.product
      );
    }

    if (this.courses.length === 1) {
      return this.courseContext.setCurrentCourse(this.courses[0]);
    }

    if (this.showCourses || this.contextHelper.inProduct) return;

    this.products = this.productContext.getAvailableProducts(this.args.book);

    if (this.products.length === 1) {
      return this.productContext.setActiveProduct(this.products[0]);
    }
  }

  @action onClose() {
    return this.router.transitionTo('master.index');
  }

  @action selectCourse(course, evt) {
    evt.preventDefault();

    if (this.args.onSelectCourse) {
      return this.args
        .onSelectCourse?.(course)
        .then(() => this.courseContext.setCurrentCourse(course));
    } else {
      return this.courseContext.setCurrentCourse(course);
    }
  }

  @action selectProduct(product, evt) {
    evt.preventDefault();

    this.productContext.setActiveProduct(product);
    this.args.onSelectProduct?.(product);
  }
}
