import { all, resolve } from 'rsvp';

export function resolvePathForTeacherAssignmentAnswer(answer, books) {
  return all([books, answer.teacherAssignment, answer.book, answer.content])
    .then(async ([books, teacherAssignment, book, content]) => {
      if (!books?.includes(book)) {
        const contents = await resolve(teacherAssignment.contents);
        const bookIds = books.map((book) => book.id);

        content = contents.find((content) =>
          bookIds.includes(content.belongsTo('book').id())
        );
      }

      return all([
        teacherAssignment.createAsEntity(content),
        content,
        content.parent,
      ]);
    })
    .then(([entity, content, area]) => ({
      entity,
      content,
      area,
    }));
}
