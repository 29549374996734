import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class ReadingResolveComponent extends Component {
  /**
   * Arguments:
   * readingId (string)
   */

  @service store;

  @cached get readingProxy() {
    const readingId = this.args.readingId;
    const promise = readingId
      ? this.store.findRecord('material', readingId)
      : resolve();

    return load(promise, this);
  }

  get reading() {
    return this.readingProxy.isResolved ? this.readingProxy.value : null;
  }

  get readingUrl() {
    if (this.readingProxy.isResolved) {
      return this.reading?.meta?.attachment_url || 'none';
    } else {
      return null;
    }
  }
}
