import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { resolve } from 'rsvp';

export default class DiagnosisService extends Service {
  @service intl;

  @service contextHelper;

  @service confirmManager;

  @service router;

  @tracked turningIn = false;

  @action done() {
    return this.confirmManager
      .confirm({
        title: this.intl.t('services.diagnosis.confirmTurnInTitle'),
        message: htmlSafe(
          this.intl.t('services.diagnosis.confirmTurnInMessage')
        ),
      })
      .then(() => this._turnIn())
      .catch((err) => console.log(err));
  }

  @action timesUp() {
    return this._turnIn().catch((err) => console.log(err));
  }

  startDiagnosis(attempt) {
    this.contextHelper.setActive('activeDiagnosisAttempt', attempt);
  }

  endDiagnosis() {
    this.contextHelper.setActive('activeDiagnosisAttempt', null);
  }

  _turnIn() {
    if (!this.contextHelper.activeDiagnosisAttempt) return resolve();

    this.turningIn = true;

    return this.contextHelper.activeDiagnosisAttempt
      .turnIn()
      .then(() => this.contextHelper.activeDiagnosisAttempt.entity)
      .then((collection) =>
        this.router.transitionTo(
          'master.interactives.diagnoses.done',
          this.contextHelper.activeContent.id,
          collection.id,
          this.contextHelper.activeDiagnosisAttempt.id
        )
      )
      .then(() => {
        this.turningIn = false;
      });
  }
}
