import Route from '@ember/routing/route';
import { resolve } from 'rsvp';

export default class MyResultsRoute extends Route {
  // METHODS

  model() {
    const course = this.modelFor('master.courses.show');
    return resolve(course.books).then(() => course);
  }
}
