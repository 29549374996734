import { cancel, once } from '@ember/runloop';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ContextHelperService extends Service {
  // Diagnosis

  @tracked activeDiagnosisAttempt = null;

  // Mission

  @tracked activeMission = null;

  @tracked activeCustomContent = null;

  // Course

  @tracked activeCourse = null;

  // Product

  @tracked activeProduct = null;

  // Entity

  @tracked activeBook = null;

  @tracked activeArea = null;

  @tracked activeContent = null;

  @tracked activeSection = null;

  @tracked activeExercise = null;

  @tracked activeWorkspaceSection = null;

  @tracked activeWorkspaceExercise = null;

  // Navigation

  @tracked closeUrl = null;

  @tracked returnUrl = null;

  // Page title

  @tracked pageTitle = null;

  // Timers

  timers = {};

  // Context states

  get inActiveDiagnosis() {
    return this.activeDiagnosisAttempt ? true : false;
  }

  get inMission() {
    return this.activeMission ? true : false;
  }

  get inCustomContent() {
    return this.activeCustomContent ? true : false;
  }

  get inCourse() {
    return this.activeCourse ? true : false;
  }

  get inProduct() {
    return this.activeProduct ? true : false;
  }

  get inBook() {
    return this.activeBook ? true : false;
  }

  get inArea() {
    return this.activeArea ? true : false;
  }

  get inContent() {
    return this.activeContent ? true : false;
  }

  get inSection() {
    return this.activeSection ? true : false;
  }

  get inExercise() {
    return this.activeExercise ? true : false;
  }

  get activeEntity() {
    if (this.inContent) return this.activeContent;
    if (this.inArea) return this.activeArea;
    if (this.inBook) return this.activeBook;
    return null;
  }

  get inWorkspaceSection() {
    return this.activeWorkspaceSection ? true : false;
  }

  get inWorkspaceExercise() {
    return this.activeWorkspaceExercise ? true : false;
  }

  get hasCloseUrl() {
    return this.closeUrl ? true : false;
  }

  get hasReturnUrl() {
    return this.returnUrl ? true : false;
  }

  get hasPageTitle() {
    return this.pageTitle ? true : false;
  }

  // METHODS

  setActive(type, obj) {
    if (this.timers[type]) {
      cancel(this.timers[type]);
    }

    const val = obj ?? null;

    this.timers[type] = once(this, this[`${type}Update`], val);
  }

  activeDiagnosisAttemptUpdate(val) {
    this.activeDiagnosisAttempt = val;
  }

  activeMissionUpdate(val) {
    this.activeMission = val;
  }

  activeCustomContentUpdate(val) {
    this.activeCustomContent = val;
  }

  activeCourseUpdate(val) {
    this.activeCourse = val;
  }

  activeProductUpdate(val) {
    this.activeProduct = val;
  }

  activeBookUpdate(val) {
    this.activeBook = val;
  }

  activeAreaUpdate(val) {
    this.activeArea = val;
  }

  activeContentUpdate(val) {
    this.activeContent = val;
  }

  activeSectionUpdate(val) {
    this.activeSection = val;
  }

  activeExerciseUpdate(val) {
    this.activeExercise = val;
  }

  activeWorkspaceSectionUpdate(val) {
    this.activeWorkspaceSection = val;
  }

  activeWorkspaceExerciseUpdate(val) {
    this.activeWorkspaceExercise = val;
  }

  closeUrlUpdate(val) {
    this.closeUrl = val;
  }

  returnUrlUpdate(val) {
    this.returnUrl = val;
  }

  pageTitleUpdate(val) {
    this.pageTitle = val;
  }

  setCloseUrl(url) {
    this.setActive('closeUrl', this.#validateUrl(url));
  }

  setReturnUrl(url) {
    this.setActive('returnUrl', this.#validateUrl(url));
  }

  #validateUrl(url) {
    // just to make sure we never link to an external site
    if (!url) return url;
    if (!url.startsWith('/')) return null;
    return url;
  }
}
