import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import { load } from 'ember-async-data';
import { all } from 'rsvp';

export default class CourseUserListComponent extends Component {
  /**
   * Arguments:
   * course (object)
   */

  @service session;

  @service router;

  @service helpList;

  @tracked activeProduct;

  @tracked activeUser;

  @tracked licenseModalOpen = false;

  get selectableCourse() {
    return `course.${this.args.course?.id}`;
  }

  @cached
  get coursePagesDisabledProxy() {
    const promise = all([
      this.session.user?.licenses,
      this.args.course?.products,
    ]).then(([userLicenses, courseProducts]) => {
      const userISBNs = userLicenses
        .filterBy('isExpired', false)
        .map((license) => license.isbn);
      const courseISBNs = courseProducts.map((prod) => prod.id);

      let disabled = false;

      courseISBNs.forEach((isbn) => {
        if (userISBNs.indexOf(isbn) === -1) {
          disabled = true;
        }
      });

      return disabled;
    });

    return load(promise, this);
  }

  get coursePagesDisabled() {
    return this.coursePagesDisabledProxy.isResolved
      ? this.coursePagesDisabledProxy.value
      : true;
  }

  get users() {
    return this.args.course?.users ?? [];
  }

  get teachers() {
    return this.users.filterBy('role', 'teacher');
  }

  get students() {
    return this.users.filterBy('role', 'student').sortBy('showname');
  }

  get loggedInStudents() {
    return this.students.filter((x) => x.metadata?.last_login);
  }

  get notLoggedInStudents() {
    return this.students.filter((x) => !x.metadata?.last_login);
  }

  get hasPortalUrl() {
    return config.nokPortalURL && config.nokPortalURL !== 'null';
  }

  get portalUrl() {
    return this.hasPortalUrl ? config.nokPortalURL : '';
  }

  get courseSettingsURL() {
    return `${this.portalUrl}/course-settings/${
      this.args?.course?.id
    }?return-url=${encodeURIComponent(this.returnURL)}`;
  }

  get returnURL() {
    return this.router.currentURL;
  }

  @action
  openLicenseModal(product, user) {
    this.activeProduct = product;
    this.activeUser = user;
    this.licenseModalOpen = true;
  }
}
