import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  role=\"menuitem\"\n  class=\"action-menu-item\"\n  tabindex=\"0\"\n  data-test-action-menu-item\n  data-highlighted={{this.isHighlighted}}\n  ...attributes\n  {{on \"pointerenter\" this.handlePointerEnter}}\n  {{on \"pointerleave\" this.handlePointerLeave}}\n  {{on \"click\" this.handleClick}}\n  {{on \"keydown\" this.handleKeyDown}}\n  {{ref (set this \"element\")}}\n>\n  {{#if @icon}}\n    <DcIcon class=\"action-menu-icon nr1\" @name=\"{{@icon}}\" />\n  {{/if}}\n  <div class=\"action-menu-item-text\">\n    <span class=\"action-menu-item-label {{if @icon 'flex flex-center'}}\">\n      {{yield to=\"label\"}}\n    </span>\n\n    {{#if (has-block \"description\")}}\n      <div class=\"action-menu-item-description\">\n        {{yield to=\"description\"}}\n      </div>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div\n  role=\"menuitem\"\n  class=\"action-menu-item\"\n  tabindex=\"0\"\n  data-test-action-menu-item\n  data-highlighted={{this.isHighlighted}}\n  ...attributes\n  {{on \"pointerenter\" this.handlePointerEnter}}\n  {{on \"pointerleave\" this.handlePointerLeave}}\n  {{on \"click\" this.handleClick}}\n  {{on \"keydown\" this.handleKeyDown}}\n  {{ref (set this \"element\")}}\n>\n  {{#if @icon}}\n    <DcIcon class=\"action-menu-icon nr1\" @name=\"{{@icon}}\" />\n  {{/if}}\n  <div class=\"action-menu-item-text\">\n    <span class=\"action-menu-item-label {{if @icon 'flex flex-center'}}\">\n      {{yield to=\"label\"}}\n    </span>\n\n    {{#if (has-block \"description\")}}\n      <div class=\"action-menu-item-description\">\n        {{yield to=\"description\"}}\n      </div>\n    {{/if}}\n  </div>\n</div>","moduleName":"babel/components/action-menu/item/index.hbs","parseOptions":{"srcName":"babel/components/action-menu/item/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import waitForAnimation from 'compton/utils/dom/wait-for-animation';
import { KEY } from 'compton/utils/event';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class ActionMenuItem extends Component {
  element = null;

  @tracked isHighlighted = false;

  @action
  handlePointerEnter() {
    this.element.focus({ preventScroll: true });
  }

  @action
  handlePointerLeave() {
    this.args.wrapperElement.focus({ preventScroll: true });
  }

  @action
  async handleClick() {
    await this.highlight();
    this.select();
  }

  @action
  async handleKeyDown(evt) {
    if (evt.key === KEY.enter || evt.key === KEY.space) {
      evt.preventDefault();
      await this.highlight();
      this.select();
    }
  }

  async highlight() {
    this.isHighlighted = true;
    this.args.onHighlightChange?.(true);
    await sleep(1);
    await waitForAnimation(this.element);
    this.isHighlighted = false;
    this.args.onHighlightChange?.(false);
  }

  select() {
    this.args.onSelect(this.args.value);
  }
}
