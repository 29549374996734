import Component from '@ember/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default Component.extend({
  // SETUP

  tagName: 'article',

  classNames: ['editor-preview'],

  // EXTERNAL PROPERTIES

  content: null,

  // COMPUTED

  htmlSafeContent: computed('content', function() {
    return htmlSafe(this.get('content'));
  }),

  // LIFECYCLE HOOKS

  didInsertElement() {
    this._super(...arguments);

    const elem = this.get('element');

    if (elem) {
      const links = elem.querySelectorAll('a');

      links.forEach((link) => {
        link.target = '_blank';
      });
    }
  }
});
