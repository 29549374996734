import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';

const actions = {
  SHOW_LOG: 'show_log',
  AUDIT_EXERCISES: 'audit_exercises',
  AUDIT_COMMENTS: 'audit_comments',
  ADMINISTER_USER: 'administer_user',
};

export default class CourseUserListComponent extends Component {
  /**
   * Arguments:
   * user (object)
   * coursePagesDisabled (bool)
   * usersMissingLicenses (array)
   * products (array)
   * openLicenseModal (function)
   */

  @service contextHelper;

  @service intl;

  @service router;

  @tracked modalOpen = false;

  @tracked tabValue = null;

  get actionMenuButtonItems() {
    return [
      {
        value: actions.SHOW_LOG,
        label: this.intl.t('components.courseUserList.user.showLog'),
        disabled: this.args.coursePagesDisabled,
      },
      {
        value: actions.AUDIT_EXERCISES,
        label: this.intl.t('components.courseUserList.user.auditExercises'),
        disabled: this.args.coursePagesDisabled,
      },
      {
        value: actions.AUDIT_COMMENTS,
        label: this.intl.t('components.courseUserList.user.auditComments'),
        disabled: this.args.coursePagesDisabled,
      },
      {
        type: 'divider',
      },
      {
        value: actions.ADMINISTER_USER,
        label: this.intl.t('components.courseUserList.user.administerUser'),
        description: this.intl.t(
          'components.courseUserList.user.administerUserDescription'
        ),
      },
    ];
  }

  get hasPortalUrl() {
    return config.nokPortalURL && config.nokPortalURL !== 'null';
  }

  get portalUrl() {
    return this.hasPortalUrl ? config.nokPortalURL : '';
  }

  @action
  onActionMenuButtonChange(value) {
    const userType = value.user.isTeacher ? 'teachers' : 'students';
    switch (value.action) {
      case actions.SHOW_LOG:
        this.#routeToAudit('log');
        break;
      case actions.AUDIT_EXERCISES:
        this.#routeToAudit('overview');
        break;
      case actions.AUDIT_COMMENTS:
        this.#routeToAudit('comments');
        break;
      case actions.ADMINISTER_USER:
        window.location.href = `${this.portalUrl}/admin/${userType}?search=${value.user.username}`;
        break;
    }
  }

  @action
  close() {
    return null;
  }

  #routeToAudit(auditType) {
    const courseId = this.contextHelper.activeCourse?.id;
    const userId = this.args.user?.id;

    if (!courseId || !userId) return;

    return this.router.transitionTo(
      'master.courses.show.audit.' + auditType,
      courseId,
      {
        queryParams: {
          'selected-student': userId,
        },
      }
    );
  }
}
