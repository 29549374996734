import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex hover-highlight\">\n  <a\n    href=\"#\"\n    class=\"flex flex-auto flex-center p1 text-ui ui-link text-color-default\"\n    {{on \"click\" this.handleClick}}\n  >\n    <div class=\"flex-auto\">\n      {{or @index @entity.sort}}.\n      {{@entity.title}}\n    </div>\n\n    <DcIcon @name=\"group\" class=\"flex-shrink ml1 nr1\" />\n\n    <div class=\"flex-shrink\">\n      {{t\n        \"components.courseAudit.standard.exercise.numAnswered\"\n        count=this.numAnswers\n        total=this.numSelectedStudents\n      }}\n    </div>\n  </a>\n</div>", {"contents":"<div class=\"flex hover-highlight\">\n  <a\n    href=\"#\"\n    class=\"flex flex-auto flex-center p1 text-ui ui-link text-color-default\"\n    {{on \"click\" this.handleClick}}\n  >\n    <div class=\"flex-auto\">\n      {{or @index @entity.sort}}.\n      {{@entity.title}}\n    </div>\n\n    <DcIcon @name=\"group\" class=\"flex-shrink ml1 nr1\" />\n\n    <div class=\"flex-shrink\">\n      {{t\n        \"components.courseAudit.standard.exercise.numAnswered\"\n        count=this.numAnswers\n        total=this.numSelectedStudents\n      }}\n    </div>\n  </a>\n</div>","moduleName":"babel/components/course-audit/standard/exercise/index.hbs","parseOptions":{"srcName":"babel/components/course-audit/standard/exercise/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class CourseAuditStandardExerciseComponent extends Component {
  /**
   * Arguments:
   * entity (object)
   * collection (object)
   * index (number)
   * answers (array)
   * selectedStudents (array)
   * openAuditMode (function)
   */

  get numSelectedStudents() {
    return this.args.selectedStudents?.length || 0;
  }

  @cached get numAnswersProxy() {
    const answers = this.args.answers;

    let promise;

    if (!answers) {
      promise = resolve(0);
    } else {
      const entityId = this.args.entity?.id;
      const teacherAssignmentId = this.args.entity?.isTeacherAssignment
        ? this.args.entity?.teacherAssignment?.id
        : null;

      promise = all(
        answers.map((answer) =>
          all([answer.user, answer.teacherAssignment]).then(
            ([user, teacherAssignment]) =>
              answer.node_id === entityId ||
              (teacherAssignmentId &&
                teacherAssignment?.id === teacherAssignmentId)
                ? user.id
                : null
          )
        )
      ).then(
        (users) =>
          users.filter((val, idx, self) => val && self.indexOf(val) === idx)
            .length
      );
    }

    return load(promise, this);
  }

  get numAnswers() {
    return this.numAnswersProxy.isResolved ? this.numAnswersProxy.value : 0;
  }

  @action handleClick(evt) {
    evt.preventDefault();

    this.args.openAuditMode?.({
      collection: this.args.collection || this.args.entity.parent,
      exercise: this.args.entity,
      answers: this.args.answers,
      student: null,
      mode: 'assignments',
    });
  }
}
