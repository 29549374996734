import Component from '@glimmer/component';

export default class UserSelectCheckbox extends Component {
  /**
   * Arguments:
   * user (object)
   * selectedUsers (array)
   * onChange (function)
   */

  get isSelected() {
    const selectedUsers = this.args.selectedUsers;
    const user = this.args.user;

    if (selectedUsers && user) {
      if (selectedUsers.indexOf(user) !== -1) {
        return true;
      }
    }

    return false;
  }
}
