import StorageObject from 'ember-local-storage/local/object';

const Storage = StorageObject.extend();

Storage.reopenClass({
  initialState() {
    return {
      theme: 'light',
      fontfamily: 'system-font',
      marking: 'underlined',
      mode: 'scroll',
      onlytext: false,
      onlyImage: false,
      readRulerIsOpen: false,
      readRulerSpace: 40,
      readRulerOffset: 0,
      textformats: 'on',
      textsize: '2',
      lineHeight: 'standard',
    };
  },
});

export default Storage;
