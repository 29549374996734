import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"header-base-basic-button\"\n  type=\"button\"\n  ...attributes\n  {{on \"click\" this.handleClick}}\n>\n  {{yield}}\n</button>", {"contents":"<button\n  class=\"header-base-basic-button\"\n  type=\"button\"\n  ...attributes\n  {{on \"click\" this.handleClick}}\n>\n  {{yield}}\n</button>","moduleName":"babel/components/header/base/basic-button/index.hbs","parseOptions":{"srcName":"babel/components/header/base/basic-button/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class HeaderBasicButton extends Component {
  get active() {
    return this.args.active ?? false;
  }

  @action
  handleClick() {
    this.args.onClick?.();
  }
}
