import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { storageFor } from 'ember-local-storage';

export default class ContentsWorkflowComponent extends Component {
  @service footnotes;

  @tracked showContentSummary = false;

  @storageFor('read-setting') settings;

  get isSection() {
    return this.args.model?.type === 'sections';
  }

  get showDoneButton() {
    return !this.showContentSummary && this.args.showDoneButton;
  }

  get showSummary() {
    return this.showContentSummary && !this.args.hasNextSection;
  }

  get hasExercises() {
    return this.args.model.children.length > 0;
  }

  @cached get modelProxy() {
    return load(this.args.model, this);
  }

  get heading() {
    const model = this.modelProxy.isResolved ? this.modelProxy.value : null;

    if (model?.body?.hide_heading) return null;

    return model ? model.heading : null;
  }

  @action handleDone() {
    this.showContentSummary = true;
  }

  constructor() {
    super(...arguments);
    this.footnotes.createIndexContext(this.args.sections);
  }
}
