import { action } from '@ember/object';
import { later, scheduleOnce } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ExerciseViewCommonDragAssignmentComponent extends Component {
  @tracked element;

  @tracked dragging = false;

  @tracked activeDraggable;

  @tracked draggablePositions;

  get draggingClassName() {
    return this.dragging && ['drag-assignment', 'dragging'].join('--');
  }

  @action setElement(elem) {
    this.element = elem;
  }

  @action handleDragStart() {
    this.dragging = true;
    this.draggablePositions = this._getDraggablePositions();

    scheduleOnce('afterRender', this, '_getActiveDraggable');
  }

  @action handleDragEnd() {
    this.dragging = false;

    scheduleOnce('afterRender', this, '_findChangedDraggable');
  }

  _getDraggablePositions() {
    const elem = this.element;
    const droppables = elem && elem.querySelectorAll('.x-droppable');
    const positions = [];

    if (droppables) {
      droppables.forEach((droppable) => {
        const paired = droppable.closest('.item-state--paired');
        const draggables = [];

        let textContent = '';

        droppable.querySelectorAll('.x-draggable').forEach((draggable) => {
          draggables.push({
            id: draggable.id,
            text: draggable.textContent,
            elem: draggable,
          });

          textContent += draggable.textContent;
        });

        positions.push({
          id: droppable.id,
          paired: paired ? true : false,
          text: textContent,
          draggables,
        });
      });
    }

    return positions;
  }

  _getActiveDraggable() {
    const elem = this.element;
    const activeDraggable = elem && elem.querySelector('.x-draggable--started');

    this.activeDraggable = activeDraggable;
  }

  _findChangedDraggable() {
    const initial = this.draggablePositions;
    const current = this._getDraggablePositions();

    if (
      current.filter((curr) => !initial.find((x) => x.id === curr.id)).length >
      0
    ) {
      // droppables have been replaced = match
      // check for changes in paired state

      current.forEach((curr, idx) => {
        const init = initial[idx];

        if (
          init &&
          curr.paired &&
          (curr.text !== init.text || curr.paired !== init.paired)
        ) {
          curr.draggables.forEach((draggable) => {
            this._highlightDraggable(draggable.elem);
          });
        }
      });
    } else if (
      current.filter(
        (curr, idx) => !(initial[idx] && initial[idx].id === curr.id)
      ).length > 0
    ) {
      // droppables moved = arrange
      // highlight active draggable

      const activeDraggable = this.activeDraggable;
      this._highlightDraggable(activeDraggable);
    } else {
      // droppables unchanged, look for draggable changes

      current.forEach((curr) => {
        const init = initial.find((x) => x.id === curr.id);

        if (init) {
          curr.draggables.forEach((draggable) => {
            const initDraggable = init.draggables.find(
              (x) => x.id === draggable.id
            );

            if (!initDraggable || initDraggable.text !== draggable.text) {
              this._highlightDraggable(draggable.elem);
            }
          });
        }
      });
    }
  }

  _highlightDraggable(elem) {
    if (elem) {
      elem.classList.add('x-draggable--dropped');
    }

    later(
      this,
      () => {
        if (elem) {
          elem.classList.remove('x-draggable--dropped');
        }
      },
      1000
    );
  }
}
