import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/*
  @file (object)
  @entity (object)
  @removeable (boolean)
  @onRemoveEntity (function)
  @onRemoveFile (function)
  @allowedBooks (array)
*/

export default class AttachmentPreviewItemComponent extends Component {
  @service session;

  @tracked externalLinkDialogIsOpen = false;

  get downloadable() {
    if (this.args.entity?.constructor.modelName === 'entity') return false;

    return !['external'].includes(this.args.file?.type);
  }

  @action
  handleRemove() {
    if (this.args.entity?.constructor?.modelName === 'entity') {
      this.args.onRemoveEntity?.(this.args.entity);
    } else {
      this.args.onRemoveFile?.(this.args.file);
    }
  }

  @action
  handleExternalLink() {
    if (['google-docs', 'office365'].includes(this.args.file.externalType)) {
      window.open(this.args.file.externalUrl, '_blank');
    } else {
      this.externalLinkDialogIsOpen = true;
    }
  }
}
