import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CollectionExerciseIndexItemLinkComponent extends Component {
  /**
   * exercise (object)
   * onClick (function)
   * routed (bool)
   */

  @service contextHelper;
}
