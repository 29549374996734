const STATIC_AUDIO_SOURCES = [
  {
    text: 'Inläsningstjänst - första meningen.',
    url: 'http://vaas.acapela-group.com/MESSAGES/007105103105108097114/Digilar_APP/sounds/651402408_ef712c40d1947.mp3',
  },
  {
    text: 'Inläsningstjänst - andra meningen.',
    url: 'http://vaas.acapela-group.com/MESSAGES/007105103105108097114/Digilar_APP/sounds/651402607_ef712d4602986.mp3',
  },
  {
    text: 'Inläsningstjänst - tredje meningen.',
    url: 'http://vaas.acapela-group.com/MESSAGES/007105103105108097114/Digilar_APP/sounds/651402715_ef712e29d8762.mp3',
  },
];

class IltMockApi {
  constructor({ sentenceGroupId }) {
    this._sentenceGroupId = sentenceGroupId;
  }

  _mockArticle(articleId) {
    const newSentenceGroup = (sentenceGroupId) => {
      return {
        Id: sentenceGroupId,
        Sentences: STATIC_AUDIO_SOURCES.map((audioSource, i) => {
          return {
            Order: i,
            Content: audioSource.text,
            RecordingUrl: {
              Url: audioSource.url,
              Ttl: null,
              Created: '2019-07-03T13:11:38.390Z',
              TimeLeft: null,
              TimeLeftSeconds: 0,
            },
            RecordingId: `00000000-0000-0000-0000-00000000000${i + 1}`,
          };
        }),
      };
    };

    // Generate a mock article using static audio source data, and a single sentence group using the group ID passed to the constructor
    const article = {
      Id: articleId,
      PublisherDomContainer: 'N/A',
      SentenceGroups: [newSentenceGroup(this._sentenceGroupId)],
    };

    return article;
  }

  fetchArticle({ articleId, jwt, useBrowserCache = false }) {
    // Resolve with mock article
    return Promise.resolve(this._mockArticle(articleId));
  }

  reportListening(recordingId) {
    // Do nothing in mocked API. Real API will call /api/Student/articles/{articleId}/recordings/{recordingId}/listenings
    console.debug(
      `Mock API not reporting listening back to ILT for recordingId ${recordingId}`
    );
  }
}

export default IltMockApi;
