const ARCHIVES = 'archives';
const AREAS = 'areas';
const ASSIGNMENTS = 'assignments';
const BOOKS = 'books';
const COLLECTIONS = 'collections';
const CONTENTS = 'contents';
const EXERCISES = 'exercises';
const FOLDERS = 'folders';
const GLOSSARIES = 'glossaries';
const INTERACTIVES = 'interactives';
const SECTIONS = 'sections';
const STUDIES = 'studies';
const MATERIALS = 'materials';

const SPACES = {
  content: 'content',
  workspace: 'workspace',
};

const NAME_PARTS = {
  archives: 'archives',
  assignments: 'assignments',
  contents: 'contents',
  diagnoses: 'diagnoses',
  folders: 'folders',
  index: 'index',
  interactives: 'interactives',
  master: 'master',
  practices: 'practices',
  show: 'show',
  wordlists: 'wordlists',
  materials: 'materials',
  products: 'products',
};

async function getChildRouteName(entity) {
  const parent = await entity.parent;

  if (parent.type === CONTENTS) {
    if (entity.space === 'workspace') {
      return NAME_PARTS.assignments;
    } else {
      return NAME_PARTS.contents;
    }
  } else if (entity.isDiagnosis) {
    return NAME_PARTS.diagnoses;
  } else {
    return NAME_PARTS.practices;
  }
}

async function getFirstContentOrInteractiveAncestor(entity) {
  let parent = await entity.parent;

  while (![CONTENTS, INTERACTIVES].includes(parent.type)) {
    parent = await parent.parent;
  }

  return parent;
}

function isWithinWorkspace(nameParts) {
  return nameParts.any((namePart) =>
    [NAME_PARTS.assignments, NAME_PARTS.wordlists].includes(namePart)
  );
}

function resolveStandardRoute(entity, nameParts, models, queryParams) {
  nameParts.addObject(entity.type, NAME_PARTS.index);
  models.addObject(entity.id);

  if (entity.type === CONTENTS) {
    queryParams.section = undefined;
    queryParams.exercise = undefined;
  }
}

async function resolveSectionRoute(entity, nameParts, models, queryParams) {
  const parent = await entity.parent;

  nameParts.addObject(NAME_PARTS.contents);
  models.addObject(parent.id);
  queryParams.section = entity.id;
}

async function resolveGlossariesRoute(entity, nameParts, models) {
  const parent = await entity.parent;

  nameParts.addObjects([
    NAME_PARTS.contents,
    NAME_PARTS.wordlists,
    NAME_PARTS.show,
  ]);

  models.addObjects([parent.id, entity.id]);
}

async function resolveContentCollectionRoute(
  entity,
  nameParts,
  models,
  queryParams
) {
  const [contentOrInteractiveAncestor, parent, children] = await Promise.all([
    getFirstContentOrInteractiveAncestor(entity),
    entity.parent,
    entity.children,
  ]);

  const ancestorType = contentOrInteractiveAncestor.type;

  if (ancestorType === INTERACTIVES) {
    const childRouteName = await getChildRouteName(entity);

    nameParts.addObjects([ancestorType, childRouteName]);
    models.addObjects([contentOrInteractiveAncestor.id, entity.id]);
  } else {
    nameParts.addObject(NAME_PARTS.contents);
    models.addObject(parent.id);
    queryParams.section = entity.id;

    const exercise =
      (queryParams.section && children.findBy('id', queryParams.exercise)) ||
      children.firstObject;

    if (exercise) {
      queryParams.exercise = exercise.id;
    }
  }
}

async function resolveWordspaceCollectionRoute(
  entity,
  nameParts,
  models,
  missionMode
) {
  const [contentOrInteractiveAncestor, children, childRouteName] =
    await Promise.all([
      getFirstContentOrInteractiveAncestor(entity),
      entity.children,
      getChildRouteName(entity),
    ]);

  let exercises = children;

  if (missionMode) {
    exercises = await missionMode.allowedEntities(children);
  }

  const firstChild = exercises?.[0];

  const ancestorType = contentOrInteractiveAncestor.type;

  nameParts.addObject(ancestorType);
  models.addObject(contentOrInteractiveAncestor.id);

  if (ancestorType === INTERACTIVES) {
    models.addObject(entity.id);
  }

  nameParts.addObject(childRouteName);

  if (firstChild) {
    nameParts.addObject(NAME_PARTS.show);
    models.addObject(firstChild.id);
  } else {
    nameParts.addObject(NAME_PARTS.index);
  }
}

async function resolveCollectionRoute(
  entity,
  nameParts,
  models,
  queryParams,
  missionMode
) {
  if (entity.space === SPACES.content) {
    await resolveContentCollectionRoute(entity, nameParts, models, queryParams);
  } else if (entity.space === SPACES.workspace) {
    await resolveWordspaceCollectionRoute(
      entity,
      nameParts,
      models,
      missionMode
    );
  }
}

async function resolveInteractivesDiagnosisExerciseRoute(
  contentOrInteractiveAncestor,
  parent,
  nameParts,
  models
) {
  nameParts.addObjects([
    NAME_PARTS.interactives,
    NAME_PARTS.diagnoses,
    NAME_PARTS.index,
  ]);

  models.addObjects([contentOrInteractiveAncestor.id, parent.id]);
}

async function resolveInteractivesPracticeExerciseRoute(
  contentOrInteractiveAncestor,
  parent,
  entity,
  nameParts,
  models
) {
  let templateType = NAME_PARTS.practices;

  nameParts.addObjects([
    NAME_PARTS.interactives,
    templateType,
    NAME_PARTS.show,
  ]);

  models.addObjects([contentOrInteractiveAncestor.id, parent.id, entity.id]);
}

async function resolveInteractivesExerciseRoute(
  contentOrInteractiveAncestor,
  parent,
  entity,
  nameParts,
  models
) {
  if (parent.isDiagnosis) {
    await resolveInteractivesDiagnosisExerciseRoute(
      contentOrInteractiveAncestor,
      parent,
      nameParts,
      models
    );
  } else {
    await resolveInteractivesPracticeExerciseRoute(
      contentOrInteractiveAncestor,
      parent,
      entity,
      nameParts,
      models
    );
  }
}

async function resolveContentExerciseRoute(
  contentOrInteractiveAncestor,
  parent,
  entity,
  nameParts,
  models,
  queryParams
) {
  nameParts.addObject(NAME_PARTS.contents);

  if (parent.space === SPACES.workspace) {
    nameParts.addObjects([NAME_PARTS.assignments, NAME_PARTS.show]);
    models.addObjects([contentOrInteractiveAncestor.id, entity.id]);
  } else if (parent.space === SPACES.content) {
    const parentParent = await parent.parent;

    models.addObject(parentParent.id);
    queryParams.section = parent.id;
    queryParams.exercise = entity.id;
  }
}

async function resolveExerciseRoute(entity, nameParts, models, queryParams) {
  const [parent, contentOrInteractiveAncestor] = await Promise.all([
    entity.parent,
    getFirstContentOrInteractiveAncestor(entity),
  ]);

  if (contentOrInteractiveAncestor.type === INTERACTIVES) {
    await resolveInteractivesExerciseRoute(
      contentOrInteractiveAncestor,
      parent,
      entity,
      nameParts,
      models
    );
  } else {
    await resolveContentExerciseRoute(
      contentOrInteractiveAncestor,
      parent,
      entity,
      nameParts,
      models,
      queryParams
    );
  }
}

async function resolveFolderRoute(entity, nameParts, models) {
  const parent = await entity.parent;

  nameParts.addObjects([NAME_PARTS.archives, NAME_PARTS.folders]);
  models.addObjects([parent.id, entity.id]);
}

async function resolveProductRoute(product, nameParts, models) {
  nameParts.addObject(NAME_PARTS.products);
  models.addObject(product.id);
}

export default async function (entity, initialQueryParams = {}, missionMode) {
  entity = await entity;

  const nameParts = [NAME_PARTS.master];
  const models = [];
  const queryParams = { ...initialQueryParams };

  switch (entity?.type) {
    case BOOKS:
    case AREAS:
    case ARCHIVES:
    case CONTENTS:
    case INTERACTIVES:
    case MATERIALS:
      resolveStandardRoute(entity, nameParts, models, queryParams);
      break;
    case SECTIONS:
      await resolveSectionRoute(entity, nameParts, models, queryParams);
      break;
    case GLOSSARIES:
      await resolveGlossariesRoute(entity, nameParts, models);
      break;
    case COLLECTIONS:
      await resolveCollectionRoute(
        entity,
        nameParts,
        models,
        queryParams,
        missionMode
      );
      break;
    case ASSIGNMENTS:
    case EXERCISES:
    case STUDIES:
      await resolveExerciseRoute(entity, nameParts, models, queryParams);
      break;
    case FOLDERS:
      await resolveFolderRoute(entity, nameParts, models);
      break;
    default:
      if (entity?.constructor?.modelName === 'product') {
        await resolveProductRoute(entity, nameParts, models);
      }
      break;
  }

  if (isWithinWorkspace(nameParts)) {
    const contentAncestor = await getFirstContentOrInteractiveAncestor(entity);
    let children = await contentAncestor.children;

    let section =
      queryParams.section && children.findBy('id', queryParams.section);

    if (!section) {
      const parent = await entity.parent;

      if (parent.type === COLLECTIONS && !parent.isTeacherCollection) {
        children = children.filterBy('cat.id', parent.cat?.id);
      }

      section = children.findBy('type', SECTIONS);
    }

    if (section) {
      queryParams.section = section.id;
    } else {
      // NOTE Redirect to the cover page if there's no sections. In the future
      // we may want to remove this code as it's now technically possible to
      // display the nested workspace routes directly on the cover page.
      return [
        [NAME_PARTS.master, NAME_PARTS.contents, NAME_PARTS.index].join('.'),
        contentAncestor.id,
      ];
    }
  }

  return [nameParts.join('.'), ...models, { queryParams }];
}
