import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

export default Component.extend({
  intl: service(),

  userAgent: service(),

  classNames: ['word-quiz-quiz-component'],

  // EXTERNAL

  currentWord: null,

  translationLanguage: null,

  correctAnswers: null,

  trainOnTranslations: false,

  title: null,

  answerWithWord: false,

  wrongAnswer: null,

  enter() {},

  canContinue: false,

  answer: null,

  percentDone: 0,

  hasStarted: false,

  // INTERNAL

  showBetaInfo: false,

  defaultUserLang: 'sv_SE',

  // COMPUTED

  questionLabel: computed('trainOnTranslations', 'answerWithWord', function () {
    if (this.trainOnTranslations) {
      if (this.answerWithWord) {
        return this.intl.t('components.wordQuiz.quiz.translation');
      } else {
        return this.intl.t('components.wordQuiz.quiz.word');
      }
    } else {
      return this.intl.t('components.wordQuiz.quiz.explanation');
    }
  }),

  question: computed(
    'answerWithWord',
    'currentWord.{explanation,translation,word}',
    'trainOnTranslations',
    function () {
      let question;

      if (this.trainOnTranslations) {
        if (this.answerWithWord) {
          question = this.get('currentWord.translation');
        } else {
          question = this.get('currentWord.word');
        }
      } else {
        if (this.get('currentWord.explanation')) {
          question = this.get('currentWord.explanation').replace(
            new RegExp(this.get('currentWord.word'), 'ig'),
            this.intl.t('components.wordQuiz.quiz.replaceWord')
          );
        }
      }

      if (!question) return null;

      return question;
    }
  ),

  answerLabel: computed('trainOnTranslations', 'answerWithWord', function () {
    if (this.trainOnTranslations) {
      if (this.answerWithWord) {
        return this.intl.t('components.wordQuiz.quiz.word');
      } else {
        return this.intl.t('components.wordQuiz.quiz.translation');
      }
    } else {
      return this.intl.t('components.wordQuiz.quiz.word');
    }
  }),

  correctAnswersString: computed('correctAnswers.length', function () {
    let correctAnswersString;

    if (this.get('correctAnswers.length') > 0) {
      correctAnswersString = this.correctAnswers.join(', ');
    } else {
      correctAnswersString = this.correctAnswers;
    }

    return htmlSafe(correctAnswersString);
  }),
});
