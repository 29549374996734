import { later } from '@ember/runloop';
import { getURLFromRouteInfo } from 'babel/utils/routing';

export default function scrollToTopIfNeeded(router) {
  let scrollPrevented = false;

  function handleRouteWillChange(transition) {
    router.off('routeWillChange', handleRouteWillChange);

    if (getURLFromRouteInfo(transition.to, router) !== router.currentURL) {
      scrollPrevented = true;
    }
  }

  function scrollToTopUnlessPrevented() {
    if (scrollPrevented === false) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  router.on('routeWillChange', handleRouteWillChange);

  later(scrollToTopUnlessPrevented);
}
