import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<MaterialViewer::Dialog\n  ...attributes\n  @size={{hash width=\"small\" height=\"auto\"}}\n  @onClose={{@onClose}}\n  @headerDivider={{false}}\n  as |dialog|\n>\n\n  <dialog.header class=\"pb0\" />\n\n  <dialog.content class=\"px2 pb2 flex-center\">\n\n    <DcIcon\n      class=\"material-viewer-attachment-dialog-download-icon\"\n      @name=\"cloud-download\"\n    />\n\n    <h3 class=\"text-secondary m0 mt1 nb1\">\n\n      {{t \"components.materialViewer.attachmentDialog.downloadHeadingLabel\"}}\n\n    </h3>\n\n    <div class=\"mb1\">\n\n      {{@material.name}}\n\n    </div>\n\n    <UiButton @appearance=\"link\" @onClick={{this.handleRetryButtonClick}}>\n\n      {{t \"components.materialViewer.attachmentDialog.retryButtonLabel\"}}\n\n    </UiButton>\n\n  </dialog.content>\n\n</MaterialViewer::Dialog>", {"contents":"<MaterialViewer::Dialog\n  ...attributes\n  @size={{hash width=\"small\" height=\"auto\"}}\n  @onClose={{@onClose}}\n  @headerDivider={{false}}\n  as |dialog|\n>\n\n  <dialog.header class=\"pb0\" />\n\n  <dialog.content class=\"px2 pb2 flex-center\">\n\n    <DcIcon\n      class=\"material-viewer-attachment-dialog-download-icon\"\n      @name=\"cloud-download\"\n    />\n\n    <h3 class=\"text-secondary m0 mt1 nb1\">\n\n      {{t \"components.materialViewer.attachmentDialog.downloadHeadingLabel\"}}\n\n    </h3>\n\n    <div class=\"mb1\">\n\n      {{@material.name}}\n\n    </div>\n\n    <UiButton @appearance=\"link\" @onClick={{this.handleRetryButtonClick}}>\n\n      {{t \"components.materialViewer.attachmentDialog.retryButtonLabel\"}}\n\n    </UiButton>\n\n  </dialog.content>\n\n</MaterialViewer::Dialog>","moduleName":"babel/components/material-viewer/attachment-dialog/index.hbs","parseOptions":{"srcName":"babel/components/material-viewer/attachment-dialog/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import config from 'babel/config/environment';
import arg from 'compton/utils/arg';

function getDownloadUrl(material) {
  const { metadata, id } = material;

  const name = metadata?.download_name ?? metadata?.filename;

  return `${config.endpoint}/api/v1/content/materials/${id}/download/${name}`;
}

export default class MaterialViewerAttachmentDialog extends Component {
  // SETUP

  @service ajax;

  // ARGS

  @arg material;

  // HOOKS

  constructor() {
    super(...arguments);
    this.#downloadAttachment();
  }

  // ACTIONS

  @action async handleRetryButtonClick() {
    return this.#downloadAttachment();
  }

  // PRIVATE

  async #downloadAttachment() {
    const data = await this.ajax.request(getDownloadUrl(this.material), true, {
      type: 'GET',
    });

    if (data?.signed_url) {
      window.open(data.signed_url);
    } else {
      return Promise.reject();
    }
  }
}
