import { attr, belongsTo, hasMany } from '@ember-data/model';

import Model from './model';

export default class HelpListModel extends Model {
  @belongsTo('setting', { async: true, inverse: null }) setting;

  @hasMany('user', { async: true, inverse: null }) users;

  @attr('date') createdAt;
}
