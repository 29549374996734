import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class\n    \"wordlist-picker-item\"\n    (if this.isDisabled \"wordlist-picker-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @wordlist}}\n  >\n\n    {{@wordlist.titleWithFallback}}\n\n  </XCheckbox>\n\n  {{#unless @wordlist.isPublished}}\n\n    <XBadge class=\"ml1\" @size=\"small\">\n      {{t \"components.wordlistBrowser.item.draftBadgeLabel\"}}\n    </XBadge>\n\n  {{/unless}}\n</label>", {"contents":"<label\n  class={{class\n    \"wordlist-picker-item\"\n    (if this.isDisabled \"wordlist-picker-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @wordlist}}\n  >\n\n    {{@wordlist.titleWithFallback}}\n\n  </XCheckbox>\n\n  {{#unless @wordlist.isPublished}}\n\n    <XBadge class=\"ml1\" @size=\"small\">\n      {{t \"components.wordlistBrowser.item.draftBadgeLabel\"}}\n    </XBadge>\n\n  {{/unless}}\n</label>","moduleName":"babel/components/wordlist-picker/item/index.hbs","parseOptions":{"srcName":"babel/components/wordlist-picker/item/index.hbs"}});
import Component from '@glimmer/component';

/*

import WordlistModel from 'babel/models/wordlist';

export interface WordlistPickerItemComponentArgs {
  wordlist: WordlistModel;
  pickedWordlists: WordlistModel[];
  disabledWordlists: WordlistModel[];
  onPick(wordlist: WordlistModel): void;
  onUnpick(wordlist: WordlistModel): void;
}

*/

export default class WordlistPickerItemComponent extends Component {
  get isPicked() {
    return this.args.pickedWordlists.includes(this.args.wordlist);
  }

  get isDisabled() {
    const disabledWordlists = this.args.disabledWordlists;

    return disabledWordlists && disabledWordlists.includes(this.args.wordlist);
  }
}
