import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import imageSource from 'compton/utils/image-source';

export default class ContentMissionNextComponent extends Component {
  /**
   * Arguments:
   * nextMissionContent (object)
   */

  @service contextHelper;
  @service missionDispatcher;
  @service router;

  imageWidth = 132;
  imageHeight = 132;

  @tracked element = null;
  @tracked elementWidth = null;

  get image() {
    return this.args.nextMissionContent?.entity?.get('image');
  }

  get imageSource() {
    const url = this.image?.imageUrl;

    if (!url) {
      return null;
    }

    const settings = {
      width: this.imageWidth,
      height: this.imageHeight,
      embed: true,
    };

    if (this.image?.metadata?.focus_point) {
      settings.x = this.image.metadata.focus_point.x;
      settings.y = this.image.metadata.focus_point.y;
    }

    return imageSource(url, settings);
  }

  get backgroundColor() {
    return this.image?.backgroundColor;
  }

  get responsiveClass() {
    return this.elementWidth > 480 ? 'content-mission-next--wide' : null;
  }

  @action setElement(element) {
    this.element = element;
    this.handleResize();
  }

  @action handleResize() {
    if (!this.element) return;

    const { width: elementWidth } = this.element.getBoundingClientRect();
    if (this.elementWidth !== elementWidth) {
      this.elementWidth = elementWidth;
    }
  }

  @action goToNextContent() {
    if (!this.args.nextMissionContent) {
      return;
    }

    return this.missionDispatcher.open(
      this.contextHelper.activeMission,
      this.args.nextMissionContent,
      this.contextHelper.closeUrl
    );
  }
}
