import Component from '@ember/component';
import { computed } from '@ember/object';
import { sendEvent } from '@ember/object/events';
import { inject as service } from '@ember/service';
import { computed as overridable } from 'compton/utils/computed-override';
import DS from 'ember-data';
import { storageFor } from 'ember-local-storage';
import { all } from 'rsvp';

const { PromiseArray } = DS;

export default Component.extend({
  // SETUP

  snackbar: service(),

  session: service(),

  contextHelper: service(),

  store: service(),

  intl: service(),

  wordlist: service(),

  missionMode: service(),

  favWordlists: storageFor('wordlists'),

  // PARAMS

  word: null,

  wordClass: null,

  wordConstruction: null,

  explanation: null,

  examples: null,

  translation: null,

  wordSaved: false,

  // PROPERTIES

  wordlists: computed(
    'session.user.id',
    'contextHelper.activeBook',
    function () {
      const promise = this.store.findAll('wordlist').then((wordlists) =>
        all(
          wordlists.map((wordlist) =>
            all([wordlist.get('books'), wordlist.get('contents')])
          )
        ).then(() =>
          wordlists
            .filter((wordlist) =>
              wordlist.hasBook(this.get('contextHelper.activeBook'))
            )
            .filter((wordlist) => wordlist.isUser(this.get('session.user')))
            .sortBy('created')
            .reverse()
        )
      );

      return PromiseArray.create({ promise });
    }
  ),

  contentWordLists: computed(
    'wordlists.[]',
    'contextHelper.activeContent.id',
    function () {
      const currentEntityId = this.get('contextHelper.activeContent.id');

      return this.wordlists.filter((wordlist) => {
        return (
          wordlist
            .get('contents')
            .map((x) => x.get('id'))
            .indexOf(currentEntityId) !== -1
        );
      });
    }
  ),

  selectedWordlist: overridable(
    'contentWordLists.[]',
    'favWordlists.[]',
    function () {
      const contentWordLists = this.contentWordLists;
      const favWordlists = this.favWordlists;

      if (favWordlists && contentWordLists) {
        const wordlistIds = contentWordLists.map((list) => list.get('id'));
        let favListId;

        favWordlists.forEach((listId) => {
          if (wordlistIds.indexOf(listId) !== -1) {
            favListId = listId;
          }
        });

        if (favListId) {
          return contentWordLists.find((list) => list.get('id') === favListId);
        }
      }

      if (contentWordLists && contentWordLists.length > 0) {
        return contentWordLists.get('firstObject');
      }

      return null;
    }
  ),

  relatedWordLists: computed(
    'wordlists.[]',
    'contentWordLists.[]',
    function () {
      const contentWordLists = this.contentWordLists;

      return this.wordlists.filter((wordlist) => {
        return contentWordLists.indexOf(wordlist) === -1;
      });
    }
  ),

  wordlistOptions: computed(
    'contentWordLists.[]',
    'relatedWordLists.[]',
    function () {
      const contentWordLists = this.contentWordLists.map((wordlist) => {
        return { value: wordlist, label: wordlist.get('name') };
      });
      const relatedWordLists = this.relatedWordLists.map((wordlist) => {
        return { value: wordlist, label: wordlist.get('name') };
      });

      const wordlistOptions = [].concat(contentWordLists);

      if (contentWordLists.length > 0 && relatedWordLists.length > 0) {
        wordlistOptions.push({ type: 'divider' });
      }

      return wordlistOptions.concat(relatedWordLists);
    }
  ),

  // ACTIONS

  actions: {
    changeWordlist(wordlist) {
      const favWordlists = this.favWordlists;
      const contentWordLists = this.contentWordLists;

      if (contentWordLists) {
        contentWordLists.forEach((list) => {
          favWordlists.removeObject(list.get('id'));
        });
      }

      favWordlists.addObject(wordlist.get('id'));
    },

    handleFormSubmit() {
      const book = this.get('contextHelper.activeBook');
      const content = this.get('contextHelper.activeContent');
      const user = this.get('session.user');
      let selectedWordlist = this.selectedWordlist;

      if (!selectedWordlist) {
        const lang = book.get('body.language');
        const name = content.get('heading')
          ? this.intl.t('components.wordSave.wordExplanation.listName', {
              title: content.get('title'),
            })
          : book.get('title');

        selectedWordlist = this.store.createRecord('wordlist', {
          books: [book],
          language: lang,
          user: user,
          name: name,
          type: lang === 'sv_SE' ? 'concepts' : 'glossaries',
          public: this.get('session.user.role') === 'student',
        });

        this.set('selectedWordlist', selectedWordlist);
      }

      selectedWordlist
        .get('contents')
        .addObject(this.get('contextHelper.activeContent'));

      return selectedWordlist
        .save()
        .then(() => {
          const newWord = this.store.createRecord('word', {
            wordlist: selectedWordlist,
            ...this.getProperties(
              'word',
              'wordClass',
              'wordConstruction',
              'explanation',
              'examples',
              'translation'
            ),
          });

          return newWord.save().then(() => {
            this.set('wordSaved', true);

            sendEvent(this.wordlist, 'refresh');

            this.snackbar.enqueue(
              this.intl.t(
                'components.wordSave.wordExplanation.wordSavedMessage'
              ),
              {
                variant: 'success',
                autoDismiss: true,
              }
            );
          });
        })
        .then(() =>
          this.missionMode.addIncludedWordlistIfNeeded(selectedWordlist)
        )

        .catch(() => {
          this.snackbar.enqueue(
            this.intl.t(
              'components.wordSave.wordExplanation.wordNotSavedMessage'
            ),
            {
              variant: 'error',
            }
          );
        });
    },
  },
});
