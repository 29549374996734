import EmberRouter from '@ember/routing/router';
import config from 'babel/config/environment';

class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  // NOT AUTHENTICATED
  this.route('login');

  this.route('logout');

  this.route('activate', { path: '/activate/:link_hash' });

  this.route('invalidate');

  // AUTHENTICATED

  this.route('master', { path: '/' }, function () {
    this.route('nodes', { path: 'nodes/:node_id', resetNamespace: true });

    // COURSES

    this.route('courses', function () {
      this.route('new');

      this.route('show', { path: ':course_id' }, function () {
        this.route('books');
        this.route('settings');
        this.route('my-results');
        this.route('missions');

        // Keep this route for legacy reasons
        this.route('todos');

        this.route('audit', function () {
          this.route('overview');
          this.route('inbox');
          this.route('log');
          this.route('comments');

          this.route('missions', function () {
            this.route('show', { path: ':mission_id' });
          });

          // Keep these routes for legacy reasons
          this.route('todos', function () {
            this.route('show', { path: ':todo_id' });
          });
        });
      });
    });

    this.route('course-settings', { path: 'course-settings/:course_id' });

    // Keep this route for legacy reasons
    this.route('todos', { path: 'todos/:todo_id' });

    this.route('missions', { path: 'missions/:mission_id' });

    this.route('products', { path: '/products/:product_id' }, function () {
      this.route('my-results');
    });

    this.route('profile', function () {
      this.route('settings');
    });

    this.route('admin', function () {
      this.route('books');
    });

    this.route('books', { path: 'books/:book_id' });

    this.route('areas', { path: 'areas/:area_id' });

    this.route('archives', { path: 'archives/:archive_id' }, function () {
      this.route('folders', { path: 'folders/:folder_id' }, function () {
        this.route('materials', { path: 'materials/:material_id' });
      });
    });

    this.route('contents', { path: 'contents/:content_id' }, function () {
      // NOTE Route to redirect legacy section urls.
      this.route('sections', { path: '*path' });

      this.route('assignments', function () {
        this.route('new');
        this.route('show', { path: ':assignment_id' });
        this.route('edit', { path: ':assignment_id/edit' });
      });

      this.route('notes', function () {
        this.route('new');
        this.route('edit', { path: ':note_id' });
      });

      this.route('wordlists', function () {
        this.route('new');
        this.route('show', { path: ':wordlist_id' }, function () {
          this.route('words', function () {
            this.route('new');
            this.route('show', { path: ':word_id' });
            this.route('edit', { path: ':word_id/edit' });
          });
        });
        this.route('edit', { path: ':wordlist_id/edit' });
      });
    });

    this.route(
      'interactives',
      { path: 'interactives/:interactive_id' },
      function () {
        this.route(
          'diagnoses',
          { path: 'diagnoses/:diagnosis_id' },
          function () {
            this.route('show', { path: 'show/:result_id' });
            this.route('done', { path: 'done/:result_id' }, function () {
              this.route('audit');
            });
          },
        );

        this.route(
          'practices',
          { path: 'practices/:practice_id' },
          function () {
            this.route('show', { path: 'show/:practice_show_id' });
          },
        );
      },
    );

    this.route('materials', { path: 'materials/:material_id' });

    this.route('error');

    this.route('forbidden');
  });

  this.route('url-resolve', { path: '*url' });

  this.route('404');
});

export default Router;
