import { service } from '@ember/service';
import AuthenticatedRoute from 'babel/routes/authenticated-route';

export default class NotFoundRoute extends AuthenticatedRoute {
  @service contextHelper;

  @service intl;

  setupController() {
    super.setupController(...arguments);
    this.contextHelper.setActive(
      'pageTitle',
      this.intl.t('services.contextHelper.pageTitle.notFound')
    );
  }

  resetController() {
    super.resetController(...arguments);
    this.contextHelper.setActive('pageTitle', null);
  }
}
