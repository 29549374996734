import Route from '@ember/routing/route';
import { resolve } from 'rsvp';

export default class MasterProductsMyResultsRoute extends Route {
  async model() {
    const product = this.modelFor('master.products');
    const books = await resolve(product.userBooks);

    return { product, books };
  }
}
