import $ from 'jquery';

// Implementation of the communication with ILT's API (Inläsningstjänst).
class IltApi {
  constructor({ apiBaseUrl, reportListeningsDisabled = false } = {}) {
    this._apiBaseUrl = apiBaseUrl;

    // Allow listenings (royalty) to be completely disabled
    // Required when running dev/test env towards ILT's prod environment but we don't want to report any listenings.
    this._reportListeningsDisabled = reportListeningsDisabled;
  }

  /**
   * Fetch a single article
   * @param {*} articleId
   * @param {String} jwt A valid JWT
   * @return {Promise} A promise that resolves when article has been fetched
   */
  fetchArticle({ articleId, jwt, useBrowserCache = false }) {
    const urlWithParams = `${this._apiBaseUrl}/api/Student/articles/articleId?articleId=${articleId}`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: urlWithParams,
        // Disable browser caching to make sure that user always gets the latest article
        cache: useBrowserCache,
        type: 'GET',
        // Setting a timeout to prevent entire speaker functionality to break down if ILT is not responding...
        timeout: 30000,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
        },
      })
        .done((article) => {
          // console.debug(`ILT article ${articleId}:`, article);
          resolve(article);
        })
        .fail((xhr) => {
          // console.debug(`ILT fetch error:`, xhr.responseJSON);
          reject(xhr.status);
        });
    });
  }

  /**
   * Report a single listening to the "royalty API"
   * @param {*} articleId
   * @param {*} recordingId
   * @param {*} jwt
   */
  reportListening(articleId, recordingId, jwt) {
    if (this._reportListeningsDisabled) {
      return false;
    }

    const url = `${this._apiBaseUrl}/api/Student/articles/${articleId}/recordings/${recordingId}/listenings`;

    $.ajax({
      url,
      type: 'POST',
      // Setting a timeout to prevent entire speaker functionality to break down if ILT is not responding...
      timeout: 2000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
      },
    })
      .done(() => {
        // console.debug(`Successfully reported listening to ILT for articleId ${articleId}, recordingId ${recordingId}`);
      })
      .fail((xhr) => {
        console.warn(
          `Error reporting listening to ILT for articleId ${articleId}, recordingId ${recordingId}. Error code ${xhr.status}`
        );
      });
  }
}

export default IltApi;
