import EmberObject, { get as proxyGet } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { attr, belongsTo, hasMany } from '@ember-data/model';

// eslint-disable-next-line ember/no-mixins
import Userable from '../mixins/userable';
import Model from './model';

export default class CustomContentModel extends Model.extend(Userable) {
  // SETUP

  @service store;

  // RELATIONSHIPS

  @belongsTo('course', { async: true, inverse: null }) course;

  @belongsTo('entity', { async: true, inverse: null }) entity;

  @hasMany('entity', { async: true, inverse: null }) includedEntities;

  @hasMany('teacher-assignment', { async: true, inverse: null })
  includedTeacherAssignments;

  @hasMany('wordlist', { async: true, inverse: null }) includedWordlists;

  @hasMany('mission', { async: true, inverse: null }) missions;

  // ATTRIBUTES

  @attr('string') title;

  @attr('boolean', { defaultValue: false }) populated;

  @attr('number', { defaultValue: 0 }) sort;

  @attr('array', {
    defaultValue() {
      return [];
    },
  })
  includedEntitySettings;

  // PROPERTIES

  get titleWithFallback() {
    const title = this.title;

    if (isPresent(title)) {
      return title;
    }

    // eslint-disable-next-line ember/no-get
    return proxyGet(this.entity, 'title');
  }

  // METHODS

  async populate() {
    if (this.populated) {
      return this;
    }

    const entity = await this.entity;

    const [
      includedEntities,
      includedTeacherAssignments,
      includedWordlists,
      descendents,
      teacherAssignments,
      wordlists,
    ] = await Promise.all([
      this.includedEntities,
      this.includedTeacherAssignments,
      this.includedWordlists,
      entity.customizableDescendents,
      this.#loadTeacherAssignments(),
      this.#loadWordlists(),
    ]);

    includedTeacherAssignments.setObjects(
      teacherAssignments
        .filterBy('isPublished')
        .filter((teacherAssignment) => teacherAssignment.hasContent(entity))
    );

    includedWordlists.setObjects(
      wordlists
        .filterBy('isPublished')
        .filter((wordlist) => wordlist.hasContent(entity))
    );

    includedEntities.setObjects(descendents);

    descendents.forEach((descendent) => {
      this.includedEntitySettings.addObject(
        this.#getDefaultSettingsForIncludedEntity(descendent)
      );
    });

    this.populated = true;

    return this;
  }

  // PRIVATE

  #getDefaultSettingsForIncludedEntity(entity) {
    // eslint-disable-next-line ember/no-get
    const entityId = proxyGet(entity, 'id');

    // eslint-disable-next-line ember/no-get
    if (proxyGet(entity, 'isDiagnosis')) {
      return EmberObject.create({
        entityId,
        numberOfTries: null,
        timeAllowed: null,
      });
    }

    return EmberObject.create({
      entityId,
    });
  }

  async #loadTeacherAssignments() {
    const teacherAssignments = await this.store.findAll('teacher-assignment');
    await Promise.all(
      teacherAssignments.map(async (teacherAssignment) => {
        await Promise.all([
          teacherAssignment.books,
          teacherAssignment.contents,
        ]);
      })
    );
    return teacherAssignments;
  }

  async #loadWordlists() {
    const wordlists = await this.store.findAll('wordlist');
    await Promise.all(
      wordlists.map(async (wordlist) => {
        await Promise.all([wordlist.books, wordlist.contents]);
      })
    );
    return wordlists;
  }
}
