import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class NodesController extends Controller {
  queryParams = ['content', 'transition'];

  @tracked content;

  @tracked transition;
}
