import Component from '@ember/component';

export default Component.extend({
  // PUBLIC

  tagGroups: null,

  visibleTagGroupIDs: null,

  // PRIVATE

  _tagGroups: null,

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get('tagGroups')) {
      this.get('tagGroups').then((tagGroups) => {
        if (!tagGroups || !this.get('visibleTagGroupIDs')) {
          return;
        }

        const _tagGroups = tagGroups.filter((tagGroup) => {
          if (tagGroup.tags > 0) {
            tagGroup.set('tags', tagGroup.tags.join(', '));
          } else {
            tagGroup.set('tags', tagGroup.tags);
          }

          return this.get('visibleTagGroupIDs').includes(
            tagGroup.get('tag_group_id')
          );
        });

        this.set('_tagGroups', _tagGroups);
      });
    }
  }
});
