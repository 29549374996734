import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MasterCoursesShowAuditTodosShowRoute extends Route {
  @service router;

  model({ todo_id }) {
    this.router.replaceWith('master.courses.show.audit.missions.show', todo_id);
  }
}
