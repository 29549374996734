import DigilarDomElementsConfig from 'babel/utils/speaker/DigilarDomElementsConfig';
import $ from 'jquery';
import { SpeakerStringUtil } from 'speaker';

import exponents from './helpers/exponents';
import translate from './translation/translate';

/**
 * Appends sentence separators to some element types to automatically force the TTS provider to pause when reading
 */
const _addPauseAfterElements = (
  textProcessor,
  syntax,
  htmlElementsToPauseAfter
) => {
  // Append a '.' to each element's html content, but only where the content doesn't already end with a sentence separator.
  // The '.' is used to force the TTS provider to pause after reading, and also separates sentences for highlighting
  htmlElementsToPauseAfter.forEach((elementConfig) => {
    // console.log('** append sentence separator to element: ', elementConfig);

    textProcessor.appendToHtmlElementContent(
      elementConfig,
      // Also add an extra space just in case, since Acapela can't handle words separated by '.' without any space very well.
      '. ',
      // Note! Using pause function instead of '.' would be possible and "works",
      // but for example for tables all text will then be treated and highlighted as a single sentence which is probably not what we want.
      // ` ${syntax.pause(500)} `,
      (content) => !SpeakerStringUtil.endsWithSentenceSeparator(content)
    );
  });

  textProcessor.replaceHtmlElementByAttribute(
    { elementName: 'span', attributeName: 'data-speaker-pause-for' },
    (elementMetaData) => {
      const { completeElement } = elementMetaData;
      const match = completeElement.match(/data-speaker-pause-for="(\d+)"/);
      const pauseFor = (match && match[1]) || 500;
      return `${syntax.pause(pauseFor)}`;
    }
  );
};

const ruleSet = (
  language,
  { htmlElementsToRemove = [], htmlElementsToPauseAfter = [] } = {}
) => {
  return {
    initialCleanUp: (textProcessor, syntax, { withPlaceholder }) => {
      // Remove all html elements that have been configured to not be part of text processing
      htmlElementsToRemove.forEach((elementToRemove) => {
        // console.log('* Removing html element: ', elementToRemove);
        textProcessor.removeHtmlElementsByClass(elementToRemove);
      });
    },

    beforeHtmlRemoved: (textProcessor, syntax, { withPlaceholder }) => {
      _addPauseAfterElements(textProcessor, syntax, htmlElementsToPauseAfter);

      // If we have a translation for mathematical text, use it to replace any math elements
      // Otherwise, just remove the math elements
      translate(
        'math-text',
        language,
        // Callback function when translation exists
        (translation) => {
          const replacement = `${syntax.pause(250)}${translation}${syntax.pause(
            250
          )}`;

          textProcessor.replaceHtmlElementByClass(
            DigilarDomElementsConfig.MATHLIVE,
            (elementMetaData) => replacement
          );
        },
        // Callback function when translation does not exist
        () => {
          textProcessor.removeHtmlElementsByClass(
            DigilarDomElementsConfig.MATHLIVE
          );
        }
      );

      // Find alternative texts and replace them, i.e html tags for which the content should be read as something else.
      // The html for this is a wrapping <span> for the text to replace, with an additional appended <span> containing the alternative text.
      // Example:
      // <span data-speaker-alt-text>
      //   <p>This is the original <b>text/html</b> that will be replaced</p>
      //   <span class="speaker-alt-text">This is the alternative text that will be read by TTS</span>
      // </span>
      //
      // Note: The alternative text may contain other "special html elements" that can then be handled by subsequent rules
      // for example to enable fonetic support (not impemented yet) in alternative texts.
      textProcessor.replaceHtmlElementByAttribute(
        { elementName: 'span', attributeName: 'data-speaker-alt-text' },
        (elementMetaData) => {
          // Get matched element as string
          const { completeElement } = elementMetaData;

          // Using jQuery to extract replacement text from matched element
          const $element = $(completeElement);
          const $altTextElem = $element.find('.speaker-alt-text').first();

          if ($altTextElem.length === 0) {
            // Don't replace anything if alternative text is not found
            return completeElement;
          }

          // Get full inner html to enable future support for html elements within alternative text
          const altHtml = $altTextElem.get(0).innerHTML;
          // console.debug('Alternative text/html =', altHtml);

          return altHtml;
        }
      );

      textProcessor.replaceHtmlElementByAttribute(
        { elementName: 'button', attributeName: 'data-footnote' },
        () => {
          return '';
        }
      );

      // Find exponents in html and replace them with placeholders
      exponents.replaceHtmlWithPlaceholders(textProcessor);
    },

    afterHtmlRemoved: (textProcessor, syntax, { withPlaceholder }) => {
      // Replace the exponent placeholders with plain text now that all html is gone
      exponents.replacePlaceholdersWithText(textProcessor, syntax);

      // Translate some characters/expressions
      translate('unicode-arrow', language, (translation) => {
        textProcessor.replaceText('⟶', () => translation);
      });

      // Remove all emojis
      exponents.removeEmojis(textProcessor);
    },

    finalCleanUp: (textProcessor, syntax, { withPlaceholder }) => {},
  };
};

export default ruleSet;
