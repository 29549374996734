import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (generate-id) as |id|}}\n\n  <Header::Base::ComboButton::Button\n    id={{id}}\n    class=\"header-base-combo-button-primary-button {{if (eq @variant \"link\") \"header-base-combo-button-primary-button-link\"}}\"\n    aria-label={{@tooltip}}\n    ...attributes\n    @active={{@active}}\n    @onClick={{this.handleClick}}\n    as |button|\n  >\n    {{yield button}}\n  </Header::Base::ComboButton::Button>\n\n  {{#if @tooltip}}\n    <XTooltip @for={{id}}>\n      {{@tooltip}}\n    </XTooltip>\n  {{/if}}\n\n{{/let}}", {"contents":"{{#let (generate-id) as |id|}}\n\n  <Header::Base::ComboButton::Button\n    id={{id}}\n    class=\"header-base-combo-button-primary-button {{if (eq @variant \"link\") \"header-base-combo-button-primary-button-link\"}}\"\n    aria-label={{@tooltip}}\n    ...attributes\n    @active={{@active}}\n    @onClick={{this.handleClick}}\n    as |button|\n  >\n    {{yield button}}\n  </Header::Base::ComboButton::Button>\n\n  {{#if @tooltip}}\n    <XTooltip @for={{id}}>\n      {{@tooltip}}\n    </XTooltip>\n  {{/if}}\n\n{{/let}}","moduleName":"babel/components/header/base/combo-button/primary-button/index.hbs","parseOptions":{"srcName":"babel/components/header/base/combo-button/primary-button/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import scrollToTopIfNeeded from 'babel/utils/scroll-to-top-if-needed';

export default class HeaderBaseComboButtonPrimaryButtonComponent extends Component {
  @service router;

  @action handleClick() {
    scrollToTopIfNeeded(this.router);
    return this.args.onClick?.(...arguments);
  }
}
