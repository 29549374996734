import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<WaitFor @resource={{this.resource}} as |model|>\n  {{yield model}}\n  <MissionCollator\n    @missions={{model.missions}}\n    @onChange={{this.handleMissionCollatorChange}}\n    as |missions|>\n      <MissionList\n        @missions={{missions}}\n        @disabledMissions={{model.disabledMissions}}\n        @selectedMissions={{@selectedMissions}}\n        @onSelect={{@onSelect}}\n        @onDeselect={{@onDeselect}}\n      />\n  </MissionCollator>\n</WaitFor>", {"contents":"<WaitFor @resource={{this.resource}} as |model|>\n  {{yield model}}\n  <MissionCollator\n    @missions={{model.missions}}\n    @onChange={{this.handleMissionCollatorChange}}\n    as |missions|>\n      <MissionList\n        @missions={{missions}}\n        @disabledMissions={{model.disabledMissions}}\n        @selectedMissions={{@selectedMissions}}\n        @onSelect={{@onSelect}}\n        @onDeselect={{@onDeselect}}\n      />\n  </MissionCollator>\n</WaitFor>","moduleName":"babel/components/course-mission-copy-from-modal/mission-part/index.hbs","parseOptions":{"srcName":"babel/components/course-mission-copy-from-modal/mission-part/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import Resource from 'babel/utils/resource';

export default class CourseMissionCopyFromModalMissionPart extends Component {
  @service store;
  @service session;

  @tracked missionQuery = {
    page: 1,
  };

  @cached
  get resource() {
    return new Resource(async ({ page, search }) => {
      const missions = await this.store.query('mission', {
        filter: {
          course_id: this.args.course.id,
        },
        page: {
          size: 20,
          number: page,
        },
        search,
        order: [
          {
            field: 'created',
            direction: 'DESC',
          },
        ],
      });

      return {
        missions,
        disabledMissions: [],
      };
    }, this.missionQuery);
  }

  @action
  async handleMissionCollatorChange(query) {
    return this.resource.load(query);
  }
}
