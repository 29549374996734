import ApplicationAdapter from 'babel/adapters/application';
import config from 'babel/config/environment';

export default class SchoolAdapter extends ApplicationAdapter {
  host = config.userApiEndpoint;

  namespace = 'api';

  shouldBackgroundReloadRecord() {
    return false;
  }

  shouldBackgroundReloadAll() {
    return false;
  }
}
