import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import collectionClose from 'babel/utils/collection-close';
import { all } from 'rsvp';

export default class MasterInteractivesDiagnosesIndexController extends Controller {
  @service assignmentEvents;
  @service contextHelper;
  @service store;
  @service router;
  @service session;

  @action async handleStart(latestResult, allowedTime) {
    let activeAttempt = null;
    const { collection, interactive } = this.model;
    if (latestResult?.isActive) {
      activeAttempt = latestResult;
    } else {
      const [book, area, exercises] = await all([
        collection.book,
        interactive.parent,
        collection.children,
      ]);

      activeAttempt = this.store.createRecord('interactive', {
        attempt: (latestResult ? latestResult.attempt : 0) + 1,
        time_allowed: allowedTime,
        entity: collection,
        document_id: collection?.document_id,
        book,
        content: interactive,
        area,
        type: 'diagnosis_part',
        user: this.session.user,
        started: new Date(),
      });

      await activeAttempt.createAnswers(exercises);
      await activeAttempt.save();

      this.assignmentEvents.updateInteractiveForCollection(
        collection,
        activeAttempt
      );
    }

    return this.router.transitionTo(
      'master.interactives.diagnoses.show',
      interactive?.id,
      collection?.id,
      activeAttempt?.id
    );
  }

  @action handleCancel() {
    return collectionClose(this.model, this.contextHelper, this.router);
  }
}
