import DS from 'ember-data';

import Model from './model';

const { attr, belongsTo } = DS;

export default Model.extend({
  status: attr('string'),

  user: belongsTo('user', {
    async: true,
    inverse: null,
  }),
});
