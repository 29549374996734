import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TbRenderInlineElementEncloseImmersionComponent extends Component {
  @tracked hasMaterialPreview = false;

  @action openMaterialPreview() {
    this.hasMaterialPreview = true;
  }

  @action closeMaterialPreview() {
    this.hasMaterialPreview = false;
  }
}
