import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderAttachmentComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-attachment';

  asyncType = 'material';

  @tracked materialViewerIsOpen = false;

  get asyncId() {
    return this.args.part?.material_id;
  }

  get material() {
    return this.asyncData;
  }

  get materialPromise() {
    return this.store.findRecord('material', this.asyncId);
  }

  get title() {
    return this.args.part?.title || this.material?.name;
  }

  get description() {
    return this.args.part?.description;
  }

  @action openMaterialViewer() {
    this.materialViewerIsOpen = true;
  }

  @action closeMaterialViewer() {
    this.materialViewerIsOpen = false;
  }
}
