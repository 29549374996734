import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import EntityRouteSupport from 'babel/mixins/entity-route-support';
// eslint-disable-next-line ember/no-mixins
import PageViewTracker from 'babel/mixins/page-view-tracker';
import EntityContextRoute from 'babel/routes/entity-context-route';
import getCollectionData from 'babel/utils/get-collection-data';
import refreshRouteAndNotifyExerciseChange from 'babel/utils/refresh-route-and-notify-exercise-change';
import { all, hash, resolve } from 'rsvp';

const WORKSPACE_ROUTE_NAMES = ['assignments', 'wordlists', '.notes'];

function transitionIsWithinWorkspace(transition) {
  const { from, to } = transition;

  if (from && to) {
    return (
      WORKSPACE_ROUTE_NAMES.any((x) => from.name.includes(x)) ||
      WORKSPACE_ROUTE_NAMES.any((x) => to.name.includes(x))
    );
  }
  return false;
}

export default class ContentsRoute extends EntityContextRoute.extend(
  EntityRouteSupport,
  PageViewTracker
) {
  // SETUP

  @service router;

  @service speaker;

  @service courseContext;

  @service contextHelper;

  @service pusher;

  @service store;

  // PROPERTIES

  includeGrandchildren = true;

  includeCategories = true;

  // HOOKS

  afterModel({ model, teacherAssignments, wordlists }) {
    super.afterModel(...arguments);

    return hash({
      ...model.getProperties('categories', 'video', 'children'),
      teacherAssignmentUsers: all(teacherAssignments.getEach('user')),
      wordlistUsers: all(wordlists.getEach('user')),
    }).then(({ children }) => {
      return all(
        children
          .filterBy('type', 'collections')
          .map((collection) => getCollectionData(this.store, collection))
      );
    });
  }

  deactivate() {
    super.deactivate(...arguments);
    const imageCache = document.getElementById('image-cache-content-story');
    imageCache?.remove();
  }

  setupController(controller, { model }) {
    super.setupController(...arguments);

    controller.setActiveSectionIdIfNeeded();
    controller.set('isActive', true);

    this.courseContext.storeVisitedContent(model?.id);

    this.pusher.on('teacher-assignment', this.handleTeacherAssignment);
    this.pusher.on('custom-content', this.reload);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.set('isActive', false);

      controller.setProperties({
        activeSectionId: null,
        missionId: null,
        customContentId: null,
        activeExerciseId: null,
        viewAs: null,
      });
    }

    this.pusher.off('teacher-assignment', this.handleTeacherAssignment);
    this.pusher.off('custom-content', this.reload);

    this.speaker.resetSpeakerActive();
  }

  // ACTIONS

  @action
  reload() {
    refreshRouteAndNotifyExerciseChange(
      this.router,
      this.contextHelper,
      'master.contents'
    );
  }

  @action
  handleTeacherAssignment(teacherAssignment, event) {
    const content = this.context.model;

    resolve(teacherAssignment.contents)
      .then((contents) => contents.reload())
      .then(() => {
        switch (event) {
          case 'create':
            teacherAssignment.createAsEntity(content);
            break;
          case 'update':
            teacherAssignment.updateAsEntity(content);
            break;
          case 'willDelete':
            teacherAssignment.deleteAsEntity(content);
            break;
        }
      });
  }

  @action
  willTransition(transition) {
    const controller = this.controllerFor('master.contents');

    const lastTransitionWasWithinWorkspace =
      transitionIsWithinWorkspace(transition);

    controller.set(
      'lastTransitionWasWithinWorkspace',
      lastTransitionWasWithinWorkspace
    );

    if (lastTransitionWasWithinWorkspace) {
      later(controller, 'set', 'lastTransitionWasWithinWorkspace', false);
    }

    return true;
  }
}
