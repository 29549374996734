import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import routeArgsForEntity from 'babel/utils/route-args-for-entity';

export default class MissionDispatcherService extends Service {
  @service contextHelper;
  @service router;
  @service ajax;
  @service store;
  @service intl;
  @service session;
  @service snackbar;
  @service missionMode;
  @service confirmManager;

  @tracked highlightedMissions = [];

  async save(mission, { fromOverview = false } = {}) {
    try {
      await this.#save(mission);
      this.#hightlight(mission);

      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.save.snackbar.success', {
          fromOverview,
          state: mission.state,
        }),
        {
          variant: 'success',
          autoDismiss: fromOverview ? mission.isVisible : true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.save.snackbar.error'),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async schedule(mission, fields, { fromOverview = false } = {}) {
    try {
      await this.#schedule(mission, fields);

      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.schedule.snackbar.success', {
          fromOverview,
          isOngoing: mission.isOngoing,
        }),
        {
          variant: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.schedule.snackbar.error'),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async cancelSchedule(mission, { fromOverview = false } = {}) {
    const wasOngoing = mission.isOngoing;

    try {
      await this.#cancelSchedule(mission);

      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.cancelSchedule.snackbar.success',
          {
            fromOverview,
            wasOngoing,
          }
        ),
        {
          variant: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.cancelSchedule.snackbar.error'),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async toggleActivation(mission, { fromOverview = false } = {}) {
    try {
      await this.#toggleActivation(mission);

      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.toggleActivation.snackbar.success',
          {
            fromOverview,
            visible: mission.active,
          }
        ),
        {
          variant: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.toggleActivation.snackbar.error',
          {
            fromOverview,
          }
        ),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async hide(mission, { fromOverview = false } = {}) {
    const wasOngoing = mission.isOngoing;

    try {
      await this.#hide(mission);

      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.hide.snackbar.success', {
          fromOverview,
          wasOngoing,
        }),
        {
          variant: 'success',
          autoDismiss: true,
          autoDismissDelay: 6000,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.hide.snackbar.error', {
          fromOverview,
          wasOngoing,
        }),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async copyToManyCourses(mission, courses) {
    try {
      await this.#request('copy', {
        ids: [mission.id],
        course_ids: courses.mapBy('id'),
        active: mission.active,
        keep_receivers: true,
      });

      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.copyToManyCourses.snackbar.success'
        ),
        {
          variant: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.copyToManyCourses.snackbar.error'
        ),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async copyManyToCourse(missions, course, { fromOverview = false } = {}) {
    const count = missions.length;

    try {
      const newMissions = await this.#request('copy', {
        ids: missions.mapBy('id'),
        course_id: course.id,
        active: fromOverview,
      });

      this.#highlightMany(newMissions);

      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.copyManyToCourse.snackbar.success',
          {
            count,
            fromOverview,
          }
        ),
        {
          variant: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.copyManyToCourse.snackbar.error',
          {
            count,
            fromOverview,
          }
        ),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  async activateMany(missions, { fromOverview = false } = {}) {
    const count = missions.length;

    try {
      const newMissions = await this.#request('activate', {
        ids: missions.mapBy('id'),
      });

      this.#highlightMany(newMissions);
      this.snackbar.enqueue(
        this.intl.t(
          'services.missionDispatcher.activateMany.snackbar.success',
          {
            count,
            fromOverview,
          }
        ),
        {
          variant: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      this.snackbar.enqueue(
        this.intl.t('services.missionDispatcher.activateMany.snackbar.error', {
          count,
          fromOverview,
        }),
        {
          variant: 'error',
        }
      );
      throw error;
    }
  }

  delete(mission, { fromOverview = false } = {}) {
    return this.confirmManager
      .perform(this.intl.t('services.missionDispatcher.delete.confirm'), {
        danger: true,
      })
      .then(
        async () => {
          try {
            await this.#delete(mission);

            this.snackbar.enqueue(
              this.intl.t(
                'services.missionDispatcher.delete.snackbar.success',
                {
                  fromOverview,
                }
              ),
              {
                variant: 'success',
                autoDismiss: true,
              }
            );
          } catch (error) {
            this.snackbar.enqueue(
              this.intl.t('services.missionDispatcher.delete.snackbar.error', {
                fromOverview,
              }),
              {
                variant: 'error',
              }
            );
            throw error;
          }
        },
        () => {} // Needed to prevent unhandled rejection.
      );
  }

  async getRoute(mission, customContent, closeUrl) {
    if (!closeUrl) {
      if (!this.contextHelper.inMission) {
        closeUrl = this.router.currentURL;
      } else if (this.contextHelper.closeUrl) {
        closeUrl = this.contextHelper.closeUrl;
      }
    }

    const entity = await customContent.entity;

    const routeArgs = await routeArgsForEntity(
      entity,
      {
        close_url: closeUrl,
        mission: mission.id,
        custom_content: customContent.id,
      },
      this.missionMode
    );

    return routeArgs;
  }

  async open(mission, customContent, closeUrl) {
    const routeArgs = await this.getRoute(mission, customContent, closeUrl);

    return this.router.transitionTo(...routeArgs);
  }

  #hightlight(mission) {
    this.highlightedMissions.addObject(mission);
    later(() => {
      this.highlightedMissions.removeObject(mission);
    }, 1000);
  }

  #highlightMany(missions) {
    this.highlightedMissions.addObjects(missions);
    later(() => {
      this.highlightedMissions.removeObjects(missions);
    }, 1000);
  }

  async #save(mission) {
    if (!mission.startDate && mission.endDate) {
      mission.startDate = new Date();
    }

    await mission.save();
    const customContents = await mission.customContents;

    const validCustomContents = customContents.rejectBy('missingEntity');

    validCustomContents.forEach((customContent) => {
      customContent.missions.addObject(mission);
    });

    await Promise.all(
      validCustomContents.map((customContent) => {
        return Promise.all([
          customContent.includedEntities,
          customContent.includedTeacherAssignments,
          customContent.includedWordlists,
        ]);
      })
    );

    return Promise.all(validCustomContents.map((x) => x.save()));
  }

  async #delete(mission) {
    const customContents = await mission.customContents;

    customContents.forEach((customContent) => {
      customContent?.missions.removeObject(mission);
    });

    return Promise.all([customContents.save(), mission.destroyRecord()]);
  }

  async #schedule(mission, { startDate, endDate = null } = {}) {
    if (!startDate && endDate) {
      startDate = new Date();
    }

    mission.startDate = startDate;
    mission.endDate = endDate;
    mission.active = false;
    return mission.save();
  }

  async #cancelSchedule(mission) {
    return this.#schedule(mission, {
      startDate: null,
      endDate: null,
    });
  }

  async #toggleActivation(mission, { force = null } = {}) {
    mission.active = force ? force : !mission.active;
    return mission.save();
  }

  async #hide(mission) {
    if (mission.active) {
      return this.#toggleActivation(mission, { force: false });
    }

    return this.#cancelSchedule(mission);
  }

  async #request(name, data) {
    const doc = await this.ajax.request(
      `${config.endpoint}/api/v1/meta/missions/${name}`,
      true,
      {
        type: 'POST',
        data: JSON.stringify(data),
        headers: { 'Content-Type': 'application/vnd.api+json' },
      }
    );

    this.store.pushPayload(doc);

    return doc.data
      .map(({ id }) => this.store.peekRecord('mission', id))
      .compact();
  }
}
