import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TbRenderInlineElementFormatLineBreakComponent extends Component {
  /**
   * node (object)
   */

  @action onElementInsert(elem) {
    if (this._isLineBreak(elem.previousSibling)) {
      if (!this._isLineBreak(elem.nextSibling)) {
        // Add --visible state to the last in any group of line breaks
        elem.classList.add('tb-render-inline-element-line-break--visible');
      }
    }
  }

  _isLineBreak(elem) {
    return (
      elem &&
      elem.nodeType === 1 &&
      elem.classList.contains('tb-render-inline-element-line-break')
    );
  }
}
