import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: [
    {
      selectedStudent: 'selected-student'
    },
    {
      isAuditing: 'is-auditing'
    }
  ],

  selectedStudent: null,

  isAuditing: false
});
