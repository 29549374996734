import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterInteractivesDiagnosesIndexRoute extends Route {
  @service assignmentEvents;
  @service session;
  @service store;
  @service router;

  async model() {
    const { collection, interactive } = this.modelFor(
      'master.interactives.diagnoses'
    );

    const query = {
      filter: {
        node_id: collection?.id,
        user_id: this.session.user.id,
        all: true,
        type: 'diagnosis_part',
      },
    };

    const results = await this.store.query('interactive', query);

    const exercises = await collection.children;

    return {
      collection,
      interactive,
      results,
      exercises,
    };
  }

  afterModel(model) {
    // Route to diagnosis if there is an active attempt
    if (model.results?.firstObject?.isActive) {
      return this.router.replaceWith(
        'master.interactives.diagnoses.show',
        model.interactive?.id,
        model.collection?.id,
        model.results?.firstObject?.id
      );
    }
  }
}
