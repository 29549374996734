import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import { MissingContentError } from 'babel/utils/errors';
import { all, reject, resolve } from 'rsvp';

export default class MasterContentsWordlistsEditRoute extends Route {
  @service session;

  @service contextHelper;

  @service missionMode;

  @service store;

  async model({ wordlist_id }) {
    let wordlist;

    try {
      wordlist = await this.store.findRecord('wordlist', wordlist_id);
    } catch (err) {
      return reject(new MissingContentError());
    }

    await resolve(wordlist.books);

    return wordlist;
  }

  async afterModel(model) {
    const { book } = this.modelFor('master.contents');

    if (this.contextHelper.activeCustomContent) {
      await all([
        this.contextHelper.activeCustomContent.includedEntities,
        this.contextHelper.activeCustomContent.includedWordlists,
      ]);
    }

    if (!model.hasBook(book) || !this.missionMode.wordlistIsAllowed(model)) {
      return reject(NotFoundError);
    }

    if (!model.isUser(this.session.user)) {
      return reject(ForbiddenError);
    }
  }

  resetController(controller) {
    const model = controller.model;

    if (!model.isDestroyed && !model.isDestroying) {
      model.rollbackAttributes();
    }
  }
}
