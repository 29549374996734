import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TableOfContentsListItemAssignmentItemComponent extends Component {
  @service router;

  @service routerScroll;

  get itemProps() {
    const item = this.args.item.exercise;

    const props = {
      title: item.title,
      type: item.type,
      name: null,
    };

    if (this.args.space === 'content') {
      if (this.args.isInteractive) {
        props.params = [
          'master.interactives.practices.show',
          this.args.collection.id,
          item.id,
        ];
      } else {
        props.params = [
          {
            isQueryParams: true,
            values: {
              exercise: item.id,
              section: this.args.collection.id,
            },
          },
        ];
      }
    } else {
      props.params = ['master.contents.assignments.show', item.id];
      if (this.args.firstSectionQuery) {
        props.params.push({
          isQueryParams: true,
          values: {
            ...this.args.firstSectionQuery,
          },
        });
      }
    }
    return props;
  }

  @action handleClick() {
    if (
      this.args.space === 'content' &&
      !this.args.isInteractive &&
      this.router.currentRoute.queryParams.section === this.args.collection.id
    ) {
      // This covers the case where we are in a content and viewing the same
      // section as the link targets. When we then change exercise, we should
      // not first be scrolled to the top before scrolling down to the exercise.

      const initialPreserveScrollPositionState =
        this.routerScroll.preserveScrollPosition;

      this.routerScroll.preserveScrollPosition = true;

      later(this, () => {
        this.routerScroll.preserveScrollPosition =
          initialPreserveScrollPositionState;
      });
    }
  }
}
