import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { trackPlaySettingEvent } from 'babel/utils/matamo-events';
import DigilarLanguageUtil from 'babel/utils/speaker/DigilarLanguageUtil';
import DigilarSpeakerConfig from 'babel/utils/speaker/DigilarSpeakerConfig';
import { storageFor } from 'ember-local-storage';
import { WebPageTranslationDetector } from 'speaker';

const TextHighlightingOptions = {
  NONE: 'none',
  WORDS_ONLY: 'words',
  SENTENCES_ONLY: 'sentences',
  EVERYTHING: 'everything',
};

const DEFAULT_LANGUAGE = 'sv';

export default class ContentSpeakerSettingsComponent extends Component {
  /**
   * model (object)
   * onShowSpeakerInfo (bool)
   */

  @service intl;

  @service speaker;

  @service languages;

  @storageFor('play-settings') settings;

  speechSpeedOptions = null;

  speechSpeedValue = null;

  defaultSpeechSpeed = 2; // Default to "Standard" when disabled

  constructor() {
    super(...arguments);

    this.digilarSpeakerConfig = new DigilarSpeakerConfig({
      getContextData: () => {
        return {};
      },
      getSpeakerSettings: () => this.settings,
    });

    this.markings = [
      {
        label: this.intl.t(
          'components.contentPlaysettings.markings.wordsAndSentences'
        ),
        value: TextHighlightingOptions.EVERYTHING,
      },
      {
        label: this.intl.t('components.contentPlaysettings.markings.onlyWords'),
        value: TextHighlightingOptions.WORDS_ONLY,
      },
      {
        label: this.intl.t(
          'components.contentPlaysettings.markings.onlySentences'
        ),
        value: TextHighlightingOptions.SENTENCES_ONLY,
      },
      {
        label: this.intl.t('components.contentPlaysettings.markings.none'),
        value: TextHighlightingOptions.NONE,
      },
    ];

    this.speechSpeedOptions = [
      {
        label: this.intl.t(
          'components.contentPlaysettings.speechSpeed.verySlow'
        ),
        value: 0,
      },
      {
        label: this.intl.t('components.contentPlaysettings.speechSpeed.slow'),
        value: 1,
      },
      {
        label: this.intl.t(
          'components.contentPlaysettings.speechSpeed.standard'
        ),
        value: 2,
      },
      {
        label: this.intl.t('components.contentPlaysettings.speechSpeed.fast'),
        value: 3,
      },
    ];

    this.speechSpeedValue = this.settings.get('speechSpeed') + 1;
  }

  get speechSpeedDisabled() {
    return !this.speaker.speechSpeedSettingHasEffectForActiveSpeaker;
  }

  get selectedMarking() {
    let highlighting = this.settings.get('highlighting');
    let highlightingOption = TextHighlightingOptions.NONE;

    if (highlighting.words && highlighting.sentences) {
      highlightingOption = TextHighlightingOptions.EVERYTHING;
    } else if (highlighting.words) {
      highlightingOption = TextHighlightingOptions.WORDS_ONLY;
    } else if (highlighting.sentences) {
      highlightingOption = TextHighlightingOptions.SENTENCES_ONLY;
    }

    return highlightingOption;
  }

  set selectedMarking(markingType) {
    let highlighting = {
      words: [
        TextHighlightingOptions.EVERYTHING,
        TextHighlightingOptions.WORDS_ONLY,
      ].includes(markingType),
      sentences: [
        TextHighlightingOptions.EVERYTHING,
        TextHighlightingOptions.SENTENCES_ONLY,
      ].includes(markingType),
    };
    trackPlaySettingEvent('Ändra markering för talsyntes', markingType);
    this.settings.set('highlighting', highlighting);
  }

  /*
   * The voices to choose from depend on the language param
   */
  get voices() {
    let currentLanguage = this._currentLanguage();

    let voices = [];

    voices = this.digilarSpeakerConfig
      .supportedVoices(currentLanguage)
      .map((voice) => {
        return {
          label: voice.label,
          value: voice.id,
        };
      });

    voices.sort((v1, v2) => {
      return v1.label.localeCompare(v2.label);
    });

    return voices;
  }

  set voices(voice) {
    let language = this._currentLanguage();
    if (language) {
      let voices = this.settings.get('voices');

      // Note! Must set voices property to a completely new object, or else observers listening to changes won't fire
      voices = {
        ...voices,
        [language]: voice,
      };

      this.settings.set('voices', voices);
    }
  }

  /**
   * Get the selected voice for current language.
   * If user has selected a voice for the given language (through action setVoice), use that voice. Otherwise, use configured default voice for that language.
   */
  get selectedVoice() {
    let currentLanguage = this._currentLanguage();

    let selectedVoice = this.settings.get('voices', [currentLanguage]);

    if (
      !selectedVoice ||
      !this.digilarSpeakerConfig.isValidVoice(currentLanguage, selectedVoice)
    ) {
      // Fall back to default voice if no voice, or an invalid voice, has been selected
      selectedVoice = this.digilarSpeakerConfig.preferredVoice(currentLanguage);
    }

    return selectedVoice;
  }

  set selectedVoice(voice) {
    let language = this._currentLanguage();
    if (language) {
      let voices = this.settings.get('voices');

      // Note! Must set voices property to a completely new object, or else observers listening to changes won't fire
      voices = {
        ...voices,
        [language]: voice,
      };

      trackPlaySettingEvent('Ändra röst för talsyntes', voice);
      this.settings.set('voices', voices);
    }
  }

  get isTranslated() {
    return WebPageTranslationDetector.isTranslated();
  }

  /**
   * Update the selected voice for current language in local storage, to make it the pre-selected voice for content of that language
   */

  @action
  setFollowText(followText) {
    trackPlaySettingEvent('Ändra beteende - Textmedföljning');
    this.settings.set('followText', followText);
  }
  @action
  setPreferSpeechSynthesis(preferSpeechSynthesis) {
    trackPlaySettingEvent('Ändra beteende - Alltid talsyntes');
    this.settings.set('preferSpeechSynthesis', preferSpeechSynthesis);
  }
  @action
  setSpeechSpeed(speed) {
    this.speechSpeedValue = speed - 1;
    trackPlaySettingEvent(
      'Ändra rösthastighet',
      undefined,
      this.speechSpeedValue
    );
    this.settings.set('speechSpeed', this.speechSpeedValue);
  }

  @action
  showSpeakerInfo() {
    trackPlaySettingEvent('Visa instruktioner');
    // Call injected action function
    this.args.onShowSpeakerInfo();
  }

  _currentLanguage() {
    return this.isTranslated
      ? WebPageTranslationDetector.detectLanguage()
      : DigilarLanguageUtil.parseLanguage(
          this.languages.contentLanguage || DEFAULT_LANGUAGE
        );
  }
}
