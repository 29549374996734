import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<XAccordion @expanded={{this.expanded}} @disabled={{this.disabled}} as |a|>\n  <a.header @isClickable={{true}} class=\"p0\">\n    <div class=\"py1 {{if this.disabled 'disabled text-muted'}}\">\n      <div class=\"text-heading flex flex-center\">\n        <DcIcon @name=\"book\" class=\"nr1\" />\n        {{@book.title}}\n      </div>\n\n      {{#if this.disabled}}\n        {{t \"components.courseAudit.teacherAssignments.noTeacherAssignments\"}}\n      {{/if}}\n    </div>\n  </a.header>\n\n  <a.content>\n    {{#each this.teachers as |teacher|}}\n      <CourseAudit::TeacherAssignments::Teacher\n        @teacher={{teacher}}\n        @teacherAssignments={{this.bookTeacherAssignments}}\n        @selectedStudents={{@selectedStudents}}\n        @openAuditMode={{@openAuditMode}}\n      />\n    {{/each}}\n  </a.content>\n</XAccordion>", {"contents":"<XAccordion @expanded={{this.expanded}} @disabled={{this.disabled}} as |a|>\n  <a.header @isClickable={{true}} class=\"p0\">\n    <div class=\"py1 {{if this.disabled 'disabled text-muted'}}\">\n      <div class=\"text-heading flex flex-center\">\n        <DcIcon @name=\"book\" class=\"nr1\" />\n        {{@book.title}}\n      </div>\n\n      {{#if this.disabled}}\n        {{t \"components.courseAudit.teacherAssignments.noTeacherAssignments\"}}\n      {{/if}}\n    </div>\n  </a.header>\n\n  <a.content>\n    {{#each this.teachers as |teacher|}}\n      <CourseAudit::TeacherAssignments::Teacher\n        @teacher={{teacher}}\n        @teacherAssignments={{this.bookTeacherAssignments}}\n        @selectedStudents={{@selectedStudents}}\n        @openAuditMode={{@openAuditMode}}\n      />\n    {{/each}}\n  </a.content>\n</XAccordion>","moduleName":"babel/components/course-audit/teacher-assignments/index.hbs","parseOptions":{"srcName":"babel/components/course-audit/teacher-assignments/index.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class CourseAuditTeacherAssignmentsComponent extends Component {
  /**
   * Arguments:
   * selectedStudents (array)
   * book (object)
   * groups (array)
   * course (object)
   * teacherAssignments (array)
   * openAuditMode (function)
   */

  @tracked expanded = false;

  @cached get bookTeacherAssignments() {
    const teacherAssignments = this.args.teacherAssignments;
    const book = this.args.book;

    if (teacherAssignments && book) {
      return teacherAssignments.filter(
        (teacherAssignment) =>
          teacherAssignment.isPublished &&
          teacherAssignment.hasBook(book) &&
          teacherAssignment.contents.length > 0
      );
    }

    return null;
  }

  @cached get teachersProxy() {
    let promise = resolve();

    if (this.bookTeacherAssignments) {
      promise = all(
        this.bookTeacherAssignments.map((assignment) =>
          resolve(assignment.user)
        )
      ).then((teachers) => teachers.uniqBy('id').sortBy('showname'));
    }

    return load(promise, this);
  }

  get teachers() {
    return this.teachersProxy.isResolved ? this.teachersProxy.value : null;
  }

  get disabled() {
    return this.bookTeacherAssignments?.length >= 1 ? false : true;
  }
}
