import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<nok-account logout-url=\"/logout\" {{did-insert this.passToken}}></nok-account>\n", {"contents":"<nok-account logout-url=\"/logout\" {{did-insert this.passToken}}></nok-account>\n","moduleName":"babel/components/header/base/user-button/index.hbs","parseOptions":{"srcName":"babel/components/header/base/user-button/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class UserButton extends Component {
  @service session;

  @action
  passToken(nokAccountComponent) {
    nokAccountComponent.accessToken = () => this.session.accessToken;
  }
}
