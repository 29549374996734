import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterTodosRoute extends Route {
  @service router;

  beforeModel(transition) {
    const { todo_id } = transition.to.params;
    return this.router.replaceWith('master.missions', todo_id);
  }
}
