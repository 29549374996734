import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import config from 'babel/config/environment';

export default class CourseMainTeacherLicenseAlert extends Component {
  @service router;

  get hasPortalUrl() {
    return config.nokPortalURL && config.nokPortalURL !== 'null';
  }

  get portalUrl() {
    return this.hasPortalUrl ? config.nokPortalURL : '';
  }

  get createCourseUrl() {
    return `${this.portalUrl}/course-settings/${
      this.args.course?.id
    }?return-url=${encodeURIComponent(this.returnURL)}`;
  }

  get returnURL() {
    return this.router.currentURL;
  }

  @action
  openCourseSettings() {
    window.location.href = this.createCourseUrl;
  }
}
