import { action } from '@ember/object';
import EmberObject from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class AttachmentPreviewImageComponent extends Component {
  @tracked imageViewerIsOpen = false;

  get imageObj() {
    return EmberObject.create({
      source: this.args.file?.url,
    });
  }

  @action
  closeImageViewer() {
    this.imageViewerIsOpen = false;
  }
}
