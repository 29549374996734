import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class SettingModel extends Model {
  @belongsTo('course', { async: false, inverse: null }) course;

  // books is deprecated and is only user for setting up hiddenBooks
  // for older courses. It can be removed in the future.
  @hasMany('entity', { inverse: null, async: true }) books;

  @hasMany('entity', { inverse: null, async: true }) hiddenBooks;

  @hasMany('files', { async: true, inverse: null }) shortcutFiles;

  @belongsTo('help-list', { async: true, inverse: null }) helpList;

  @attr('boolean', {
    defaultValue() {
      return true;
    },
  })
  showKey;

  @attr('boolean', {
    defaultValue() {
      return true;
    },
  })
  showDiagnosis;

  @attr('string') language;

  @attr('string') cover_url;

  @attr('date') created;

  @attr('date') updated;

  @attr({
    defaultValue() {
      return {
        overview_display_first: 'shortcuts',
        sorted_shortcut_ids: [],
        sorted_mission_ids: [],
      };
    },
  })
  metadata;

  @hasMany('entity') shortcuts;
}
