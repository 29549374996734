import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DigilarSpeakerConfig from 'babel/utils/speaker/DigilarSpeakerConfig';

export default class SpeakerService extends Service {
  // INTERNAL PROPERTIES ///////////////////////////////////////////////////////

  // By default, speaker is never activated.
  @tracked active = false;

  @tracked activeSpeakerAdapter = null;

  @tracked encourageListeningEnabled = false;

  @tracked digilarRecordedSpeechEnabled = false;

  // true will automatically start playing speaker when content is loaded and speaker is active.
  @tracked _autoplay = false;

  // Keep an array of speaker contexts. Each context will register/unregister itself.
  _activeSpeakerContexts = null;

  constructor() {
    super(...arguments);
    this._activeSpeakerContexts = [];
    this.digilarSpeakerConfig = new DigilarSpeakerConfig({
      getContextData: () => {
        // No context data required here
        return {};
      },
    });
  }

  // PUBLIC/COMPUTED PROPERTIES ////////////////////////////////////////////////

  // A property that can be set to true to temporarily disable speaker completely,
  // for example for specific contents that we don't want speaker functionality for
  // Use with care!
  disabled = false;

  // When a recorded speech plugin is initialized/activated with at least one audio source,
  // this property will be set to that provider's id, one of: ['digilar', 'ilt']
  activeRecordedSpeechProvider = null;

  get autoplay() {
    return this._autoplay;
  }

  // Contains the uuid of the SpeakerContext that should be considered as currently active.
  // Makes it possible to for example restrict keyboard controls to a single SpeakerContext
  activeSpeakerContextUuid = null;

  // Contains the uuid of the SpeakerContext that last started playing an audio source.
  // Makes it possible to pause all other speaker contexts when a new context has started playing.
  lastPlayingSpeakerContextUuid = null;

  // To avoid pitch changes when playback rate changes, the user's speech speed setting does not always have an effect.
  // This property will change whenever a new speaker context becomes active
  // The "Adapt" menu can then check this flag to determine how to handle it.
  speechSpeedSettingHasEffectForActiveSpeaker = true;

  // The speaker needs to know if a story is displayed in cinema mode (image only) or not (image+text)
  // Will change by action toggling cinema mode on/off
  storyCinemaMode = false;

  // PUBLIC FUNCTIONS /////////////////////////////////////////////////////////

  // Set speaker active state
  setSpeakerActive(isActive) {
    this.active = !!isActive;
  }

  // Toggle speaker active state
  toggleSpeakerActive() {
    this.active = !this.active;
  }

  // Reset active state, for example when route is changed
  resetSpeakerActive() {
    this._autoplay = false;
  }

  // Set autoplay on/off
  setAutoplay(autoplay) {
    this._autoplay = !!autoplay;
  }

  registerSpeakerContext(context) {
    const uuids = this._activeSpeakerContexts?.map((cx) => {
      return cx.getUuid();
    });

    if (!uuids.includes(context.getUuid())) {
      this._activeSpeakerContexts.push(context);
      // console.debug(`- Registered context ${contextUuid} with speaker service. Registered contexts =`, this.get('_activeSpeakerContextUuids'));
    }
  }

  unregisterSpeakerContext(context) {
    this._activeSpeakerContexts = this._activeSpeakerContexts.filter((obj) => {
      return obj.getUuid() !== context.getUuid();
    });
  }

  countRegisteredSpeakerContexts() {
    return this._activeSpeakerContexts.length;
  }

  getRegisteredSpeakerContexts() {
    return this._activeSpeakerContexts;
  }
}
