import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { resolve } from 'rsvp';

export default class MasterCoursesShowBooksController extends Controller {
  @service session;

  get books() {
    if (this.session.user.isTeacher) {
      return this.model.productBooks.uniqBy('id');
    }
    return this.model.course.books;
  }

  get selectedBooks() {
    return this.model.course.books;
  }

  get selectableBooks() {
    return this.session.user?.isTeacher;
  }

  get canToggleBooks() {
    return this.books && this.session.user?.isTeacher;
  }

  @action
  async handleSelectBook(book) {
    const course = await resolve(this.model.course);
    const setting = await resolve(course?.setting);

    if (!setting) return;

    setting.hiddenBooks.removeObject(book);
    setting.save();
  }

  @action
  async handleDeselectBook(book) {
    const course = await resolve(this.model.course);
    const setting = await resolve(course?.setting);

    if (!setting) return;

    setting.hiddenBooks.addObject(book);
    setting.save();
  }
}
