class DigilarPollyProxy {
  constructor({ ajax, apiBaseUrl }) {
    this._ajax = ajax;
    this._apiBaseUrl = apiBaseUrl;
  }

  synthesizeSpeech({
    text,
    language,
    voice,
    speechSpeed,
    textToSpeechData,
    generateSpeechMarks,
  }) {
    return new Promise((resolve, reject) => {
      // Post request to our own backend Polly endpoint
      const url = `${this._apiBaseUrl}/api/v1/content/polly/synthesize`;

      const data = {
        textId: textToSpeechData.ttsTextId,
        text,
        language,
        voiceName: voice.id,
        voiceEngineType: voice.engineType,
        speechSpeed,
      };

      const useAuth = true;

      this._ajax
        .request(url, useAuth, {
          type: 'POST',
          data,
        })
        .then((response) => {
          // console.debug(`Response from backend Polly API:`, response);
          resolve(JSON.parse(response));
        })
        .catch(reject);
    });
  }
}

export default DigilarPollyProxy;
