import Component from '@glimmer/component';

export default class TbRenderInlineElementExtensionFormulaComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */

  get value() {
    return this.args.node?.attributes?.findBy('key', 'data-value')?.value || '';
  }

  get content() {
    return this.args.node?.children?.firstObject?.content;
  }

  get formula() {
    return this.value || this.content;
  }
}
