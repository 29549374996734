import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import generateId from 'compton/utils/generate-id';
import showFilePicker from 'compton/utils/show-file-picker';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CourseShortcutsComponent extends Component {
  @service session;
  @service intl;

  // PROPERTIES

  @tracked expandedEntities;
  @tracked entityImportModalIsOpen;
  @tracked attachLinkModalOpen;
  @tracked fileChooseModalOpen;
  @tracked sortable;
  @tracked sortingShortcuts;
  @tracked isMoving = false;
  @tracked selectedItem = null;
  @tracked saveBtn = null;
  @tracked isCourseShortcutActionMenuOpen = false;

  originalPositions = null;
  newPosition = null;
  shortcutsContainer = false;
  cursorX = 0;
  cursorY = 0;
  elements = [];
  dragging = null;
  target = null;
  direction = null;

  // HOOKS

  constructor() {
    super(...arguments);
    this.expandedEntities = [];
  }

  @cached
  get settingProxy() {
    const promise = resolve(this.args.course?.setting);
    return load(promise, this);
  }

  get setting() {
    return this.settingProxy.isResolved ? this.settingProxy.value : null;
  }

  get firstBook() {
    return this.args.course.get('books.firstObject');
  }

  get shortcuts() {
    const shortcuts = [
      ...(this.setting?.shortcutFiles?.toArray() || []),
      ...(this.setting?.shortcuts?.toArray() || []),
    ];

    // The following fixes diffs between sort array and relation.
    // It can be removed once the data has been cleaned.
    const sorted = (this.args.course?.sortedShortcutIds || [])
      .map((id) => {
        return shortcuts.find((shortcut) => shortcut.id === id);
      })
      .filter((item) => item);

    shortcuts.forEach((item) => {
      if (!sorted.includes(item)) {
        sorted.push(item);
      }
    });

    return sorted;
  }

  @action
  handleCourseShortcutActionMenuSelect(value) {
    switch (value) {
      case 'internal_link':
        this.entityImportModalIsOpen = true;
        break;
      case 'link':
        this.attachLinkModalOpen = true;
        break;
      case 'archive':
        this.fileChooseModalOpen = true;
        break;
      case 'file':
        this.showFilePicker();
        break;
      default:
        break;
    }
  }

  @action
  handleEntityImportModal(entities) {
    this.args.course.sortedShortcutIds =
      this.args.course?.sortedShortcutIds || [];

    this.args.course?.shortcuts?.addObjects(entities);
    this.args.course?.sortedShortcutIds?.addObjects(entities.mapBy('id'));

    this.setting?.save();
  }

  @action
  deleteShortcutEntity(entity) {
    this.args.course?.shortcuts?.removeObject(entity);
    this.args.course?.sortedShortcutIds?.removeObject(entity.id);

    this.setting?.save();
  }

  @action
  deleteShortcutFile(entity) {
    this.setting?.shortcutFiles?.removeObject(entity);
    this.args.course?.sortedShortcutIds?.removeObject(entity.id);

    this.setting?.save();
  }

  @action
  handleFileAttachmentAdd(attachment) {
    this.args.course.sortedShortcutIds =
      this.args.course?.sortedShortcutIds || [];

    const file = [].concat(attachment);
    this.args.course?.sortedShortcutIds?.addObjects(file.mapBy('id'));
    return resolve(this.setting?.shortcutFiles).then((files) => {
      files.addObjects(file);
      if (!this.setting) return;
      this.setting.shortcutFiles = files;
      this.setting.save();
    });
  }

  @action
  handleEntityImportModalExpand(entity) {
    this.expandedEntities.addObject(entity);
  }

  @action
  handleEntityImportModalCollapse(entity) {
    this.expandedEntities.removeObject(entity);
  }

  @action
  handleEntityModalToggle() {
    this.entityImportModalIsOpen = !this.entityImportModalIsOpen;
  }

  @action
  handleFileAttachmentClose() {
    this.fileChooseModalOpen = false;
  }

  @action
  handleLinkAttachmentClose() {
    this.attachLinkModalOpen = false;
  }

  @action
  toggleSort() {
    if (this.sortable) {
      this._deleteEventListener();
    } else {
      this.sortingShortcuts = [...this.shortcuts];
      document.addEventListener('mousemove', this.mousePosition);
      document.addEventListener('touchmove', this.mousePosition);
    }
    this.sortable = !this.sortable;
  }

  @action
  selectSort(item) {
    this.originalPositions = null;
    this.elements = Array.from(this.shortcutsContainer.children);
    this.selectedItem = item;
    document.addEventListener('keydown', this.moveItem);
  }

  @action
  moveItem(event) {
    const currentIndex = this.sortingShortcuts.indexOf(this.selectedItem);

    if (currentIndex !== -1) {
      if (event.key === 'ArrowLeft' && currentIndex > 0) {
        [
          this.sortingShortcuts[currentIndex],
          this.sortingShortcuts[currentIndex - 1],
        ] = [
          this.sortingShortcuts[currentIndex - 1],
          this.sortingShortcuts[currentIndex],
        ];
      } else if (
        event.key === 'ArrowRight' &&
        currentIndex < this.sortingShortcuts.length - 1
      ) {
        [
          this.sortingShortcuts[currentIndex],
          this.sortingShortcuts[currentIndex + 1],
        ] = [
          this.sortingShortcuts[currentIndex + 1],
          this.sortingShortcuts[currentIndex],
        ];
      }
    }

    const keyCode = event.keyCode || event.which;
    if (keyCode === 9 && this.selectedItem) {
      event.preventDefault();
    }

    if (keyCode === 13) {
      this._resetMovingElements();
      this.args.course.sortShortcuts(this.sortingShortcuts);
      this.saveBtn.focus();
      this.selectedItem = null;
      event.preventDefault();
      document.removeEventListener('keydown', this.moveItem);
    }

    if (keyCode === 27) {
      this._resetMovingElements();
      this.sortable = !this.sortable;
      this.selectedItem = null;
      event.preventDefault();
      document.removeEventListener('keydown', this.moveItem);
    }

    if (!this.selectedItem) return;

    this._calculateNewPositions();
  }

  showFilePicker() {
    const onChange = (fileData) => {
      this.fileUpload(fileData);
    };

    return showFilePicker({
      multiple: false,
      accept: null,
      capture: null,
      onChange,
    });
  }

  async fileUpload(fileData) {
    const file = await this.args.onFileUpload(fileData);
    this.handleFileAttachmentAdd(file);
  }

  get scope() {
    return generateId('shortcuts');
  }

  @action
  onStart() {
    this.originalPositions = null;
  }

  @action
  droppableAccepts(droppableData, draggableData) {
    let droppableAccepts =
      draggableData?.scope && draggableData?.scope === droppableData?.scope;

    if (
      droppableAccepts &&
      !(droppableData.index == draggableData.index) &&
      !this.isMoving
    ) {
      this.isMoving = true;
      this.newPosition = droppableData.index;
      this.elements = Array.from(this.shortcutsContainer.children);

      const movingObject = this.shortcuts?.objectAt(draggableData.index);
      const slotObject = this.shortcuts?.objectAt(droppableData.index);

      const indexToRemove = this.sortingShortcuts.findIndex(
        (item) => item === movingObject
      );

      const slotIndex = this.sortingShortcuts.findIndex(
        (item) => item === slotObject
      );

      this.dragging = this.elements[draggableData.index];
      this.target = this.elements[droppableData.index];

      if (
        this.direction == null ||
        (this.direction === 'up' && slotIndex + 1 == indexToRemove) ||
        (this.direction === 'down' && slotIndex == indexToRemove + 1)
      ) {
        this.isMoving = false;
        return;
      }

      this.sortingShortcuts.removeAt(indexToRemove);
      this.sortingShortcuts.insertAt(slotIndex, movingObject);

      this._calculateNewPositions();
    }

    return droppableAccepts;
  }

  @action
  mousePosition(event) {
    if (event.type === 'touchmove') {
      event = event.touches[0];
    }

    this.cursorX = event.clientX;
    this.cursorY = event.clientY;

    if (this.target) {
      const targetRect = this.target.getBoundingClientRect();
      const draggingRect = this.dragging.getBoundingClientRect();

      if (targetRect.width < draggingRect.width || draggingRect.width > 250) {
        this.direction = 'ignore';
        return;
      }

      const targetCenter = targetRect.left + targetRect.width / 2;
      const mouseX = this.cursorX - draggingRect.width / 2 + 32;

      if (mouseX > targetCenter) {
        this.direction = 'up';
      } else {
        this.direction = 'down';
      }
    }
  }

  _calculateNewPositions() {
    let shortcutsContainerWidth = this.shortcutsContainer.offsetWidth;

    let row = 0;
    let tempWidth = 0;

    if (!this.originalPositions) {
      this.originalPositions = this.elements.map((child, index) => {
        tempWidth = tempWidth + child.offsetWidth;
        if (tempWidth >= shortcutsContainerWidth) {
          tempWidth = child.offsetWidth;
          row++;
        }

        return {
          object: this.shortcuts[index],
          width: child.offsetWidth,
          height: child.offsetHeight,
          row: row + 1,
          top: row * child.offsetHeight,
          left: tempWidth - child.offsetWidth,
        };
      });
    }

    tempWidth = 0;
    row = 0;

    let newPositions = this.sortingShortcuts.map((shortcut) => {
      const object = this.originalPositions.find(
        (item) => item.object === shortcut
      );
      tempWidth = tempWidth + object.width;
      if (tempWidth >= shortcutsContainerWidth) {
        tempWidth = object.width;
        row++;
      }

      return {
        object: object.object,
        width: object.width,
        height: object.height,
        row: row + 1,
        top: row * object.height,
        left: tempWidth - object.width,
      };
    });

    this._moveToNewPositions(newPositions);
  }

  _moveToNewPositions(newPositions) {
    this.originalPositions.map((originalPosition, index) => {
      const element = this.elements[index];
      const newPosition = newPositions.find(
        (newPosition) => newPosition.object === originalPosition.object
      );

      const xCoord = newPosition.left - originalPosition.left;
      const yCoord = newPosition.top - originalPosition.top;

      element.style.transform = `translate(${xCoord}px, ${yCoord}px)`;
    });

    this.target = null;
    this.direction = null;

    later(() => {
      this.isMoving = false;

      const mouseMoveEvent = new MouseEvent('mousemove', {
        bubbles: true,
        clientX: this.cursorX,
        clientY: this.cursorY,
      });

      document.dispatchEvent(mouseMoveEvent);
    }, 300);
  }

  _resetMovingElements() {
    this.elements.map((child) => {
      child.style.transition = `transform 0s`;
      child.style.transform = `translate(0px, 0px)`;
    });
  }

  _deleteEventListener() {
    document.removeEventListener('mousemove', this.mousePosition);
    document.removeEventListener('touchmove', this.mousePosition);
  }

  @action
  onEnd() {
    this._resetMovingElements();
    this.args.course.sortShortcuts(this.sortingShortcuts);
  }
}
