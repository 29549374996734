import Component from '@ember/component';
import { observer } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import babelEnv from 'babel/config/environment';
import {
  elemOffset,
  scrollTo,
  viewportBottom,
  viewportTop,
} from 'babel/utils/chapter-viewport';
import Poller from 'babel/utils/Poller';
import DigilarAcapelaCloudProviderFactory from 'babel/utils/speaker/DigilarAcapelaCloudProviderFactory';
import DigilarAcapelaProviderFactory from 'babel/utils/speaker/DigilarAcapelaProviderFactory';
import DigilarAudioSourceHandler from 'babel/utils/speaker/DigilarAudioSourceHandler';
import DigilarDomHelper from 'babel/utils/speaker/DigilarDomHelper';
import digilarDomSpeakerEventHandler from 'babel/utils/speaker/digilarDomSpeakerEventHandler';
import DigilarLanguageUtil from 'babel/utils/speaker/DigilarLanguageUtil';
import DigilarPollyProviderFactory from 'babel/utils/speaker/DigilarPollyProviderFactory';
import DigilarRecordedSpeechProvider from 'babel/utils/speaker/DigilarRecordedSpeechProvider';
import DigilarSpeakerConfig from 'babel/utils/speaker/DigilarSpeakerConfig';
import digilarSpeakerControls from 'babel/utils/speaker/digilarSpeakerControls';
import digilarSpeakerOptions from 'babel/utils/speaker/digilarSpeakerOptions';
import DigilarSpeakerType from 'babel/utils/speaker/DigilarSpeakerType';
import IltApi from 'babel/utils/speaker/ilt/IltApi';
import IltDataParser from 'babel/utils/speaker/ilt/IltDataParser';
import IltRecordedSpeechProvider from 'babel/utils/speaker/ilt/IltRecordedSpeechProvider';
import digilarGeneralRuleSet from 'babel/utils/speaker/text-processing-rule-sets/digilar-general';
import digilarGeneralRuleSetSv from 'babel/utils/speaker/text-processing-rule-sets/digilar-general-sv';
import { storageFor } from 'ember-local-storage';
import {
  SpeakerAdapter,
  SpeakerContext,
  SpeakerDomHandler,
  SpeakerTextProcessorRuleSet,
  TtsProviders,
  WebPageTranslationDetector,
} from 'speaker';

export default Component.extend({
  classNames: ['speaker-context-component'],

  // INJECT SERVICES
  ajax: service(),
  speakerService: service('speaker'),
  speakerSession: service(),
  languages: service(),

  // Set to true to require a user interaction to start playing. Needed especially on Safari/iOS when autoplay fails.
  requireUserInteraction: false,
  // And set this to a function to get called after the required user interaction happens.
  onRequiredUserInteraction: null,

  actions: {
    onRequiredUserInteraction() {
      this.set('requireUserInteraction', false);

      if (this.onRequiredUserInteraction) {
        this.onRequiredUserInteraction();
      }
    },
    onCancelRequiredUserInteraction() {
      this.set('requireUserInteraction', false);

      if (this.onCancelRequiredUserInteraction) {
        this.onCancelRequiredUserInteraction();
      }
    },
  },

  init() {
    this._super(...arguments);

    // Keep track of which providers are initialized
    this._recordedSpeechProviderId = null;
    this._textToSpeechProviderId = null;

    // State variable to handle special cases when a speaker should autoplay when initialized
    this._autoplaySpeakerOnLoad = {
      [DigilarSpeakerType.MAIN]: this.get('speakerService.autoplay'),
      [DigilarSpeakerType.POPUP]: false,
    };

    // A Digilär specific extension of the default speakerConfig.
    this._digilarSpeakerConfig = new DigilarSpeakerConfig({
      getContextData: () => {
        let contextData = {
          contentType: this.get('contentType'),
          autoplay: this.get('speakerService.autoplay'),
          digilarRecordedSpeechEnabled:
            (
              !!this.get('contentInfo.digilarRecordedSpeechEnabled') || 
              (
                !!this.get('speakerService.digilarRecordedSpeechEnabled') &&
                !this.get('contentInfo.iltRecordedSpeechEnabled')
              )
            ) &&
            !this.get('speakerSettings.preferSpeechSynthesis'),
          iltRecordedSpeechEnabled:
            !!this.get('contentInfo.iltRecordedSpeechEnabled') &&
            !this.get('speakerSettings.preferSpeechSynthesis'),
        };
        return contextData;
      },
      getSpeakerSettings: () => this.get('speakerSettings'),
    });

    this._domHelper = new DigilarDomHelper({
      speakerConfig: this._digilarSpeakerConfig,

      /**
       * Compile an object with properties that will be used by some of the DOM helper's functions
       * This is necessary because Ember's didRender() will be called several times before the html is fully loaded,
       * so we must be able to detect if content is ready to initialize the speaker.
       */
      getContextData: () => {
        let exercise = this.get('exercise');

        // TODO: Maybe try to break this contextData object apart since it right now contains more data than always necessary.
        let contextData = {
          sections: this.get('sections'),
          section: this.get('section'),
          exercise,
          exerciseType: exercise ? exercise.get('body.exercise_type') : null,
          mode: this.get('readSettings.mode'),
          contentType: this.get('contentType'),
          contentTitle: this.get('contentInfo.contentTitle'),
          onlyImages: this.get('speakerService.storyCinemaMode'),
          isPageTranslated: WebPageTranslationDetector.isTranslated(),

          // Util functions required to scroll to text blocks
          viewportUtils: {
            viewportTop,
            viewportBottom,
            elemOffset,
            scrollTo,
          },
        };

        return contextData;
      },

      getSingleRecordedSpeechAudioSourceConfig:
        this._getSingleRecordedSpeechAudioSourceConfig.bind(this),
    });

    // SpeakerContext is the class that holds all the speaker functionality together.
    this._speakerContext = new SpeakerContext({
      // speakerConfig is the general customizable configuration for the entire SpeakerContext.
      speakerConfig: this._digilarSpeakerConfig,

      // Not to be confused with speakerConfig, getSpeakerOptions() returns an object used when initializing the actual Speaker instance
      getSpeakerOptions: this._getSpeakerOptions.bind(this),

      // Customize the control functions for clicks on text block and play/pause buttons
      // We do it to be able to resume a suspended Web Audio API context on user interaction
      speakerControls: this._getSpeakerControls.bind(this),

      speakerTypes: Object.values(DigilarSpeakerType),
      defaultSpeakerType: () => DigilarSpeakerType.MAIN,

      getContentLanguage: this._getContentLanguage.bind(this),

      handleSpeakerEvent: this._handleSpeakerEvent.bind(this),

      checkIfReadyToInitializeSpeaker:
        this._checkIfReadyToInitializeSpeaker.bind(this),

      // The SpeakerAdapter is the glue between SpeakerContext and the actual Speaker instance
      newSpeakerAdapter: this._newSpeakerAdapter.bind(this),

      // The DOM handler is responsible for everything that has to do with the DOM
      newDomHandler: this._newDomHandler.bind(this),

      // Optional function that will get called after the SpeakerContext has been successfully initialized.
      // The param audioSources contains information about all the initialized audio sources.
      onInit: (audioSources) => {
        // Apply custom logic here if necessary.
        // IMPORTANT NOTE: Do not change the html of the text blocks here.
        // That should be done after an audio source has loaded and text meta data has been applied.
        // Otherwise the word highlighting will most likely break.
        // Instead, add custom 'load' event handling to digilarDomSpeakerEventHandler if the html of text blocks needs to be modified.
        this._afterSpeakerContextInit(audioSources);
      },

      // Handling of focus for SpeakerContext, to prevent more than one SpeakerContext from having focus at a time.
      onContextFocusChange: (contextUuid, hasFocus) => {
        if (hasFocus) {
          // Change value in service to notify other speaker-contexts about the focus change and thereby disable them in speakerContextUuidObserver()
          this.set('speakerService.activeSpeakerContextUuid', contextUuid);
        }
      },

      // esline-disable-next-line no-unused-vars
      onSpeakerActive: (speakerType, speakerContext, speakerAdapter) => {
        // To avoid pitch changes when playback rate changes, the user's speech speed setting does not always have an effect.
        // Let the speaker service know this, for example to allow the "Adapt" menu to hide/disable the playback speed options.
        // Note that we only want to do this if the context actually has focus
        if (speakerContext.hasContextFocus()) {
          this._updateSpeechSpeedSetting();

          // Set (or reset) current recorded speech provider when context receives focus. This will be used to display different graphics (icon) depending on provider
          this.set(
            'speakerService.activeRecordedSpeechProvider',
            this._recordedSpeechProviderId
          );
        }
      },

      initialContextFocus: () => !!this.get('focus'),
      pauseOnContextFocusLost: () => {
        // Instead of auto-pausing as soon as focus is lost, we handle pausing manually when another context has started playing.
        // By doing this, we allow the user to click around within other speaker contexts without always pausing the speaker context that is currently playing.
        // (Note: A click within a speaker context always gives focus to that context, see click handler in didInsertElement())
        //
        // For implementation, see lastPlayingSpeakerContextUuidObserver() and _handleSpeakerEvent()
        return false;
      },
    });

    this.set('speakerService.activeSpeakerAdapter', this._speakerContext._speakerSandboxes.main.speakerAdapter);

    this.get('speakerService').registerSpeakerContext(
      this._speakerContext
    );

    this._updateTextHighlightingOptions();
    this._initObservedProperties();
  },

  // LOCAL STORAGE
  speakerSettings: storageFor('play-settings'),
  readSettings: storageFor('read-setting'),

  _updateSpeechSpeedSetting() {
    const speechSpeedSettingHasEffect =
      // For HTML audio the speech speed always has an effect because the pitch remains the same even if playback speed changes,
      // (unlike Web Audio API where pitch changes along with playback rate).
      this._getSpeakerOptions().forceHtml5Audio ||
      // On Web Audio API, we require that TTS is used (i.e. no recorded speech provider) AND that the TTS provider's speed is used instead of using playback rate on the audio source.
      (!this.get('speakerService.activeRecordedSpeechProvider') &&
        this._digilarSpeakerConfig.useTtsSpeechSpeed());

    try {
      this.set(
        'speakerService.speechSpeedSettingHasEffectForActiveSpeaker',
        speechSpeedSettingHasEffect
      );
    } catch (err) {
      // We catch any error just to suppress the error message in case the speakerService object has been destroyed before the onSpeakerActive callback happens.
    }
  },

  _afterSpeakerContextInit(audioSources) {
    let currentRecordedSpeechProviderId = null;
    let currentTextToSpeechProviderId = null;

    if (audioSources) {
      // It's enough to find a single recorded speech audio source to find a provider since we never mix providers
      const recordedSpeechAudioSource = audioSources.find(
        (src) =>
          src.isRecordedSpeech &&
          src.recordedSpeechConfig &&
          // ILT sources can be marked as invalid so if provider data is specified we need to check that audio is valid
          (!src.recordedSpeechConfig.recordedSpeechProviderData ||
            !src.recordedSpeechConfig.recordedSpeechProviderData.invalid)
      );

      if (recordedSpeechAudioSource) {
        currentRecordedSpeechProviderId =
          recordedSpeechAudioSource.recordedSpeechConfig
            .recordedSpeechProviderId;
      }

      // Same for TTS, it's enough to find a single TTS audio source to find a provider since we never mix providers
      const textToSpeechAudioSource = audioSources.find(
        (src) => !src.isRecordedSpeech
      );

      if (
        textToSpeechAudioSource &&
        textToSpeechAudioSource.textToSpeechConfig
      ) {
        currentTextToSpeechProviderId =
          textToSpeechAudioSource.textToSpeechConfig.textToSpeechProviderId;
      }
    }

    // Remember which providers are used for later settings such as speech speed
    this._recordedSpeechProviderId = currentRecordedSpeechProviderId;
    this._textToSpeechProviderId = currentTextToSpeechProviderId;

    // Update playback speed immediately now that we know the providers
    this._updatePlaybackSpeed();

    // Set (or reset) current recorded speech provider if the context has focus. This will be used to display different graphics (icon) depending on provider
    if (this._speakerContext.hasContextFocus()) {
      this.set(
        'speakerService.activeRecordedSpeechProvider',
        currentRecordedSpeechProviderId
      );
    }

    // Now we can also update some settings regarding speech when we know what audio sources have been initialized
    this._updateSpeechSpeedSetting();
  },

  _getContentLanguage() {
    const defaultLanguage = 'sv_SE';
    let contentLanguage = this.get('languages.contentLanguage');

    // When in exercise context, the language of the exercise has priority over the content language.
    if (this.get('contentType') === 'practice') {
      const exercise = this.get('exercise');
      if (exercise && exercise.get('body.language')) {
        contentLanguage = exercise.get('body.language');
        // console.debug(`Exercise language: ${contentLanguage}`);
      }
    }

    return DigilarLanguageUtil.parseLanguage(
      contentLanguage || defaultLanguage
    );
  },

  _getSpeakerControls(speakerContext, speakerAdapter) {  
    // Function that dynamically creates a promise that must resolve before normal click functionality (play/pause) is executed.
    const getOnClickPromise = () => {
      if (this.get('requireUserInteraction')) {
        return this._resumeAfterAutoplayError(speakerAdapter, {
          autoplay: false,
        });
      } else {
        return Promise.resolve();
      }
    };
    return digilarSpeakerControls(
      speakerContext,
      this._digilarSpeakerConfig,
      speakerAdapter,
      getOnClickPromise
    );
  },

  _getSpeakerOptions(speakerType) {
    return digilarSpeakerOptions(this._digilarSpeakerConfig, {
      getSelectedSpeechSpeed: this._getSelectedSpeechSpeed.bind(this),
    });
  },

  _newDomHandler({ speakerType, speakerUuid }) {
    let domHandler = new SpeakerDomHandler({
      speakerType,
      speakerUuid,

      // Inject the Digilär specific DOM helper to the generic DOM handler
      domHelper: this._domHelper,

      speakerConfig: this._digilarSpeakerConfig,

      // Returning true will allow default event handling in dom handler to execute, false will prevent it.
      // Note: customSpeakerEventHandler is always executed before default event handling.
      //
      // TODO: If needed to perform custom event handling _after_ default event handling,
      // a possible simple solution could be to return a function instead of true/false when delayed execution is necessary.
      customSpeakerEventHandler: (domHandler, event, params) => {
        let contextData = {
          mode: this.get('readSettings.mode'),
          contentType: this.get('contentType'),
          onlyImages: this.get('speakerService.storyCinemaMode'),
        };
        return digilarDomSpeakerEventHandler(
          domHandler,
          event,
          params,
          contextData,
          this._domHelper
        );
      },

      //
      // Makes it possible to extend and/or prevent default text block markup to handle special cases
      // Returning true will allow default speaker markup to be applied, false will prevent it
      //
      applyCustomSpeakerMarkup: (
        speakerSandbox,
        language,
        initializedAudioSources,
        speakerControls
      ) => {
        return this._domHelper.applyCustomSpeakerMarkup(
          speakerSandbox,
          language,
          initializedAudioSources,
          speakerControls
        );
      },

      // When a new text block starts playing, this functions is called to find out if the text block should be scrolled into view.
      // The actual scrolling implementation is located in the custom dom helper class injected to SpeakerDomHandler.
      shouldFollowText: (speakerType) => {
        return !!this.get('speakerSettings.followText');
      },

      getTextHighlightingOptions: (speakerType) => {
        // Example: { words: true, sentences: true }
        return this.get('speakerSettings.highlighting');
      },

      // Called when the DOM handler detects breaking changes to the DOM, such as initialized text blocks that don't exist anymore.
      onBreakingDomChange: (domHandler, { message }) => {
        // console.debug(`!!! Breaking DOM change detected! Will reset speaker "${speakerType}" ${speakerUuid}: ${message}`);

        // Reset speaker context with default options
        let options = {};
        this._speakerContext.reset(
          speakerType,
          domHandler.getSpeakerContextDomElement(),
          options
        );
      },
    });

    return domHandler;
  },

  _getSingleRecordedSpeechAudioSourceConfig() {
    let config = null;

    if (this.get('contentType') === 'story') {
      // Content nodes have a setting that states if legacy audio file (one file per section) should be used instead of component-level audio.
      const useLegacyFile = !!this.get(
        'contentInfo.useLegacyRecordedSpeechForStory'
      );

      if (useLegacyFile) {
        let sectionId = this.get('section').document_id;

        config = {
          // We use the sectionId as audio source id to be able to distinguish it from regular text blocks IDs.
          id: sectionId,
          recordedSpeechUrl: `${this._digilarSpeakerConfig.recordedSpeechBaseUrl()}/${sectionId}`,

          // The provider ID is necessary for the speaker to know which provider plugin to use for the audio source
          recordedSpeechProviderId: 'digilar',

          recordedSpeechProviderData: {},
        };
      }
    }

    return config;
  },

  _newDigilarRecordedSpeechProvider() {
    let singleRecordedSpeechConfig =
      this._getSingleRecordedSpeechAudioSourceConfig();

    let params = {
      recordedSpeechBaseUrl: this._digilarSpeakerConfig.recordedSpeechBaseUrl(),
    };
    if (singleRecordedSpeechConfig !== null) {
      params.singleRecordedSpeechConfig = singleRecordedSpeechConfig;
      params.prioritizeSingleRecordedSpeechAudioSource =
        this._digilarSpeakerConfig.alwaysUseSingleRecordedSpeechFileForStory();
    }

    return new DigilarRecordedSpeechProvider(params);
  },

  _newIltRecordedSpeechProvider() {
    //console.debug('sections = ', this.get('sections'));

    // Helper function to get a different set of article IDs depending on content type
    const getArticleIds = () => {
      // TODO: Exclude sections that have not been synched to ILT.

      let articleIds = [];
      const contentType = this.get('contentType');
      const readMode = this.get('readSettings.mode');

      if (contentType === 'chapter' && readMode === 'scroll') {
        // In chapter+scroll mode, article data must be fetched for all sections to be able to init speaker correctly with ILT support
        articleIds = this.get('sections').map((section) => section.id);
      } else {
        // For remaining cases, just need to fetch article data for a single section
        const section = this.get('section');
        if (section) {
          articleIds.push(section.get('id'));
        }
      }

      // console.debug(`articleIds:`, articleIds);

      return articleIds;
    };

    const speakerSession = this.get('speakerSession');

    let params = {
      enablePlayNextOnLoadError:
        this._getSpeakerOptions().enablePlayNextOnLoadError,

      articleIds: getArticleIds(),

      // Note: Injecting api + data parser to enable mocking.
      // Change to use IltMockApi and IltMockDataParser to bypass ILT communication during development. See commented out code below.
      iltApi: new IltApi({
        apiBaseUrl: babelEnv.speaker.ilt.apiBaseUrl,
        reportListeningsDisabled: babelEnv.speaker.ilt.reportListeningsDisabled,
      }),
      iltDataParser: new IltDataParser(),

      // iltApi: new IltMockApi({
      //   sentenceGroupId: '123',
      // }),
      // iltDataParser: new IltMockDataParser({
      //   sentenceGroupId: '123',
      // }),

      iltError: () => {
        return speakerSession.get('iltError').call(speakerSession);
      },

      // Inject function to get JWT
      getJwt: () => {
        return speakerSession.get('getJwt').call(speakerSession);
      },
    };

    return new IltRecordedSpeechProvider(params);
  },

  _newSpeakerAdapter() {
    const _newAudioSourceHandler = () => {
      // For a story, there is currently a single recorded speech file for the whole section rather than one for each text block component.
      // Pass the config for that audio source as an extra param, and let the SpeakerAdapter handle the logic for which audio sources to initialize.
      // Note: In the future there will probably be stories with one recorded speech file per component, so we must be able to handle both cases which is done below.

      let singleRecordedSpeechConfig =
        this._getSingleRecordedSpeechAudioSourceConfig();

      let params = {};
      if (singleRecordedSpeechConfig !== null) {
        params.prioritizeSingleRecordedSpeechAudioSource =
          this._digilarSpeakerConfig.alwaysUseSingleRecordedSpeechFileForStory();
      }
      params.recordedSpeechProviderPriority =
        this._digilarSpeakerConfig.recordedSpeechProviderPriority();

      return new DigilarAudioSourceHandler(params);
    };

    return new SpeakerAdapter({
      speakerConfig: this._digilarSpeakerConfig,

      // Factory method to get recorded speech plugins for all providers that should be supported
      getRecordedSpeechProviderPlugins: () => {
        const selectedPlugins = {};

        const pluginFactoryFunctions = {
          digilar: this._newDigilarRecordedSpeechProvider.bind(this),
          ilt: this._newIltRecordedSpeechProvider.bind(this),
        };

        this._digilarSpeakerConfig
          .recordedSpeechProviderPriority()
          .forEach((providerId) => {
            if (pluginFactoryFunctions[providerId]) {
              selectedPlugins[providerId] =
                pluginFactoryFunctions[providerId]();
            }
          });

        // console.debug('Selected recorded speech plugins:', selectedPlugins);

        return selectedPlugins;
      },

      // Specify the priority order of recorded speech providers. Highest priority first in array.
      recordedSpeechProviderPriority:
        this._digilarSpeakerConfig.recordedSpeechProviderPriority,

      // Factory method to get TTS provider plugins for all TTS providers that should be supported
      getTextToSpeechProviderPlugins: () => {
        const newAcapelaPlugin = () => {
          const httpClientParams = {
            apiBaseUrl: babelEnv.endpoint,
            ajax: this.get('ajax'),
          };

          return DigilarAcapelaProviderFactory.newAcapelaPlugin({
            httpClientParams,
            speechSpeed: this._digilarSpeakerConfig.useTtsSpeechSpeed()
              ? this._getSelectedSpeechSpeed('acapela')
              : 1.0,
            generalDigilarTextProcessingRuleSets:
              this._getDigilarTextProcessingRuleSets.bind(this),
          });
        };

        const newPollyPlugin = () => {
          const pollyProxyParams = {
            apiBaseUrl: babelEnv.endpoint,
            ajax: this.get('ajax'),
          };

          return DigilarPollyProviderFactory.newPollyPlugin({
            pollyProxyParams,
            speechSpeed: this._digilarSpeakerConfig.useTtsSpeechSpeed()
              ? this._getSelectedSpeechSpeed('polly')
              : 1.0,
            generalDigilarTextProcessingRuleSets:
              this._getDigilarTextProcessingRuleSets.bind(this),
          });
        };

        const newAcapelaCloudPlugin = () => {
          const httpClientParams = {
            apiBaseUrl: babelEnv.endpoint,
            ajax: this.ajax,
          };

          return DigilarAcapelaCloudProviderFactory.newAcapelaCloudPlugin({
            httpClientParams,
            speechSpeed: this._digilarSpeakerConfig.useTtsSpeechSpeed()
              ? this._getSelectedSpeechSpeed('acapela')
              : 1.0,
            generalDigilarTextProcessingRuleSets:
              this._getDigilarTextProcessingRuleSets.bind(this),
          });
        };

        // Return an object of plugins, indexed by TTS provider id.
        return {
          [TtsProviders.Acapela.id()]: newAcapelaPlugin(),
          [TtsProviders.Polly.id()]: newPollyPlugin(),
          [TtsProviders.AcapelaCloud.id()]: newAcapelaCloudPlugin(),
        };
      },

      // Inject a factory method to create a customizable audio source handler,
      // used for example to look up recorded speech audio sources and to handle special cases during speaker initialization
      // Using factory method to be able to initialize it with different context params every time it is needed.
      getAudioSourceHandler: () => _newAudioSourceHandler(),

      // Custom function that can invalidate a text for one or all TTS providers depending on the text content.
      // Return true for valid text, false for invalid text.
      isValidTtsText: (ttsProviderId, rawText) => {
        return this._domHelper.isValidTtsElem(rawText);
      },
    });
  },

  /**
   * Custom checks to know if we can initialize the speaker, for example by checking if we have all params necessary.
   * NOTE! Do not check any html/dom here, that is the work of the DOM handler.
   * @param {*} speakerSandbox
   */
  _checkIfReadyToInitializeSpeaker(speakerSandbox) {
    if (this._domHelper.hasUnresolvedTextBlocks(this.element)) {
      return {
        ready: false,
        message: 'Speaker blocks not resolved',
      };
    }
    if (!this.get('speakerService.active')) {
      return {
        ready: false,
        message: 'Speaker functionality not activated',
      };
    }

    if (this.get('speakerService.disabled')) {
      return {
        ready: false,
        message: 'Speaker functionality temporarily disabled',
      };
    }

    let sections = this.get('sections');
    let exercise = this.get('exercise');
    let contentType = this.get('contentType');

    // Check that necessary data exists for different content types
    if (['story', 'chapter', 'workflow'].includes(contentType)) {
      if (!sections || sections.length === 0) {
        return {
          ready: false,
          message: 'No sections to render',
        };
      }
    } else if (contentType === 'practice') {
      if (!exercise) {
        return {
          ready: false,
          message: 'No exercise exists',
        };
      }
    } else {
      return {
        ready: false,
        message: `Unknown content type "${contentType}"`,
      };
    }

    // Ok to go ahead an initialize speaker
    return {
      ready: true,
    };
  },

  _resumeAfterAutoplayError(speakerAdapter, { autoplay }) {
    return speakerAdapter
      .resumeAfterAutoplayError({ autoplay })
      .then(() => {
        // console.debug(`Successfully resumed suspended context with autoplay = ${autoplay}`);
      })
      .catch((error) => {
        // console.debug(`Error resuming suspended context with autoplay = ${autoplay}`, error);
      });
  },

  /**
   * If the generic event handling implemented in SpeakerContext is not enough, additional logic should be implemented here.
   *
   * Note! This event handler should only handle pure speaker functionality
   * Anyhing concerning the DOM should be done in the customSpeakerEventHandler injected into SpeakerDomHandler
   *
   * @return false to prevent normal event handler in SpeakerContext to execute
   */
  _handleSpeakerEvent(event, speakerSandbox) {
    let { speakerType, speakerAdapter, domHandler } = speakerSandbox;

    // Handle autoplay errors (mostly Safari/iOS) to be able to show a "click to start playing" info box to the user
    if (event.type === 'autoPlayError') {
      // console.debug('autoPlayError event =', event);

      this.set('requireUserInteraction', true);

      // After an autoplay error, allow the SpeakerAdapter to handle resuming any existing Web Audio API Context.
      // Note: Calling play directly (without resume) may have unwanted consequences depending on the platform/browser and if Web Audio API is used instead of HTML5 audio.
      // Note: The callback functions are setup here to have access to the correct speakerAdapter.
      this.onRequiredUserInteraction = () => {
        // Resume+play
        this._resumeAfterAutoplayError(speakerAdapter, { autoplay: true });
      };
      this.onCancelRequiredUserInteraction = () => {
        // Resume only
        this._resumeAfterAutoplayError(speakerAdapter, { autoplay: false });
      };
    }

    if (speakerType === DigilarSpeakerType.MAIN) {
      // When receiving an 'end' event, we want to auto-switch content under some circumstances
      if (event.type === 'end') {
        // Goto next section if in step mode or story, and the last text block finished playing
        let mode = this.get('readSettings.mode');
        let contentType = this.get('contentType');

        let shouldGotoNextSection =
          event.isLastAudioSource &&
          contentType !== 'workflow' &&
          contentType === 'story';

        if (shouldGotoNextSection) {
          let nextSection = this.get('nextSection');

          if (nextSection) {
            const success = nextSection();
            // Enable autoplay on successful section change, disable otherwise
            this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] = success;
          }
        }
      } else if (event.type === 'play') {
        // When speaker is playing we want to autoplay for example when changing section.
        this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] = true;

        // Also reset the require user interaction flag, just in case the info dialog is open...
        this.set('requireUserInteraction', false);
      } else if (event.type === 'pause') {
        this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] = false;
      }
    }

    // Always report to service when this context has started playing
    if (event.type === 'play') {
      if (
        this.get('speakerService.lastPlayingSpeakerContextUuid') !==
        this._speakerContext.getUuid()
      ) {
        this.set(
          'speakerService.lastPlayingSpeakerContextUuid',
          this._speakerContext.getUuid()
        );
      }
    }

    if (event.type === 'load') {
      // console.debug('load event:', event);

      // For load events, check that meta data have been applied properly. Else reset speaker.
      // This might happen for example if Ember rerenders the page after the speaker(s) have already added their html attributes to the text blocks.
      // The rerender causes all speaker attributes to disappear, forcing us to start over with speaker initialization.
      if (event.textMetaDataError) {
        console.warn(
          `Text meta data error in load event. Will attempt to reset speaker: ${event.textMetaDataError}`
        );
        let options = {}; // Use default options
        this._speakerContext
          .reset(speakerType, domHandler.getSpeakerContextDomElement(), options)
          .then(() => {
            // If we get a textMetaDataError and the page is translated
            // assume that we've reached a textblock that contains
            // untranslated text. Chrome only translates text that is currently visible
            // but the speaker finds all textblocks on the page including untranslated text.
            // In that case force the speaker to play next text block.
            if (WebPageTranslationDetector.isTranslated())
              this._speakerContext.withSpeaker(
                speakerType,
                (speakerSandbox) => {
                  const { speakerAdapter } = speakerSandbox;
                  // Can't use speakerAdatper.next(), need to force the speaker to autoplay
                  // therefore using _speaker.next(true, true)
                  speakerAdapter._speaker.next(true, true);
                }
              );
          });
      }
    }

    // Allow SpeakerContext event handler to execute
    return true;
  },

  /**
   * Get provider-independent text processing rule sets to include in text processing based on content type etcetera.
   * (Note: Some standard rule sets will always be applied by SpeakerContext.)
   */
  _getDigilarTextProcessingRuleSets(language) {
    let ruleSets = [
      // Add a Digilär specific rule set, shared for all TTS providers
      new SpeakerTextProcessorRuleSet(
        digilarGeneralRuleSet(language, {
          htmlElementsToPauseAfter:
            this._domHelper.getHtmlElementsToPauseAfter(),
          htmlElementsToRemove:
            this._domHelper.getHtmlElementsToRemoveFromTextProcessing(),
        })
      ),
    ];

    const getLanguageSpecificRuleSet = (language) => {
      const languageRuleSets = {
        sv: digilarGeneralRuleSetSv,
      };

      return languageRuleSets[language]
        ? new SpeakerTextProcessorRuleSet(languageRuleSets[language]())
        : null;
    };

    // Check if there is any language specific rule set for the current language
    const languageSpecificRuleSet = getLanguageSpecificRuleSet(language);
    if (languageSpecificRuleSet) {
      ruleSets.push(languageSpecificRuleSet);
    }

    return ruleSets;
  },

  _getSelectedSpeechSpeed(providerId) {
    // Note: speechSpeedSetting will be a value [0-3] matching an index of this._digilarSpeakerConfig.speechSpeedValues()
    // +1 is a fix for localstorage after adding very slow speed
    let speechSpeedSetting = this.get('speakerSettings.speechSpeed') + 1;

    // Map selected setting to an actual speed value
    let playbackSpeed =
      this._digilarSpeakerConfig.speechSpeedValues(providerId)[
        speechSpeedSetting
      ];

    return playbackSpeed || 1.0;
  },

  /**
   * This seems to be necessary to make observers fire on changes for properties that are not used elsewhere
   */
  _initObservedProperties() {
    this.get('readSettings');
    this.get('section');
  },

  /**
   * This observer will either activate or deactivate the SpeakerContext when a new "context UUID" is observed from the speaker service
   */
  speakerContextUuidObserver: observer(
    'speakerService.activeSpeakerContextUuid',
    function () {
      const hasFocus =
        this.get('speakerService.activeSpeakerContextUuid') ===
        this._speakerContext.getUuid();
      this._speakerContext.setContextFocus(hasFocus);
    }
  ),

  /**
   * This observer will pause all speakers within this context as soon as another context has started playing.
   */
  lastPlayingSpeakerContextUuidObserver: observer(
    'speakerService.lastPlayingSpeakerContextUuid',
    function () {
      const isThisContextPlaying =
        this.get('speakerService.lastPlayingSpeakerContextUuid') ===
        this._speakerContext.getUuid();

      // Pause all speakers within this context if another context has started playing
      if (!isThisContextPlaying) {
        this._speakerContext.forEachSpeaker(({ speakerAdapter }) => {
          speakerAdapter.pause();
        });
      }
    }
  ),

  speakerActiveObserver: observer('speakerService.active', function () {
    this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] = false;

    if (this.get('speakerService.active')) {
      this._startContentPoller();
    } else {
      this._stopContentPoller();
    }
  }),

  cinemaModeObserver: observer('speakerService.storyCinemaMode', function () {
    // Don't do anything if speaker is not active
    if (!this.get('speakerService.active')) {
      return false;
    }

    // When turning "only images" on/off, we should not reset any speakers, but some speaker controls and highlighting need to be changed
    let contentType = this.get('contentType');

    if (contentType === 'story') {
      this._speakerContext.withSpeaker(
        DigilarSpeakerType.MAIN,
        (speakerSandbox) => {
          let { speakerAdapter, domHandler } = speakerSandbox;

          if (this.get('speakerService.storyCinemaMode')) {
            const speakerControls = this._getSpeakerControls(
              this._speakerContext,
              speakerAdapter
            );
            this._domHelper.addSpeakerControlsToStoryImage(
              speakerSandbox,
              speakerControls
            );

            let progressBarValue = 0;
            let audioSource = speakerAdapter.getCurrentAudioSource();
            if (audioSource) {
              progressBarValue = audioSource.position / audioSource.duration; // A value [0-1]
            }

            this._domHelper.applySpeakerMarkUpToStoryImage(domHandler, {
              numberOfAudioSources:
                speakerAdapter.numberOfInitializedAudioSources(),
              highlightAsSelected: speakerAdapter.isPlaying(),
              highlightAsPlaying: speakerAdapter.isPlaying(),
              // If speaker is paused, we always highlight the image as paused, since it doesn't matter which text block has been paused
              highlightAsPaused: speakerAdapter.isPaused(),
              // The progress bar must also be initialized to current progress value or else it won't be visible until the next progress event is received
              progressBarValue,
            });
          } else {
            this._domHelper.cleanUpStoryImage(domHandler);
          }
        }
      );
    }
  }),

  /**
   * The popup speaker only needs to be reset under specific circumstances, very different from the main speaker.
   * Only if the content itself changes and we need to create and load audio sources must it be reset.
   *
   * Add more observed attributes to this method if necessary.
   * If the method starts to look like observePropsToResetMainSpeaker(), consider merging into one method instead and just do the same check for all (both) speaker types
   */
  observePropsToResetPopupSpeaker: observer(
    'exercise',
    function (sender, attributeName) {
      // Reset the popup speaker after next render. If not waiting until 'afterRender' there's a risk that all components haven't been rendered yet.
      scheduleOnce('afterRender', this, () => {
        // console.debug(`*** Resetting POPUP speaker because attribute "${attributeName}" changed ***`);
        let options = {
          loadFromAudioSourceIndex: 0, // Always start from first audio source when changing exercise
          autoplayFirstLoadedAudioSource: false, // Never autoplay when changing exercise
        };
        return this._speakerContext.reset(
          DigilarSpeakerType.POPUP,
          this._domHelper.findPopupSpeakerDomContextElem(),
          options
        );
      });
    }
  ),

  /**
   * Add an observer to the attributes that will/might affect the main speaker's behavior.
   * When any of these attributes has changed, we need to clean up the existing main speaker and create a new one.
   */
  // TODO: Not all attributes are relevant for all content types. Maybe break this apart into different observers for different content types.
  observePropsToResetMainSpeaker: observer(
    'section',
    'exercise',
    'languages.contentLanguage',
    'speakerService.active',
    'speakerSettings.speechSpeed',
    'speakerSettings.preferSpeechSynthesis',
    'readSettings.mode',
    'readSettings.onlytext',
    'speakerSettings.voices',
    'contentInfo.contentTitle',
    function (sender, attributeName) {
      // console.debug(`--- speaker-context ${this._speakerContext.getUuid()}: Attribute "${attributeName}" affecting Speaker changed. Will reset speaker...`);

      // If the observed attribute is anything except 'speakerService.active', the speaker should only be reset if active
      if (
        attributeName !== 'speakerService.active' &&
        !this.get('speakerService.active')
      ) {
        return false;
      }

      // For some attributes we only want to init/reset the speaker if it has not already been initialized.
      // This is because the attribute might trigger the observer even if the content has not changed.
      if (attributeName === 'contentInfo.contentTitle') {
        let isInitialized = false;

        this._speakerContext.withSpeaker(
          DigilarSpeakerType.MAIN,
          ({ speakerAdapter }) => {
            isInitialized = speakerAdapter.isInitialized();
          }
        );

        if (isInitialized) {
          return false;
        }
      }

      // Get some information from the speaker's sandbox
      let ttsAudioSourceExists = false;
      this._speakerContext.withSpeaker(
        DigilarSpeakerType.MAIN,
        ({ speakerAdapter }) => {
          // Find out if at least one TTS source has been initialized which is necessary to know if we need to reload audio source when some attributes have changed.
          ttsAudioSourceExists = speakerAdapter.hasTtsAudioSources();
        }
      );

      // If speech speed has changed, speaker doesn't need to be reset if there are no TTS audio sources or if we're not using the TTS provider's speech speed.
      // When that is the case, the separate observer for 'speakerSettings.speechSpeed' will adjust playback speed instead.
      if (attributeName === 'speakerSettings.speechSpeed') {
        if (
          !ttsAudioSourceExists ||
          !this._digilarSpeakerConfig.useTtsSpeechSpeed()
        ) {
          return false;
        }
      }

      // If any voices have changed, the speaker needs to be reset since any content may contain any number of different languages/voices
      if (attributeName === 'speakerSettings.voices') {
        // Don't reset speaker if there are no TTS audio sources
        if (!ttsAudioSourceExists) {
          return false;
        }
      }

      // Disable autoplay when toggling between recorded speech and TTS to prevent the
      // speaker from starting all over from the beginning again.
      if (attributeName === 'speakerSettings.preferSpeechSynthesis') {
        this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] = false;
      }

      let mode = this.get('readSettings.mode');
      let contentType = this.get('contentType');

      // Do not reset speaker if section has changed in scroll mode when viewing a chapter, since that will happen when scrolling up/down
      if (
        contentType === 'chapter' &&
        mode === 'scroll' &&
        attributeName === 'section'
      ) {
        return;
      }

      // Stop any speaker that might be playing
      this._speakerContext.forEachSpeaker(({ speakerAdapter }) => {
        speakerAdapter.stop();
      });

      // ...and then reset the speaker after next render. If not waiting until 'afterRender' there's a risk that all components haven't been rendered yet.
      scheduleOnce('afterRender', this, async () => {
        const shouldSetContextFocus = () => {
          let giveFocusToContext = false;

          if (
            this.get('speakerService').countRegisteredSpeakerContexts() === 1
          ) {
            // If this is the only registered context, we always give it focus.
            // (This can happen for example in a workflow, if an exercise context has focus and is destroyed as we move back to the workflow context)
            giveFocusToContext = true;
            // console.debug(`** This context is alone. Giving it focus! **`);
          } else {
            // If in exercise/practice content, we should give focus only if the speaker context is within a workflow,
            // because in that case the other context(s) within the workflow are not currently visible.
            if (
              this.get('contentType') === 'practice' &&
              this._domHelper.isSpeakerContextWithinWorkflow(this.element)
            ) {
              giveFocusToContext = true;
              // console.debug(`** This exercise context is within a workflow. Giving it focus! **`);
            }
          }

          return giveFocusToContext;
        };

        // We need to find out if the context should receive focus when being reset, for keyboard controls to work.
        // When we have several contexts and one or more of them is being reset, it's not obvious which one should have focus.
        if (shouldSetContextFocus()) {
          this._speakerContext.setContextFocus(true);
        }

        const allowRestartFromCurrentAudioSourceIndex = [
          'speakerSettings.voices',
          'speakerSettings.speechSpeed',
        ].includes(attributeName);

        // Do not autoselect first audio source, since we're autoselecting the first
        // visible audiosource instead. Need to set it to false, since it defaults to true
        // in the speaker repo.
        const autoSelectFirstAudioSource = false;

        const options = {
          loadFromAudioSourceIndex: allowRestartFromCurrentAudioSourceIndex
            ? -1
            : 0,
          autoSelectFirstAudioSource,
          autoplayFirstLoadedAudioSource:
            this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] &&
            // Never start speaker automatically when in an exercise
            !this.get('exercise'),
          autoSelectFirstVisibleAudioSource: !this.get('speakerService.encourageListeningEnabled'),
        };

        // console.debug(`observePropsToResetMainSpeaker, reset speaker context with options:`, options);

        // When changing exercise in fullscreen mode, we need to wait until the next tick,
        // otherwise the speaker controls won't be rendered when navigating to next/previous exercise.
        await this._nextTick();

        return this._speakerContext
          .reset(DigilarSpeakerType.MAIN, this.element, options)
          .then((response) => {
            // Make sure to "reactivate" the main speaker to trigger the onSpeakerActive callback
            this._speakerContext.setActiveSpeaker(DigilarSpeakerType.MAIN);
          });
      });
    }
  ),

  _nextTick() {
    return new Promise((resolve) => setTimeout(resolve));
  },

  _updatePlaybackSpeed() {
    // Update the playback speed of each SpeakerAdapter in real time
    this._speakerContext.forEachSpeaker(({ speakerAdapter }) => {
      // Update playback speed only if HTML5 audio is used, to avoid pitch changes with Web Audio API.
      if (this._getSpeakerOptions().forceHtml5Audio) {
        const rsPlaybackSpeed = this._getSelectedSpeechSpeed(
          this._recordedSpeechProviderId
        );
        // console.debug('Setting RS speed to: ', rsPlaybackSpeed);
        speakerAdapter.setPlaybackSpeedRecordedSpeech(rsPlaybackSpeed);

        // Only update playback speed for TTS if not already using TTS provider's speech speed
        // (The audio file will already have the correct speed when generated, so we don't want to alter it with a different playback speed)
        if (!this._digilarSpeakerConfig.useTtsSpeechSpeed()) {
          const ttsPlaybackSpeed = this._getSelectedSpeechSpeed(
            this._textToSpeechProviderId
          );
          // console.debug('Setting TTS speed to: ', ttsPlaybackSpeed);
          speakerAdapter.setPlaybackSpeedTextToSpeech(ttsPlaybackSpeed);
        }
      }
    });
  },

  speakerSpeechSpeedObserver: observer(
    'speakerSettings.speechSpeed',
    function () {
      this._updatePlaybackSpeed();
    }
  ),

  _updateTextHighlightingOptions() {
    let highlighting = this.get('speakerSettings.highlighting');

    // Update highlighting options for all speaker types
    let speakerType = null;
    this._speakerContext.setTextHighlighting(speakerType, highlighting);
  },

  highlightingObserver: observer('speakerSettings.highlighting', function () {
    this._updateTextHighlightingOptions();
  }),

  _startContentPoller() {
    // Stop in case it's already running
    this._stopContentPoller();

    // Setup a polling function that inspects the DOM to decide if a switch should be made between main/popup speaker.
    this._contentPoller = new Poller();
    this._contentPoller.setInterval(200);
    this._contentPoller.start(this, function () {
      let popupSpeakerDomElem =
        this._domHelper.findPopupSpeakerDomContextElem();
      if (popupSpeakerDomElem) {
        // Make sure that the modal is only handled by the speaker context currently in focus.
        if (!this._speakerContext.hasContextFocus()) {
          return;
        }

        // Check if popupSpeakerDomElem is already handled by another speaker-context and in that case return.
        let isDomElemAvailable = false;
        this._speakerContext.withSpeaker(
          DigilarSpeakerType.POPUP,
          ({ domHandler }) => {
            isDomElemAvailable = domHandler.isSpeakerContextDomElementAvailable(
              popupSpeakerDomElem,
              this._speakerContext.getUuid()
            );
          }
        );
        if (!isDomElemAvailable) {
          return;
        }

        // Don't proceed if popup speaker is already active
        if (
          this._speakerContext.getActiveSpeaker() === DigilarSpeakerType.POPUP
        ) {
          return;
        }

        // Get access to the sandbox of the MAIN speaker first
        this._speakerContext.withSpeakerTransactional(
          DigilarSpeakerType.MAIN,
          ({ speakerAdapter }) => {

            // Give focus to this speaker context in case it doesn't already have focus.
            //
            // Might happen for example if speaker is playing in chapter context and the user opens an exercise in fullscreen mode from the workspace context.
            // If not changing focus, the speaker in the chapter context will just continue playing
            this._speakerContext.setContextFocus(true);

            // Pause main speaker immediately if playing
            let isMainSpeakerPlaying = speakerAdapter.pause();

            // Set popup speaker autoplay to true if main speaker was playing,
            // but never set it to false here since this code will sometimes run several times.
            if (isMainSpeakerPlaying) {
              this._autoplaySpeakerOnLoad[DigilarSpeakerType.POPUP] = true;
            }

            let initPopupSpeakerOptions = {
              autoplayFirstLoadedAudioSource:
                this._autoplaySpeakerOnLoad[DigilarSpeakerType.POPUP],
            };

            // Now try to init the popup speaker
            return this._speakerContext
              .initSpeaker(
                DigilarSpeakerType.POPUP,
                popupSpeakerDomElem,
                initPopupSpeakerOptions
              )
              .then(({ success }) => {
                if (success) {
                  // Set the popup speaker as active after successful initialization
                  // for keyboard controls to switch focus from main speaker to popup speaker
                  this._speakerContext.setActiveSpeaker(
                    DigilarSpeakerType.POPUP
                  );
                }
                return Promise.resolve(success);
              });
          }
        );
      } else {
        // console.debug(`speaker-context content poller: modal not found, will try to init main speaker and clean up popup speaker`);

        // Clean up popup speaker when no modal is visible
        this._speakerContext.cleanUp(DigilarSpeakerType.POPUP);

        // Set the main speaker as active again in case popup speaker was previously active
        if (
          this._speakerContext.getActiveSpeaker() !== DigilarSpeakerType.MAIN
        ) {
          this._speakerContext.setActiveSpeaker(DigilarSpeakerType.MAIN);
        }

        // Reset popup speaker autoplay to false for the next time it will be used
        this._autoplaySpeakerOnLoad[DigilarSpeakerType.POPUP] = false;

        // Intitialize the main speaker
        let initMainSpeakerOptions = {
          autoplayFirstLoadedAudioSource:
            this._autoplaySpeakerOnLoad[DigilarSpeakerType.MAIN] &&
            // Never start speaker automatically when in an exercise
            !this.get('exercise'),
          autoSelectFirstVisibleAudioSource: !this.get('speakerService.encourageListeningEnabled'),
        };

        this._speakerContext
          .initSpeaker(
            DigilarSpeakerType.MAIN,
            this.element,
            initMainSpeakerOptions
          )
          .then((resolveData) => {
            //eslint-disable-next-line no-unused-vars
            const { success, message, audioSources } = resolveData;
            // console.debug('initSpeaker resolveData = ', resolveData);
            if (!success) {
              // console.debug(`SpeakerContext could not be initialized for MAIN speaker: ${message}`);
            }
          });
      }
    });
  },

  _stopContentPoller() {
    if (this._contentPoller) {
      this._contentPoller.stop();
      this._contentPoller = null;
    }
  },

  willDestroyElement() {
    this._stopContentPoller();

    this._speakerContext.cleanUp();

    this.get('speakerService').unregisterSpeakerContext(
      this._speakerContext
    );
  },

  didInsertElement() {
    // Start the content poller that will look for content changes on regular short intervals
    if (this.get('speakerService.active')) {
      this._startContentPoller();
    }

    /**
     * Listens for click events and gives focus to the speaker context if a click happened within the context's DOM.
     */
    this._domHelper.addSpeakerContextClickListener(
      this._speakerContext,
      this.element
    );
  },

  didRender() {
    this._super(...arguments);
  },
});
