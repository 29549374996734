import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class AdditionCustomizerGroupSectionComponent extends Component {
  /**
   * Arguments:
   * additions (array)
   * includedAdditions (array)
   * type (string)
   * onInclude (function)
   * onExclude (function)
   */

  @tracked isExpanded = false;

  get classes() {
    const classes = ['addition-customizer-group-section'];

    if (this.isExpanded) {
      classes.push('addition-customizer-group-section--expanded');
    }

    return classes.join(' ');
  }

  get includedGroupAdditions() {
    const additions = this.args.additions || [];
    const includedAdditions = this.args.includedAdditions || [];
    const intersection = [];

    additions.forEach((item) => {
      if (includedAdditions.includes(item)) {
        intersection.push(item);
      }
    });

    return intersection;
  }

  get excludedAdditions() {
    const additions = this.args.additions || [];
    const includedGroupAdditions = this.includedGroupAdditions;
    const diff = [];

    additions.forEach((item) => {
      if (!includedGroupAdditions.includes(item)) {
        diff.push(item);
      }
    });

    return diff;
  }

  get hasFullInclusion() {
    return this.excludedAdditions.length === 0;
  }

  get hasPartialInclusion() {
    return this.includedGroupAdditions.length && this.excludedAdditions.length;
  }

  @action handleCheckboxChange() {
    if (this.hasFullInclusion) {
      this.#excludeAll();
    } else {
      this.#includeAll();
    }
  }

  @action handleToggleButtonClick() {
    this.isExpanded = !this.isExpanded;
  }

  #includeAll() {
    (this.args.additions || []).forEach((addition) => {
      this.args.onInclude?.(addition);
    });
  }

  #excludeAll() {
    (this.args.additions || []).forEach((addition) => {
      this.args.onExclude?.(addition);
    });
  }
}
