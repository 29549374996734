import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class EntityMetaComponent extends Component {
  /**
   * Arguments:
   * guidanceIconOnly (bool)
   * model (object)
   * categoryName (string)
   * onOpen(function)
   */

  @service entityInspector;

  @service session;

  get entityGuidance() {
    if (this.session.user?.isTeacherForSchool) {
      if (this.args.model?.body?.guidance?.length > 0) {
        return true;
      }
    }

    return false;
  }

  @action handleOpenEntityInspector(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.args.onOpen?.();
    this.args.closeCollectionModal?.();
    this.entityInspector.openModal(this.args.model, 'guidance');
  }
}
