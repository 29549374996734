import Component from '@glimmer/component';

export default class TbRenderInlineIteratorComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */

  get pronounceValue() {
    return this.args.node?.attributes?.findBy('key', 'data-pronounce')?.value;
  }

  get componentName() {
    const type = this.args.node?.type || 'comment';
    return type === 'comment' ? null : `tb-render-inline/${type}`;
  }
}
