import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CollectionViewTopComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * activeExercise (object)
   * answers (array)
   * audioSource (string)
   * onExerciseChange (function)
   * hasSidebar (bool)
   * hasAudio (bool)
   * hasPagination (bool)
   */

  @service intl;

  @cached
  get ownerProxy() {
    return load(resolve(this.args.collection.owner), this);
  }

  get owner() {
    return this.ownerProxy.isResolved ? this.ownerProxy.value : null;
  }

  get title() {
    if (this.owner) return this.owner.showname;

    const collection = this.args.collection;

    if (collection.type === 'collections') return collection.title;

    return this.intl.t(`components.collectionView.top.practice`);
  }
}
