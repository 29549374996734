import Component from '@glimmer/component';

const FILES = [
  { template: 'conclusion', src: '/assets/images/tb-render/conclusion.svg' },
  { template: 'example', src: '/assets/images/tb-render/example.svg' },
  { template: 'fact', src: '/assets/images/tb-render/fact.svg' },
  { template: 'important', src: '/assets/images/tb-render/important.svg' },
  { template: 'instruction', src: '/assets/images/tb-render/instruction.svg' },
  {
    template: 'introduction',
    src: '/assets/images/tb-render/introduction.svg',
  },
  { template: 'quote', src: '/assets/images/tb-render/quote.svg' },
  {
    template: 'recommendation',
    src: '/assets/images/tb-render/recommendation.svg',
  },
  { template: 'discussion', src: '/assets/images/tb-render/discussion.svg' },
  { template: 'tool', src: '/assets/images/tb-render/tools.svg' },
  { template: 'laboration', src: '/assets/images/tb-render/lab.svg' },
];

export default class TbRenderGroupComponent extends Component {
  /**
   * Arguments:
   * template (string)
   */

  baseClass = 'tb-render-group';

  get imgSrc() {
    const file = FILES.findBy('template', this.args.template);

    if (!file) return null;

    return file.src;
  }

  get templateClass() {
    return [this.baseClass, this.args.template].join('--');
  }

  get classes() {
    const classes = [this.baseClass];

    classes.push(this.templateClass);

    if (this.imgSrc) {
      classes.push(`${this.baseClass}--has-icon`);
    }

    return classes.join(' ');
  }
}
