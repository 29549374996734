import { action } from '@ember/object';
import Component from '@glimmer/component';
import { storageFor } from 'ember-local-storage';

export default class CollectionAudioComponent extends Component {
  /**
   * Arguments:
   * audioSource (string)
   */

  @storageFor('collection-sound') collectionSoundStore;

  get showTip() {
    return this.collectionSoundStore.get('showTip');
  }

  @action hideTip() {
    this.collectionSoundStore.set('showTip', false);
  }
}
