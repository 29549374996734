import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
export default class PageHeaderComponent extends Component {
  @service intl;

  get productMenuItems() {
    const menu_items = ['books', 'myResults'];

    const menu_icons = {
      books: 'home',
      myResults: 'log',
    };

    const menu_routes = {
      books: 'master.products.index',
      myResults: 'master.products.my-results',
    };

    let items = [];

    for (let i = 0; i < menu_items.length; i++) {
      items.addObject({
        title: this.intl.t('components.products.productMain.' + menu_items[i]),
        icon: menu_icons[menu_items[i]],
        route: menu_routes[menu_items[i]],
        disabled: false,
      });
    }

    return items;
  }
}
