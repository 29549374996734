import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderInteractiveImageComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-interactive-image';

  asyncType = 'material';

  get asyncId() {
    return this.args.part?.media_id;
  }

  get material() {
    return this.asyncData;
  }
}
