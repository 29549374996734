import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"drag-item {{this.classNames}}\" ...attributes>\n  <ContentPresenter class=\"content\">\n    {{#if this.showValidation}}\n      <span class=\"drag-item-state drag-item-state--key\">\n        <DcIcon @name=\"key\" />\n      </span>\n\n      <span class=\"drag-item-state drag-item-state--success\">\n        <DcIcon @name=\"quite-happy\" />\n      </span>\n\n      <span class=\"drag-item-state drag-item-state--error\">\n        <DcIcon @name=\"t-warning\" />\n      </span>\n\n    {{/if}}\n\n    {{yield}}\n\n    {{#if @canRemove}}\n      <UiButton\n        class=\"drag-item-remove-button\"\n        @appearance=\"none\"\n        @onClick={{@onRemove}}\n      >\n        <DcIcon @name=\"simple-remove\" />\n      </UiButton>\n    {{/if}}\n  </ContentPresenter>\n</div>", {"contents":"<div class=\"drag-item {{this.classNames}}\" ...attributes>\n  <ContentPresenter class=\"content\">\n    {{#if this.showValidation}}\n      <span class=\"drag-item-state drag-item-state--key\">\n        <DcIcon @name=\"key\" />\n      </span>\n\n      <span class=\"drag-item-state drag-item-state--success\">\n        <DcIcon @name=\"quite-happy\" />\n      </span>\n\n      <span class=\"drag-item-state drag-item-state--error\">\n        <DcIcon @name=\"t-warning\" />\n      </span>\n\n    {{/if}}\n\n    {{yield}}\n\n    {{#if @canRemove}}\n      <UiButton\n        class=\"drag-item-remove-button\"\n        @appearance=\"none\"\n        @onClick={{@onRemove}}\n      >\n        <DcIcon @name=\"simple-remove\" />\n      </UiButton>\n    {{/if}}\n  </ContentPresenter>\n</div>","moduleName":"babel/components/exercise-view/common/drag-item/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/common/drag-item/index.hbs"}});
import Component from '@glimmer/component';

export default class ExerciseViewAssignmentCommonDragItemComponent extends Component {
  /**
   * Arguments:
   * compact (bool)
   * hasHover (bool)
   * placeholder (string)
   * size (string)
   * canRemove (bool)
   * hasValidation (bool)
   * onRemove (function)
   */

  get placeholder() {
    return this.args.placeholder || 'shadow';
  }

  get size() {
    return this.args.size || 'normal';
  }

  get showValidation() {
    if (this.args.showValidation) {
      return this.args.hasValidation === false ? false : true;
    }
    return false;
  }

  get placeholderClass() {
    return ['drag-item', this.placeholder].join('--');
  }

  get sizeClass() {
    return ['drag-item', this.size].join('--');
  }

  get classNames() {
    const classNames = [this.placeholderClass, this.sizeClass];

    if (this.args.compact) classNames.push('drag-item--compact');
    if (this.args.hasHover) classNames.push('drag-item--has-hover');
    if (this.args.behaviour === 'images') classNames.push('drag-item--image');

    return classNames.join(' ');
  }
}
