import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { ForbiddenError } from '@ember-data/adapter/error';
import config from 'babel/config/environment';
import getUserLicenseForEntity from 'babel/utils/get-user-license-for-entity';
import { all, reject } from 'rsvp';

export default class MasterProductsRoute extends Route {
  @service ajax;

  @service courseContext;

  @service productContext;

  @service router;

  @service session;

  @service store;

  model({ product_id }) {
    return all([
      this.store.findRecord('product', product_id),
      this.session.user.licenses,
    ]).then(([product, licenses]) => {
      if (!product || !licenses) {
        return reject();
      }

      if (
        !licenses
          .filter((license) => license.isActive && !license.isExpired)
          .some((license) => license.isbn === product.id)
      ) {
        const err = new ForbiddenError();

        err.product = product;

        return reject(err);
      }

      return product;
    });
  }

  async afterModel(model) {
    const courses = await this.courseContext.fetchCoursesForProduct(model);

    if (courses.length === 1) {
      return this.router.transitionTo(
        'master.courses.show.index',
        courses.firstObject.id
      );
    }

    if (model && !model.get('hasProductPage')) {
      const books = await model.books;
      const internalBooks = books.filterBy('type', 'books');

      if (internalBooks.length > 0) {
        return this.router.transitionTo(
          'master.books',
          books.filterBy('type', 'books').firstObject.id
        );
      }
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    this.courseContext.removeAllContext();
    this.productContext.removeAllContext();

    this.productContext.setActiveProduct(model);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.productContext.setActiveProduct(null);
    }
  }

  @action
  error(err, transition) {
    if (err instanceof ForbiddenError) {
      const entityId = err.product?.hasMany('books')?.ids()?.[0];

      if (!entityId) return true;

      return getUserLicenseForEntity(
        entityId,
        this.session,
        this.ajax,
        config.endpoint,
        config.userApiEndpoint,
        transition
      ).catch(() => {
        this.router.transitionTo('master.forbidden');
      });
    } else {
      return true;
    }
  }
}
