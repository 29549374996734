import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MasterCoursesShowAuditMissionsIndexController extends Controller {
  @service router;

  queryParams = ['page'];

  @tracked model;

  @tracked page = 1;

  get numPages() {
    return this.model?.meta?.total_pages || 0;
  }

  @action handlePaginationChange(page) {
    this.router.transitionTo({ queryParams: { page } });
  }
}
