import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  ForbiddenError,
  NotFoundError,
  ServerError,
} from '@ember-data/adapter/error';
import config from 'babel/config/environment';
import { MissingContentError } from 'babel/utils/errors';
import {
  generateCodeVerifier,
  generatePkceChallenge,
} from 'ember-simple-auth-oidc/utils/pkce';
import uuid from 'uuid';

export default class ApplicationRoute extends Route {
  @service router;

  @service store;

  @service session;

  @service pusher;

  @service appUpdate;

  constructor() {
    super(...arguments);

    this.setupTracking();
  }

  async beforeModel(transition) {
    await this.session.setup();

    this.appUpdate.initialize();
    this.pusher.subscribe();

    if (transition.to && transition.to.queryParams.provider) {
      let idp = null;

      // LEGACY: Needed until drop for v1 link support
      let arbetsuppgift = null;

      let url = window.location.pathname;

      if (transition.to.queryParams.url) {
        url = transition.to.queryParams.url;
      }

      if (transition.to.queryParams.idp) {
        idp = transition.to.queryParams.idp;
      }

      if (transition.to.queryParams.arbetsuppgift) {
        arbetsuppgift = transition.to.queryParams.arbetsuppgift;
        url = `${url}?arbetsuppgift=${arbetsuppgift}`;
      }

      const isLoggedIn = this.session.isAuthenticated;

      /*
      // TODO: Make this work to make the page load faster after login.
      // Kunskapsskolan redirects to the old Urls, which mean they will get an extra unnecessary redirect after logging in

      // Before: /contents/{content_id}/sections/{section_id}
      // After: /contents/{content_id}?section={section_id}
      const contentsRegex = new RegExp('\/?contents\/([A-Za-z-0-9]*)\/sections\/([A-Za-z-0-9]*)\/?$');

      // Before: /contents/{content_id}/sections/{section_id}/workspace/exercises/{exercise_id}
      // After: /contents/{content_id}/assignments/{exercise_id/}?section={section_id}
      const contentsSectionsRegex = new RegExp('\/?contents\/([A-Za-z-0-9]*)\/sections\/([A-Za-z-0-9]*)\/workspace\/exercises\/([A-Za-z-0-9]*)\/?$');

      if (contentsRegex.test(url)) {
        console.log('TODO: Fix contents');
      } else if (contentsSectionsRegex.test(url)) {
        console.log('TODO: Fix contents with section');
      }
      */

      const queryParams = { ...transition.to.queryParams };
      delete queryParams.provider;
      delete queryParams.idp;

      const queryString = Object.keys(queryParams).map(
        (x) => `${x}=${queryParams[x]}`
      );

      url += `?${queryString}`;

      if (isLoggedIn) {
        window.location = url;
      } else if (transition.to.queryParams.provider?.length && !idp) {
        transition.abort();

        const state = uuid.v4();
        this.session.set('data.state', state);
        this.session.set('data.nextURL', url);

        const searchParams = new URLSearchParams({
          client_id: config['ember-simple-auth-oidc'].clientId,
          redirect_uri: `${location.protocol}//${location.host}/login`,
          response_type: 'code',
          state,
          scope: 'openid profile offline_access',
          prompt: 'consent',
          acr_values: `idp:${transition.to.queryParams.provider}`,
        });

        if (config['ember-simple-auth-oidc'].enablePkce) {
          let verifier = this.session.data.pkceCodeVerifier;

          if (!verifier) {
            verifier = generateCodeVerifier(96);
            this.session.set('data.pkceCodeVerifier', verifier);
          }

          searchParams.append(
            'code_challenge',
            generatePkceChallenge(verifier)
          );
          searchParams.append('code_challenge_method', 'S256');
        }

        const authHost = config['ember-simple-auth-oidc'].host;

        window.location = `${`${authHost}/auth`}?${searchParams}`;
      } else {
        transition.abort();

        this.session.set('data.nextURL', url);

        window.location = `${config['ember-simple-auth-oidc'].host}/signin-skolfederation?idp=${idp}&url=${url}`;
      }
    }
  }

  @action
  loading(transition) {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controllerFor('application');

    controller.isLoading = true;

    transition.promise.finally(() => {
      controller.isLoading = false;
    });

    return true;
  }

  @action
  didTransition() {
    // Unregister all service-workers
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  @action
  error(err, transition) {
    if (err instanceof MissingContentError) {
      if (err.redirectTarget) {
        this.router.transitionTo(err.redirectTarget);
        return false;
      }
    }

    if (err instanceof ForbiddenError) {
      this.router.transitionTo('master.forbidden');
    }

    if (err instanceof NotFoundError) {
      this.router.transitionTo('404');
    }

    if (err instanceof ServerError) {
      this.router.transitionTo('master.error').then(() => {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        const controller = this.controllerFor('master.error');

        if (controller) {
          controller.abortedTransition = transition;
        }
      });
    }

    return true;
  }

  setupTracking() {
    if (
      !config.matomo.endpoint ||
      !config.matomo.site_id ||
      // Looks like they are always strings
      config.matomo.endpoint === 'null' ||
      config.matomo.site_id === 'null'
    ) {
      return;
    }

    // Initialize Matomo script
    const _paq = (window._paq = window._paq || []);

    (function () {
      _paq.push(['setDomains', config.matomo.domains]);
      _paq.push(['enableCrossDomainLinking']);
      _paq.push(['setConversionAttributionFirstReferrer', true]);
      _paq.push(['setTrackerUrl', `${config.matomo.endpoint}/matomo.php`]);
      _paq.push(['setSiteId', config.matomo.site_id]);
      _paq.push(['enableLinkTracking']);

      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = `${config.matomo.endpoint}/matomo.js`;
      s.parentNode.insertBefore(g, s);
    })();

    // Listen to route changes
    this.router.on('routeDidChange', (transition) => {
      // Need a timeout to wait the title to be updated
      setTimeout(() => {
        const _paq = window._paq;
        if (!_paq) return;

        // Track user id
        const userId = this.session?.data?.authenticated?.userinfo?.sub;

        if (userId) {
          _paq.push(['setUserId', userId]);
        }

        // Track pages
        _paq.push([
          'setCustomUrl',
          `${window.location.origin}${this.router.currentURL}`,
        ]);

        // Reversing the title to get the hierarchy right
        const splitTitle = document.title.split(' | ').reverse();

        // The reason we are adding the last part of the title twice is so matomo can show the "first" page of the hierarchy
        // under the same title instead of being outside. It means that pages with no hierarchy (sections, exercises, etc) will
        // show a "+" (in matomo) as if it was a hierarchy, but it will just show the same title twice (which is not problematic).
        if (splitTitle.length > 0) {
          splitTitle.push(splitTitle[splitTitle.length - 1]);
        }

        const title = splitTitle.join(' | ');

        _paq.push(['trackPageView', title]);

        const transitionTarget = transition?.to?.name?.split('.') || [];

        // If we are on a content page with an exercise or wordlist open
        // we log the exercise or wordlist as a separate title. We are
        // never interested in teacher created content.
        if (
          transitionTarget.length > 3 &&
          transitionTarget[0] === 'master' &&
          transitionTarget[1] === 'contents'
        ) {
          if (
            transitionTarget[2] === 'assignments' &&
            transitionTarget[3] === 'show'
          ) {
            const id = transition?.to?.params?.assignment_id;
            if (!id) return;

            const exercise = this.store.peekRecord('entity', id);
            if (!exercise || exercise.teacherAssignment) return;

            let exerciseTitle = splitTitle.slice(0, splitTitle.length - 2);

            exerciseTitle.push(
              exercise.get('parent.title'),
              `${exercise.sort}. ${exercise.title}`,
              `${exercise.sort}. ${exercise.title}`
            );

            exerciseTitle = exerciseTitle.join(' | ');

            _paq.push(['trackPageView', exerciseTitle]);
          } else if (
            transitionTarget[2] === 'wordlists' &&
            transitionTarget[3] === 'show'
          ) {
            const id = transition?.to?.parent?.params?.wordlist_id;
            if (!id) return;

            const wordlist = this.store.peekRecord('wordlist', id);
            if (!wordlist || !wordlist.isTransformed) return;

            let wordlistTitle = splitTitle.slice(0, splitTitle.length - 2);

            wordlistTitle.push(wordlist.title, wordlist.title);

            wordlistTitle = wordlistTitle.join(' | ');

            _paq.push(['trackPageView', wordlistTitle]);
          }
        }
      }, 100);
    });
  }
}
