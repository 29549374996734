import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DiagnosisShowComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * queryExercise (string)
   * turningIn (bool)
   * disabled (bool)
   */

  @service entityContext;

  @service store;

  @service router;

  constructor() {
    super(...arguments);
    this.setEntityContext();
  }

  get activeExercise() {
    return (
      this.args.model?.exercises?.findBy('id', this.args.queryExercise) ||
      this.args.model?.exercises?.firstObject
    );
  }

  get activeAnswer() {
    return this.args.model?.answers?.findBy(
      'entity.id',
      this.activeExercise.id
    );
  }

  @action openExercise(exercise) {
    return this.router
      .transitionTo({ queryParams: { exercise } })
      .then(() => this.setEntityContext());
  }

  @action setEntityContext() {
    this.entityContext.changeExercise(this.activeExercise);
  }

  @action unsetEntityContext() {
    this.entityContext.changeExercise();
  }
}
