import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class TbRenderComponent extends Component {
  /**
   * Arguments:
   * content (object)
   * canvasUser (object)
   */

  @cached get contentProxy() {
    return load(resolve(this.args.content), this);
  }

  get content() {
    return this.contentProxy.isResolved ? this.contentProxy.value : null;
  }

  get sortedRows() {
    return this.content?.rows?.sortBy('index');
  }
}
