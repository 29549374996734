import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
export default class ContentChapterComponent extends Component {
  @tracked showContentSummary = false;

  @action handleDone() {
    this.showContentSummary = true;
  }

  get showDoneButton() {
    return !this.showContentSummary && this.args.showDoneButton;
  }

  get showSummary() {
    return this.showContentSummary && !this.args.hasNextSection;
  }
}
