import de from './languages/de';
import en from './languages/en';
import es from './languages/es';
import fr from './languages/fr';
import it from './languages/it';
import sv from './languages/sv';

const TRANSLATION_TABLES = { sv, en, es, fr, it, de };

/**
 *
 * @param {*} key The key to look up in the translation table for given language
 * @param {*} language The language to translate to
 * @param {*} onTranslation Callback function that will get called only if a translation was found. The translation is passed as the only parameter to the function.
 * @param {*} onMissingTranslation Optional callback function that will get called only if a translation was NOT found.
 */
const translate = (key, language, onTranslation, onMissingTranslation) => {
  const translation =
    TRANSLATION_TABLES[language] && TRANSLATION_TABLES[language][key]
      ? TRANSLATION_TABLES[language][key]
      : null;

  if (translation) {
    onTranslation(translation);
  } else {
    if (onMissingTranslation) {
      onMissingTranslation();
    }
  }
};

export default translate;
