import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class WorkspaceNotesEditComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * onClose (function)
   */

  // SETUP

  @service router;

  @service session;

  @service noteDispatcher;

  @controller('master.contents') contentController;

  // PROPERTIES

  get note() {
    return this.args.model;
  }

  get content() {
    return this.contentController.content;
  }

  // ACTIONS

  @action openIndex() {
    return this.router.transitionTo('master.contents.notes');
  }

  @action handleNoteDetailUnlink() {
    return this.noteDispatcher
      .unlink(this.note, this.content)
      .then(() => this.router.transitionTo('master.contents.notes.index'));
  }

  @action handleNoteDetailSave() {
    if (this.note.validate()) {
      return this.noteDispatcher
        .save(this.note)
        .then(() => this.router.transitionTo('master.contents.notes.index'))
        .then(() => this.noteDispatcher.highlight(this.note));
    }
  }

  @action handleNoteDetailDelete() {
    return this.noteDispatcher
      .delete(this.note)
      .then(() => this.router.transitionTo('master.contents.notes.index'));
  }
}
