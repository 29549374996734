import { get as proxyGet } from '@ember/object';
import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/* eslint-disable ember/no-get */
export default class NoteDispatcherService extends Service {
  @service session;
  @service store;
  @service intl;
  @service snackbar;
  @service confirmManager;

  @tracked highlightedNotes = [];

  async save(note) {
    try {
      const result = await note.save();
      this.#success('save');
      return result;
    } catch (error) {
      this.#error('save');
      throw error;
    }
  }

  delete(note) {
    return this.#confirm('delete', { danger: true }).then(async () => {
      try {
        const result = await note.destroyRecord();
        this.#success('delete');
        return result;
      } catch (error) {
        this.#error('delete');
        throw error;
      }
    });
  }

  deleteMany(notes) {
    return this.#confirm('deleteMany', { danger: true }, notes.length).then(
      async () => {
        try {
          const result = await Promise.all(
            notes.map((note) => note.destroyRecord())
          );
          this.#success('deleteMany', { count: notes.length });
          return result;
        } catch (error) {
          this.#error('delete');
          throw error;
        }
      }
    );
  }

  async link(note, content) {
    try {
      const result = await this.#link(note, content);
      this.#success('link');
      return result;
    } catch (error) {
      this.#error('link');
      throw error;
    }
  }

  async linkMany(notes, content) {
    try {
      const result = await Promise.all(
        notes.map((note) => this.#link(note, content))
      );
      this.#success('linkMany', { count: notes.length });
      return result;
    } catch (error) {
      this.#error('linkMany');
      throw error;
    }
  }

  unlink(note, content) {
    this.#confirm('unlink', { danger: true }).then(async () => {
      try {
        note.rollback();
        proxyGet(note, 'contents').removeObject(content);
        const result = await note.save();
        this.#success('unlink');
        return result;
      } catch (error) {
        this.#error('unlink');
        throw error;
      }
    });
  }

  highlight(note) {
    this.highlightMany([note]);
  }

  highlightMany(notes) {
    this.highlightedNotes.addObjects(notes);

    later(() => {
      this.highlightedNotes.removeObjects(notes);
    }, 3000);
  }

  #confirm(method, options, count = 1) {
    return this.confirmManager.perform(
      this.intl.t(`services.noteDispatcher.${method}.confirm`, {
        count,
      }),
      options
    );
  }

  #success(method, { count = 1 } = {}) {
    this.snackbar.enqueue(
      this.intl.t(`services.noteDispatcher.${method}.snackbar.success`, {
        count,
      }),
      {
        variant: 'success',
        autoDismiss: true,
      }
    );
  }

  #error(method, count = 1) {
    this.snackbar.enqueue(
      this.intl.t(`services.noteDispatcher.${method}.snackbar.error`),
      {
        variant: 'error',
      },
      { count }
    );
  }

  async #link(note, content) {
    const book = await proxyGet(content, 'book');

    note.rollback();
    proxyGet(note, 'books').addObject(book);
    proxyGet(note, 'contents').addObject(content);

    return note.save();
  }
}
