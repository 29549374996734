import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<MaterialViewer::Popup\n  ...attributes\n  @for={{@for}}\n  @material={{@material}}\n  @onClose={{@onClose}}\n>\n\n  <div class=\"p1\">\n    <XImage\n      class=\"material-viewer-image-popup-image\"\n      @source={{image-source @material.imageUrl}}\n      @width={{@material.imageWidth}}\n      @height={{@material.imageHeight}}\n      @color={{@material.imageColor}}\n      @mimetype={{@material.imageMimetype}}\n      {{on \"click\" (set this \"isMaterialViewerOpen\" true)}}\n    />\n  </div>\n\n</MaterialViewer::Popup>\n\n{{#if this.isMaterialViewerOpen}}\n\n  <MaterialViewer\n    @material={{@material}}\n    @onClose={{set this \"isMaterialViewerOpen\" false}}\n  />\n\n{{/if}}", {"contents":"<MaterialViewer::Popup\n  ...attributes\n  @for={{@for}}\n  @material={{@material}}\n  @onClose={{@onClose}}\n>\n\n  <div class=\"p1\">\n    <XImage\n      class=\"material-viewer-image-popup-image\"\n      @source={{image-source @material.imageUrl}}\n      @width={{@material.imageWidth}}\n      @height={{@material.imageHeight}}\n      @color={{@material.imageColor}}\n      @mimetype={{@material.imageMimetype}}\n      {{on \"click\" (set this \"isMaterialViewerOpen\" true)}}\n    />\n  </div>\n\n</MaterialViewer::Popup>\n\n{{#if this.isMaterialViewerOpen}}\n\n  <MaterialViewer\n    @material={{@material}}\n    @onClose={{set this \"isMaterialViewerOpen\" false}}\n  />\n\n{{/if}}","moduleName":"babel/components/material-viewer/image-popup/index.hbs","parseOptions":{"srcName":"babel/components/material-viewer/image-popup/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class MaterialViewerImagePopup extends Component {
  // PROPERTIES

  @tracked isMaterialViewerOpen = false;
}
