import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  {{#let (component (if @inline \"tb-render-inline\" \"tb-render\")) as |RenderComponent|}}\n    <RenderComponent\n      @value={{@tbRenderContent}}\n      @content={{@tbRenderContent}}\n      as |render|\n    >\n      {{~#if render.customComponent~}}\n        <ExerciseView::Assignment::TextGap::Write::Gap\n          @disabled={{@disabled}}\n          @node={{render.node}}\n          @id={{this.id}}\n          @parentElementId={{render.parentElementId}}\n          @onChange={{@onChange}}\n          @onEnter={{@handleEnter}}\n          @assignment={{@assignment}}\n          @input={{@assignmentAnswer}}\n          @showKey={{@showKey}}\n          @incomplete={{@incomplete}}\n          @showValidation={{@showValidation}}\n          @isAutoCorrected={{@isAutoCorrected}}\n        />\n      {{~/if~}}\n    </RenderComponent>\n  {{/let}}\n</div>\n", {"contents":"<div>\n  {{#let (component (if @inline \"tb-render-inline\" \"tb-render\")) as |RenderComponent|}}\n    <RenderComponent\n      @value={{@tbRenderContent}}\n      @content={{@tbRenderContent}}\n      as |render|\n    >\n      {{~#if render.customComponent~}}\n        <ExerciseView::Assignment::TextGap::Write::Gap\n          @disabled={{@disabled}}\n          @node={{render.node}}\n          @id={{this.id}}\n          @parentElementId={{render.parentElementId}}\n          @onChange={{@onChange}}\n          @onEnter={{@handleEnter}}\n          @assignment={{@assignment}}\n          @input={{@assignmentAnswer}}\n          @showKey={{@showKey}}\n          @incomplete={{@incomplete}}\n          @showValidation={{@showValidation}}\n          @isAutoCorrected={{@isAutoCorrected}}\n        />\n      {{~/if~}}\n    </RenderComponent>\n  {{/let}}\n</div>\n","moduleName":"babel/components/exercise-view/assignment/text-gap/write/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/text-gap/write/index.hbs"}});
import Component from '@glimmer/component';
import { v4 as uuid } from 'ember-uuid';

export default class ExerciseViewAssignmentTextGapWriteComponent extends Component {
  /**
   * Arguments:
   * inline (bool)
   * disabled (bool)
   * tbRenderContent (object)
   * assignment (object)
   * assignmentAnswer (object)
   * showKey (bool)
   * showValidation (bool)
   * incomplete (bool)
   * onChange (function)
   * handleEnter (function)
   * isAutoCorrected (bool)
   */

  get id() {
    return uuid();
  }
}
