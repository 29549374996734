import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import urlHelper from 'babel/utils/urls';

export default class AttachLinkModalComponent extends Component {
  @service intl;
  @service store;
  @service session;
  @service snackbar;

  file = null;

  constructor() {
    super(...arguments);
    this.file = this.store.createRecord('file', {
      user: this.session.user,
      type: 'external',
      externalType: 'link',
    });
  }

  @action
  async saveLink() {
    try {
      const host = new URL(this.file.externalUrl)?.host;
      const url = this.file.externalUrl;

      if (host === 'docs.google.com') {
        this.file.externalType = 'google-docs';
      }
      if (
        host.includes('sharepoint.com') ||
        host.includes('onedrive.com') ||
        host.includes('teams.microsoft.com')
      ) {
        this.file.externalType = 'office365';
      }
      if (
        url.includes('youtube.com/watch') ||
        url.includes('youtu.be') ||
        url.includes('youtube.com/embed')
      ) {
        this.file.externalType = 'youtube';
        this.file.externalUrl = this._youtubeEmbedUrl(url);
      }
      if (host.includes('vimeo.com')) {
        this.file.externalType = 'vimeo';
        this.file.externalUrl = this._vimeoEmbedUrl(url);
      }
    } catch (e) {
      // console.log(e);
    }

    if (this.file.externalUrl) {
      this.file.externalUrl = urlHelper.addHttp(this.file.externalUrl);
    }

    if (this.file.validate()) {
      return this.file
        .save()
        .then(() => {
          this.args.onChoose?.(this.file);
          this.args.onClose?.();
        })
        .catch(() => {
          this.snackbar.enqueue(
            this.intl.t('components.attachLinkModal.saveFailed'),
            {
              variant: 'error',
              dismissible: false,
              autoDismiss: true,
            }
          );
        });
    }
  }

  _youtubeEmbedUrl(url) {
    let embed_url = url.replace(
      /youtube.com\/watch\?v=|youtu.be\//g,
      'youtube.com/embed/'
    );

    embed_url += '?autoplay=0&fs=1&iv_load_policy=3&rel=0&modestbranding=0';

    return embed_url;
  }

  _vimeoEmbedUrl(url) {
    if (url.contains('player.vimeo.com')) return;

    const vimeoPatterns = [
      /vimeo\.com\/[0-9]+/,
      /vimeo\.com\/channels\/[\w]+\/[0-9]+/,
      /vimeo\.com\/groups\/[\w]+\/videos\/[0-9]+/,
    ];

    // Check if the URL matches any of the Vimeo patterns
    const isVimeo = vimeoPatterns.some((pattern) => pattern.test(url));

    if (isVimeo) {
      // Add "player." prefix to the video URL to be able to embed it
      url = url.replace(/(https?:\/\/)(www\.)?/, '$1player.');
    }

    return url;
  }
}
