import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from 'babel/config/environment';
import { all, reject, resolve } from 'rsvp';

export default Route.extend({
  // METHODS

  store: service(),

  ajax: service('ajax'),

  lastVisited: null,

  async model() {
    const product = this.modelFor('master.products');

    if (!product) return reject();

    let pageViews, lastVisited, continueVisited;

    try {
      pageViews = await this.fetchPageViews(product.userBooks);

      lastVisited = pageViews.filter(
        (item) => item.attributes.is_latest_location !== true
      );

      continueVisited = pageViews.find(
        (item) => item.attributes.is_latest_location === true
      );
    } catch (err) {
      // Page views are not critical for the route,
      // API errors here should not stop the transition
    }

    return { product, lastVisited, continueVisited };
  },

  async fetchPageViews(books) {
    await books;

    const booksQuery = books
      .map((item) => {
        return `books[]=${item.id}&`;
      })
      .join('');

    return this.ajax
      .request(
        `${config.endpoint}/api/v1/meta/page-views/latest?${booksQuery}`,
        true,
        {
          type: 'GET',
        }
      )
      .then((response) => {
        const pageViews = response.data;

        return all(
          pageViews.map((pageView) => {
            return this.store
              .findRecord('entity', pageView.attributes.entity_id)
              .then((entity) => {
                if (pageView.attributes.is_latest_location === true) {
                  // custom logic with entity
                  return this.handleLatestLocation(pageView, entity);
                }

                return pageView;
              })
              .catch(() => null);
          })
        );
      })
      .then((results) => resolve(results.filter((x) => x)))
      .then((pageViews) => this.handleExercisesInDiagnosis(pageViews));
  },

  async handleLatestLocation(pageView, entity) {
    if (!entity) return null;

    if (
      !['contents', 'interactives', 'archives', 'materials'].includes(
        pageView.attributes.type
      )
    ) {
      entity = await this.getClosestContentEntity(entity);
    }

    if (!entity) return null;

    pageView.attributes.title = entity.title;

    if (entity.body.image) {
      pageView.attributes.image = entity.body.image;
    } else {
      delete pageView.attributes.image;
      pageView.attributes.default_image_name = this.getFallbackImage(entity);
    }

    pageView.attributes.subtitle = entity.template;

    if (pageView.attributes.metadata) {
      if (pageView.attributes.metadata.assignment) {
        pageView.attributes.assignment_id =
          pageView.attributes.metadata.assignment;
      }

      if (pageView.attributes.metadata.wordlist) {
        pageView.attributes.wordlist_id = pageView.attributes.metadata.wordlist;
      }

      if (pageView.attributes.metadata.section) {
        pageView.attributes.section_id = pageView.attributes.metadata.section;
      }

      if (pageView.attributes.metadata.material) {
        pageView.attributes.material_id = pageView.attributes.metadata.material;
      }
    }

    return pageView;
  },

  async getClosestContentEntity(entity) {
    let parentEntity;

    try {
      parentEntity = await this.store.findRecord('entity', entity.parent_id);
    } catch (err) {
      return null;
    }

    if (!parentEntity) {
      return null;
    }

    if (['contents', 'interactives', 'archives'].includes(parentEntity.type)) {
      return parentEntity;
    }

    return this.getClosestContentEntity(parentEntity);
  },

  getFallbackImage(entity) {
    const types = ['contents', 'interactives'];
    const templates = ['chapter', 'story', 'workflow'];

    let type = entity.type;
    let template = entity.template;

    if (!types.includes(type)) {
      type = 'image';
      template = 'missing';
    }

    if (templates.includes(template)) {
      return `${type}-${template}`;
    }

    return type;
  },

  async handleExercisesInDiagnosis(pageViews) {
    return await all(
      pageViews.map(async (pageView) => {
        if (pageView.attributes.type !== 'exercises') {
          return pageView;
        }

        const parentEntity = await this.store.findRecord(
          'entity',
          pageView.attributes.parent_id
        );

        if (parentEntity.isDiagnosis) {
          pageView.attributes.entity_id = parentEntity.id;
          pageView.attributes.parent_id = parentEntity.parent_id;
        }

        return pageView;
      })
    );
  },
});
