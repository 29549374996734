const WHITELISTED_ROUTE_NAMES = [
  'master.index',
  'master.courses.new',
  'master.courses.show.index',
  'master.courses.show.books',
  'master.courses.show.missions',
  'master.courses.show.my-results',
  'master.courses.show.audit.index',
  'master.courses.show.audit.overview',
  'master.courses.show.audit.inbox',
  'master.courses.show.audit.log',
  'master.courses.show.audit.comments',
  'master.courses.show.audit.missions.index',
  'master.courses.show.audit.missions.show',
  'master.course-settings',
  'master.missions',
  'master.products.index',
  'master.products.my-results',
  'master.profile.settings',
  'master.admin.books',
  'master.admin.teachers.list',
  'master.admin.students.list',
  'master.admin.courses',
  'master.books',
  'master.areas',
  'master.archives.index',
  'master.archives.folders.index',
  'master.archives.folders.materials',
  'master.contents.index',
  'master.interactives.index',
  'master.materials',
];

export default function shouldReloadOnUpdate(routeName) {
  if (WHITELISTED_ROUTE_NAMES.includes(routeName)) return true;
  return false;
}
