import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-list-summary\" ...attributes>\n  <div class=\"text-primary\">\n    {{t \"components.contentSummary.header\"}}\n  </div>\n  <div class=\"nt1\">\n    {{t \"components.contentSummary.ingress\"}}\n  </div>\n\n  <div class=\"content-list-summary-inner\">\n\n    <TableOfContentsList\n      @categorizedCollections={{this.categorizedCollections}}\n      @wordlists={{this.categorizedWordlists}}\n      @activeCategory={{@category}}\n      @goToSection={{@routeToSection}}\n      @isInteractive={{false}}\n      @routeOnSubHeadingClick={{false}}\n      @routed={{this.routed}}\n    />\n\n  </div>\n\n</div>", {"contents":"<div class=\"content-list-summary\" ...attributes>\n  <div class=\"text-primary\">\n    {{t \"components.contentSummary.header\"}}\n  </div>\n  <div class=\"nt1\">\n    {{t \"components.contentSummary.ingress\"}}\n  </div>\n\n  <div class=\"content-list-summary-inner\">\n\n    <TableOfContentsList\n      @categorizedCollections={{this.categorizedCollections}}\n      @wordlists={{this.categorizedWordlists}}\n      @activeCategory={{@category}}\n      @goToSection={{@routeToSection}}\n      @isInteractive={{false}}\n      @routeOnSubHeadingClick={{false}}\n      @routed={{this.routed}}\n    />\n\n  </div>\n\n</div>","moduleName":"babel/components/content-list-summary/index.hbs","parseOptions":{"srcName":"babel/components/content-list-summary/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ContentListSummaryComponent extends Component {
  @service session;

  @tracked _category;

  get routed() {
    return this.args.routed ?? true;
  }

  get categorizedWordlists() {
    if (!this.args.wordlists) {
      return null;
    }
    return this.args.wordlists?.filter((wordlist) => {
      if (!wordlist?.category) {
        return true;
      }
      if (wordlist?.category?.id === this.category?.id) {
        return true;
      }
      return false;
    });
  }

  get category() {
    return this._category || this.args.activeCategory;
  }

  get categorizedCollections() {
    if (!this.args.collections) {
      return null;
    }
    let filteredCollections = this.args.collections?.filter((collection) => {
      if (collection?.category?.id === this.category?.id) {
        return true;
      }
      if (collection.isTeacherCollection) {
        return true;
      }
      return false;
    });
    filteredCollections = filteredCollections.reject((collection) =>
      collection.get('children').isEvery('isPublished', false)
    );

    const sorted = filteredCollections.sort((a, b) => {
      if (a.isTeacherCollection) {
        if (b.isTeacherCollection) {
          if (a.title) {
            if (b.title) {
              return a.title.localeCompare(b.title);
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      } else {
        if (b.isTeacherCollection) {
          return 1;
        } else {
          return a.sort > b.sort ? 1 : -1;
        }
      }
    });

    if (this.session?.user?.isStudent) {
      return sorted.rejectBy('userId', this.session?.user?.id);
    }

    return sorted;
  }

  @action
  setCategory(value) {
    this._category = value;
  }
}
