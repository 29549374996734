import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  namespace: 'api/v1/meta',
  shouldBackgroundReloadRecord() {
    return true;
  },

  shouldBackgroundReloadAll() {
    return true;
  },
});
