import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { MissingContentError } from 'babel/utils/errors';
import { reject, resolve } from 'rsvp';

export default class MasterContentsAssignmentsEditRoute extends Route {
  @service session;

  @service store;

  beforeModel() {
    const user = this.session.user;

    if (!user.isTeacher) {
      return reject(ForbiddenError);
    }
  }

  async model({ assignment_id }) {
    let teacherAssignment;

    try {
      teacherAssignment = await this.store.findRecord(
        'teacher-assignment',
        assignment_id
      );
    } catch (err) {
      return reject(new MissingContentError());
    }

    await resolve(teacherAssignment.contents);

    return teacherAssignment;
  }

  afterModel(model) {
    const { model: content } = this.modelFor('master.contents');

    if (!model.hasContent(content)) {
      return reject(NotFoundError);
    }
  }

  resetController(controller) {
    const model = controller.model;

    if (!model.isDestroyed && !model.isDestroying) {
      model.rollback();
    }
  }
}
