import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterContentsWordlistsNewRoute extends Route {
  @service session;

  @service store;

  model() {
    const user = this.session.user;

    const { model: content, book } = this.modelFor('master.contents');
    const language = book.body.language || 'sv_SE';

    return this.store.createRecord('wordlist', {
      user,
      books: [book],
      contents: [content],
      language,
      type: language === 'sv_SE' ? 'concepts' : 'glossaries',
    });
  }

  resetController(controller) {
    controller.model.rollback();
  }
}
