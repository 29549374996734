class DigilarAcapelaCloudHttpClient {
  constructor(apiBaseUrl, ajax) {
    this._apiBaseUrl = apiBaseUrl;
    this._ajax = ajax;
  }

  postSpeechRequest(data) {
    // Post request to our own backend Acapela endpoint
    // console.debug('Acapela postSpeechRequest data:', data);
    const url = `${this._apiBaseUrl}/api/v1/content/acapela/command`;

    let useAuth = true;

    return this._ajax.request(url, useAuth, {
      type: 'POST',
      data,
    });
  }
}

export default DigilarAcapelaCloudHttpClient;
