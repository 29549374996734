import { defaultSpeakerControls } from 'speaker';

const digilarSpeakerControls = (
  speakerContext,
  speakerConfig,
  speakerAdapter,
  getOnClickPromise
) => {
  const defaultControls = defaultSpeakerControls(
    speakerContext,
    speakerConfig,
    speakerAdapter
  );

  return {
    ...defaultControls,

    onTextBlockClick: (speakerAudioSourceIndex, options) => {
      // Wait for injected promise to resolve before proceeding with click handling
      getOnClickPromise()
        .then(() => {
          defaultControls.onTextBlockClick(speakerAudioSourceIndex, options);
        })
        .catch((error) => console.warn(`promiseOnClick rejected:`, error));
    },

    onPlayButtonClick: (speakerAudioSourceIndex, options) => {
      // Wait for injected promise to resolve before proceeding with click handling
      getOnClickPromise()
        .then(() =>
          defaultControls.onPlayButtonClick(speakerAudioSourceIndex, options)
        )
        .catch((error) => console.warn(`promiseOnClick rejected:`, error));
    },
  };
};

export default digilarSpeakerControls;
