import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default Service.extend({
  // SETUP

  session: service(),

  store: service(),

  intl: service(),

  snackbar: service(),

  confirm: service('confirmManager'),

  // METHODS

  save(word) {
    const onResolve = () => {
      this._success('save');
    };

    const onReject = () => {
      this._error('save');
    };

    // NOTE Make sure that we return the orginal promise.
    const promise = word.save().then(() => {
      return word.get('wordlist').then((wordlist) => {
        wordlist.get('words').addObject(word);
        return wordlist.save();
      });
    });
    promise.then(onResolve, onReject);
    return promise;
  },

  delete(word) {
    return this._confirm('delete', { danger: true }).then(() => {
      const onResolve = () => {
        this._success('delete');
      };

      const onReject = () => {
        this._error('delete');
      };

      // NOTE Make sure that we return the orginal promise.
      const promise = word.destroyRecord();
      promise.then(onResolve, onReject);
      return promise;
    });
  },

  // PRIVATE

  _confirm(method, options) {
    return this.get('confirm').perform(
      this.get('intl').t(
        ['services', 'wordDispatcher', method, 'confirm'].join('.')
      ),
      options
    );
  },

  _success(method) {
    this.get('snackbar').enqueue(
      this.get('intl').t(
        ['services', 'wordDispatcher', method, 'snackbar', 'success'].join('.')
      ),
      {
        variant: 'success',
        autoDismiss: true
      }
    );
  },

  _error(method) {
    this.get('snackbar').enqueue(
      this.get('intl').t(
        ['services', 'wordDispatcher', method, 'snackbar', 'error'].join('.')
      ),
      {
        variant: 'error'
      }
    );
  }
});
