import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ExerciseViewAssignmentCheckComponent extends Component {
  /**
   * Arguments:
   * assignment (object)
   * canHaveAttachments (bool)
   * assignmentAnswer (object)
   * answer (object)
   * handleOnChange (function)
   * showValidation (bool)
   * showKey (bool)
   * disabled (bool)
   * showSuggestion (bool)
   * incomplete (bool)
   * image (object)
   */

  @service assignmentEvents;

  get showIncompleteMessage() {
    return this.args.incomplete && this.args.assignmentAnswer.input !== true;
  }

  @action handleChange(val) {
    if (this.args.assignmentAnswer) {
      this.args.assignmentAnswer.input = val;
    }

    return this.args.handleOnChange?.(val);
  }

  @action handleEnter() {
    this.assignmentEvents.trigger('onEnter');
  }
}
