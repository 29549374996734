import Component from '@glimmer/component';
import Part from 'babel/utils/part';
import { storageFor } from 'ember-local-storage';

export default class ContentsChapterHeroComponent extends Component {
  /**
   * Arguments:
   * activeSection (object)
   * content (object)
   */

  @storageFor('read-setting') settings;

  part = new Part();

  get showPlaceholder() {
    return this.settings.get('onlytext') && !this.part.mediaForced;
  }
}
