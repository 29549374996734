import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import jwtDecode from 'ember-cli-jwt-decode';
import { storageFor } from 'ember-local-storage';
import { getConfig } from 'ember-simple-auth-oidc/config';
import ESASession from 'ember-simple-auth-oidc/services/session';
import getAbsoluteUrl from 'ember-simple-auth-oidc/utils/absolute-url';
import { generatePkceChallenge } from 'ember-simple-auth-oidc/utils/pkce';
import { resolve } from 'rsvp';
import { v4 } from 'uuid';

export default class SessionService extends ESASession {
  @service imbox;

  @service store;

  @service router;

  @service pusher;

  @service ajax;

  @service ilt;

  @storageFor('session-states') states;

  @tracked lastLoggedProduct = null;

  get inPreviewMode() {
    return this.states.get('inPreviewMode');
  }

  set inPreviewMode(val) {
    this.states.set('inPreviewMode', val);
  }

  get inUndercover() {
    return this.states?.inUndercover;
  }

  get accessToken() {
    return this.session?.content?.authenticated?.access_token;
  }

  get clientUserRole() {
    if (!this.accessToken) {
      return undefined;
    }
    return jwtDecode(this.accessToken)?.role;
  }

  get isUser() {
    return this.clientUserRole === 'user';
  }

  get isAdmin() {
    return this.clientUserRole === 'admin';
  }

  get isSuperAdmin() {
    return this.clientUserRole === 'superadmin';
  }

  get allowPreview() {
    if (!this.accessToken) {
      return false;
    }
    return jwtDecode(this.accessToken)?.allow_preview;
  }

  constructor() {
    super(...arguments);

    this.pusher.on('logout', (logoutEvent) => {
      const idToken = this.data.authenticated.id_token;

      if (idToken) {
        // if the session sent by logoutEvent is the same as idToken session
        // we should logout this session
        if (jwtDecode(idToken).sid === logoutEvent.id) {
          // Invalidate the ember-simple-auth session
          this.invalidate();

          const authenticator = this.session._lookupAuthenticator(
            this.session.authenticator
          );

          // Trigger a single logout on the authorization server
          return authenticator.singleLogout(idToken);
        }
      } else {
        this.router.transitionTo('/login');
      }
    });
  }

  refreshUserSession() {
    return this.ajax.request(
      `${config.userApiEndpoint}/api/users/refresh-userdata`,
      true,
      {
        type: 'GET',
      }
    );
  }

  refreshUserLicenses() {
    return this.ajax
      .request(`${config.userApiEndpoint}/api/users/refresh-licenses`, true, {})
      .then((data) =>
        resolve(this.user.licenses)
          .then((licenses) =>
            licenses
              .reload()
              .then(() => licenses.invoke('notifyPropertyChange', 'userIds'))
          )
          .then(() => data)
      );
  }

  load(stayOnPage = false) {
    return this.store
      .queryRecord('user', { me: true })
      .then((user) => {
        // Attempt to fetch an ILT token
        this.ilt.getToken().catch(() => {});

        if (!this.user) {
          this.user = user;
        }

        return user;
      })
      .then((user) => {
        user.get('school').then((school) => {
          this.imbox.load(user, school);
        });

        return user;
      })
      .catch(() => {
        this.invalidate();

        if (!stayOnPage) this.triggerPromptNone();
      });
  }

  triggerPromptNone() {
    const state = v4();

    // Store state to session data
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.set('data.state', state);

    /**
     * Store the `nextURL` in the localstorage so when the user returns after
     * the login he can be sent back to error destination.
     */
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.set('data.nextURL', window.location.pathname);

    const esaConfig = getConfig(getOwner(this));

    const { protocol, host } = location;

    let search = [
      `client_id=${esaConfig.clientId}`,
      `redirect_uri=${protocol}//${host}/login`, // cant use this.redirectUri because it will point to whatever route path we are on
      `response_type=code`,
      `state=${state}`,
      `scope=${esaConfig.scope}`,
      `prompt=none`,
    ];

    if (esaConfig.enablePkce) {
      const pkceChallenge = generatePkceChallenge(this.data.pkceCodeVerifier);
      search.push(`code_challenge=${pkceChallenge}`);
      search.push('code_challenge_method=S256');
    }

    search = search.filter(Boolean).join('&');

    location.replace(
      `${getAbsoluteUrl(esaConfig.host)}${esaConfig.authEndpoint}?${search}`
    );
  }

  singleLogout() {
    const idToken = this.data.authenticated.id_token;

    // Invalidate the ember-simple-auth session
    this.invalidate();

    if (idToken) {
      // Trigger a single logout on the authorization server
      const authenticator = this.session._lookupAuthenticator(
        this.session.authenticator
      );

      return authenticator.singleLogout(idToken);
    } else {
      // No id_token found which means we can not use post_logout_redirect_uri
      return location.replace(
        `${config['ember-simple-auth-oidc'].host}/session/end`
      );
    }
  }

  invalidateSession() {
    return this.invalidate();
  }

  restore() {
    return this.session.restore();
  }
}
