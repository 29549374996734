import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['note-detail'],

  // PARAMS

  note: null,

  indexRouteName: null,

  unlinkable: false,

  hasBreadcrumb: true,
});
