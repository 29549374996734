import { action } from '@ember/object';
import { later } from '@ember/runloop';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

const REJECTED_NODE_TYPES = ['root', 'node-imports-root'];

export default class GlobalBreadcrumb extends Component {
  @cached get ancestorsProxy() {
    let promise = resolve();
    if (this.args.parent) {
      promise = this.args.parent.getParents().then((parents) =>
        parents
          .reject((node) => REJECTED_NODE_TYPES.includes(node.get('type')))
          .reverse()
          .addObject(this.node)
      );
    }
    return load(promise, this);
  }

  get ancestors() {
    return this.ancestorsProxy.isResolved ? this.ancestorsProxy.value : null;
  }

  @action scrollToTop() {
    later(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
}
