import ArrayProxy from '@ember/array/proxy';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { inject as service } from '@ember/service';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from 'babel/models/model';
import { resolve } from 'rsvp';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);

export default Model.extend({
  session: service(),

  title: attr('string'),

  type: attr('string'),

  free: attr('boolean'),

  disabledUntilPublished: attr('boolean'),

  published: attr('boolean'),

  for_trial: attr('boolean'),

  for_single_day_trial: attr('boolean', {
    defaultValue() {
      return false;
    },
  }),

  metadata: attr(),

  fields: attr(),

  subjects: attr(),

  stages: attr(),

  released: attr('only-date'),

  externalUrl: attr('string'),

  teachersOnly: attr('boolean'),

  license_time: attr('number'),

  unlimited_license_time: attr('boolean'),

  hasProductPage: attr('boolean'),

  // PROPERTIES

  forTrial: readOnly('for_trial'),

  // RELATIONSHIPS

  books: hasMany('entity', { async: true, inverse: null }),

  image: belongsTo('material', { async: true, inverse: null }),

  cover: belongsTo('material', { async: true, inverse: null }),

  // COMPUTED

  isExtraMaterial: computed('free', 'unlimited_license_time', function () {
    return this.get('free') && this.get('unlimited_license_time');
  }),

  authors: computed('metadata.authors', function () {
    const authors = this.get('metadata.authors');

    if (!authors) return [];

    return authors.map((author) => {
      const parts = author.split(',');

      if (parts.length > 1) {
        const lastName = parts.shift().trim();
        const firstName = parts.join(',').trim();

        return firstName + ' ' + lastName;
      } else {
        return author;
      }
    });
  }),

  illustrators: readOnly('metadata.illustrators'),

  translators: readOnly('metadata.translators'),

  userBooks: computed('books', 'session.user.isTeacher', function () {
    const promise = resolve(this.books).then((books) => {
      return books.filter((book) => {
        const isTeacherOnlyBook = book?.body?.teacher_only || false;
        return this.session.user.isTeacher || !isTeacherOnlyBook;
      });
    });

    return ArrayPromiseProxy.create({ promise });
  }),
});
