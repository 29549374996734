class DigilarAcapelaHttpClient {
  constructor(apiBaseUrl, ajax) {
    this._apiBaseUrl = apiBaseUrl;
    this._ajax = ajax;
  }

  postSpeechRequest(data) {
    // Post request to our own backend Acapela endpoint
    //
    // Note! To bypass backend api here, do the following:
    // 1. Change url to: //vaas.acapela-group.com/Services/UrlMaker.json
    // 2. Add properties cl_login, cl_app and cl_pwd to data object.
    // 3. Change useAuth to false
    // console.debug('Acapela postSpeechRequest data:', data);
    let url = `${this._apiBaseUrl}/api/v1/content/acapela/urlmaker`;

    let useAuth = true;

    return this._ajax.request(url, useAuth, {
      type: 'POST',
      data,
    });
  }

  getWordPositionFile(url) {
    // Simple get request to the url we've received from Acapela
    let useAuth = false;

    return this._ajax.request(url, useAuth, { type: 'GET' });
  }
}

export default DigilarAcapelaHttpClient;
