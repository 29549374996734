import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterCoursesShowIndexRoute extends Route {
  @service store;
  @service session;
  @service pusher;

  async model() {
    const courses = this.modelFor('master.courses');
    const course = this.modelFor('master.courses.show');

    const [missions, shortcuts, teacherAssignments, wordlists] =
      await Promise.all([
        this.store.query('mission', {
          filter: {
            course_id: course.id,
            scope: 'visible',
          },
          order: [
            {
              field: 'created',
              direction: 'DESC',
            },
          ],
        }),
        course.shortcuts,
        courses.teacherAssignments,
        courses.wordlists,
      ]);

    return {
      course,
      courses,
      missions,
      shortcuts,
      teacherAssignments,
      wordlists,
    };
  }

  setupController() {
    super.setupController(...arguments);

    this.pusher.on('didReceiveMission', this.handleDidReceiveMission);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.pusher.off('didReceiveMission', this.handleDidReceiveMission);
      controller.expandedMissions = [];
      controller.isCourseMissionActivateModalOpen = false;
      controller.isCourseMissionCopyModalOpen = false;
    }
  }

  @action
  handleDidReceiveMission() {
    this.refresh();
  }
}
