import { modifier } from 'ember-modifier';

function removeHighlighting(text) {
  return text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
}

function highlightTerm(term, text) {
  if (!term?.length) {
    return text;
  }

  // Escape regex chars
  term = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(term, 'gi');
  return text.replace(regex, '<mark class="highlight">$&</mark>');
}

function highlightTerms(element, [terms]) {
  if (!element.classList.contains('highlight-terms')) {
    element.classList.add('highlight-terms');
  }

  let text = removeHighlighting(element.innerHTML);

  text = highlightTerm(terms.join(' '), text);

  element.innerHTML = text;

  return () => {
    if (element.classList.contains('highlight-terms')) {
      element.classList.remove('highlight-terms');
    }

    let text = removeHighlighting(element.innerHTML);
    element.innerHTML = text;
  };
}

export default modifier(highlightTerms, { eager: false });
