import Component from '@ember/component';
import { computed } from '@ember/object';
import EmberObject from '@ember/object';
import sortBy from 'babel/utils/sort-by';
import shuffleArray from 'compton/utils/array-shuffle';
import groupBy from 'compton/utils/group-by';

function flatten(arr) {
  return [].concat.apply([], arr);
}

const ALLOWED_TYPES = [
  'books',
  'areas',
  'archives',
  'glossaries',
  'contents',
  'interactives',
  'sections',
  'folders',
  'collections',
  'exercises',
  'assignments',
  'studies',
  'materials'
];

const MANUAL = 'manual';
const INVERSE = 'inverse';
const RANDOM = 'random';
const TITLE_ASC = 'title_asc';
const TITLE_DESC = 'title_desc';
const DATE_ASC = 'date_asc';
const DATE_DESC = 'date_desc';

const SORT = 'sort';
const TITLE = 'title';
const CREATED = 'created';

const ORDER_OPTIONS = {
  [MANUAL]: {
    shuffle: false,
    reverse: false,
    sortBy: SORT,
    group: true
  },
  [INVERSE]: {
    shuffle: false,
    reverse: true,
    sortBy: SORT,
    group: false
  },
  [RANDOM]: {
    shuffle: true,
    reverse: false,
    sortBy: SORT,
    group: false
  },
  [TITLE_ASC]: {
    shuffle: false,
    reverse: false,
    sortBy: TITLE,
    group: false
  },
  [TITLE_DESC]: {
    shuffle: false,
    reverse: true,
    sortBy: TITLE,
    group: false
  },
  [DATE_ASC]: {
    shuffle: false,
    reverse: false,
    sortBy: CREATED,
    group: false
  },
  [DATE_DESC]: {
    shuffle: false,
    reverse: true,
    sortBy: CREATED,
    group: false
  }
};

export default Component.extend({
  // SETUP

  tagName: '',

  // PARAMS

  entity: null,

  blacklistedEntityTypes: null,

  validEntities: null,

  // PROPERTIES

  allowedEntityTypes: computed('blacklistedEntityTypes.[]', function() {
    const blacklistedEntityTypes = this.get('blacklistedEntityTypes');

    if (blacklistedEntityTypes && blacklistedEntityTypes.get('length')) {
      return ALLOWED_TYPES.reject((type) => {
        return blacklistedEntityTypes.includes(type);
      });
    }

    return ALLOWED_TYPES;
  }),

  children: computed(
    'entity.{isTeacherCollection,template,children.isFulfilled,children.[]}',
    'validEntities.[]',
    function() {
      const validEntities = this.get('validEntities');
      const entityChildren = this.get('entity.children') || [];

      if (
        !validEntities ||
        this.get('entity.isTeacherCollection') ||
        this.get('entity.isCarousel')
      ) {
        return entityChildren;
      }

      return entityChildren.filter((child) => {
        return validEntities.includes(child);
      });
    }
  ),

  orderOptions: computed('entity.sortBy', function() {
    return ORDER_OPTIONS[this.get('entity.sortBy') || MANUAL];
  }),

  categoryGroups: computed(
    'orderOptions',
    'entity.sortBy',
    'children.@each',
    'allowedEntityTypes',
    function() {
      const children = this.get('children')
        .rejectBy('isTeacherCollection')
        .rejectBy('isTeacherAssignment');
      let categoryGroupsFinal = [];

      if (this.get('entity.type') === 'areas') {
        let sort = this.get('entity.sortBy');

        if (sort === 'name_asc') sort = 'title_asc';
        else if (sort === 'name_desc') sort = 'title_desc';

        const filter = (entity) =>
          this.get('allowedEntityTypes').includes(entity.get('type'));

        let entities = sortBy(children.filter(filter), sort);

        let categoryGroups = groupBy(entities, 'category', 'entities');

        const uncategorizedGroup = categoryGroups.findBy('category', null);

        // If we've an uncatorgized group, move it to the beginning.
        if (uncategorizedGroup) {
          categoryGroups.removeObject(uncategorizedGroup);
          categoryGroups = categoryGroups.insertAt(0, uncategorizedGroup);
        }

        categoryGroupsFinal = sortBy(categoryGroups, sort).map(
          (categoryGroup) => {
            const entities = categoryGroup.get('entities').map((entity) => {
              return EmberObject.create({
                entity,
                index: entity.get('sort')
              });
            });

            return EmberObject.create({
              entities,
              category: categoryGroup.get('category')
            });
          }
        );
      } else {
        const { sortBy, reverse, shuffle, group } = this.get('orderOptions');
        const filter = (entity) =>
          this.get('allowedEntityTypes').includes(entity.get('type'));

        let filteredEntities = children.filter(filter);

        if (group) {
          let categoryGroups = groupBy(
            filteredEntities,
            'category',
            'entities'
          );

          const order = ['contents', 'workspace', 'glossaries'];
          const sortSpaceGroups = (a, b) => {
            return order.indexOf(a.space) - order.indexOf(b.space);
          };

          categoryGroupsFinal = categoryGroups.map((categoryGroup) => {
            let spaceGroups = groupBy(
              categoryGroup.get('entities'),
              'space',
              'entities'
            ).sort(sortSpaceGroups);

            const entities = [];

            spaceGroups.forEach((spaceGroup) => {
              spaceGroup.get('entities').forEach((entity) => {
                entities.push(
                  EmberObject.create({
                    entity,
                    index: entity.get('sort')
                  })
                );
              });
            });

            return EmberObject.create({
              entities: entities.sortBy(sortBy),
              category: categoryGroup.get('category'),
            });
          });
        } else {
          let entities = filteredEntities.sortBy(sortBy);
          if (reverse) {
            entities = entities.reverse();
          }

          if (shuffle) {
            entities = shuffleArray(entities);
          }

          entities = entities.map((entity, index) =>
            EmberObject.create({ entity, index })
          );

          categoryGroupsFinal = [EmberObject.create({ entities })];
        }
      }

      let index = 1;

      categoryGroupsFinal.forEach((groupEntites) => {
        groupEntites.entities.forEach((entity) => {
          entity.set('globalIndex', index);
          index++;
        });
      });

      return categoryGroupsFinal;
    }
  )
});
