import digilarAcapelaRuleSet from 'babel/utils/speaker/text-processing-rule-sets/acapela/digilar-acapela';
import {
  AcapelaTextToSpeechProvider,
  SpeakerTextProcessorRuleSet,
} from 'speaker';

import DigilarAcapelaHttpClient from './DigilarAcapelaHttpClient';

class DigilarAcapelaProviderFactory {
  static newAcapelaPlugin({
    httpClientParams,
    speechSpeed = 1.0,
    generalDigilarTextProcessingRuleSets,
  }) {
    const { ajax, apiBaseUrl } = httpClientParams;

    let acapelaProvider = new AcapelaTextToSpeechProvider({
      // Inject a simple "http client" to not have hard dependencies to any http libs or URLs in AcapelaTextToSpeechProvider
      acapelaHttpClient: new DigilarAcapelaHttpClient(apiBaseUrl, ajax),

      cacheEnabled: false,
      // Injecting a simple "cache function" that can be used either as a cache or for mocking during dev and test.
      // The audioSourceKey received as param is the textToSpeechCacheKey for each audio source config.

      fetchSpeechCache: (audioSourceKey, language, voice, speechSpeed) => {
        return null;
      },

      // URL protocol can be forced by setting for example 'https' here. Not forcing will use protocol relative URLs.
      forcedUrlProtocol: null,

      // The speech speed here is not the playback speed. Instead it is the speed of the speech generated by Acapela. 1.0 = default. Lower value = slower, higher value = faster.
      speechSpeed,

      customTextProcessingRuleSets: (language) => [
        ...generalDigilarTextProcessingRuleSets(language),

        // Digilär+Acapela specific rules
        new SpeakerTextProcessorRuleSet(digilarAcapelaRuleSet(language)),
      ],
    });

    return acapelaProvider;
  }
}

export default DigilarAcapelaProviderFactory;
