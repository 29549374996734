import Component from '@ember/component';
import { observer } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { scrollTo, viewportTop } from 'babel/utils/chapter-viewport';

export default Component.extend({
  // SETUP

  footnotesService: service('footnotes'),

  router: service(),

  classNames: ['contents-chapter-scroll'],

  // PROPERTIES

  preventScrollIntoView: false,

  isPrevented: false,

  footnotes: null,

  // EVENTS

  windowScroll() {
    this._debouncedRouteToClosestSection();
  },

  // HOOKS

  init() {
    this._super(...arguments);
    this.windowScroll = this.windowScroll.bind(this);
    this.set(
      'footnotes',
      this.footnotesService.createIndexContext(this.sections)
    );
  },

  didInsertElement() {
    this._super(...arguments);

    window.addEventListener('scroll', this.windowScroll, {
      passive: true,
    });
  },

  willDestroyElement() {
    this._super(...arguments);

    window.removeEventListener('scroll', this.windowScroll, {
      passive: true,
    });
  },

  _debouncedRouteToClosestSection() {
    debounce(this, '_routeToClosestSection', 2);
  },

  _routeToClosestSection() {
    if (this.isPrevented) {
      return;
    }

    const sectionElements = Array.from(
      this.element.querySelectorAll('.chapter-part')
    );

    let closest = null;

    sectionElements.forEach((sectionElement) => {
      const top =
        (sectionElement.previousElementSibling ? sectionElement.offsetTop : 0) -
        window.pageYOffset;

      if (top <= viewportTop()) {
        closest = sectionElement;
      }
    });

    if (closest) {
      const sectionId = closest.getAttribute('id');

      if (this.get('activeSection.id') !== sectionId) {
        this.set('preventScrollIntoView', true);
        this.router.replaceWith({
          queryParams: { section: sectionId },
        });
      }
    }
  },

  activeSectionObserver: observer('activeSection', function () {
    if (!this.preventScrollIntoView) {
      const element = document.getElementById(this.get('activeSection.id'));

      if (element) {
        this.set('isPrevented', true);

        scrollTo(element).finally(() => {
          if (!this.isDestroying && !this.isDestroyed) {
            this.set('isPrevented', false);
          }
        });
      }
    }
    this.set('preventScrollIntoView', false);
  }),
});
