import { inject as service } from '@ember/service';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import Model from 'babel/models/model';
import {
  correctExercise,
  emptyAnswer,
  getNumCorrectAnswers,
  getNumStartedAnswers,
  getNumSubmittedAnswers,
} from 'babel/utils/assignments';
import sum from 'compton/utils/array-sum';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class InteractiveModel extends Model {
  // SERVICES

  @service assignmentEvents;

  @service session;

  @service store;

  @service missionMode;

  // ATTRIBUTES

  @attr('string') document_id;

  @attr('date') ended;

  @attr('number') score;

  @attr('date') started;

  @attr('number') attempt;

  @attr('number') progress;

  @attr('string') type;

  // RELATIONS

  @hasMany('answer', { async: true, inverse: null }) answers;

  @belongsTo('entity', { async: true, inverse: null }) currentExercise;

  @belongsTo('entity', { async: true, inverse: null }) entity;

  @belongsTo('entity', { async: true, inverse: null }) book;

  @belongsTo('entity', { async: true, inverse: null }) area;

  @belongsTo('entity', { async: true, inverse: null }) content;

  @belongsTo('user', { async: true, inverse: null }) user;

  @hasMany('entity', { async: true, inverse: null }) correctExercises;

  // PROPERTIES

  @cached
  get answersProxy() {
    const exerciseIds = this.exercises.map((exercise) => exercise.id);
    const teacherAssignmentIds = this.exercises
      .filter((exercise) => exercise.isTeacherAssignment)
      .map((exercise) => exercise.belongsTo('teacherAssignment').id());

    const promise = resolve(this.answers).then((answers) =>
      []
        .concat(
          exerciseIds.map((id) =>
            answers.find((answer) => answer.belongsTo('entity').id() === id)
          ),
          teacherAssignmentIds.map((id) =>
            answers.find(
              (answer) => answer.belongsTo('teacherAssignment').id() === id
            )
          )
        )
        .filter((answer) => answer)
    );

    return load(promise, this);
  }

  get resolvedAnswers() {
    return this.answersProxy.isResolved ? this.answersProxy.value : [];
  }

  @cached
  get entityProxy() {
    return load(resolve(this.entity), this);
  }

  get resolvedEntity() {
    return this.entityProxy.isResolved ? this.entityProxy.value : null;
  }

  get pointsRequired() {
    return this.resolvedEntity?.pointsRequired;
  }

  get isDiagnosis() {
    return this.type === 'diagnosis_part';
  }

  @cached
  get exercisesProxy() {
    let promise = resolve([]);

    if (this.resolvedEntity) {
      promise = resolve(this.resolvedEntity.children).then((children) =>
        this.missionMode.allowedEntities(children)
      );
    }

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : [];
  }

  get numExercises() {
    return this.exercises.length;
  }

  @cached
  get attemptExercisesProxy() {
    let promise = resolve([]);

    if (this.exercises.length > 0) {
      promise = resolve(this.correctExercises).then((correctExercises) =>
        this.exercises.filter(
          (exercise) => !correctExercises.includes(exercise)
        )
      );
    }

    return load(promise, this);
  }

  get attemptExercises() {
    return this.attemptExercisesProxy.isResolved
      ? this.attemptExercisesProxy.value
      : [];
  }

  get numAttemptExercises() {
    return this.attemptExercises.length;
  }

  get attemptAnswers() {
    const exerciseIds = this.attemptExercises.map((exercise) => exercise.id);

    return this.resolvedAnswers.filter((answer) =>
      exerciseIds.includes(answer.belongsTo('entity').id())
    );
  }

  get numStartedAnswers() {
    return getNumStartedAnswers(this.resolvedAnswers);
  }

  get numSubmittedAnswers() {
    return getNumSubmittedAnswers(this.resolvedAnswers);
  }

  get numSubmittedAttemptAnswers() {
    return getNumSubmittedAnswers(this.attemptAnswers);
  }

  get numCorrectAnswers() {
    return getNumCorrectAnswers(this.resolvedAnswers);
  }

  get numCorrectAttemptAnswers() {
    return getNumCorrectAnswers(this.attemptAnswers);
  }

  get isStarted() {
    return this.attempt > 1 || this.numStartedAnswers > 0;
  }

  get isCompleted() {
    if (this.numExercises < 1) return false;

    let requiredPoints = this.numExercises;

    if (this.isDiagnosis && this.pointsRequired) {
      requiredPoints = this.pointsRequired;
    }

    return this.numCorrectAnswers >= requiredPoints;
  }

  get isActive() {
    if (!this.started) {
      return false;
    }

    if (this.ended) {
      return false;
    }

    if (!this.isDiagnosis) {
      return this.numStartedAnswers > 0 && !this.isCompleted;
    }

    if (!this.resolvedEntity?.timeAllowed) {
      return false;
    }

    if (
      new Date().getTime() <=
      new Date(this.started).getTime() + this.resolvedEntity.timeAllowed * 60000
    ) {
      return true;
    }

    return false;
  }

  get isAborted() {
    if (this.isActive || this.ended) {
      return false;
    }

    return true;
  }

  // METHODS

  async createAnswers(exercises) {
    const answers = await all(
      exercises.map((exercise) => {
        const assignments = exercise.body?.exercise?.assignments?.map(
          (assignment) => emptyAnswer(assignment)
        );

        const answer = this.store.createRecord('answer', {
          user: this.session.user,
          entity: exercise,
          type: 'diagnosis',
          book_id: this.book_id,
          area_id: this.area_id,
          content_id: this.content_id,
          collection_id: this.node_id,
          status: 'not-started',
          document_id: exercise.document_id,
          assignments,
        });

        return answer.save();
      })
    );

    this.answers = answers;
  }

  async turnIn() {
    if (this.assignmentEvents.autoSaveAnswer.isRunning) {
      this.assignmentEvents.autoSaveAnswer.cancelAll();
    }

    if (this.assignmentEvents.saveAnswer.isRunning) {
      this.assignmentEvents.saveAnswer.cancelAll();
    }

    const answers = await resolve(this.answers);
    const items = await all(answers.map((answer) => this.handleAnswer(answer)));

    this.ended = new Date();
    this.score = items.filter(
      (item) => item.answer.status === 'correct'
    ).length;
    this.scoreMax = sum(items.mapBy('entity.scoreMax'));

    await this.save();
  }

  async handleAnswer(answer) {
    const entity = await resolve(answer.entity);

    correctExercise(entity, answer);

    await answer.save();

    return { answer, entity };
  }
}
