import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import shakeElement from 'babel/utils/shake-element';

export default class ExerciseViewCommonItemStateComponent extends Component {
  @tracked assigned = false;

  @tracked paired = false;

  @tracked inGroup = false;

  @tracked suffix;

  @tracked gapValue;

  @tracked inputValue;

  @tracked incomplete;

  @tracked componentData;

  @tracked element;

  get isIncomplete() {
    return (
      !this.assigned &&
      !this.paired &&
      isBlank(this.gapValue) &&
      isBlank(this.inputValue) &&
      (this.incomplete || this.componentData?.incomplete)
    );
  }

  get assignedClassName() {
    return this.assigned && ['item-state', 'assigned'].join('--');
  }

  get pairedClassName() {
    return this.paired && ['item-state', 'paired'].join('--');
  }

  get inGroupClassName() {
    return this.inGroup && ['item-state', 'in-group'].join('--');
  }

  get suffixClassName() {
    const elem = this.element;
    const suffix = this.suffix;

    if (elem && suffix === 'error') {
      shakeElement(elem);
    }

    return this.suffix && ['item-state', this.suffix].join('--');
  }

  get classNames() {
    const classNames = [];

    if (this.assignedClassName) classNames.push(this.assignedClassName);
    if (this.pairedClassName) classNames.push(this.pairedClassName);
    if (this.inGroupClassName) classNames.push(this.inGroupClassName);
    if (this.suffixClassName) classNames.push(this.suffixClassName);
    if (this.isIncomplete) classNames.push('item-state--incomplete');

    return classNames.join(' ');
  }

  @action setElement(elem) {
    this.element = elem;
  }
}
