import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const PICKABLE_TYPES = [
  'books',
  'areas',
  'archives',
  'glossaries',
  'contents',
  'interactives',
  'sections',
  'folders',
  'collections',
  'exercises',
  'assignments',
  'studies',
  'materials',
];

export default class EntityBrowser extends Component {
  /**
   * Arguments:
   * entity (object)
   * selectedEntity (object)
   * expandedEntities (array)
   * pickedEntities (array)
   * pickedEntity (object)
   * blacklistedEntityTypes
   * root (false)
   * selectable (true)
   * routed (true)
   * inspectable (true)
   * pickable (false)
   * multiple (false)
   * onSelect (function or null)
   * onExpand (function)
   * onCollapse (function)
   * onPick (function)
   * onUnpick (function)
   * onClose (null?)
   */

  selectable = this.args.selectable ?? true;

  routed = this.args.routed ?? true;

  inspectable = this.args.inspectable ?? true;

  pickable = this.args.pickable ?? false;

  multiple = this.args.multiple ?? false;

  @tracked rootMode = 'collapsed'; // expanded or collapsed

  @action onShouldAllowPick() {
    return true;
  }

  get pickableTypes() {
    if (this.args.pickableTypes) return this.args.pickableTypes;

    const entity = this.args.pickedEntities.firstObject;

    if (this.args.multiple && entity) {
      return [entity.type];
    }

    return PICKABLE_TYPES;
  }
}
