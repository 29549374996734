import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Header::Base::Button\n  ...attributes\n  class={{if\n    this.shouldShowNotification\n    \"header-base-course-user-button-notification\"\n  }}\n  @ariaButtonLabel={{t \"components.header.courseUsersButton.ariaButtonLabel\"}}\n  @tooltip={{t \"components.header.courseUsersButton.tooltip\"}}\n  @onClick={{set this.helpList \"isOpen\" true}}\n  @active={{this.helpList.isOpen}}\n  as |button|\n>\n  <button.Icon @name=\"group\" />\n</Header::Base::Button>", {"contents":"<Header::Base::Button\n  ...attributes\n  class={{if\n    this.shouldShowNotification\n    \"header-base-course-user-button-notification\"\n  }}\n  @ariaButtonLabel={{t \"components.header.courseUsersButton.ariaButtonLabel\"}}\n  @tooltip={{t \"components.header.courseUsersButton.tooltip\"}}\n  @onClick={{set this.helpList \"isOpen\" true}}\n  @active={{this.helpList.isOpen}}\n  as |button|\n>\n  <button.Icon @name=\"group\" />\n</Header::Base::Button>","moduleName":"babel/components/header/base/course-users-button/index.hbs","parseOptions":{"srcName":"babel/components/header/base/course-users-button/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CourseUsersButton extends Component {
  @service session;

  @service helpList;

  get shouldShowNotification() {
    if (this.session?.user?.school?.get('metadata.external_licenses')) {
      return false;
    }

    return (this.args?.activeCourse?.usersMissingLicenses || []).any(
      (obj) => obj?.numMissing > 0
    );
  }
}
