import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  getAnswersForExercise,
  getLatestVersion,
  getOriginalAnswer,
} from 'babel/utils/get-answer';

export default class CollectionExerciseIndexItemComponent extends Component {
  /**
   * Arguments:
   * exercise (object)
   * active (bool)
   * index (number)
   * answers (object)
   * showTitle (bool)
   * showStatus (bool)
   * useOriginalAnswer (bool)
   * showNumAnswers (bool)
   * onExerciseChange (function)
   * routed (bool)
   */

  get title() {
    return `${this.args.index}. ${this.args.exercise?.title}`;
  }

  get exerciseAnswers() {
    return getAnswersForExercise(this.args.exercise, this.args.answers);
  }

  get answer() {
    return this.args.useOriginalAnswer
      ? getOriginalAnswer(this.args.exercise, this.exerciseAnswers)
      : getLatestVersion(this.args.exercise, this.exerciseAnswers);
  }

  get numAnswers() {
    return (
      this.exerciseAnswers
        ?.map((answer) => answer.belongsTo('user').id())
        ?.filter((userId, idx, arr) => userId && arr.indexOf(userId) === idx)
        ?.length || 0
    );
  }

  @action handleClick(evt) {
    evt.preventDefault();
    this.args.onExerciseChange?.(this.args.index);
  }
}
