import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class EntityContextRoute extends Route {
  @service contextHelper;

  @service entityContext;

  setupController(controller, { model, closeUrl, returnUrl }) {
    super.setupController(...arguments);

    this.entityContext.setEntityContext(model);

    if (closeUrl) {
      this.contextHelper.setCloseUrl(closeUrl);
    } else {
      this.contextHelper.setCloseUrl(null);
    }

    if (returnUrl) {
      this.contextHelper.setReturnUrl(returnUrl);
    } else {
      this.contextHelper.setReturnUrl(null);
    }
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.entityContext.resetEntityContext();
    }
  }
}
