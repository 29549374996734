import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li\n  class=\"select-list-item\"\n  data-selected={{this.selected}}\n  ...attributes\n  {{did-insert this.handleDidInsert}}\n>\n  <UiButton\n    class=\"select-list-item-button\"\n    @appearance=\"link\"\n    @onClick={{@onSelect}}\n    @disabled={{@disabled}}\n  >\n\n    {{#if this.selected}}\n      <DcIcon class=\"select-list-item-button-icon\" @name=\"check\" @size=\"xs\" />\n    {{/if}}\n\n    {{yield}}\n\n  </UiButton>\n</li>", {"contents":"<li\n  class=\"select-list-item\"\n  data-selected={{this.selected}}\n  ...attributes\n  {{did-insert this.handleDidInsert}}\n>\n  <UiButton\n    class=\"select-list-item-button\"\n    @appearance=\"link\"\n    @onClick={{@onSelect}}\n    @disabled={{@disabled}}\n  >\n\n    {{#if this.selected}}\n      <DcIcon class=\"select-list-item-button-icon\" @name=\"check\" @size=\"xs\" />\n    {{/if}}\n\n    {{yield}}\n\n  </UiButton>\n</li>","moduleName":"babel/components/select-list/item/index.hbs","parseOptions":{"srcName":"babel/components/select-list/item/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { scrollIntoViewIfNeeded } from 'compton/utils/dom';

export default class SelectListItem extends Component {
  get selected() {
    return this.args.selected ?? false;
  }

  @action
  handleDidInsert(element) {
    if (this.selected) {
      scrollIntoViewIfNeeded(element);
    }
  }
}
