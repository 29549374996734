import { action } from '@ember/object';
import { service } from '@ember/service';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderAudioComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  @service speaker;

  @service audioManager;

  baseClass = 'tb-render-audio';

  asyncType = 'material';

  get asyncId() {
    return this.args.part?.media_id;
  }

  get material() {
    return this.asyncData;
  }

  get showLabel() {
    return this.args.part?.appearance === 'label';
  }

  @action
  handleAudioPlayButtonClick() {
    // For all speaker contexts, pause any playing audio
    this.speaker.getRegisteredSpeakerContexts()?.forEach((speakerContext) => {
      speakerContext?.forEachSpeaker((sandbox) => {
        const adapter = sandbox?.speakerAdapter;

        if (adapter) {
          if (adapter.isPlaying) adapter.pause();

          // Add a listener to the adapter, if it is started, pause any playing audio
          adapter.one('play', () => {
            if (this.audioManager.currentAudio) {
              this.audioManager.currentAudio.pause();
            }
          });
        }
      });
    });
  }
}
