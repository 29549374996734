import { defaultSpeakerOptions } from 'speaker';

import { iOS } from './deviceUtils';

const digilarSpeakerOptions = (
  digilarSpeakerConfig,
  { getSelectedSpeechSpeed }
) => {
  const disableProgressBar =
    !digilarSpeakerConfig.showProgressBarWhenPlayingRecordedSpeech() &&
    !digilarSpeakerConfig.showProgressBarWhenPlayingTts();

  // On iOS, using HTML5 audio requires a user interaction for each individual audio source which makes "autoplay next" fail.
  // Therefor we use Web Audio API for iOS, which only requires a single user interaction for the first autoplay.
  // For other platforms HTML5 audio is used (only) to prevent pitch from changing along with playback speed for recorded speech.
  const forceHtml5Audio = !iOS();

  const playbackSpeedRecordedSpeech = () => {
    if (forceHtml5Audio) {
      // For HTML5 audio, just call injected function to get user's preferred playback speed. Pitch will be the same for all speeds.
      return getSelectedSpeechSpeed();
    } else {
      // For Web Audio API, don't allow playback speed change for recorded speech because it will change the pitch of the voice.
      return 1.0;
    }
  };

  return {
    ...defaultSpeakerOptions({ disableProgressBar }),

    progressEventsIntervalMs: disableProgressBar ? 0 : 25,

    playbackSpeedRecordedSpeech: playbackSpeedRecordedSpeech(),

    // If the TTS provider's speech speed is used, then the playback speed for TTS will always be 1.0 (100%).
    playbackSpeedTextToSpeech: digilarSpeakerConfig.useTtsSpeechSpeed()
      ? 1.0
      : getSelectedSpeechSpeed(),

    forceHtml5Audio,

    // Change this to a higher value if it is not enough to detect failed autoplay attempts
    autoplayTimeoutMs: 500,

    // Retry once on load error. Should be enough to handle most glitches against backend Acapela API.
    maxLoadAttempts: 2,

    audioFormats: ['mp3', 'webm'],

    enablePlayNextOnLoadError: true,

    // 1000 ms delay on load error before the load is rejected and loadError event is emitted.
    loadErrorDelayMs: 1000
  };
};

export default digilarSpeakerOptions;
