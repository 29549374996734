import Component from '@ember/component';

export default Component.extend({
  // SETUP

  tagName: '',

  // PARAMS

  notes: null,

  narrow: null,

  showRouteName: null,

  performMenuButtonItems: null,

  onPerform: null,
});
