import Component from '@ember/component';
import { computed, set } from '@ember/object';
import EmberObject from '@ember/object';
import { alias, oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import pagedArray from 'babel/utils/paged-array';
import sortBy from 'babel/utils/sort-by';
import { task } from 'ember-concurrency';

export default Component.extend({
  classNames: ['area-view'],

  intl: service(),

  router: service(),

  pageNumber: 1,

  sortedChildren: computed(
    'model.{sortBy,children.[]}',
    'sortValue',
    function () {
      let sortValue = this.get('sortValue');

      if (sortValue === 'standard') {
        sortValue = this.get('model.sortBy');
      }

      return sortBy(this.get('model.children'), sortValue);
    }
  ),

  pagedModels: computed(
    'sortedChildren.{isFulfilled,content,length}',
    'pageNumber',
    function () {
      let sortedChildren = [];

      if (this.sortedChildren.isFulfilled) {
        sortedChildren = this.sortedChildren.toArray();
      }

      return pagedArray({
        content: sortedChildren,
        page: this.pageNumber,
        perPage: 32,
      });
    }
  ),

  totalPages: oneWay('pagedModels.totalPages'),

  groupedSortedContents: computed('pagedModels.@each', function () {
    return this.get('pagedModels').map((entity) => {
      return EmberObject.create({
        entity,
      });
    });
  }),

  store: service(),

  onSearchCleared() {},

  searchValue: '',

  searchVal: null,

  categoryId: null,

  sortValue: 'standard',

  _search() {
    if (!this.get('searchValue.length') || this.get('searchValue.length') < 2) {
      return;
    }

    this.set('isLoading', true);
    this.set('pageNumber', 1);
    this.set('categoryId', null);

    return this.get('store')
      .query('entity', {
        filter: {
          book_id: [this.get('model.book_id')],
          parent_id: this.get('model.id'),
        },
        search: this.get('searchValue'),
      })
      .then((hits) => {
        this.set('isLoading', false);

        let entities = hits.sortBy('rank').reverse();

        entities = entities.map((entity) => {
          return EmberObject.create({
            entity,
          });
        });

        this.set('searchResults', entities);
      });
  },

  noResults: computed('searchValue', 'searchResults', 'isLoading', function () {
    if (
      this.get('searchValue.length') > 0 &&
      this.get('searchResults.length') === 0 &&
      !this.get('isLoading')
    ) {
      return true;
    } else {
      return false;
    }
  }),

  hideEditions: computed('model.template', function () {
    if (this.get('model.template') === 'grid') {
      return true;
    }
    return false;
  }),

  showSearchBar: computed('model.metadata.searchable', function () {
    return this.get('model.metadata.searchable') === true;
  }),

  isPaginated: computed('model.metadata.paginated', function () {
    return this.get('model.metadata.paginated') === true;
  }),

  hasFilters: computed('model.metadata.show_filters', function () {
    return this.get('model.metadata.show_filters') === true;
  }),

  hasOrder: computed('model.metadata.show_order', function () {
    return this.get('model.metadata.show_order') === true;
  }),

  hasRandom: computed('model.metadata.show_random', function () {
    return this.get('model.metadata.show_random') === true;
  }),

  hasCategorySelect: computed(
    'hasFilters',
    'model.categories.length',
    function () {
      if (!this.get('hasFilters')) return false;
      if (this.get('model.categories.length') < 2) return false;

      return true;
    }
  ),

  categoryEntities: computed('model.categories.@each.entities', function () {
    return this.get('model.categories').mapBy('entities');
  }),

  categoryOptions: computed(
    'hasCategorySelect',
    'model.categories.[]',
    'categoryEntities.@each.isFulfilled',
    function () {
      if (!this.get('hasCategorySelect')) return [];

      const categoryEntities = this.get('categoryEntities');

      const options = this.get('model.categories')
        .map((x, idx) => {
          const entities = categoryEntities[idx];
          if (!entities || !entities.isFulfilled || entities.length === 0)
            return null;
          return { label: x.get('name'), value: x.get('id') };
        })
        .filter((x) => x);

      options.unshift({
        label: this.get('intl').t('components.areaView.allCategories'),
        value: null,
      });

      return options;
    }
  ),

  sortOptions: computed('hasOrder', function () {
    if (!this.get('hasOrder')) return [];

    return [
      {
        label: this.get('intl').t('components.areaView.sort.standard'),
        value: 'standard',
      },
      {
        label: this.get('intl').t('components.areaView.sort.name_asc'),
        value: 'title_asc',
      },
      {
        label: this.get('intl').t('components.areaView.sort.name_desc'),
        value: 'title_desc',
      },
    ];
  }),

  search: task(function* () {
    yield this._search();
  }).keepLatest(),

  doSearch: task(function* () {
    const searchVal = this.get('searchVal');
    const searchValue = this.get('searchValue');

    if (searchValue === searchVal) {
      return;
    }

    this.set('searchValue', searchVal);

    this.set('isLoading', true);

    if (this.get('searchValue.length') === 0) {
      this.get('onSearchCleared')();
      this.set('searchResults', []);
    } else {
      yield this.get('search').perform();
    }
  }).restartable(),

  actions: {
    handlePaginationChange(pageNumber) {
      set(this, 'pageNumber', pageNumber);
    },

    openRandom() {
      const contents = this.get('model.children');

      if (contents && contents.length > 0) {
        const rand = Math.floor(Math.random() * contents.length);
        const content = contents.objectAt(rand);

        return this.get('router').transitionTo(
          'master.' + content.get('type'),
          content.get('id')
        );
      }
    },

    changeSortValue(value) {
      this.set('sortValue', value);
    },
  },
});
