import { ForbiddenError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { reject } from 'rsvp';

export default class MasterContentsAssignmentsNewRoute extends Route {
  @service session;

  @service store;

  beforeModel() {
    const user = this.session.user;

    if (!user.isTeacher) {
      return reject(ForbiddenError);
    }
  }

  model() {
    const user = this.session.user;
    const { model: content, book } = this.modelFor('master.contents');

    const teacherAssignment = this.store.createRecord('teacher-assignment', {
      user,
      books: [book],
      contents: [content],
    });

    teacherAssignment.addNewExerciseAssigment();

    return teacherAssignment;
  }

  resetController(controller) {
    controller.model?.rollback();
  }
}
