import EmberObject from '@ember/object';
import clone from 'babel/utils/clone';
import isEqual from 'babel/utils/isEqual';
import shuffle from 'compton/utils/array-shuffle';

function emptyAnswer(assignment) {
  if (!assignment) return;

  let alternatives, input, indexArr;

  switch (assignment.template) {
    case 'arrange':
      alternatives = assignment.content.alternatives;

      do {
        input = shuffle(alternatives.map((alt) => alt));
      } while (
        input.mapBy('uuid').toString() === alternatives.mapBy('uuid').toString()
      );

      break;
    case 'choice':
      input = [];
      break;
    case 'editor':
      if (assignment.settings?.pattern && assignment.content?.pattern?.length) {
        input = assignment.content.pattern;
      } else {
        input = '';
      }
      break;
    case 'match':
      alternatives = assignment.content.alternatives.map((alternative) => {
        return EmberObject.create({
          assigned: false,
          uuid: alternative.uuid,
        });
      });

      input = clone(shuffle(alternatives));
      break;
    case 'number-line':
      input = [];
      break;
    case 'reply':
      input = '';
      break;
    case 'place-choices':
      input = [];
      break;
    case 'place-words':
      alternatives = assignment.content.alternatives.concat(
        assignment.content.extra
      );

      // Filter out null or undefined values
      alternatives = alternatives.filter(
        (item) => item !== null && item?.value !== null
      );

      input = alternatives.map((item) => {
        return EmberObject.create({
          value: item?.value || item,
          assignedTo: null,
          extra: item?.value ? false : true,
        });
      });

      input = clone(shuffle(input));
      break;
    case 'place-images':
      alternatives = assignment.content.alternatives;

      input = alternatives.map((item) => {
        return EmberObject.create({
          uuid: item.uuid,
          assignedTo: null,
          image: item.image,
          extra: item.extra,
        });
      });

      input = clone(shuffle(input));
      break;
    case 'place-text-gaps':
      input = assignment.content.markers.map((marker) => {
        return EmberObject.create({
          uuid: marker.uuid,
          value: '',
        });
      });
      break;
    case 'sort':
      indexArr = shuffle([...Array(assignment.content.items.length).keys()]);

      if (assignment.settings.behaviour === 'images') {
        alternatives = assignment.content.items.filterBy('isImage');
      } else {
        alternatives = assignment.content.items.rejectBy('isImage');
      }

      input = alternatives.map((item, index) => {
        return EmberObject.create({
          uuid: item.uuid,
          assignedTo: null,
          index: indexArr[index],
        });
      });

      input = clone(input);
      break;
    case 'matrix':
    case 'text-gap':
    case 'text-gap-multiple':
      input = assignment.content.objects.map((gap) => {
        return EmberObject.create({
          uuid: gap.uuid,
          value: '',
        });
      });
      break;
    case 'check':
      input = false;
  }

  return EmberObject.create({
    uuid: assignment.uuid,
    correct: false,
    input: input,
    score: 0,
    scoreMax: 1,
  });
}

function dragGapIsCorrect(assignment, gap, input) {
  if (!gap.alternatives) return false;

  let keys = gap.alternatives.filterBy('correct').map((alternative) => {
    if (alternative && alternative.value) {
      return alternative.value.trim();
    } else {
      return null;
    }
  });

  let value = input.findBy('uuid', gap.uuid).value;

  if (!value) return false;

  value = value.trim();

  return keys.includes(value);
}

function selectGapIsCorrect(assignment, gap, input) {
  if (!gap.alternatives) return false;

  let keys = gap.alternatives.filterBy('correct').map((alternative) => {
    if (alternative && alternative.value) {
      return alternative.value.trim();
    } else {
      return null;
    }
  });

  const item = input.findBy('uuid', gap.uuid);
  let value = item && item.value;

  if (!value) return false;

  value = value.trim();

  return keys.includes(value);
}

function writeGapIsCorrect(assignment, gap, input) {
  if (!gap.alternatives) return false;

  let keys = gap.alternatives.filterBy('correct').map((alternative) => {
    if (alternative && alternative.value) {
      return alternative.value.trim();
    } else {
      return null;
    }
  });

  let value = input.findBy('uuid', gap.uuid).value;

  if (!value) return false;

  value = value.trim();

  const settings = assignment.settings.correctionwrite;

  if (!settings.includes('case')) {
    keys = keys.map((key) => key.toLowerCase());
    value = value.toLowerCase();
  }

  if (!settings.includes('whitespace')) {
    keys = keys.map((key) => key.replace(/\s/g, ''));
    value = value.replace(/\s/g, '');
  }

  return keys.includes(value);
}

function gapIsCorrect(assignment, gap, input) {
  if (!assignment || !assignment.settings) return;

  switch (assignment.settings.behaviour) {
    case 'drag':
      return dragGapIsCorrect(assignment, gap, input);
    case 'select':
      return selectGapIsCorrect(assignment, gap, input);
    case 'write':
      return writeGapIsCorrect(assignment, gap, input);
  }
}

function normalizeLatexInput(input) {
  /**
   * This function handles whitespace and decimal separator in the
   * latex code so that any amount of whitespace or using period or
   * comma as the decimal separator is all valid.
   *
   * Since Mathlive is configured to use comma as the default
   * decimal separator, the latex decimal separator period {.}
   * is never generated and therefore doesn't need to be handled.
   *
   * \; is regular whitespace in latex
   * {,} is the way a comma separator is represented in latex
   *
   * So basically the code does the following to the input string:
   * 1. Removes latex whitespace
   * 2. Removes regular whitespace
   * 3. Replaces latex decimal separator comma with regular comma
   * 4. Replaces comma with period
   */

  const values = [/\\;/g, /\\ /g, /\{,\}/g, /,/g];
  const replacements = ['', '', ',', '.'];

  let replacement;

  values.forEach((value, idx) => {
    replacement = replacements[idx];
    input = input.replace(value, replacement);
  });

  // A special replacement is needed for fractions where the new
  // syntax writes them as frac12 whereas the old would write
  // frac{1}{2}.
  const fracRegexp = /\\frac([0-9])([0-9])/g;
  input = input.replace(fracRegexp, `\\frac{$1}{$2}`);

  return input;
}

const WHITESPACE_RX = /\s/g;

export function isPlaceTextGapWordCorrect(x, y, corrections = []) {
  if (typeof x !== 'string' || typeof y !== 'string') {
    return false;
  }

  if (!corrections.includes('case')) {
    x = x.toLowerCase();
    y = y.toLowerCase();
  }

  if (!corrections.includes('whitespace')) {
    x = x.replace(WHITESPACE_RX, '');
    y = y.replace(WHITESPACE_RX, '');
  }

  return x === y;
}

function validatePlaceTextGaps(assignment, input) {
  const markers = assignment.content.markers;
  const corrections = assignment.settings.correctionwrite;
  return markers.every((answer) =>
    isPlaceTextGapWordCorrect(
      answer.word,
      input.findBy('uuid', answer.uuid).value,
      corrections
    )
  );
}

function isCorrect(assignment, answer) {
  if (!assignment) return;

  let alternatives,
    markers,
    input,
    items,
    isFormula,
    doLowerCase,
    keepWhitespace,
    keys,
    objects;

  switch (assignment.template) {
    case 'arrange':
      alternatives = assignment.content.alternatives;
      input = answer.input;

      if (!alternatives || !input || input.length === 0) {
        return false;
      }

      return isEqual(input.mapBy('uuid'), alternatives.mapBy('uuid'));
    case 'choice':
      alternatives = assignment.content.alternatives;
      input = clone(answer.input);

      if (!alternatives || !input || input.length === 0) {
        return false;
      }

      return alternatives.every((alternative) => {
        return input.includes(alternative.uuid) === alternative.correct;
      });
    case 'match':
      alternatives = assignment.content.alternatives;
      input = clone(answer.input);

      if (!alternatives || !input || !input.every((a) => a.assigned)) {
        return false;
      }

      return alternatives.every((alternative, index) => {
        return input.objectAt(index).uuid === alternative.uuid;
      });
    case 'number-line':
      alternatives = assignment.content.alternatives;
      input = clone(answer.input);

      if (!alternatives || !input || input.length === 0) {
        return false;
      }

      if (alternatives.length != input.length) return false;

      return alternatives.every((alternative) =>
        input.includes(alternative.start_index)
      );

    case 'place-choices':
      markers = assignment.content.markers.filterBy('correct');
      input = clone(answer.input);

      if (
        !markers ||
        !input ||
        input.length === 0 ||
        markers.length !== input.length
      ) {
        return false;
      }

      return markers.mapBy('uuid').every((uuid) => input.includes(uuid));

    case 'place-words':
      alternatives = assignment.content.alternatives;
      input = clone(answer.input);

      alternatives = alternatives.filter(
        (item) => item !== null && item?.value !== null
      );

      if (
        input.filter(
          (answer) => answer.extra === true && answer.assignedTo != null
        ).length
      ) {
        return false;
      }

      if (
        !alternatives.every(
          (answer) =>
            input.findBy('assignedTo', answer.assigned_to)?.value ===
            answer.value
        )
      ) {
        return false;
      }
      return true;
    case 'place-images':
      alternatives = assignment.content.alternatives;
      input = clone(answer.input);

      items = alternatives.map((alternative) => {
        const answer = input.findBy('uuid', alternative.uuid);

        // This fix has been added to handle cases where the same image should be accepted at multiple points.
        const answers = input.filter((item) => item.image === answer.image);

        return {
          correct: answers.some(
            (answer) => answer.assignedTo === alternative.assignedTo
          ),
        };
      });
      return items.every((answer) => answer.correct);
    case 'place-text-gaps':
      input = clone(answer.input);

      return validatePlaceTextGaps(assignment, input);

    case 'reply':
      input = clone(answer.input);

      if (!input || typeof input !== 'string' || input === '') {
        return false;
      }

      isFormula = assignment.settings.behaviour === 'formula';

      if (!assignment.settings.correctiontext) {
        assignment.settings.correctiontext = [];
      }

      doLowerCase = !assignment.settings.correctiontext.includes('case');
      keepWhitespace =
        assignment.settings.correctiontext.includes('whitespace');

      keys = assignment.content.keys;

      if (isFormula) {
        input = normalizeLatexInput(input);
        keys = keys.map((key) => normalizeLatexInput(key));
      } else {
        input = input.trim();

        if (!keepWhitespace) {
          input = input.replace(/\s/g, '');
          keys = keys.map((key) => key.replace(/\s/g, ''));
        }

        if (doLowerCase) {
          input = input.toLowerCase();
          keys = keys.map((key) => key.toLowerCase());
        }
      }

      if (keys.includes(input)) return true;

      if (isFormula && parseFloat(input) == input) {
        return keys
          .filter((key) => parseFloat(key) == key && parseFloat(key))
          .includes(parseFloat(input));
      }

      return false;
    case 'sort':
      if (assignment.settings.behaviour === 'images') {
        items = assignment.content.items.filterBy('isImage');
      } else {
        items = assignment.content.items.rejectBy('isImage');
      }

      input = answer.input;

      // check that items or input is valid and all input are
      // assigned to a dropzone
      if (!items || !input || !input.any((a) => a.assignedTo)) {
        return false;
      }

      // check that num assigned items for each group matches
      if (
        !assignment.content.groups.every(
          (group) =>
            items.filterBy('assigned_to', group.uuid).length ===
            input.filterBy('assignedTo', group.uuid).length
        )
      ) {
        return false;
      }

      // check that all item values are present among the correct
      // values for the dropzone it is attached to
      if (
        !input.every(
          (a) =>
            items
              .filterBy('assigned_to', a.assignedTo)
              .mapBy('value')
              .indexOf(items.findBy('uuid', a.uuid).value) !== -1
        )
      ) {
        return false;
      }

      return true;
    case 'matrix':
    case 'text-gap':
    case 'text-gap-multiple':
      objects = assignment.content.objects;
      input = clone(answer.input);

      if (!objects || !input || !input.every((a) => a.value)) {
        return false;
      }

      return objects.every((gap) => gapIsCorrect(assignment, gap, input));
    case 'check':
      return answer.input === true;
  }
}

function correctExercise(exercise, answer) {
  const assignments = exercise.get('body.exercise.assignments');

  const assignmentResults = assignments.map((assignment) => {
    let assignmentAnswer = answer
      .get('assignments')
      .findBy('uuid', assignment.uuid);

    if (!assignmentAnswer) {
      assignmentAnswer = emptyAnswer(assignment);
      answer.get('assignments').addObject(assignmentAnswer);
    }

    const correct = isCorrect(assignment, assignmentAnswer);

    assignmentAnswer.set('correct', correct);
    assignmentAnswer.set('score', correct ? 1 : 0);

    return correct;
  });

  const correct = assignmentResults.every(Boolean);

  answer.set('status', correct ? 'correct' : 'wrong');
}

const NOT_STARTED_ANSWER_STATUSES = ['not-started'];

const NOT_SUBMITTED_ANSWER_STATUSES = NOT_STARTED_ANSWER_STATUSES.concat([
  'started',
  'incomplete',
]);

const NOT_CORRECT_ANSWER_STATUSES = NOT_SUBMITTED_ANSWER_STATUSES.concat([
  'wrong',
  'teacher-wrong',
]);

const CORRECT_ANSWER_STATUSES = ['correct'];

function getCorrectAnswers(answers) {
  return answers.filter((answer) =>
    CORRECT_ANSWER_STATUSES.includes(answer.status)
  );
}

function getNumCorrectAnswers(answers) {
  return answers.filter(
    (answer) => !NOT_CORRECT_ANSWER_STATUSES.includes(answer.status)
  ).length;
}

function getNumStartedAnswers(answers) {
  return answers.filter(
    (answer) => !NOT_STARTED_ANSWER_STATUSES.includes(answer.status)
  ).length;
}

function getNumSubmittedAnswers(answers) {
  return answers.filter(
    (answer) => !NOT_SUBMITTED_ANSWER_STATUSES.includes(answer.status)
  ).length;
}

export {
  correctExercise,
  emptyAnswer,
  getCorrectAnswers,
  getNumCorrectAnswers,
  getNumStartedAnswers,
  getNumSubmittedAnswers,
};
