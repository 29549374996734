import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const PUBLISH = 'publish';
const UNPUBLISH = 'unpublish';
const ADD_WORD = 'add_word';
const QUIZ = 'quiz';
const DUPLICATE = 'duplicate';
const EDIT = 'edit';
const LINK = 'link';
const UNLINK = 'unlink';
const DELETE = 'delete';

export default class WorkspaceWordlistsIndexComponent extends Component {
  /**
   * Arguments:
   * onClose (function)
   */

  // SETUP

  @service() router;

  @service() missionMode;

  @service() wordlistDispatcher;

  @controller('master.contents') contentsController;

  // PROPERTIES

  @tracked wordlistChooseModalIsOpen = false;

  @tracked relatedAccordionIsExpanded = false;

  get content() {
    return this.contentsController.content;
  }

  get currentWordlists() {
    return this.contentsController.currentWordlists;
  }

  get relatedWordlists() {
    return this.contentsController.relatedWordlists;
  }

  // ACTIONS

  @action openWordlistChooseModal() {
    this.wordlistChooseModalIsOpen = true;
  }

  @action closeWordlistChooseModal() {
    this.wordlistChooseModalIsOpen = false;
  }

  @action handleWordlistBrowserPerform(wordlist, value) {
    switch (value) {
      case ADD_WORD:
        return this.router.transitionTo(
          'master.contents.wordlists.show.words.new',
          wordlist.id
        );
      case QUIZ:
        return this.router.transitionTo(
          'master.contents.wordlists.show',
          wordlist.id,
          { queryParams: { quiz: true } }
        );
      case DUPLICATE:
        return this.wordlistDispatcher
          .duplicate(wordlist, this.content)
          .then((duplicatedWordlist) => {
            this.wordlistDispatcher.highlight(duplicatedWordlist);
          });
      case EDIT:
        return this.router.transitionTo(
          'master.contents.wordlists.edit',
          wordlist.id
        );
      case PUBLISH:
        return this.wordlistDispatcher.save(wordlist, true);
      case UNPUBLISH:
        return this.wordlistDispatcher.save(wordlist, false);
      case LINK:
        return this.wordlistDispatcher.link(wordlist, this.content);
      case UNLINK:
        return this.wordlistDispatcher.unlink(wordlist, this.content);
      case DELETE:
        return this.wordlistDispatcher.delete(wordlist);
      default:
        break;
    }
  }

  @action async handleWordlistChooseModalChoose(wordlists) {
    await this.wordlistDispatcher.linkMany(wordlists, this.content);
    this.wordlistDispatcher.highlightMany(wordlists);

    wordlists.forEach((wordlist) => {
      this.missionMode.addIncludedWordlistIfNeeded(wordlist);
    });
  }
}
