import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CarouselResultComponent extends Component {
  /**
   * Arguments:
   * interactive (object)
   * onCollectionClose (function)
   * onCreateNewCarouselAttempt (function)
   * onNewCarouselAttemptCreated (function)
   */

  @service intl;

  @service assignmentEvents;

  get image() {
    if (this.args.interactive?.isCompleted) {
      return '/assets/images/collection/completed.svg';
    }

    return '/assets/images/collection/ended.svg';
  }

  get message() {
    if (this.args.interactive?.isCompleted) {
      return this.intl.t('components.carouselResult.completed');
    }

    return this.intl.t('components.carouselResult.result', {
      numCorrect: this.args.interactive?.numCorrectAttemptAnswers,
      numTotal: this.args.interactive?.numAttemptExercises,
    });
  }

  get infoText() {
    if (
      !this.args.interactive?.isCompleted &&
      this.args.interactive?.numAttemptExercises !==
        this.args.interactive?.numExercises
    ) {
      return this.intl.t('components.carouselResult.tryAgainInfo', {
        numCorrect: this.args.interactive?.numCorrectAnswers,
        numTotal: this.args.interactive?.numExercises,
      });
    }

    return null;
  }

  get hasRedoButton() {
    return this.args.interactive?.isCompleted ? true : false;
  }

  get hasCloseButton() {
    if (!this.args.onCollectionClose) return false;
    return this.args.interactive?.isCompleted ? true : false;
  }

  get hasTryAgainButton() {
    return !this.args.interactive?.isCompleted ? true : false;
  }

  @action handleCarouselResultRedo() {
    this.args.onCreateNewCarouselAttempt?.();

    return this.assignmentEvents
      .newCarouselAttempt(this.args.interactive, true)
      .then(() => this.args.onNewCarouselAttemptCreated?.());
  }

  @action handleCarouselResultTryAgain() {
    return this.assignmentEvents.newCarouselAttempt(this.args.interactive);
  }
}
