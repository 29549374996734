import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"place-images-image\">\n  {{#if this.imageSrc}}\n    <div>\n      <div class=\"place-images-image-container\">\n        <XImage\n          class=\"place-images-image-object\"\n          @source={{this.imageUrl}}\n          @width={{this.image.imageWidth}}\n          @height={{this.image.imageHeight}}\n          @mimetype={{this.image.imageMimetype}}\n          @alt={{or this.image.name \"Image\"}}\n        />\n      </div>\n    </div>\n\n  {{else}}\n    <DcLoader />\n  {{/if}}\n</div>", {"contents":"<div class=\"place-images-image\">\n  {{#if this.imageSrc}}\n    <div>\n      <div class=\"place-images-image-container\">\n        <XImage\n          class=\"place-images-image-object\"\n          @source={{this.imageUrl}}\n          @width={{this.image.imageWidth}}\n          @height={{this.image.imageHeight}}\n          @mimetype={{this.image.imageMimetype}}\n          @alt={{or this.image.name \"Image\"}}\n        />\n      </div>\n    </div>\n\n  {{else}}\n    <DcLoader />\n  {{/if}}\n</div>","moduleName":"babel/components/exercise-view/assignment/place-images/image/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/place-images/image/index.hbs"}});
import ItemImageComponent from 'babel/components/exercise-view/common/item-image/index';

export default class ExerciseViewPlaceImagesComponent extends ItemImageComponent {
  /**
   * Arguments:
   * imageId (string)
   * width (number)
   * height (number)
   */
   get imageUrl() {
    return `${this.image.imageUrl}&preserve-transparency=true`;
  }
}
