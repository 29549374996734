import Component from '@glimmer/component';
import Part from 'babel/utils/part';

const ALLOWED_COMPONENTS = [
  'assignment_link',
  'attachment',
  'audio',
  'code',
  'embed',
  'gallery',
  'heading',
  'image',
  'divider',
  'list',
  'paragraph',
  'table',
  'video',
  'vr_image',
  'canvas',
  'interactive_image',
  'placeholder',
  'ilt-video',
];

export default class TbRenderColumnComponent extends Component {
  /**
   * Arguments:
   * column (object)
   */

  get groups() {
    const components = this.args.column?.components?.sortBy('index') || [];
    const groups = [];

    components.forEach((component) => {
      const part = new Part(component);

      if (!ALLOWED_COMPONENTS.includes(part.component)) return;

      const templateDisjoin = part.settings?.template_disjoin ? true : false;
      const prevGroup = groups.objectAt(groups.length - 1);

      if (
        prevGroup &&
        part.template === prevGroup.template &&
        !templateDisjoin
      ) {
        prevGroup.parts.addObject(part);
      } else {
        groups.addObject({
          template: part.template,
          parts: [part],
        });
      }
    });

    return groups;
  }
}
