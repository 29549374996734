import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderParagraphComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-paragraph';

  get extraClasses() {
    const part = this.args.part;
    const classes = [];

    classes.push(`${this.baseClass}--${part?.settings?.size}`);

    if (part?.settings?.alignment) {
      classes.push(`${this.baseClass}--${part.settings.alignment}`);
    }

    return classes;
  }
}
