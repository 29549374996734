import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { scrollTo } from 'babel/utils/chapter-viewport';
import { resolve } from 'rsvp';

export default Component.extend({
  // SETUP

  store: service(),

  assignmentEvents: service(),

  tagName: 'section',

  classNames: ['chapter-part'],

  classNameBindings: ['isActive:chapter-part--active'],

  // PARAMS

  section: null,

  index: null,

  isActive: false,

  hasMultipleSections: false,

  editor: null,

  exercises: null,

  activeExercise: null,

  answers: null,

  activeAnswer: null,

  // OBSERVER

  activeExerciseObserver: observer(
    'exerciseId',
    'exercises.[]',
    function() {
      if (this.exerciseId && this.exercises) {
        const exercise = this.exercises.findBy('id', this.exerciseId);

        if (exercise) {
          this.set('activeExercise', exercise);
        }
      }
    }
  ),

  // HOOKS

  didInsertElement() {
    this._super(...arguments);

    this._scrollIntoViewIfActiveInNextFrame();

    this.get('section.contenteditor').then((editor) => {
      this.set('editor', editor);
    });

    this.get('section.children').then((exercises) => {
      this.set('exercises', exercises);

      if (exercises.length > 0) {
        this._setActiveExerciseAndAnswers();
      }
    });
  },

  _scrollIntoViewIfActiveInNextFrame() {
    window.requestAnimationFrame(() => {
      this._scrollIntoViewIfActive();
    });
  },

  _scrollIntoViewIfActive() {
    if (this.get('isActive')) {
      scrollTo(this.get('element'), 'auto');
    }
  },

  async _setActiveExerciseAndAnswers() {
    const { interactive, activeAnswer, activeExercise } =
      await this.assignmentEvents.getOrCreateInteractiveForCollection(
        this.section,
        this.activeExercise
      );

    this.set('answers', interactive.answers);
    this.set('activeExercise', activeExercise);
    this.set('activeAnswer', activeAnswer);
  },

  // ACTIONS

  actions: {
    openExercise(exerciseId) {
      const exercise = this.get('section.children')?.findBy('id', exerciseId);

      if (exercise) {
        this.set('activeExercise', exercise);
        return this._setActiveExerciseAndAnswers()
      }

      return resolve();
    }
  }
});
