import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MasterArchivesFoldersMaterialsController extends Controller {
  queryParams = [{ returnUrl: 'return-url' }];

  @service router;

  @service routerScroll;

  @tracked returnUrl;

  @action async handleMaterialViewerClose() {
    this.routerScroll.preserveScrollPosition = true;

    let transition;

    if (this.returnUrl) {
      transition = this.router.transitionTo(this.returnUrl);
    } else {
      transition = this.router.transitionTo('master.archives');
    }

    return transition.then(() => {
      later(
        this,
        () => {
          this.routerScroll.preserveScrollPosition = false;
        },
        100
      );
    });
  }
}
