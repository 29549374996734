import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{this.classNames}} ...attributes>\n  <UiButton\n    @appearance={{this.appearance}}\n    @onClick={{this.toggleModal}}\n  >\n    <DcIcon\n      @name={{this.icon}}\n      class={{if (not-eq this.appearance \"bulb\") \"n1\"}}\n    />\n  </UiButton>\n</div>\n", {"contents":"<div class={{this.classNames}} ...attributes>\n  <UiButton\n    @appearance={{this.appearance}}\n    @onClick={{this.toggleModal}}\n  >\n    <DcIcon\n      @name={{this.icon}}\n      class={{if (not-eq this.appearance \"bulb\") \"n1\"}}\n    />\n  </UiButton>\n</div>\n","moduleName":"babel/components/collection-modal-toggler/index.hbs","parseOptions":{"srcName":"babel/components/collection-modal-toggler/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class CollectionModalTogglerComponent extends Component {
  /**
   * Arguments:
   * appearance (string)
   * isModalOpen (bool)
   * closeModal (function)
   * openModal (function)
   */

  get classNames() {
    const classNames = [
      'collection-modal-toggler',
      'flex',
      'flex-center',
      'justify-center',
    ];

    if (this.args.isModalOpen) {
      classNames.push('collection-modal-open');
    } else {
      classNames.push('collection-modal-closed');
    }

    return classNames.join(' ');
  }

  get icon() {
    return this.args.isModalOpen ? 'simple-remove' : 'expand';
  }

  get appearance() {
    return this.args.appearance || 'bulb';
  }

  @action toggleModal() {
    if (this.args.isModalOpen) {
      this.args.closeModal?.();
      document.body.classList.remove('collection-modal--open');
    } else {
      this.args.openModal?.();
      document.body.classList.add('collection-modal--open');
    }
  }
}
