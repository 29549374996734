import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['wordlist-browser'],

  // PARAMS

  wordlists: null,

  disabledWordlists: null,

  highlightedWordlist: null,

  showRouteName: null,

  unlinkable: false,

  onPerform() {}
});
