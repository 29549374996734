import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import imageSource from 'compton/utils/image-source';
import DS from 'ember-data';

const { PromiseObject } = DS;

function createPromiseObject(promise) {
  return PromiseObject.create({ promise });
}

export default Component.extend({
  classNames: ['continue-content'],

  store: service(),

  continueVisited: null,

  continueVisitedEntity: null,

  imageSrc: null,

  actions: {},

  type: readOnly('continueVisited.attributes.type'),

  linkedEntity: computed(
    'entity',
    'parent',
    'assignment',
    'wordlist',
    'type',
    function () {
      const { entity, assignment, wordlist } = this.getProperties(
        'entity',
        'parent',
        'assignment',
        'wordlist',
        'type'
      );

      if (assignment) {
        return assignment;
      } else if (wordlist) {
        return wordlist;
      }
      return entity;
    }
  ),

  entity: computed('continueVisited', function () {
    const entityId = this.get('continueVisited.attributes.entity_id');

    if (entityId) {
      return createPromiseObject(
        this.get('store').findRecord('entity', entityId)
      );
    }
    return null;
  }),

  parent: computed('continueVisited', function () {
    const parentId = this.get('continueVisited.attributes.parent_id');

    if (parentId) {
      return createPromiseObject(
        this.get('store').findRecord('entity', parentId)
      );
    }
    return null;
  }),

  assignment: computed('continueVisited', function () {
    const assignmentId = this.get('continueVisited.attributes.assignment_id');

    if (assignmentId) {
      return createPromiseObject(
        this.get('store').findRecord('entity', assignmentId)
      );
    }
    return null;
  }),

  wordlist: computed('continueVisited', function () {
    const wordlistId = this.get('continueVisited.attributes.wordlist_id');

    if (wordlistId) {
      return createPromiseObject(
        this.get('store').findRecord('entity', wordlistId)
      );
    }
    return null;
  }),

  section: computed('continueVisited', function () {
    const sectionId = this.get('continueVisited.attributes.section_id');

    if (sectionId) {
      return createPromiseObject(
        this.get('store').findRecord('entity', sectionId)
      );
    }
    return null;
  }),

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get('continueVisited')) {
      const imageId = this.get('continueVisited').attributes.image;
      if (imageId) {
        this.get('store')
          .findRecord('material', imageId)
          .then((image) => {
            if (!this.get('isDestroying') || !this.get('isDestroyed')) {
              this.set(
                'imageSrc',
                imageSource(image.imageUrl, {
                  embed: true,
                  w: 96,
                  h: 96,
                })
              );
            }
          });
      } else {
        this.set(
          'imageSrc',
          imageSource(
            this._getDefaultImage(
              this.get('continueVisited').attributes.default_image_name
            ).path,
            {
              embed: true,
              w: 96,
              h: 96,
            }
          )
        );
      }
    }
  },

  imageThumbnail: computed('imageUrl', function () {
    const image = this.get('imageSrc');

    return image && htmlSafe(`background-image: url(${image})`);
  }),

  _getDefaultImage(type) {
    const DEFAULT_IMAGES = [
      { type: 'archives', path: '/assets/images/defaults/archives.svg' },
      { type: 'areas', path: '/assets/images/defaults/areas.svg' },
      {
        type: 'contents-chapter',
        path: '/assets/images/defaults/contents-chapter.svg',
      },
      {
        type: 'contents-story',
        path: '/assets/images/defaults/contents-story.svg',
      },
      {
        type: 'contents-workflow',
        path: '/assets/images/defaults/contents-workflow.svg',
      },
      {
        type: 'interactives',
        path: '/assets/images/defaults/interactives.svg',
      },
      {
        type: 'image-missing',
        path: '/assets/images/defaults/image-missing.svg',
      },
    ];

    return (
      DEFAULT_IMAGES.findBy('type', type) || {
        path: '/assets/images/defaults/image-missing.svg',
      }
    );
  },
});
