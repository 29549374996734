import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class WorkspaceNotesIndexComponent extends Component {
  /**
   * Arguments:
   * onClose (function)
   */

  // SETUP

  @service noteDispatcher;

  @service router;

  @controller('master.contents') contentsController;

  // PROPERTIES

  @tracked noteChooseModalIsOpen = false;

  @tracked relatedAccordionIsExpanded = false;

  @tracked highlightedNote = null;

  get content() {
    return this.contentsController.content;
  }

  get currentNotes() {
    return this.contentsController.currentNotes;
  }

  get relatedNotes() {
    return this.contentsController.relatedNotes;
  }

  // ACTIONS

  @action openNoteChooseModal() {
    this.noteChooseModalIsOpen = true;
  }

  @action closeNoteChooseModal() {
    this.noteChooseModalIsOpen = false;
  }

  @action handleNoteBrowserPerform(note, value) {
    switch (value) {
      case 'edit':
        return this.router.transitionTo('master.contents.notes.edit', note.id);
      case 'unlink':
        return this.noteDispatcher.unlink(note, this.content);
      case 'delete':
        return this.noteDispatcher.delete(note);
      default:
        break;
    }
  }

  @action async handleNoteChooseModalChoose(notes) {
    await this.noteDispatcher.linkMany(notes, this.content);
    this.noteDispatcher.highlightMany(notes);
  }
}
