import ApplicationAdapter from 'babel/adapters/application';
import { handleDirtyHasManyRelationshipData } from 'babel/utils/handle-dirty-relationship-data';
import { resolve } from 'rsvp';

const DIRTY_DATA_RELATIONSHIPS = ['shortcuts', 'books', 'hiddenBooks'];

export default class SettingAdapter extends ApplicationAdapter {
  namespace = 'api/v1/meta';

  shouldBackgroundReloadRecord(store, snapshot) {
    // This fixes a bug affecting tests where Ember reloads
    // the record before it is persisted, leading to an error.
    if (snapshot.isNew) return false;
    return true;
  }

  shouldBackgroundReloadAll() {
    return true;
  }

  findHasMany(store, snapshot, url, relationship) {
    if (DIRTY_DATA_RELATIONSHIPS.includes(relationship?.meta?.name)) {
      return handleDirtyHasManyRelationshipData(
        store,
        url,
        relationship?.meta?.type
      ).then(async (response) => {
        if (
          relationship?.meta?.name === 'shortcuts' &&
          Array.isArray(response?.data)
        ) {
          const course = await resolve(snapshot?.record?.course);
          const allowedBookIds = await resolve(course?.allBooks).then((books) =>
            books.map((book) => book.id)
          );

          if (allowedBookIds) {
            response.data = response.data.filter((shortcut) =>
              allowedBookIds.includes(shortcut?.attributes?.book_id)
            );
          }
        }

        return response;
      });
    }

    return super.findHasMany(...arguments);
  }
}
