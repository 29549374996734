import Component from '@ember/component';
import { inject as service } from '@ember/service';
import config from 'babel/config/environment';
import DigilarAcapelaCloudProviderFactory from 'babel/utils/speaker/DigilarAcapelaCloudProviderFactory';
import DigilarAcapelaProviderFactory from 'babel/utils/speaker/DigilarAcapelaProviderFactory';
import DigilarLanguageUtil from 'babel/utils/speaker/DigilarLanguageUtil';
import DigilarPollyProviderFactory from 'babel/utils/speaker/DigilarPollyProviderFactory';
import DigilarSpeakerConfig from 'babel/utils/speaker/DigilarSpeakerConfig';
import digilarGeneralRuleSet from 'babel/utils/speaker/text-processing-rule-sets/digilar-general';
import { task } from 'ember-concurrency';
import { storageFor } from 'ember-local-storage';
import {
  SpeakerTextProcessor,
  SpeakerTextProcessorRuleSet,
  SpeakerTtsTextConverter,
} from 'speaker';

export default Component.extend({
  classNames: ['simple-speech-player'],

  value: null,

  lang: null,

  player: null,

  playing: false,

  ajax: service(),

  // LOCAL STORAGE
  speakerSettings: storageFor('play-settings'),

  init() {
    this._super(...arguments);
    this.set('player', new Audio());

    this.digilarSpeakerConfig = new DigilarSpeakerConfig({
      getContextData: () => {
        return {};
      },
      getSpeakerSettings: () => this.get('speakerSettings'),
    });
  },

  /**
   * Get provider-independent text processing rule sets to include in text processing based on content type etcetera.
   * (Note: Some standard rule sets will always be applied by SpeakerContext.)
   */
  _getDigilarTextProcessingRuleSets(language) {
    let ruleSets = [
      // Add a Digilär specific rule set, shared for all TTS providers
      new SpeakerTextProcessorRuleSet(digilarGeneralRuleSet(language, {})),
    ];

    return ruleSets;
  },

  _play(text, lang, isLastItemToPlay = true) {
    if (typeof text === 'object') {
      text = text?.string || '';
    }

    return new Promise((resolve, reject) => {
      let language = null;

      if (lang) {
        language = DigilarLanguageUtil.parseLanguage(lang);
      } else {
        if (this.get('lang')) {
          language = DigilarLanguageUtil.parseLanguage(this.get('lang'));
        }
      }

      // Use Swedish as default if language parsing didn't succeed
      if (!language) {
        language = 'sv_SE';
      }

      const selectedVoice = this.digilarSpeakerConfig.preferredVoice(language);

      // Use text processor to remove everything html related from the text before making TTS request
      const textProcessor = new SpeakerTextProcessor(text);
      textProcessor.removeHtmlComments();
      textProcessor.removeHtmlTags();
      textProcessor.replaceHtmlEntities();
      text = textProcessor.getProcessedText();

      let plugin = null;

      if (selectedVoice.startsWith('acapela_cloud')) {
        plugin = DigilarAcapelaCloudProviderFactory.newAcapelaCloudPlugin({
          httpClientParams: {
            apiBaseUrl: config.endpoint,
            ajax: this.ajax,
          },
          speechSpeed: 1.0,
          generalDigilarTextProcessingRuleSets: [],
        });
      } else if (selectedVoice.startsWith('acapela')) {
        plugin = DigilarAcapelaProviderFactory.newAcapelaPlugin({
          httpClientParams: {
            apiBaseUrl: config.endpoint,
            ajax: this.get('ajax'),
          },
          speechSpeed: 1.0,
          generalDigilarTextProcessingRuleSets: [],
        });
      } else {
        plugin = DigilarPollyProviderFactory.newPollyPlugin({
          pollyProxyParams: {
            apiBaseUrl: config.endpoint,
            ajax: this.get('ajax'),
          },
          speechSpeed: 1.0,
          generalDigilarTextProcessingRuleSets:
            this._getDigilarTextProcessingRuleSets.bind(this),
        });

        // For Polly, some text processing is required to apply correct SSML
        const textConverter = new SpeakerTtsTextConverter(
          text,
          plugin.getSyntaxPlugin(),
          plugin.getTextProcessingRuleSets()
        );

        text = textConverter.getProcessedText();
      }

      // Disable bookmarks (not implemented anyway...)
      const bookmarks = null;

      plugin
        .textToSpeech(text, bookmarks, language, selectedVoice)
        .then(({ audioUrl }) => {
          let audio = this.get('player');

          audio.src = audioUrl.replace('http://', 'https://');
          audio.load();
          audio.play();

          if (this.isDestroying || this.isDestroyed) {
            return;
          }
          this.set('playing', true);

          audio.onended = () => {
            if (this.isDestroying || this.isDestroyed) {
              return;
            }
            // Only change the playing state to false for the last item.
            // Otherwise the icon will auto-toggle between playing/paused when playing a playlist
            if (isLastItemToPlay) {
              this.set('playing', false);
            }
            return resolve();
          };
        })
        .catch(() => {
          return reject();
        });
    });
  },

  startPlayList: task(function* () {
    for (let [index, item] of this.get('playlist').entries()) {
      const isLastItem = index === this.get('playlist').length - 1;
      yield this._play(item.text, item.language, isLastItem);
    }
  }),

  actions: {
    play(text) {
      if (this.get('playlist')) {
        this.startPlayList.perform();
      } else {
        this._play(text);
      }
    },
    pause() {
      this.set('playing', false);

      let audio = this.get('player');
      audio.pause();
      audio.currentTime = 0;
    },
  },
});
