import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"number-line-assignment nb1\"\n  {{did-update this.answerUpdated @assignmentAnswer.input}}\n  ...attributes\n>\n  <XNumberLine\n    class=\"mb3\"\n    @start={{@assignment.content.start}}\n    @end={{@assignment.content.end}}\n    @interval={{@assignment.content.interval}}\n    @annotate={{@assignment.content.annotate}}\n    @values={{this.input}}\n    @validValues={{this.validValues}}\n    @multiple={{@assignment.settings.multiple}}\n    @stage={{this.stage}}\n    @onChange={{this.handleChange}}\n  />\n\n  {{#if this.showHint}}\n    <div class=\"text-small\">\n      {{t \"components.exerciseView.assignment.numberLine.hint\" htmlSafe=true}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"number-line-assignment nb1\"\n  {{did-update this.answerUpdated @assignmentAnswer.input}}\n  ...attributes\n>\n  <XNumberLine\n    class=\"mb3\"\n    @start={{@assignment.content.start}}\n    @end={{@assignment.content.end}}\n    @interval={{@assignment.content.interval}}\n    @annotate={{@assignment.content.annotate}}\n    @values={{this.input}}\n    @validValues={{this.validValues}}\n    @multiple={{@assignment.settings.multiple}}\n    @stage={{this.stage}}\n    @onChange={{this.handleChange}}\n  />\n\n  {{#if this.showHint}}\n    <div class=\"text-small\">\n      {{t \"components.exerciseView.assignment.numberLine.hint\" htmlSafe=true}}\n    </div>\n  {{/if}}\n</div>","moduleName":"babel/components/exercise-view/assignment/number-line/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/number-line/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

export default class ExerciseViewAssignmentNumberLineComponent extends Component {
  /**
   * Arguments:
   * assignment (object)
   * canHaveAttachments (bool)
   * assignmentAnswer (object)
   * answer (object)
   * handleOnChange (function)
   * showValidation (bool)
   * showKey (bool)
   * disabled (bool)
   * showSuggestion (bool)
   * incomplete (bool)
   * image (object)
   */

  constructor() {
    super(...arguments);

    this._setAnswer();
  }

  @service assignmentEvents;

  @tracked input;

  get isMultiple() {
    return this.args.assignment?.settings?.multiple;
  }

  get showValidationAndOption() {
    const showKey = this.args.showKey;
    const showValidation = this.args.showValidation;

    return !showKey && showValidation;
  }

  get validValues() {
    return (
      this.args.assignment.content.alternatives?.map(
        (alternative) => alternative.start_index
      ) || []
    );
  }

  get stage() {
    if (this.args.showKey) return 'reveal';

    if (this.showValidationAndOption) return 'validate';

    return 'answer';
  }

  @cached get showHint() {
    const input = this.input;

    // Never show hint if answer is correct or showing keys, and only on show validation
    if (
      !input ||
      input.length === 0 ||
      this.args.assignmentAnswer?.correct ||
      this.args.showKey ||
      !this.args.showValidation ||
      this.args.disabled
    ) {
      return false;
    }

    return input.every((i) => this.validValues.includes(i));
  }

  @action handleChange(value) {
    if (this.args.assignmentAnswer) {
      this.input = value;
      this.args.assignmentAnswer.input = this.input;
    }
    this.args.handleOnChange?.(value);
  }

  @action handleEnter() {
    this.assignmentEvents.trigger('onEnter');
  }

  @action answerUpdated() {
    this._setAnswer();
  }

  _setAnswer() {
    this.input = this.args.assignmentAnswer?.input;
  }
}
