import { service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import EntityRouteSupport from 'babel/mixins/entity-route-support';
// eslint-disable-next-line ember/no-mixins
import PageViewTracker from 'babel/mixins/page-view-tracker';
import EntityContextRoute from 'babel/routes/entity-context-route';
import { all, resolve } from 'rsvp';

export default class MasterArchivesRoute extends EntityContextRoute.extend(
  EntityRouteSupport,
  PageViewTracker
) {
  @service pageviews;

  materials;

  async model() {
    const model = await super.model(...arguments);
    const archive = model.model;
    const folders = await archive.children;

    this.materials = await all(
      folders.map((folder) =>
        resolve(folder.materials).then((materials) => {
          materials.forEach((material) => {
            material.folder = folder;
          });

          return materials.toArray();
        })
      )
    ).then((materials) => materials.flat());

    return model;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.model = {
      archive: model.model,
      folder: null,
      materials: this.materials,
    };
  }
}
