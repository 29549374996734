import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';

export default class ContentSummaryItemComponent extends Component {
  @service missionMode;

  @cached
  get numAssignmentsProxy() {
    const promise = this.missionMode
      .allowedEntities(this.args.model?.children)
      .then((allowed) => allowed?.length || 0);

    return load(promise, this);
  }

  get numAssignments() {
    return this.numAssignmentsProxy.isResolved
      ? this.numAssignmentsProxy.value
      : 0;
  }
}
