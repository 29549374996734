import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';
import { storageFor } from 'ember-local-storage';

export default class TbRenderIltVideoComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  @storageFor('read-setting') settings;

  @tracked baseClass = 'tb-render-ilt-video hidden';

  @tracked videoLoaded = false;

  asyncType = 'material';

  get asyncId() {
    return this.args.part?.video_id;
  }

  get material() {
    return this.asyncData;
  }

  get showPlaceholder() {
    return (
      this.videoLoaded &&
      this.settings.get('onlytext') &&
      !this.args.part.mediaForced
    );
  }

  @action
  handleVideoReady() {
    this.baseClass = 'tb-render-ilt-video';
    this.videoLoaded = true;
  }
}
