// eslint-disable-next-line ember/no-mixins
import EntityRouteSupport from 'babel/mixins/entity-route-support';
// eslint-disable-next-line ember/no-mixins
import PageViewTracker from 'babel/mixins/page-view-tracker';
import EntityContextRoute from 'babel/routes/entity-context-route';

export default class MasterBooksRoute extends EntityContextRoute.extend(
  EntityRouteSupport,
  PageViewTracker
) {}
