import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';

export default class PageContentMenu extends Component {
  @service session;

  @service router;

  @tracked navOpen = false;

  get hasPortalUrl() {
    return config.nokPortalURL && config.nokPortalURL !== 'null';
  }

  get portalUrl() {
    return this.hasPortalUrl ? config.nokPortalURL : '';
  }

  get courseSettingsURL() {
    return `${this.portalUrl}/course-settings/${this.args.model.id}?return-url=${this.returnURL}`;
  }

  get menuItems() {
    return this.args.items.filter((item) => !item.teacherMenuItems);
  }

  get teacherMenuItems() {
    if (!this.session.user?.isTeacher) return [];
    return this.args.items.filter((item) => item.teacherMenuItems);
  }

  get returnURL() {
    return this.router.currentURL;
  }

  @action openNav() {
    this.navOpen = true;
  }

  @action closeNav() {
    this.navOpen = false;
  }
}
