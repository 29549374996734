import DS from 'ember-data';

import Model from './model';
const { attr, hasMany } = DS;

export default Model.extend({
  // ATTRIBUTES

  name: attr('string'),

  type: attr('string'),

  provider: attr('string'),

  providerId: attr('string'),

  metadata: attr('object', {
    defaultValue() {
      return {};
    },
  }),

  numUsers: attr('number'),

  numStudents: attr('number'),

  numTeachers: attr('number'),

  schoolUnitCodes: attr({
    defaultValue() {
      return [];
    },
  }),

  // RELATIONS

  users: hasMany('user', { async: true, inverse: null }),

  groups: hasMany('group', { async: true, inverse: null }),

  courses: hasMany('course', { async: true, inverse: null }),

  licenses: hasMany('license', { async: true, inverse: null }),
});
