import { dasherize } from '@ember/string';
import Component from '@glimmer/component';
import { storageFor } from 'ember-local-storage';

const HIDDEN_COMPONENTS_IN_ONLYTEXT = [
  'gallery',
  'image',
  'video',
  'vr_image',
  'embed',
  'interactive_image',
];

export default class TbRenderPartComponent extends Component {
  /**
   * Arguments:
   * part (object)
   */

  @storageFor('read-setting') settings;

  get component() {
    const part = this.args.part;

    let component = part?.component;

    // ember-local-storage requires .get
    if (this.settings.get('onlytext')) {
      if (HIDDEN_COMPONENTS_IN_ONLYTEXT.includes(component)) {
        if (!part?.mediaForced) {
          component = 'placeholder';
        }
      }
    }

    return `tb-render/${dasherize(component)}`;
  }
}
