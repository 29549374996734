import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import pagedArray from 'babel/utils/paged-array';
import shuffle from 'compton/utils/array-shuffle';

export default class ArchiveViewFolderViewComponent extends Component {
  /**
   * Arguments:
   * archive (object)
   * folder (object)
   * materials (array)
   * perPage (number)
   * pageNumber (number)
   * sortValue (string)
   * onPageChange (function)
   */

  @service router;

  @service routerScroll;

  get perPage() {
    return this.args.perPage || 24;
  }

  get isGridView() {
    return this.args.archive?.template === 'grid';
  }

  get folderId() {
    return this.args.folder?.id;
  }

  get showTitles() {
    return this.args.archive?.body?.show_titles !== false ? true : false;
  }

  get sort() {
    if (this.args.sortValue === 'standard') {
      return this.args.archive?.body?.archive_sort;
    }

    return this.args.sortValue;
  }

  @cached get sortedMaterials() {
    const materials = this.args.materials;
    const items = this.args.folder?.body?.items;
    const sort = this.sort;
    const comp = materials.mapBy('id');

    const arr = (
      items
        ? items.map((id) => {
            const index = comp.indexOf(id);

            if (index !== -1) {
              return materials.objectAt(index);
            }

            return null;
          })
        : materials.slice()
    )
      .compact()
      .uniqBy('id');

    switch (sort) {
      case 'inverse':
        return arr;
      case 'random':
        return shuffle(arr);
      case 'name_desc':
      case 'title_desc':
        return arr.sortBy('name').reverse();
      case 'name_asc':
      case 'title_asc':
        return arr.sortBy('name');
      case 'date_desc':
        return arr.sortBy('created').reverse();
      case 'date_asc':
        return arr.sortBy('created');
      default:
        return arr.reverse();
    }
  }

  get pagedMaterials() {
    return pagedArray({
      content: this.sortedMaterials,
      perPage: this.perPage,
      page: this.args.pageNumber,
    });
  }

  get totalPages() {
    return this.pagedMaterials?.totalPages || 1;
  }

  @action openMaterial(material, evt) {
    evt?.preventDefault?.();

    this.routerScroll.preserveScrollPosition = true;

    const returnUrl = this.router.currentURL;

    return this.router
      .transitionTo(
        'master.archives.folders.materials',
        this.args.archive.id,
        this.folderId || material.folder.id,
        material.id,
        {
          queryParams: { 'return-url': returnUrl },
        }
      )
      .then(() => {
        later(
          this,
          () => {
            this.routerScroll.preserveScrollPosition = false;
          },
          100
        );
      });
  }
}
