import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class MasterCoursesShowSettingsRoute extends Route {
  @service router;

  @service session;

  async beforeModel() {
    super.beforeModel(...arguments);

    if (!this.session.user?.isTeacher) {
      return this.router.transitionTo('master.courses.show');
    }
  }

  model() {
    const course = this.modelFor('master.courses.show');

    return hash({
      course,
    });
  }
}
