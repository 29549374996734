import Component from '@glimmer/component';

export default class CollectionSummaryComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * answers (array)
   * onExerciseChange (function)
   * onCollectionClose (function)
   */

  get image() {
    return '/assets/images/collection/summary.svg';
  }
}
