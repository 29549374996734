import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CollectionListItemComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * onOpenExercise (function)
   * startExpanded (bool)
   * onOpenCollection (function)
   * active (bool)
   */

  @service assignmentEvents;

  @service missionMode;

  @tracked collection = this.args.collection;

  @cached get interactiveProxy() {
    const promise = this.assignmentEvents.getOrCreateInteractiveForCollection(
      this.collection
    );

    return load(promise, this);
  }

  get interactive() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.interactive
      : null;
  }

  get currentExercise() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value.activeExercise
      : null;
  }

  get answers() {
    return this.interactive?.answers || [];
  }

  @cached get exercisesProxy() {
    const promise = resolve(this.collection?.children)
      .then((children) => this.missionMode.allowedEntities(children))
      .then((exercises) => {
        if (this.collection?.isTeacherCollection) {
          exercises = exercises.sortBy('title');
        }

        return exercises;
      });

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : [];
  }

  @action resetCollection() {
    this.collection = this.args.collection;
  }
}
