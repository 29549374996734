import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'babel/routes/authenticated-route';
import isUuid from 'compton/utils/is-uuid';

export default class UrlResolveRoute extends AuthenticatedRoute {
  @service router;

  @service store;

  model(params) {
    let url = params.url;

    if (isUuid(url)) {
      return this.router.transitionTo('nodes', url);
    }

    if (!url.startsWith('inloggad/')) {
      return this.store.query('entity', { filter: { url } });
    }

    if (window && window.location) {
      if (
        window.location.search &&
        window.location.search.indexOf('arbetsuppgift') === 1
      ) {
        url += window.location.search;
      }

      if (window.location.hash) {
        url += window.location.hash;
      }
    }

    url = url.split('/').splice(2).join('/');

    // remove #chap0 from url as first section is saved without hash
    url = url.replace(/#chap0$/i, '');

    if (url) {
      return this.store.query('entity', { filter: { url: { endsWith: url } } });
    } else {
      return;
    }
  }

  afterModel(model) {
    if (model?.firstObject) {
      this.router.transitionTo('nodes', model.firstObject);
    } else {
      this.router.transitionTo('404');
    }
  }
}
