import entityFallbackTitles from 'babel/rules/entity-fallback-titles';

export default function (type, intl) {
  const typeFallback = entityFallbackTitles[type];

  if (typeFallback) {
    return intl.t(`utils.generateFallbackTitle.${type}`);
  }

  return intl.t(`utils.generateFallbackTitle.missingType`);
}
