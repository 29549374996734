import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

const EDIT = 'edit';
const DELETE = 'delete';

export default class WorkspaceWordlistsShowComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * onClose (function)
   */

  // SETUP

  @service router;

  @service wordlistDispatcher;

  @service wordDispatcher;

  @controller('master.contents') contentsController;

  // PROPERTIES

  get wordlist() {
    return this.args.model?.wordlist;
  }

  get quiz() {
    return this.args.model?.quiz;
  }

  get content() {
    return this.contentsController.content;
  }

  // ACTIONS

  @action openIndex() {
    return this.router.transitionTo('master.contents.wordlists.index');
  }

  @action handleWordlistViewUnlink() {
    return this.wordlistDispatcher
      .unlink(this.wordlist, this.content)
      .then(() => this.router.transitionTo('master.contents.wordlists.index'));
  }

  @action handleWordlistViewDuplicate() {
    return this.wordlistDispatcher
      .duplicate(this.wordlist, this.content)
      .then(() => this.router.transitionTo('master.contents.wordlists.index'));
  }

  @action handleWordlistViewWordPerform(word, value) {
    switch (value) {
      case EDIT:
        return this.router.transitionTo(
          'master.contents.wordlists.show.words.edit',
          word.id
        );
      case DELETE:
        return this.wordDispatcher.delete(word);
      default:
        break;
    }
  }
}
