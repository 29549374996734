import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { writeCookie } from 'babel/utils/handle-cookies';

export default Component.extend({
  cookies: service(),

  classNames: ['top-message'],

  model: null,

  htmlSafeMessage: computed('model.message', function() {
    return htmlSafe(this.get('model.message'));
  }),

  didInsertElement() {
    this._super(...arguments);

    const elem = this.get('element');

    if (elem) {
      document.documentElement.style.setProperty(
        '--top-message-height',
        elem.offsetHeight + 'px'
      );
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    document.documentElement.style.setProperty('--top-message-height', '0px');
  },

  actions: {
    openTarget(target) {
      window.open(target);
    },

    close() {
      writeCookie(this.cookies, `digi-closedNotice_${this.model.id}`, true);

      this.set('model', null);
    }
  }
});
