import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CourseAuditIndex extends Route {
  @service store;

  async model() {
    const course = this.modelFor('master.courses.show');

    const [missions, students] = await Promise.all([
      this.store.query('mission', {
        filter: {
          scope: 'visible',
          course_id: course.get('id'),
        },
        page: {
          number: 1,
          size: 1,
        },
      }),
      course.get('users'),
    ]);

    return { students, hasMissions: missions.get('length') > 0 };
  }
}
