import { service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import EntityRouteSupport from 'babel/mixins/entity-route-support';
// eslint-disable-next-line ember/no-mixins
import PageViewTracker from 'babel/mixins/page-view-tracker';
import EntityContextRoute from 'babel/routes/entity-context-route';
import { resolve } from 'rsvp';

export default class MasterMaterialsRoute extends EntityContextRoute.extend(
  EntityRouteSupport,
  PageViewTracker
) {
  @service courseContext;

  async model() {
    const hash = await super.model(...arguments);
    await resolve(hash.model?.selectedMaterial);
    return hash;
  }

  setupController(controller, { model }) {
    super.setupController(...arguments);
    this.courseContext.storeVisitedContent(model?.id);
  }
}
