import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { scrollIntoViewIfNeeded } from 'compton/utils/dom';

const PUBLISH = 'publish';
const UNPUBLISH = 'unpublish';
const DUPLICATE = 'duplicate';
const ADD_WORD = 'add_word';
const QUIZ = 'quiz';
const EDIT = 'edit';
const UNLINK = 'unlink';
const DELETE = 'delete';

export default Component.extend({
  // SETUP

  session: service(),

  intl: service(),

  wordlistDispatcher: service(),

  classNames: ['wordlist-browser-item'],

  classNameBindings: ['isHighlighted:wordlist-browser-item--highlighted'],

  // PARAMS

  wordlist: null,

  selectedWordlist: null,

  disabledWordlists: null,

  unlinkable: null,

  showRouteName: null,

  onPerform: null,

  // PROPERTIES

  isDisabled: computed(
    'wordlist.{isDeleted,isBusy}',
    'disabledWordlists.[]',
    function () {
      const wordlist = this.wordlist;

      if (wordlist.get('isDeleted') || wordlist.get('isBusy')) {
        return true;
      }

      const disabledWordlists = this.disabledWordlists;

      return disabledWordlists && disabledWordlists.includes(wordlist);
    }
  ),

  isHighlighted: computed(
    'wordlist',
    'wordlistDispatcher.highlightedWordlists.[]',
    function () {
      return this.wordlistDispatcher.highlightedWordlists.includes(
        this.wordlist
      );
    }
  ),

  performMenuButtonItems: computed(
    'intl.locale',
    'wordlist.{isReadOnly,isPublished,isQuizable}',
    'unlinkable',
    'session.user.{isTeacher,isTestUser}',
    function () {
      const t = (value) => {
        return this.intl.t(
          [
            'components',
            'wordlistBrowser',
            'item',
            'performMenuButtonItems',
            camelize(value),
          ].join('.')
        );
      };

      const { isReadOnly, isPublished, isQuizable } =
        this.wordlist.getProperties('isReadOnly', 'isPublished', 'isQuizable');

      const user = this.get('session.user');

      const items = [];

      if (isReadOnly) {
        items.addObject({
          value: QUIZ,
          label: t(QUIZ),
          disabled: !isQuizable,
        });

        items.addObject({
          value: DUPLICATE,
          label: t(DUPLICATE),
        });
      } else {
        items.addObject({
          value: ADD_WORD,
          label: t(ADD_WORD),
        });

        items.addObject({
          value: QUIZ,
          label: t(QUIZ),
          disabled: !isQuizable,
        });

        items.addObjects([
          {
            type: 'divider',
          },
          {
            value: EDIT,
            label: t(EDIT),
          },
        ]);

        if (user.get('isTeacher') || user.get('isTestUser')) {
          if (isPublished) {
            items.addObject({
              value: UNPUBLISH,
              label: t(UNPUBLISH),
            });
          } else {
            items.addObject({
              value: PUBLISH,
              label: t(PUBLISH),
            });
          }
        }

        if (this.unlinkable) {
          items.addObject({
            value: UNLINK,
            label: t(UNLINK),
            variant: 'error',
          });
        }

        items.addObject({
          value: DELETE,
          label: t(DELETE),
          variant: 'error',
        });
      }

      return items;
    }
  ),

  didRender() {
    this._super(...arguments);
    if (this.isHighlighted) {
      scrollIntoViewIfNeeded(this.element);
    }
  },
});
