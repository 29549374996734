import Component from '@glimmer/component';

export default class BookBrowserComponent extends Component {
  get rangeClassName() {
    if (this.args.range) {
      return `book-browser--range-${this.args.range}`;
    } else {
      return `book-browser--range-medium`;
    }
  }
}
