import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class EntityBrowserTree extends Component {
  /**
   * Arguments:
   * entity (object)
   * selectedEntity (object)
   * expandedEntities (array)
   * blacklistedEntityTypes (array)
   * selectable (null)
   * pickable (bool, false)
   * routed (null)
   * multiple (null)
   * inspectable (null)
   * pickedEntity (object)
   * pickableTypes (array)
   * onSelect (function)
   * onPick (function)
   * onUnpick (function)
   * onExpand (function)
   * onCollapse (function)
   */

  @tracked depth = this.args.depth ?? 1;

  get indent() {
    return ['indent', this.depth].join('-');
  }

  get indentClass() {
    return ['entity-browser-tree', this.indent].join('--');
  }
}
