import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { NotFoundError } from '@ember-data/adapter/error';
import { MissingContentError } from 'babel/utils/errors';
import { all, reject, resolve } from 'rsvp';

export default class MasterContentsWordlistsShowRoute extends Route {
  @service contextHelper;

  @service missionMode;

  @service pageviews;

  @service store;

  async model({ wordlist_id }) {
    let wordlist;

    try {
      wordlist = await this.store.findRecord('wordlist', wordlist_id);
    } catch (err) {
      return reject(new MissingContentError());
    }

    await all([wordlist.words, wordlist.books]);

    return wordlist;
  }

  async afterModel(model, transition) {
    const { book } = this.modelFor('master.contents');

    if (this.contextHelper.activeCustomContent) {
      await all([
        this.contextHelper.activeCustomContent.includedEntities,
        this.contextHelper.activeCustomContent.includedWordlists,
      ]);
    }

    if (!model.hasBook(book) || !this.missionMode.wordlistIsAllowed(model)) {
      return reject(NotFoundError);
    }

    if (model.isTransformed) {
      return resolve(model.entity.parent).then((parent) => {
        const contentsRoute = transition.to.find(
          (route) => route.name === 'master.contents'
        );

        this.pageviews.logPageView(model, book, parent, {
          section: contentsRoute.queryParams.section,
        });

        return model.words;
      });
    }

    return model.words;
  }
}
