import Route from '@ember/routing/route';
// eslint-disable-next-line ember/no-mixins
import EntityRouteSupport from 'babel/mixins/entity-route-support';

export default class MasterArchivesFoldersRoute extends Route.extend(
  EntityRouteSupport
) {
  setupController(controller, { model: folder }) {
    super.setupController(...arguments);
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor('master.archives').set('model.folder', folder);
  }
}
