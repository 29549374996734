import { service } from '@ember/service';
import EntityContextRoute from 'babel/routes/entity-context-route';
import { MissingContentError } from 'babel/utils/errors';
import { reject } from 'rsvp';

export default class MasterInteractivesPracticesShowRoute extends EntityContextRoute {
  @service assignmentEvents;

  @service pageviews;

  @service router;

  @service store;

  @service missionMode;

  async model({ practice_show_id }, transition) {
    const { mission, customContent, closeUrl } = this.modelFor(
      'master.interactives'
    );

    if (mission && customContent) {
      this.missionMode.activate({ mission, customContent, closeUrl });
    }

    let { book, collection, exercises, editor, parent } = this.modelFor(
      'master.interactives.practices'
    );

    let exercise;

    if (practice_show_id !== 'carousel') {
      if (collection?.isCarousel) {
        return this.router.replaceWith(
          'master.interactives.practices.show',
          'carousel'
        );
      }

      try {
        exercise = await this.store.findRecord('entity', practice_show_id);
      } catch (err) {
        return reject(
          new MissingContentError(
            err.message,
            'master.interactives.practices.index'
          )
        );
      }
    }

    let collections = await parent.children;
    collections = collections.toArray();

    collections = await this.missionMode.allowedEntities(collections);
    exercises = await this.missionMode.allowedEntities(exercises);

    let { interactive, activeExercise, activeAnswer } =
      await this.assignmentEvents.getOrCreateInteractiveForCollection(
        collection,
        exercise
      );

    if (!activeExercise) activeExercise = exercise;

    this.pageviews.logPageView(activeExercise, book, collection);

    return {
      collection,
      exercises,
      activeExercise,
      answers: interactive.answers,
      activeAnswer,
      book,
      interactive: parent,
      collections,
      editor,
      closeUrl: transition?.to?.queryParams?.close_url,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, {
      model: model.activeExercise,
      closeUrl: model.closeUrl,
    });

    controller.model = model;
  }
}
