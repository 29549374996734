import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class\n    \"note-picker-grid-item\"\n    (if this.isDisabled \"note-picker-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <span class=\"flex justify-space-between mb1\">\n\n    <span class=\"flex-one text-quarternary word-break-all\">\n\n      {{@note.titleWithFallback}}\n\n    </span>\n\n    <XCheckbox\n      class=\"flex-shrink\"\n      @checked={{this.isPicked}}\n      @disabled={{this.isDisabled}}\n      @twoWay={{false}}\n      @onChange={{fn (if this.isPicked @onUnpick @onPick) @note}}\n    />\n\n  </span>\n\n  <NoteContent @note={{@note}} />\n\n</label>", {"contents":"<label\n  class={{class\n    \"note-picker-grid-item\"\n    (if this.isDisabled \"note-picker-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <span class=\"flex justify-space-between mb1\">\n\n    <span class=\"flex-one text-quarternary word-break-all\">\n\n      {{@note.titleWithFallback}}\n\n    </span>\n\n    <XCheckbox\n      class=\"flex-shrink\"\n      @checked={{this.isPicked}}\n      @disabled={{this.isDisabled}}\n      @twoWay={{false}}\n      @onChange={{fn (if this.isPicked @onUnpick @onPick) @note}}\n    />\n\n  </span>\n\n  <NoteContent @note={{@note}} />\n\n</label>","moduleName":"babel/components/note-picker/grid/item/index.hbs","parseOptions":{"srcName":"babel/components/note-picker/grid/item/index.hbs"}});
import Component from '@glimmer/component';

/*

import NoteModel from 'babel/models/note';

export interface NotePickerGridItemComponentArgs {
  note: NoteModel;
  pickedNotes: NoteModel[];
  disabledNotes: NoteModel[];
  onPick(note: NoteModel): void;
}

*/

export default class NotePickerGridItemComponent extends Component {
  get isPicked() {
    return this.args.pickedNotes.includes(this.args.note);
  }

  get isDisabled() {
    const disabledNotes = this.args.disabledNotes;

    return disabledNotes && disabledNotes.includes(this.args.note);
  }
}
