import Component from '@glimmer/component';

export default class DiagnosisDoneComponent extends Component {
  /**
   * Arguments:
   * interactive (object)
   * collection (object)
   * exercises (array)
   * answers (array)
   * result (object)
   */

  get pointsRequired() {
    return this.args.collection.pointsRequired;
  }

  get image() {
    if (this.args.result.score >= this.pointsRequired) {
      return '/assets/images/collection/completed.svg';
    }

    return '/assets/images/collection/ended.svg';
  }

  get items() {
    return this.args.answers
      ?.map((answer) => {
        const exerciseId = answer.belongsTo('entity').id();
        const exercise = this.args.exercises?.findBy('id', exerciseId);

        return { answer, exercise };
      })
      ?.sortBy('exercise.sort');
  }

  get scoreMax() {
    return this.args.exercises?.length;
  }
}
