import { underscore } from '@ember/string';
import { cached, tracked } from '@glimmer/tracking';
import TextGapComponent from 'babel/components/exercise-view/assignment/text-gap/index';
import { v4 as uuid } from 'ember-uuid';

const TABLE_SETTINGS = [
  'showTitle',
  'showCaption',
  'verticalHeading',
  'horizontalHeading',
];

export default class ExerciseViewAssignmentMatrixComponent extends TextGapComponent {
  @tracked inline = false;

  @cached
  get tbRenderContent() {
    const settings = {};
    const assignmentSettings = this.args.assignment?.settings?.table;

    for (const key of TABLE_SETTINGS) {
      if (assignmentSettings) {
        if (assignmentSettings[key]) {
          settings[underscore(key)] = assignmentSettings[key];
        } else if (
          Array.isArray(assignmentSettings) &&
          assignmentSettings.indexOf(key) !== -1
        ) {
          settings[underscore(key)] = true;
        }
      }
    }

    ['appearance', 'vertical_alignment', 'horizontal_alignment'].forEach(
      (key) => {
        settings[key] = this.args.assignment?.settings?.[key];
      }
    );

    const rows = (this.args.assignment?.content?.value?.cells || []).map(
      (row, rowIndex) => ({
        uuid: uuid(),
        index: rowIndex,
        columns: row.map((column, colIndex) => ({
          uuid: column.uuid,
          index: colIndex,
          value: column.value,
        })),
      })
    );

    const component = {
      component: 'table',
      index: 0,
      language: null,
      metadata: {},
      settings,
      template: 'standard',
      uuid: uuid(),
      rows,
    };

    return this.store.createRecord('editor', {
      rows: [
        {
          type: 'row',
          uuid: uuid(),
          index: 0,
          settings: {
            template: 'standard',
          },
          columns: [
            {
              type: 'column',
              uuid: uuid(),
              index: 0,
              components: [component],
            },
          ],
        },
      ],
    });
  }
}
