import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash, resolve } from 'rsvp';

export default class MasterCoursesShowBookRoute extends Route {
  @service session;

  async model() {
    const course = this.modelFor('master.courses.show');

    const productBooks = [];

    const products = course.products.sortBy('title');

    for (const product of products) {
      const books = await product.userBooks;
      productBooks.push(...books.toArray());
    }

    const setting = await resolve(course.setting);
    const settingBooks = await resolve(setting?.books).catch(() => []);

    if (settingBooks?.length > 0 && this.session.user.isTeacher) {
      const hiddenBooks = productBooks.filter(
        (book) => !settingBooks.map((obj) => obj.id).includes(book.id)
      );

      setting.hiddenBooks = hiddenBooks;
      setting.books = [];

      await setting.save();
    }

    return hash({
      course,
      productBooks,
    });
  }
}
