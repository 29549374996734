import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import imageSource from 'compton/utils/image-source';

export default Component.extend({
  classNames: ['product-header'],

  // EXTERNAL

  session: service(),

  ajax: service(),

  store: service(),

  product: null,

  coverImage: alias('product.cover'),

  image: alias('product.image'),

  backgroundType: alias('product.metadata.background_type'),

  hasColorBackground: equal('backgroundType', 'color'),

  imageUrl: computed(
    'image.imageUrl',
    'product.metadata.image_uploaded',
    function () {
      const image = this.get('image');

      return this.get('product.metadata.image_uploaded')
        ? image.get('imageUrl')
        : imageSource(image.get('imageUrl'), { preset: 'landscape' });
    }
  ),

  imageThumbnail: computed('coverImage.imageUrl', function () {
    const image = this.get('coverImage');

    return (
      image &&
      image.get('imageUrl') &&
      `background-image: url(${imageSource(image.get('imageUrl'), {
        width: '248',
        height: '248',
        embed: true,
      })})`
    );
  }),

  backgroundColor: computed('product.metadata.color', function () {
    const backgroundColor = this.get('product.metadata.color') || '#000000';
    return htmlSafe(`background-color: ${backgroundColor}`);
  }),

  textColor: computed('product.metadata.text_color', function () {
    const textColor = this.get('product.metadata.text_color') || '#ffffff';
    return htmlSafe(`color: ${textColor}`);
  }),
});
