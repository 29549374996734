import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TbRenderInlineElementEncloseWordExplanationComponent extends Component {
  /**
   * Arguments:
   * material (object)
   * parentElementId (string)
   */

  @service contextHelper;

  @service languages;

  @tracked popoverIsOpen = false;

  // Will be set in case word explanation is within a component with language specified.
  @tracked parentComponentLanguage = null;

  // Will be set in case word explanation is within an exercise with language specified.
  @tracked exerciseLanguage = null;

  get canSave() {
    if (this.contextHelper.activeContent) {
      return true;
    }

    return false;
  }

  get text() {
    return this.args.material?.metadata?.explanation;
  }

  get linkId() {
    return [this.args.parentElementId, 'a'].join('-');
  }

  @action insertElement(elem) {
    if (elem) {
      // Find out if the component that the word explanation belongs to has a specific language set.
      // We do that by simple searching the DOM upwards.
      const parentComponentElement = elem.closest('[data-id]');
      const parentComponentLanguage =
        (parentComponentElement &&
          parentComponentElement.getAttribute('data-language')) ||
        null;
      this.parentComponentLanguage = parentComponentLanguage;

      // Then do the same thing for exercises, and in case a match is found we fetch the
      // exercise language (if any) from the languages service.
      const exerciseElement = elem.closest('.exercise-main');
      const exerciseLanguage =
        (exerciseElement && this.languages?.exerciseLanguage) || null;
      this.exerciseLanguage = exerciseLanguage;
    }
  }

  @action handleClick() {
    this.popoverIsOpen = true;
  }

  @action handlePopoverClose() {
    this.popoverIsOpen = false;
  }
}
