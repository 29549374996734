const handleSpeakerEvent = (
  domHandler,
  event,
  params,
  contextData,
  domHelper
) => {
  let { contentType, onlyImages } = contextData;

  // isStoryImageMode - Support for story content when no text is visible, only story image
  // isLegacyStoryMode - Legacy support where we only have one recorded speech audio file per section instead of one file per text block for stories.
  //
  // Note that both isStoryImageMode and isLegacyStoryMode can be true at the same time.
  //
  let isStoryImageMode = !!(contentType === 'story' && onlyImages);
  let isLegacyStoryMode = !!(
    contentType === 'story' &&
    event.isRecordedSpeech &&
    event.data.singleRecordedSpeechFile
  );

  // If not handling our "special content", then skip our custom event handling
  // and allow default event handling to take place instead
  if (!isStoryImageMode && !isLegacyStoryMode) {
    return true;
  }

  let dom = domHandler.getSpeakerContextDomElement();

  const updateRelevantProgressBars = (normalizedProgressValue) => {
    let $progressBarParents = null;

    // Update both 'section' and 'image' progress bars since we can show/hide image/texts dynamically
    $progressBarParents = domHelper
      .findStoryImageElem(dom)
      .add(domHelper.findSectionElem(dom));
    domHandler._setProgressBarValue(
      $progressBarParents,
      normalizedProgressValue
    );
  };

  // Helper function to show/hide the loading indicator for image or text.
  const showLoadingIndicator = (visible) => {
    if (isStoryImageMode) {
      if (visible) {
        domHelper._showStoryImageLoader(domHandler);
      } else {
        domHelper._hideStoryImageLoader(domHandler);
      }
    } else if (isLegacyStoryMode) {
      let $section = domHelper.findSectionElem(dom);
      if (visible) {
        domHandler._showTextBlockLoader($section);
      } else {
        domHandler._hideTextBlockLoader($section);
      }
    }
  };

  // Switch to false if default event handling should be prevented for an event
  let allowDefaultEventHandling = true;

  switch (event.type) {
    case 'progress':
      updateRelevantProgressBars(event.position / event.duration);
      allowDefaultEventHandling = false;
      break;
    case 'word':
      // No custom handling
      break;
    case 'sentence':
      // No custom handling
      break;
    case 'stop':
      if (isStoryImageMode) {
        domHelper._clearStoryImagePlayingHighlighting(domHandler);
        domHelper._clearStoryImagePausedHighlighting(domHandler);
        // Reset progress bar
        updateRelevantProgressBars(0);
      }

      if (isLegacyStoryMode) {
        domHelper._clearSectionPlayingHighlighting(domHandler);
        domHelper._clearSectionPausedHighlighting(domHandler);
        // Reset progress bar
        updateRelevantProgressBars(0);

        allowDefaultEventHandling = false;
      }

      break;
    case 'play':
      if (isLegacyStoryMode) {
        domHelper._highlightSectionPlaying(domHandler, {
          isUserTriggered: event.isUserTriggered,
        });
      }

      if (isStoryImageMode) {
        // When only viewing image in story mode, the same image is always highlighted
        let $storyImage = domHelper.findStoryImageElem(dom);

        domHelper._highlightStoryImagePlaying(domHandler, $storyImage, {
          isUserTriggered: event.isUserTriggered,
        });
        // Must also highlight as selected, because a 'select' event is not received when switching to "story image mode" after playing has already started
        domHelper._highlightSelectedStoryImage(domHandler);
      }

      // Prevent default event handling, otherwise highlighting for image/section will get cleared
      allowDefaultEventHandling = false;

      break;
    case 'pause':
      if (isStoryImageMode) {
        domHelper._clearStoryImagePlayingHighlighting(domHandler);
        domHelper._highlightStoryImagePaused(domHandler);
      }
      if (isLegacyStoryMode) {
        domHelper._clearSectionPlayingHighlighting(domHandler);
        domHelper._highlightSectionPaused(domHandler);
        allowDefaultEventHandling = false;
      }

      break;
    case 'select':
      if (isStoryImageMode) {
        domHelper._highlightSelectedStoryImage(domHandler);
      }
      if (isLegacyStoryMode) {
        domHelper._highlightSelectedSection(domHandler);
        allowDefaultEventHandling = false;
      }

      // Reset progress bars
      let $progressBarParents = domHelper
        .findStoryImageElem(dom)
        .add(domHelper.findSectionElem(dom));
      domHandler._setProgressBarValue($progressBarParents, 0);

      break;
    case 'autoPlayWhenLoaded':
      showLoadingIndicator(true);
      allowDefaultEventHandling = false;
      break;
    case 'autoPlayWhenLoadedCancelled':
      showLoadingIndicator(false);
      allowDefaultEventHandling = false;
      break;
    case 'load':
      showLoadingIndicator(false);

      // Add progress bar to both story section elem and to story image elem.
      domHandler._addProgressBarToElem(domHelper.findSectionElem(dom));
      domHandler._addProgressBarToElem(domHelper.findStoryImageElem(dom));

      allowDefaultEventHandling = false;
      break;
    default:
      break;
  }

  return allowDefaultEventHandling;
};

export default handleSpeakerEvent;
