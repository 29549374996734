import { computed } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import Model from 'babel/models/model';
import integrations from 'babel/rules/integrations';

export default Model.extend({
  intl: service(),

  // ATTRIBUTES

  username: attr('string'),

  firstname: attr('string'),

  lastname: attr('string'),

  avatar: attr(),

  provider: attr('string'),

  role: attr('string', {
    defaultValue() {
      return 'test';
    },
  }),

  email: attr('string'),

  metadata: attr('object', {
    defaultValue() {
      return {};
    },
  }),

  // RELATIONS

  school: belongsTo('school', { async: true, inverse: null }),

  schools: hasMany('school', { async: true, inverse: null }),

  books: hasMany('entities', { async: true, inverse: null }),

  courses: hasMany('course', { async: true, inverse: null }),

  licenses: hasMany('license', { async: true, inverse: null }),

  class: belongsTo('group', { async: true, inverse: null }),

  groups: hasMany('group', { async: true, inverse: null }),

  logins: hasMany('login', { async: true, inverse: null }),

  roles: hasMany('role', { async: true, inverse: null }),

  // COMPUTED

  serviceProvider: computed('provider', function () {
    const provider = this.get('provider');

    if (provider && integrations[provider]) {
      return integrations[provider];
    }

    return null;
  }),

  isTeacher: computed('role', function () {
    return this.get('role') === 'teacher';
  }),

  isStudent: computed('role', function () {
    return this.get('role') === 'student';
  }),

  isTestUser: computed('role', function () {
    return this.get('role') === 'test';
  }),

  isPrivateUser: computed('role', function () {
    return this.get('role') === 'private';
  }),

  hasSchool: computed('school', function () {
    return this.belongsTo('school').id() ? true : false;
  }),

  isTeacherForSchool: computed('isTeacher', 'hasSchool', function () {
    return this.get('hasSchool') && this.get('isTeacher');
  }),

  isTeacherWithoutSchool: computed('isTeacher', 'hasSchool', function () {
    return !this.get('hasSchool') && this.get('isTeacher');
  }),

  isStudentWithoutSchool: computed('isStudent', 'hasSchool', function () {
    return !this.get('hasSchool') && this.get('isStudent');
  }),

  showname: computed('firstname', 'lastname', 'username', function () {
    if (this.firstname && this.lastname) {
      return `${this.firstname} ${this.lastname}`;
    } else if (this.firstname && !this.lastname) {
      return this.firstname;
    } else if (this.lastname && !this.firstname) {
      return this.lastname;
    } else {
      return this.username;
    }
  }),

  initials: computed('firstname', 'lastname', 'username', function () {
    let initials = '';

    if (this.firstname) {
      initials += this.firstname.substring(0, 1).toUpperCase();
    }

    if (this.lastname) {
      initials += this.lastname.substring(0, 1).toUpperCase();
    }

    if (initials.length === 0 && this.username) {
      initials += this.username.substring(0, 2).toUpperCase();
    }

    return initials;
  }),

  listname: computed('firstname', 'lastname', 'intl.locale', function () {
    let firstname = this.get('firstname');
    let lastname = this.get('lastname');

    if (!firstname) {
      firstname = '';
    }

    if (!lastname) {
      lastname = '';
    }

    if (firstname.trim() === '' && lastname.trim() === '') {
      return htmlSafe(
        '<em class="dimmed">' +
          this.get('intl').t('models.user.nameMissing') +
          '</em>'
      );
    }

    return firstname.trim() !== '' && lastname.trim() !== ''
      ? `${lastname}, ${firstname}`
      : `${lastname}${firstname}`;
  }),

  language: computed('metadata.language', {
    get() {
      return this.get('metadata.language') || 'sv';
    },
    set(key, val) {
      this.set('metadata.language', val);
      return val;
    },
  }),

  activeSchoolApplications: computed('schoolApplications', function () {
    return this.schoolApplications.then((schoolApplications) =>
      schoolApplications.filter((application) => application.is_active)
    );
  }),

  licenseProducts: mapBy('licenses', 'product'),

  getPreferredTextVersion(book_id) {
    return this.metadata?.preferred_text_versions?.find(
      (item) => item.book_id === book_id
    )?.category_id;
  },

  savePreferredTextVersion(book_id, category_id) {
    if (!book_id || !category_id) return;

    let metadata = this.metadata;

    if (!metadata) metadata = {};

    if (!metadata.preferred_text_versions) {
      metadata.preferred_text_versions = [];
    }

    const saved = metadata.preferred_text_versions.find(
      (item) => item.book_id === book_id
    );

    let changed = false;

    if (saved) {
      if (saved.category_id !== category_id) {
        saved.category_id = category_id;
        changed = true;
      }
    } else {
      metadata.preferred_text_versions.push({ book_id, category_id });
      changed = true;
    }

    if (changed) {
      this.metadata = metadata;
      return this.save();
    }
  },
});
