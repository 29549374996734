import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import config from 'babel/config/environment';

/**
 * Arguments:
 * currentCourse
 * activeProduct
 * activeCourse
 * entity
 * exercise
 * withHeader (bool)
 * header (object)
 * value
 * items
 * multiple (bool)
 * opacity (int)
 * flip (bool)
 * arrow (bool)
 * minWidth (number)
 * placementOffset
 * alignmentOffset
 * mouseDragMode (bool)
 * stickToTarget (bool)
 * appearance
 * scrollToSelected (bool)
 */

export default class ShareMetaMenuModal extends Component {
  @service entityInspector;

  @service contextHelper;

  @service router;

  get infoItems() {
    let returnArray = [];

    if (this.args.entity) {
      returnArray.push({
        icon: 'info-circle',
        value: 'entity',
        label: this.args.entity.title,
      });
    }

    if (
      this.contextHelper.activeExercise &&
      !this.contextHelper.activeExercise.isTeacherAssignment
    ) {
      returnArray.push({
        icon: 'info-circle',
        value: 'exercise',
        label: this.contextHelper.activeExercise.title,
      });
    }

    return returnArray;
  }

  get hasPortalUrl() {
    return config.nokPortalURL && config.nokPortalURL !== 'null';
  }

  get portalUrl() {
    return this.hasPortalUrl ? config.nokPortalURL : '';
  }

  get createCourseUrl() {
    return `${this.portalUrl}/courses/new?productId=${
      this.args.activeProduct?.id
    }&return-url=${encodeURIComponent(this.returnURL)}`;
  }

  get returnURL() {
    return this.router.currentURL;
  }

  // ACTIONS

  @action
  handleListChange() {
    this._change(...arguments);

    if (!this.args.multiple) {
      this._close();
    }
  }

  @action
  handleOpenEntityInspector(value) {
    if (value === 'exercise') {
      this.entityInspector.openModal(this.contextHelper.activeExercise);
    } else {
      this.entityInspector.openModal(this.args.entity);
    }
    this._close();
  }

  // PRIVATE

  _change() {
    this.args.onChange(...arguments);
  }

  _close() {
    this.args.onClose();
  }
}
