import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { storageFor } from 'ember-local-storage';
export default class StoryModalComponent extends Component {
  @service speaker;

  @storageFor('read-setting') settings;

  @action handleStartWithReading() {
    this.speaker.setSpeakerActive(true);
    this.speaker.setAutoplay(true);
    this.args.onClose?.();
  }

  @action handleStartWithoutReading() {
    this.speaker.setSpeakerActive(false);
    this.args.onClose?.();
  }

  @action handleOnlyImage(value) {
    this.settings.set('onlyImage', value);
  }
}
