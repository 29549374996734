import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderGalleryComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  @service store;

  @tracked imageViewerIsOpen = false;

  @tracked _selectedImage;

  @tracked element;

  @tracked fullscreen;

  baseClass = 'tb-render-gallery';

  asyncType = 'material';

  get asyncId() {
    return (this.args.part?.value || []).sortBy('index').mapBy('media_id');
  }

  get images() {
    return this.asyncData || [];
  }

  get selectedImage() {
    if (this._selectedImage) {
      return this._selectedImage;
    }

    return this.comparableImages?.firstObject;
  }

  get selectedImageIndex() {
    return (this.comparableImages || [])
      .mapBy('id')
      .indexOf(this.selectedImage?.id);
  }

  get captions() {
    const values = this.args.part?.value;

    if (!values) return null;

    return values.mapBy('value');
  }

  get currentCaption() {
    const index = this.selectedImageIndex;

    if (!this.captions || !this.captions[index]) return null;

    return this.captions[index];
  }

  get imageViewerImages() {
    const values = this.args.part?.value || [];

    return this.images.map((material, index) => {
      const caption = !values[index] ? null : values[index].value;

      return {
        caption,
        source: material.imageUrl,
        width: material.imageWidth,
        height: material.imageHeight,
        color: material.imageColor,
        mimetype: material.imageMimetype,
        copyright: material.metadata?.copyright,
      };
    });
  }

  get comparableImages() {
    return this.images.map((material) => ({
      imageURL: material.imageUrl,
      width: material.imageWidth,
      height: material.imageHeight,
      color: material.imageColor,
      mimetype: material.imageMimetype,
      id: material.id,
    }));
  }

  get previousImage() {
    if (this.selectedImageIndex > 0) {
      return this.comparableImages[this.selectedImageIndex - 1];
    }

    return null;
  }

  get nextImage() {
    if (this.selectedImageIndex < this.comparableImages.length) {
      return this.comparableImages[this.selectedImageIndex + 1];
    }

    return null;
  }

  get behaviour() {
    return this.args.part?.settings?.behaviour
      ? this.args.part?.settings?.behaviour
      : 'enlarge';
  }

  get isFullscreen() {
    return document.fullscreenElement || document.webkitFullscreenElement
      ? true
      : false;
  }

  get fullscreenIcon() {
    if (this.behaviour === 'enlarge') {
      return 'expand';
    } else if (this.fullscreen) {
      return 'simple-remove';
    } else {
      return 'fullscreen';
    }
  }

  @action handlePreviousButtonClick() {
    const previousImage = this.previousImage;

    if (previousImage) {
      this._selectedImage = previousImage;
    }
  }

  @action handleNextButtonClick() {
    const nextImage = this.nextImage;

    if (nextImage) {
      this._selectedImage = nextImage;
    }
  }

  @action handleImageSelectImage(image) {
    this._selectedImage = image;
  }

  @action handleFullscreenButtonClick() {
    if (this.behaviour === 'enlarge') {
      this.imageViewerIsOpen = true;
    } else if (this.behaviour === 'fullscreen') {
      if (!this.isFullscreen) {
        this.element.requestFullscreen
          ? this.element.requestFullscreen()
          : this.element.webkitRequestFullscreen?.();
      } else {
        document.exitFullscreen
          ? document.exitFullscreen()
          : document.webkitExitFullscreen?.();
      }
    }
  }

  @action handleImageViewerClose() {
    this.imageViewerIsOpen = false;
  }

  @action setElement(elem) {
    elem.addEventListener('fullscreenchange', this.toggleFullscreen);
    elem.addEventListener('webkitfullscreenchange', this.toggleFullscreen);
    this.element = elem;
  }

  @action toggleFullscreen() {
    this.fullscreen = this.isFullscreen;
  }
}
