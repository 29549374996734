import { action } from '@ember/object';
import Component from '@glimmer/component';
import { storageFor } from 'ember-local-storage';

export default class TbRenderInlineElementEncloseAComponent extends Component {
  /**
   * Arguments:
   * href (string)
   * active (bool)
   * variant (string)
   * onClick (function)
   */

  @storageFor('read-setting') settings;

  baseClass = 'tb-render-inline-element-enclose-a';

  get href() {
    return this.args.href || '#';
  }

  get markingClass() {
    // ember localStorage requires get
    const marking = this.args.active ? 'colored' : this.settings.get('marking');
    return [this.baseClass, marking].join('--');
  }

  get variantClass() {
    return this.args.variant && [this.baseClass, this.args.variant].join('--');
  }

  get classes() {
    const classes = [this.baseClass];

    if (this.markingClass) {
      classes.push(this.markingClass);
    }

    if (this.variantClass) {
      classes.push(this.variantClass);
    }

    return classes.join(' ');
  }

  @action
  handleClick(e) {
    if (this.href) {
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        return;
      }

      e.preventDefault();
    }

    this.args.onClick?.();
  }
}
