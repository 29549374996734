import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  namespace: 'api/v1/meta',

  shouldBackgroundReloadRecord() {
    return false;
  },

  shouldBackgroundReloadAll() {
    return false;
  },
  
  urlForFindMany(ids, modelName, snapshots) {
    const all = snapshots?.[0]?.adapterOptions?.all;
    let url = this._super(...arguments);

    if (all) {
      url += '?all=true';
    }

    return url;
  },
});
