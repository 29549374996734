import { service } from '@ember/service';
import ApplicationAdapter from 'babel/adapters/application';
import config from 'babel/config/environment';
import { handleDirtyBelongsToRelationshipData } from 'babel/utils/handle-dirty-relationship-data';
import { resolve } from 'rsvp';

export default class CourseAdapter extends ApplicationAdapter {
  @service session;

  host = config.userApiEndpoint;

  namespace = 'api';

  shouldBackgroundReloadRecord(store, snapshot) {
    // This fixes a bug affecting tests where Ember reloads
    // the record before it is persisted, leading to an error.
    if (snapshot.isNew) return false;
    return true;
  }

  shouldBackgroundReloadAll() {
    return true;
  }

  findBelongsTo(store, snapshot, url, relationship) {
    if (relationship?.meta?.name === 'setting') {
      return handleDirtyBelongsToRelationshipData(
        store,
        url,
        relationship?.meta?.type
      ).then(async (response) => {
        if (response?.data === null) {
          const id = snapshot.id;
          const setting = store.createRecord('setting', { id });

          if (this.session.user?.isTeacher) {
            await setting.save();
          } else {
            // In case the student enters the course before the teacher
            // we need to add the books as shortcuts. See DINO-3707
            const course = store.peekRecord('course', id);

            if (course) {
              const books = await resolve(course.allBooks);
              const shortcuts = await resolve(setting.shortcuts);

              if (books.length > 0 && shortcuts.length === 0) {
                shortcuts.addObjects(books);
              }
            }
          }

          return setting.serialize({ includeId: true });
        }

        return response;
      });
    }

    return super.findBelongsTo(...arguments);
  }
}
