import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { getNumCorrectAnswers } from 'babel/utils/assignments';

export default class CollectionListItemDiagnosisComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * interactive (object)
   * answers (array)
   */

  @service router;

  baseClass = 'collection-list-item';

  get classNames() {
    const classes = [this.baseClass];

    classes.push(`${this.baseClass}-diagnosis`);

    return classes.join(' ');
  }

  get attemptsLeft() {
    const latestAttempt = this.args.interactive?.attempt || 0;

    return Math.max(this.args.collection.allowedAttempts - latestAttempt, 0);
  }

  get canContinue() {
    return this.args.interactive?.isActive;
  }

  get showLatestResult() {
    return this.args.interactive ? true : false;
  }

  get score() {
    return getNumCorrectAnswers(this.args.answers);
  }

  get badgeVariant() {
    return this.score >= this.args.collection?.pointsRequired
      ? 'success'
      : 'default';
  }

  @action handleOpenCollection(e) {
    e?.preventDefault();
    e?.stopPropagation();
    e?.stopImmediatePropagation();

    return this.router.transitionTo(
      'master.interactives.diagnoses.index',
      this.args.collection.id
    );
  }
}
