import Component from '@glimmer/component';

export default class ContentPaginationComponent extends Component {
  get classNames() {
    const classNames = [];
    if (this.args.horizontal === true) {
      classNames.push('content-pagination-horizontal');
    } else {
      classNames.push('content-pagination-vertical');
    }
    return classNames.join(' ');
  }
}
