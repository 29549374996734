import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"mission-content-custom-content-list-item-thumbnail\"\n  data-with-image={{@image}}\n  ...attributes\n>\n\n  <XImage\n    class=\"mission-content-custom-content-list-item-thumbnail-image\"\n    @source={{this.imageSource}}\n    @width={{@image.imageWidth}}\n    @height={{@image.imageHeight}}\n    @color={{@image.imageColor}}\n    @mimetype={{@image.imageMimetype}}\n    @alt={{@image.alt}}\n    @cover={{true}}\n  />\n\n</div>", {"contents":"<div\n  class=\"mission-content-custom-content-list-item-thumbnail\"\n  data-with-image={{@image}}\n  ...attributes\n>\n\n  <XImage\n    class=\"mission-content-custom-content-list-item-thumbnail-image\"\n    @source={{this.imageSource}}\n    @width={{@image.imageWidth}}\n    @height={{@image.imageHeight}}\n    @color={{@image.imageColor}}\n    @mimetype={{@image.imageMimetype}}\n    @alt={{@image.alt}}\n    @cover={{true}}\n  />\n\n</div>","moduleName":"babel/components/mission-content/custom-content-list/item/thumbnail/index.hbs","parseOptions":{"srcName":"babel/components/mission-content/custom-content-list/item/thumbnail/index.hbs"}});
import Component from '@glimmer/component';
import imageSource from 'compton/utils/image-source';

export default class MissionContentCustomContentListItemThumbnail extends Component {
  get imageSource() {
    const url = this.args.entity.imageUrl ?? this.args.entity.featuredImage;

    const settings = {
      width: 132,
      height: 132,
      embed: true,
    };

    if (this.image?.metadata?.focus_point) {
      settings.x = this.image.metadata.focus_point.x;
      settings.y = this.image.metadata.focus_point.y;
    }

    return imageSource(url, settings);
  }
}
