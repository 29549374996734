import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CollectionListItemCarouselComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * currentExercise (object)
   * interactive (object)
   * answers (array)
   * onOpenCollection (function)
   */

  @service router;

  baseClass = 'collection-list-item';

  get linked() {
    if (this.args.onOpenCollection && this.args.currentExercise) {
      return true;
    }

    return false;
  }

  get classNames() {
    const classes = [this.baseClass];

    classes.push(`${this.baseClass}-carousel`);

    return classes.join(' ');
  }

  get showBagde() {
    return this.args.interactive?.ended;
  }

  get canContinue() {
    return (
      this.args.interactive?.isStarted && !this.args.interactive?.isCompleted
    );
  }

  get badgeVariant() {
    return this.args.interactive?.score === this.args.interactive?.numExercises
      ? 'success'
      : 'default';
  }

  @action async handleOpenCollection(e) {
    e?.preventDefault();

    return this.args.onOpenCollection?.(
      this.args.collection,
      this.args.currentExercise
    );
  }
}
