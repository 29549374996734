import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'babel/routes/authenticated-route';

export default class MasterRoute extends AuthenticatedRoute {
  @service cookies;

  @service pusher;

  @service router;

  @service intl;

  @service ajax;

  @service session;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    // Activate preview mode (for superadmins)
    if (transition.to && transition.to.queryParams.preview) {
      this.session.inPreviewMode = true;
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    if (this.cookies.read('cookies_allowed') !== 'yes') {
      controller.cookiesNotAccepted = true;
    }
  }

  @action didTransition() {
    this.controller.drawerIsOpen = false;
  }
}
