import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"arrange-image {{if @isHorizontal 'horizontal-image' 'vertical-image'}}\"\n>\n  {{#if this.imageSrc}}\n    <div\n      class=\"{{if this.allowCrop 'keep-proportions' 'allow-crop'}}\n        {{if this.imagePadding 'small-padding'}}\n        \"\n    >\n      <div class=\"image-container\">\n        <XImage\n          @source={{this.imageSrc}}\n          @width={{this.image.imageWidth}}\n          @height={{this.image.imageHeight}}\n          @color={{this.image.imageColor}}\n          @mimetype={{this.image.imageMimetype}}\n          @alt={{or this.image.name \"Image\"}}\n        />\n      </div>\n    </div>\n\n  {{else}}\n    <DcLoader />\n  {{/if}}\n</div>", {"contents":"<div\n  class=\"arrange-image {{if @isHorizontal 'horizontal-image' 'vertical-image'}}\"\n>\n  {{#if this.imageSrc}}\n    <div\n      class=\"{{if this.allowCrop 'keep-proportions' 'allow-crop'}}\n        {{if this.imagePadding 'small-padding'}}\n        \"\n    >\n      <div class=\"image-container\">\n        <XImage\n          @source={{this.imageSrc}}\n          @width={{this.image.imageWidth}}\n          @height={{this.image.imageHeight}}\n          @color={{this.image.imageColor}}\n          @mimetype={{this.image.imageMimetype}}\n          @alt={{or this.image.name \"Image\"}}\n        />\n      </div>\n    </div>\n\n  {{else}}\n    <DcLoader />\n  {{/if}}\n</div>","moduleName":"babel/components/exercise-view/assignment/arrange/image/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/arrange/image/index.hbs"}});
import ItemImageComponent from 'babel/components/exercise-view/common/item-image/index';

export default class ExerciseViewArrangeImage extends ItemImageComponent {
  /**
   * Arguments:
   * imageId (string)
   * width (number)
   * height (number)
   */
}
