import { get as proxyGet } from '@ember/object';
import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/* eslint-disable ember/no-get */
export default class WordlistDispatcher extends Service {
  @service session;
  @service store;
  @service intl;
  @service snackbar;
  @service confirmManager;

  @tracked highlightedWordlists = [];

  async save(wordlist, publish = false) {
    try {
      wordlist.public = publish;
      const result = await wordlist.save();
      this.#success('save');
      return result;
    } catch (error) {
      this.#error('save');
      throw error;
    }
  }

  async delete(wordlist) {
    return this.#confirm('delete', { danger: true }).then(async () => {
      try {
        const result = await wordlist.destroyRecord();
        this.#success('delete');
        return result;
      } catch (error) {
        this.#error('delete');
        throw error;
      }
    });
  }

  async deleteMany(wordlists) {
    return this.#confirm('deleteMany', { danger: true }, wordlists.length).then(
      async () => {
        try {
          const result = await Promise.all(
            wordlists.map((wordlist) => wordlist.destroyRecord())
          );
          this.#success('deleteMany', { count: wordlists.length });
          return result;
        } catch (error) {
          this.#error('delete');
          throw error;
        }
      }
    );
  }

  async link(wordlist, content) {
    try {
      const result = await this.#link(wordlist, content);
      this.#success('link');
      return result;
    } catch (error) {
      this.#error('link');
      throw error;
    }
  }

  async linkMany(wordlists, content) {
    try {
      const result = await Promise.all(
        wordlists.map((wordlist) => this.#link(wordlist, content))
      );
      this.#success('linkMany', { count: wordlists.length });
      return result;
    } catch (error) {
      this.#error('linkMany');
      throw error;
    }
  }

  unlink(wordlist, content) {
    return this.#confirm('unlink', { danger: true }).then(async () => {
      try {
        wordlist.rollback();
        wordlist.get('contents').removeObject(content);
        const result = await wordlist.save();
        this.#success('unlink');
        return result;
      } catch (error) {
        this.#error('unlink');
        throw error;
      }
    });
  }

  async duplicate(wordlist, content) {
    try {
      const result = await wordlist.duplicate(content);
      this.#success('duplicate');
      return result;
    } catch (error) {
      this.#error('duplicate');
      throw error;
    }
  }

  highlight(wordlist) {
    this.highlightMany([wordlist]);
  }

  highlightMany(wordlists) {
    this.highlightedWordlists.addObjects(wordlists);

    later(() => {
      this.highlightedWordlists.removeObjects(wordlists);
    }, 3000);
  }

  #confirm(method, options, count = 1) {
    return this.confirmManager.perform(
      this.intl.t(`services.wordlistDispatcher.${method}.confirm`, {
        count,
      }),
      options
    );
  }

  #success(method, { count = 1 } = {}) {
    this.snackbar.enqueue(
      this.intl.t(`services.wordlistDispatcher.${method}.snackbar.success`, {
        count,
      }),
      {
        variant: 'success',
        autoDismiss: true,
      }
    );
  }

  #error(method, count = 1) {
    this.snackbar.enqueue(
      this.intl.t(`services.wordlistDispatcher.${method}.snackbar.error`),
      {
        variant: 'error',
      },
      { count }
    );
  }

  async #link(wordlist, content) {
    const book = await proxyGet(content, 'book');

    wordlist.rollback();
    proxyGet(wordlist, 'books').addObject(book);
    proxyGet(wordlist, 'contents').addObject(content);

    return wordlist.save();
  }
}
