import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ErrorRoute extends Route {
  @service contextHelper;

  @service intl;

  setupController() {
    super.setupController(...arguments);
    this.contextHelper.setActive(
      'pageTitle',
      this.intl.t('services.contextHelper.pageTitle.error')
    );
  }

  resetController() {
    super.resetController(...arguments);
    this.contextHelper.setActive('pageTitle', null);
  }
}
