import EmberObject, { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { v4 as uuid } from 'ember-uuid';

import Contentable from '../mixins/contentable';
import Userable from '../mixins/userable';
import Model from './model';

const { attr, hasMany, belongsTo } = DS;

export default Model.extend(Contentable, Userable, {
  // SETUP

  intl: service(),

  store: service(),

  validations: {
    title: {
      presence: {
        message: function (_, __, record) {
          return record.get('intl').t('models.validations.presence');
        },
      },
    },
  },

  // RELATIONSHIPS

  files: hasMany('file', { async: true, inverse: null }),

  school: belongsTo('school', { async: true, inverse: null }),

  image: attr('string'),

  // ATTRIBUTES

  title: attr('string'),

  questionHtml: attr('string'),

  public: attr('boolean', { defaultValue: false }),

  level: attr('number'),

  exercise: attr('object', {
    camelizeDeserializedKeys: true,
    defaultValue() {
      return EmberObject.create({
        assignments: [],
        type: null,
        badge: null,
      });
    },
  }),

  // PROPERTIES

  isPublished: alias('public'),

  titleWithFallback: computed('title', 'intl.locale', function () {
    const title = this.get('title');

    if (title) {
      return title;
    }

    return this.get('intl').t('models.teacherAssignment.titleWithFallback');
  }),

  readOnly: false,

  // METHODS

  createAsEntity(content) {
    if (!this.hasContent(content)) return;

    const entity = this._findOrCreateEntity(content);

    const collection = this._findOrCreateCollection(content);

    collection.get('children').addObject(entity);

    return entity;
  },

  updateAsEntity(content) {
    if (this.hasContent(content)) {
      this.createAsEntity(content);
    } else {
      this.deleteAsEntity(content);
    }
  },

  deleteAsEntity(content) {
    const collection = this._peekCollection(content);

    if (collection) {
      const entity = this.get('store').peekRecord('entity', this.get('id'));

      if (entity && collection.get('children').includes(entity)) {
        collection.get('children').removeObject(entity);
      }

      if (collection.get('children.length') === 0) {
        content.get('children').removeObject(collection);
      }
    }
  },

  addNewExerciseAssigment(properties) {
    const assigment = this._newExerciseAssigment(properties);
    this.get('exercise.assignments').addObject(assigment);
    return assigment;
  },

  addExerciseAssigment(assigment) {
    this.get('exercise.assignments').addObject(assigment);
  },

  removeExerciseAssigment(assigment) {
    this.get('exercise.assignments').removeObject(assigment);
  },

  // PRIVATE

  _findOrCreateEntity(content) {
    let entity = this.get('store').peekRecord('entity', this.get('id'));

    const collection = this._findOrCreateCollection(content);

    const attributes = this._getEntityAttributes(content, collection);

    if (entity) {
      entity.setProperties(attributes);
      return entity;
    }

    return this.get('store').createRecord('entity', attributes);
  },

  _findOrCreateCollection(content) {
    let collection = this._peekCollection(content);

    if (collection) {
      content.get('children').addObject(collection);
      return collection;
    }

    collection = this.get('store').createRecord(
      'entity',
      this._getCollectionAttributes(content)
    );

    content.get('children').addObject(collection);

    return collection;
  },

  _peekCollection(content) {
    const userId = this.get('user.id');

    if (!userId) {
      const entity = this.get('store').peekRecord('entity', this.get('id'));
      return entity?.get('parent');
    }

    return this.get('store')
      .peekAll('entity')
      .filterBy('type', 'collections')
      .filterBy('parent.id', content.get('id'))
      .filterBy('owner.id', userId)
      .get('firstObject');
  },

  _getCollectionAttributes(content) {
    return {
      type: 'collections',
      space: 'workspace',
      title: this.get('user.showname'),
      teacherAssignment: this,
      owner: this.get('user'),
      parent: content,
      isTeacherCollection: true,
    };
  },

  _getEntityAttributes(content, collection) {
    return {
      id: this.get('id'),
      title: this.get('title'),
      questionHtml: this.get('questionHtml'),

      type: 'assignments',
      template: 'assignment',

      owner: this.get('user'),
      teacherAssignment: this,
      parent: collection,
      files: this.get('files'),
      book: content ? content.get('book') : null,
      isPublished: this.get('isPublished'),
      isTeacherAssignment: true,

      body: {
        exercise: this.get('exercise'),
      },

      // Legacy:

      document_id: this.get('id'),
      node_id: content ? content.get('id') : null,
      book_id: content ? content.belongsTo('book').id() : null,
    };
  },

  _newExerciseAssigment(attributes = {}) {
    const index = this.get('exercise.assignments.length') + 1;

    return EmberObject.create({
      index,
      uuid: uuid(),
      template: 'editor',
      audio: null,
      content: EmberObject.create(),
      settings: EmberObject.create(),
      ...attributes,
    });
  },
});
