import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { resolve } from 'rsvp';

export default class WorkspaceAssignmentsEditComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * onClose (function)
   */

  // SETUP

  @service router;

  @service teacherAssignmentDispatcher;

  @service missionMode;

  @controller('master.contents') contentsController;

  // PROPERTIES

  get teacherAssignment() {
    return this.args.model;
  }

  get content() {
    return this.contentsController?.content;
  }

  // ACTIONS

  @action openIndex() {
    if (this.teacherAssignment.isNew) {
      return this.router.transitionTo('master.contents.assignments.index');
    }

    return this.router.transitionTo(
      'master.contents.assignments.show',
      this.teacherAssignment.id
    );
  }

  @action handleTeacherAssignmentDetailAddFile(file) {
    this.teacherAssignment?.files?.addObject(file);
  }

  @action handleTeacherAssignmentDetailRemoveFile(file) {
    this.teacherAssignment?.files?.removeObject(file);
  }

  @action handleTeacherAssignmentDetailSave(publish) {
    if (this.teacherAssignment.validate()) {
      const isNew = this.teacherAssignment.isNew;

      return this.teacherAssignmentDispatcher
        .save(this.teacherAssignment, publish)
        .then(() => {
          if (isNew) {
            return this.teacherAssignment.createAsEntity(this.content);
          }

          return this.teacherAssignment.updateAsEntity(this.content);
        })
        .then(() => {
          if (isNew) {
            return this.missionMode.addIncludedTeacherAssignmentIfNeeded(
              this.teacherAssignment
            );
          }

          return resolve();
        })
        .then(() =>
          this.router.transitionTo(
            'master.contents.assignments.show',
            this.teacherAssignment.id
          )
        );
    }
  }

  @action handleTeacherAssignmentDetailDelete() {
    return this.teacherAssignmentDispatcher
      .delete(this.teacherAssignment)
      .then(() => this.teacherAssignment.deleteAsEntity(this.content))
      .then(() =>
        this.router.transitionTo('master.contents.assignments.index')
      );
  }
}
