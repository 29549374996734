import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const CONTENTS_ROUTE_NAME = 'master.contents';
const SLASH = '/';
const QUESTION_MARK = '?';

export default Route.extend({
  // SETUP

  router: service(),

  // HOOKS

  model({ path }, transition) {
    const { content_id } = this.paramsFor(CONTENTS_ROUTE_NAME);

    const [section, ...segments] = path
      .split(SLASH)
      .compact()
      .without('sections')
      .without('workspace')
      .without('show');

    const router = this.router;

    const { exercise_id, ...queryParams } = transition.to.queryParams;

    let url = router.urlFor(CONTENTS_ROUTE_NAME, content_id, {
      queryParams: { section, exercise: exercise_id, ...queryParams },
    });

    if (segments.get('length') > 0) {
      const [base, query] = url.split(QUESTION_MARK);
      url =
        base +
        SLASH +
        segments.join(SLASH).replace('exercises', 'assignments') +
        QUESTION_MARK +
        query;
    }

    if (this.router.recognize(url)) {
      return this.router.replaceWith(url);
    }
  },
});
