import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { storageFor } from 'ember-local-storage';

export default class ContentsStorySection extends Component {
  constructor() {
    super(...arguments);
    this.speaker.set('storyCinemaMode', this.cinema);
    this.footnotesService.createIndexContext(this.args.sections);

    this.resizeObserver = new ResizeObserver(this.handleResize);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.resizeObserver.unobserve(this.element);
  }

  @storageFor('read-setting') settings;

  @service('footnotes') footnotesService;

  @service speaker;

  @tracked elementWidth;

  @tracked resizeObserver;

  @tracked showContentSummary = false;

  @tracked imageElement;

  imageSize = 'xl';

  @action
  handleDone() {
    this.showContentSummary = true;
  }

  @action
  handleResize() {
    if (!this.element) return;

    const { width: elementWidth } = this.element.getBoundingClientRect();

    if (this.elementWidth !== elementWidth) {
      this.elementWidth = elementWidth;
    }
  }

  @action
  handleImageFinally() {
    this.args.cacheNextAndPreviousImage(this.imageSize);
    this.imageElement.classList.add('story-section-image-fade-in');
  }

  @action
  resetOpacity() {
    if (!this.#isImageLoaded(this.imageElement?.querySelector('img'))) {
      this.imageElement.classList.remove('story-section-image-fade-in');
    }
  }

  #isImageLoaded(image) {
    return image?.complete && image?.naturalHeight !== 0;
  }

  @action
  setElement(element) {
    this.element = element;
    this.resizeObserver.observe(this.element);
    this.handleResize();
  }

  @action
  setImageElement(element) {
    this.imageElement = element;
  }

  @action
  toggleOnlyImage() {
    const value = !this.settings.get('onlyImage');
    this.settings.set('onlyImage', value);
    this.speaker.set('storyCinemaMode', value);
  }

  get responsiveClass() {
    if (this.elementWidth > 1600) {
      return 'story-section--xl';
    } else if (this.elementWidth > 1200) {
      return 'story-section--large';
    } else if (this.elementWidth > 992) {
      return 'story-section--medium';
    } else {
      return null;
    }
  }

  get showSummary() {
    return this.showContentSummary && !this.args.hasNextSection;
  }

  get showDoneButton() {
    return !this.showContentSummary && this.args.showDoneButton;
  }

  get expandedImage() {
    return (
      !!this.args.model?.image?.metadata?.keep_proportions &&
      !this.settings.get('onlyImage')
    );
  }

  get cinema() {
    return this.settings.get('onlyImage');
  }

  get classNames() {
    if (this.cinema) {
      return 'story-section--cinema';
    } else {
      return '';
    }
  }

  get backgroundColor() {
    return this.args.model?.image?.backgroundColor;
  }

  get image() {
    return this.args.model?.image?.imageURL || this.args.model?.featuredImage;
  }

  get styleString() {
    let url = `url(${this.image})`;
    return `--bg-color: ${this.backgroundColor}; --url: ${url};`;
  }

  @cached
  get modelProxy() {
    return load(this.args.model, this);
  }

  get title() {
    const model = this.modelProxy.isResolved ? this.modelProxy.value : null;

    if (model?.body?.hide_heading) return null;

    return model ? model.title : null;
  }

  get hasExercises() {
    return this.args.model.children.length > 0;
  }
}
