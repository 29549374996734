import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['c-content-preamble'],

  // EXTERNAL

  value: null,

  excerptBreakpoint: 120,

  template: null,

  // INTERNAL

  showMoreButton: false,

  showFullText: false,

  hideFullText: false,

  // BINDINGS

  classNameBindings: [
    'showFullText:show-full-text',
    'hideFullText:hide-full-text'
  ],

  // HOOKS

  didInsertElement() {
    this._super(...arguments);

    if (this.get('template') !== 'cover') {
      let height = this.element.offsetHeight;

      this.element.style.setProperty(
        '--break',
        `${this.get('excerptBreakpoint')}px`
      );

      if (height > this.get('excerptBreakpoint')) {
        this.set('showMoreButton', true);
        this.set('hideFullText', true);
      }
    }
  },

  // ACTIONS

  actions: {
    toggleText() {
      this.toggleProperty('showFullText');
      this.toggleProperty('hideFullText');
    }
  }
});
