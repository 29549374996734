import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { MissingContentError } from 'babel/utils/errors';
import { reject, resolve } from 'rsvp';

export default class MasterContentsWordlistsShowWordsEditRoute extends Route {
  @service session;

  @service store;

  async model({ word_id }) {
    let word;

    try {
      word = await this.store.findRecord('word', word_id);
    } catch (err) {
      return reject(new MissingContentError());
    }

    return word;
  }

  afterModel(model) {
    return resolve(model.wordlist).then((wordlist) => {
      if (wordlist !== this.modelFor('master.contents.wordlists.show')) {
        return reject(NotFoundError);
      }

      if (!wordlist.isUser(this.session.user)) {
        return reject(ForbiddenError);
      }
    });
  }

  resetController(controller) {
    const model = controller.model;

    if (!model.isDestroyed && !model.isDestroying) {
      model.rollback();
    }
  }
}
