import { isPresent, typeOf } from '@ember/utils';
import Component from '@glimmer/component';
import himalaya from 'babel/utils/himalaya';

const SYMBOL_VALUE_TO_CHAR_CODE_MAPPING = {
  nbsp: 160,
  narrownbsp: 8239,
  thinsp: 8201,
  shy: 173,
};

function transformSymbols(str) {
  const div = document.createElement('div');
  div.innerHTML = str;

  div.querySelectorAll('[data-type="symbol"]').forEach((element) => {
    element.parentNode.replaceChild(
      document.createTextNode(
        String.fromCharCode(
          SYMBOL_VALUE_TO_CHAR_CODE_MAPPING[element.dataset.value]
        )
      ),
      element
    );
  });

  return div.innerHTML;
}

export default class TbRenderInlineComponent extends Component {
  /**
   * Arguments:
   * value (string)
   * nodes (array)
   * stripTags (bool)
   */

  get nodes() {
    if (this.args.nodes) return this.args.nodes;

    let value = this.args.value;

    if (isPresent(value)) {
      if (typeOf(value) === 'string') {
        value = transformSymbols(value);
        value = himalaya.parse(value);
      }

      return value;
    }

    return null;
  }
}
