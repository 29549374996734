import { SpeakerAdapterAudioSource } from 'speaker';

/**
 *
 */
class DigilarAudioSourceHandler {
  constructor(params) {
    // Destruct params to class variables
    ({
      prioritizeSingleRecordedSpeechAudioSource:
        this._prioritizeSingleRecordedSpeechAudioSource,
      recordedSpeechProviderPriority: this._recordedSpeechProviderPriority,
    } = params);
  }

  /**
   * Private helper method to compare recorded speech provider priority
   * @param {*} providerIdToCheck
   * @param {*} providerIdToCompareTo
   */
  _hasHigherPriorityThan(providerIdToCheck, providerIdToCompareTo) {
    if (!this._recordedSpeechProviderPriority) {
      // Should not happen
      return false;
    }

    // Find out which of the specified providers has the highest prio (i.e which comes first in the prio array)
    const highestPrioProviderId = this._recordedSpeechProviderPriority.find(
      (providerId) =>
        [providerIdToCheck, providerIdToCompareTo].includes(providerId)
    );

    if (!highestPrioProviderId) {
      // None of the providers were found
      return false;
    }

    return highestPrioProviderId === providerIdToCheck;
  }

  /**
   * Customize the audio source configs if necessary to be able to handle special cases
   * @param {*} audioSourceConfigs
   * @param {*} preferRecordedSpeech
   * @param {*} recordedSpeechProviderPlugins An object indexed by provider ID, containing the currently initialized instances of each recorded speech provider plugin
   * @return The customized audio sources (object with keys 'all', 'tts', 'recordedSpeech', each containing an array of SpeakerAdapterAudioSource objects), or just audioSourceConfigs if no customization is needed.
   */
  customizeAudioSources(
    audioSourceConfigs,
    preferRecordedSpeech,
    recordedSpeechProviderPlugins = {}
  ) {
    // console.debug('customizeAudioSources:', audioSourceConfigs);

    let { all, recordedSpeech, tts } = audioSourceConfigs;
    let customizedAudioSourceConfigs = { ...audioSourceConfigs };

    // Handle the special case where we have a single audio file that contains all recorded speech.
    // Only relevant when using Digilär's recorded speech plugin
    const digilarRecordedSpeechPlugin =
      recordedSpeechProviderPlugins['digilar'];

    if (digilarRecordedSpeechPlugin) {
      // The single recorded speech audio file should be used instead of normal recorded speech (per component) or TTS if:...
      let useSingleRecordedSpeechAudioSource =
        // ...The single recorded speech file exists
        digilarRecordedSpeechPlugin.hasValidSingleRecordedSpeechFile() &&
        // ... AND it is prioritized over component-level recorded speech OR if there is at least one component-level audio source that's missing recorded speech...
        (this._prioritizeSingleRecordedSpeechAudioSource ||
          recordedSpeech.some((audioSource) => audioSource === null)) &&
        // ...AND if recorded speech is preferred or if there are no valid TTS sources.
        (preferRecordedSpeech ||
          tts.every((audioSource) => audioSource === null)) &&
        // ...AND finally if there are no recorded speech sources already configured by a provider of HIGHER priority
        recordedSpeech.every((audioSource) => {
          return (
            audioSource === null ||
            audioSource.recordedSpeechConfig().recordedSpeechProviderId ===
              'digilar' ||
            this._hasHigherPriorityThan(
              'digilar',
              audioSource.recordedSpeechConfig().recordedSpeechProviderId
            )
          );
        });

      if (useSingleRecordedSpeechAudioSource) {
        // Attach some additional data to the audio source that we want received with the speaker events (in event.data)
        let extraEventData = {
          singleRecordedSpeechFile: true,
        };
        let singleRecordedSpeechAudioSource = new SpeakerAdapterAudioSource(
          digilarRecordedSpeechPlugin.getSingleRecordedSpeechConfig(),
          null,
          null,
          extraEventData
        );

        // This will initialize a single audio source for recorded speech
        // Note that we also return a single TTS audio source as null, to have the same number of audio sources for TTS and recorded speech.
        customizedAudioSourceConfigs = {
          all: [singleRecordedSpeechAudioSource],
          recordedSpeech: [singleRecordedSpeechAudioSource],
          tts: [null],
        };
      } else {
        if (preferRecordedSpeech) {
          // If recorded speech is preferred, we exclude TTS audio sources that are not allowed to be used as fallback.
          // The disableFallback property is set in: DigilarDomHelper.getTextBlockTtsData()
          for (
            let i = 0;
            i < customizedAudioSourceConfigs.recordedSpeech.length;
            i++
          ) {
            const rsConfig = customizedAudioSourceConfigs.recordedSpeech[i]
              ? customizedAudioSourceConfigs.recordedSpeech[
                  i
                ].recordedSpeechConfig()
              : null;

            // First make sure that there is an audio source for recorded speech configured
            // Disabling fallback to TTS is right now limited to Digilar's own recorded speech
            if (rsConfig && rsConfig.recordedSpeechProviderId === 'digilar') {
              const ttsConfig = customizedAudioSourceConfigs.tts[i]
                ? customizedAudioSourceConfigs.tts[i].textToSpeechConfig()
                : null;
              if (
                ttsConfig &&
                ttsConfig.textToSpeechData &&
                ttsConfig.textToSpeechData.disableFallback
              ) {
                // Exclude TTS source is excluded if not allowed as fallback
                customizedAudioSourceConfigs.tts[i] = null;
                // console.debug(`Removing TTS source for index ${i} because recorded speech exists and is preferred, and disableFallback=true`);
              }
            }
          }
          // console.debug(`Customized audio source configs = `,customizedAudioSourceConfigs);
        }
      }
    }

    return customizedAudioSourceConfigs;
  }
}

export default DigilarAudioSourceHandler;
