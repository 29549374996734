import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MasterCoursesShowMissionsRoute extends Route {
  @service store;
  @service router;
  @service session;
  @service pusher;

  queryParams = {
    page: {
      refreshModel: true,
    },
    sortBy: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    scope: {
      refreshModel: true,
    },
  };

  async beforeModel() {
    super.beforeModel(...arguments);

    if (!this.session.user?.isTeacher) {
      return this.router.transitionTo('master.courses.show');
    }
  }

  async model({ scope, sortBy, sortDirection, page }) {
    const courses = this.modelFor('master.courses');
    const course = this.modelFor('master.courses.show');

    const [missions, teacherAssignments, wordlists, setting] =
      await Promise.all([
        this.store.query('mission', {
          filter: {
            scope,
            course_id: course.id,
          },
          order: [
            {
              field: sortBy,
              direction: sortDirection.toUpperCase(),
            },
          ],
          page: {
            size: 20,
            number: page,
          },
        }),
        courses.teacherAssignments,
        courses.wordlists,
        course.setting,
      ]);

    return {
      missions,
      teacherAssignments,
      wordlists,
      course,
      setting,
      query: {
        scope,
        sortBy,
        sortDirection,
        page,
      },
    };
  }

  setupController() {
    super.setupController(...arguments);

    this.pusher.on('didReceiveMission', this.handleDidReceiveMission);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.pusher.off('didReceiveMission', this.handleDidReceiveMission);
      controller.resetQueryParams();
      controller.expandedMissions = [];
    }
  }

  @action
  handleDidReceiveMission() {
    this.refresh();
  }
}
