import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.footnote}}\n  <button\n    data-footnote\n    type=\"button\"\n    id={{this.id}}\n    class=\"tb-render-inline-element-extension-footnote\"\n    {{on \"mousedown\" this.handleMouseDown}}\n    {{on \"click\" this.handleClick}}\n  >\n    [{{this.index}}]\n  </button>\n{{/if}}\n", {"contents":"{{#if this.footnote}}\n  <button\n    data-footnote\n    type=\"button\"\n    id={{this.id}}\n    class=\"tb-render-inline-element-extension-footnote\"\n    {{on \"mousedown\" this.handleMouseDown}}\n    {{on \"click\" this.handleClick}}\n  >\n    [{{this.index}}]\n  </button>\n{{/if}}\n","moduleName":"babel/components/tb-render-inline/element/extension/footnote/index.hbs","parseOptions":{"srcName":"babel/components/tb-render-inline/element/extension/footnote/index.hbs"}});
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TbRenderInlineElementExtensionFootnoteComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */

  @service footnotes;

  // PROPERTIES

  get dataId() {
    return this.args.node?.attributes.findBy('key', 'data-id')?.value;
  }

  get id() {
    return ['footnote', this.dataId].join('-');
  }

  get footnote() {
    return this.footnotes.getFootnote(this.dataId);
  }

  get index() {
    return this.footnotes.getIndex(this.dataId);
  }

  // ACTIONS

  @action handleMouseDown(evt) {
    evt.preventDefault();
  }

  @action handleClick() {
    const element = document.getElementById(this.dataId);

    if (element) {
      later(() => element.focus());
    }
  }
}
