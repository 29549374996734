import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CollectionListItemStandardComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * currentExercise (object)
   * answers (array)
   * onOpenExercise (function)
   * startExpanded (bool)
   * onOpenCollection (function)
   * resetCollection (function)
   */

  @service contextHelper;

  @tracked expanded = this.args.startExpanded ? true : false;

  baseClass = 'collection-list-item';

  get linked() {
    if (this.args.onOpenCollection && this.args.currentExercise) {
      return true;
    }

    return false;
  }

  get classNames() {
    const classes = [this.baseClass];

    classes.push(`${this.baseClass}-standard`);

    return classes.join(' ');
  }

  @action
  openExercise(index) {
    const exercise = this.args.exercises.objectAt(index - 1);
    if (exercise) return this.args.onOpenExercise?.(exercise);
  }

  @action
  handleOpenCollection(e) {
    e?.preventDefault();
    e?.stopPropagation();

    return this.args.onOpenCollection?.(
      this.args.collection,
      this.args.currentExercise
    );
  }
}
