import { action } from '@ember/object';
import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderImageComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  @tracked isMaterialViewerOpen = false;

  @tracked element;

  @tracked fullscreen;

  @tracked backgroundColor;

  @tracked imageWidth = 0;

  baseClass = 'tb-render-image';

  asyncType = 'material';

  get size() {
    return this.args.part?.settings?.size || '';
  }

  get extraClasses() {
    const classes = [];

    classes.push([this.baseClass, dasherize(this.size)].join('--'));

    if (this.isMaterialViewerOpen) {
      classes.push(`${this.baseClass}--image-viewer-open`);
    }

    if (this.material?.noShadow) {
      classes.push(`${this.baseClass}--no-shadow`);
    }

    if (this.fullscreen) {
      classes.push(`${this.baseClass}--fullscreen-active`);
    }

    if (this.behaviour !== 'enlarge') {
      classes.push(`${this.baseClass}--no-enlarge`);
    }

    if (this.isNarrow) {
      classes.push(`${this.baseClass}--narrow`);
    }

    return classes;
  }

  get isNarrow() {
    return this.imageWidth < 240;
  }

  get asyncId() {
    return this.args.part?.media_id;
  }

  get material() {
    return this.asyncData;
  }

  get alt() {
    return this.material?.metadata?.alt_text || this.material?.name || '';
  }

  get preferredSize() {
    return this.args.part?.settings?.preferred_size;
  }

  get behaviour() {
    return this.args.part?.settings?.behaviour
      ? this.args.part?.settings?.behaviour
      : 'enlarge';
  }

  get preferredSizeStyle() {
    if (!this.preferredSize) return null;
    return htmlSafe(`--preferred-size: ${this.preferredSize}px`);
  }

  get fullscreenIcon() {
    return this.fullscreen ? 'simple-remove' : 'fullscreen';
  }

  get isFullscreen() {
    return document.fullscreenElement || document.webkitFullscreenElement
      ? true
      : false;
  }

  get imageBackground() {
    return this.backgroundColor
      ? this.backgroundColor
      : this.material?.imageColor;
  }

  @action
  handleImageClick() {
    if (this.behaviour === 'enlarge') {
      this.isMaterialViewerOpen = true;
    }
  }

  @action
  handleFullscreenClick() {
    if (this.behaviour === 'fullscreen') {
      if (!this.isFullscreen) {
        this.element.requestFullscreen
          ? this.element.requestFullscreen()
          : this.element.webkitRequestFullscreen?.();
      } else {
        document.exitFullscreen
          ? document.exitFullscreen()
          : document.webkitExitFullscreen?.();
      }
    }
  }

  @action
  setElement(elem) {
    elem.addEventListener('fullscreenchange', this.toggleFullscreen);
    elem.addEventListener('webkitfullscreenchange', this.toggleFullscreen);
    this.element = elem;
  }

  @action
  toggleFullscreen() {
    this.fullscreen = this.isFullscreen;
  }

  @action
  handleImageFinally() {
    this.backgroundColor = '#000';
  }

  @action
  handleImageResize({ width }) {
    this.imageWidth = width;
  }
}
