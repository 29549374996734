import Component from '@ember/component';
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import shuffle from 'compton/utils/array-shuffle';

const STRIP_HTML_RX = /<[^>]*>?/gm;
const STRIP_PARENTHESES = / *\([^)]*\) */g;

const SYMBOLS = Object.freeze([
  {
    value: 'nbsp',
    entity: '&nbsp;',
    replacement: ' ',
    charCode: 160
  },
  {
    value: 'narrownbsp',
    entity: '&#8239;',
    replacement: ' ',
    charCode: 8239
  },
  {
    value: 'thinsp',
    entity: '&thinsp;',
    replacement: ' ',
    charCode: 8201
  },
  {
    value: 'shy',
    entity: '&shy;',
    replacement: '',
    charCode: 173
  }
]);

function fragmentFromString(strHTML) {
  return document.createRange().createContextualFragment(strHTML);
}

function fragmentToString(fragment) {
  const div = document.createElement('div');
  div.appendChild(fragment);
  return div.innerHTML;
}

function stripHtml(str) {
  if (str) {
    return str.replace(STRIP_HTML_RX, '');
  }
  return str;
}

function stripParentheses(str) {
  if (str) {
    return str.replace(STRIP_PARENTHESES, '');
  }
  return str;
}

function replaceInvisibleCharacters(str) {
  SYMBOLS.forEach((symbol) => {
    str = str.replaceAll(symbol.entity, symbol.replacement);
    str = str.replaceAll(
      new RegExp(String.fromCharCode(symbol.charCode), 'g'),
      symbol.replacement
    );
  });

  return str;
}

function replaceSymbols(str) {
  const fragment = fragmentFromString(str);

  Array.from(fragment.querySelectorAll('[data-type="symbol"]')).forEach(
    (element) => {
      const symbol = SYMBOLS.findBy('value', element.dataset.value);
      if (symbol) {
        element.replaceWith(document.createTextNode(symbol.replacement));
      }
    }
  );

  return fragmentToString(fragment);
}

export default Component.extend({
  showResponse: service(),

  userAgent: service(),

  languages: service(),

  // SETUP

  tagName: '',

  // PUBLIC

  title: null,

  words: null,

  type: null,

  onClose() {},

  lang: null,

  // PRIVATE

  currentWordIndex: 0,

  totalWords: null,

  answerWithWord: false,

  totalCorrectAnswers: 0,

  wrongAnswer: false,

  shuffledWords: null,

  currentWord: null,

  canContinue: false,

  progress: 0,

  done: false,

  answer: null,

  hasStarted: false,

  // COMPUTED

  translationLanguage: computed(
    'lang', // Specific language for translation, probably passed in from wordlist's language...
    'languages.contentLanguage',
    function() {
      const lang = this.get('lang') || this.get('languages.contentLanguage');
      return lang;
    }
  ),

  trainOnTranslations: computed('type', function() {
    return this.get('type') === 'glossaries';
  }),

  correctAnswers: computed(
    'currentWord.{word,translation}',
    'trainOnTranslations',
    'answerWithWord',
    function() {
      if (this.get('trainOnTranslations')) {
        if (this.get('answerWithWord')) {
          let word = this._stripWord(this.get('currentWord.word'));
          return word.split(',');
        } else {
          let translation = this._stripWord(
            this.get('currentWord.translation')
          );
          return translation.split(',');
        }
      } else {
        let word = this._stripWord(this.get('currentWord.word'));
        return word.split(',');
      }
    }
  ),

  noShuffledWords: computed('shuffledWords.[]', 'words.isPending', function() {
    if (
      this.get('shuffledWords.length') === 0 &&
      !this.get('words.isPending')
    ) {
      return true;
    }

    return false;
  }),

  inputFocus: computed('userAgent.device.isDesktop', 'canContinue', function() {
    if (this.get('canContinue')) {
      return false;
    } else if (this.get('userAgent.device.isDesktop')) {
      return true;
    } else {
      return false;
    }
  }),

  percentCorrect: computed('totalCorrectAnswers', function() {
    return (this.get('totalCorrectAnswers') / this.get('totalWords')) * 100;
  }),

  percentDone: computed('progress', function() {
    return (this.get('progress') / this.get('totalWords')) * 100;
  }),

  filteredWords: computed('type', 'words.[]', function() {
    const words = this.get('words');
    const type = this.get('type');

    if (type === 'glossaries') {
      return words.filterBy('translation');
    } else if (type === 'concepts') {
      return words.filterBy('explanation');
    }

    return [];
  }),

  totalWords: computed('filteredWords.[]', function() {
    return this.get('filteredWords.length');
  }),

  // INIT

  didReceiveAttrs() {
    this._super(...arguments);
    this._initQuiz();
  },

  _initQuiz() {
    this.set('shuffledWords', shuffle(this.get('filteredWords').toArray()));
    this.set('currentWord', this.get('shuffledWords.firstObject'));
    this._setFocus();
  },

  // PRIVATE

  _reset() {
    this._initQuiz();

    this.set('currentWordIndex', 0);
    this.set('totalCorrectAnswers', 0);
    this.set('progress', 0);
    this.set('done', false);
  },

  _nextWord() {
    this.incrementProperty('currentWordIndex');

    this.set(
      'currentWord',
      this.get('shuffledWords').objectAt(this.get('currentWordIndex'))
    );

    if (this.get('currentWordIndex') === this.get('totalWords')) {
      this.set('done', true);
    }

    this.set('answer', '');
    this.set('canContinue', false);
    this.set('wrongAnswer', false);

    this._setFocus();
  },

  _setFocus() {
    later(() => {
      const input = document.querySelector(
        '.word-hearing--word-input-focus'
      );

      if (input) {
        input.focus();
      }
    }, 200);
  },

  _normalizeString(string) {
    return string.toLowerCase().trim();
  },

  _answer() {
    let correct = false;

    this.get('correctAnswers').forEach((answer) => {
      if (
        this._normalizeString(answer) ===
        this._normalizeString(this.get('answer'))
      ) {
        correct = true;
      }
    });

    if (correct) {
      this.incrementProperty('totalCorrectAnswers');
      this.showResponse.handleResponse('show-response-quiz', 'correct');
    } else {
      // WRONG
      this.set('wrongAnswer', true);
    }

    this.incrementProperty('progress');
    this.set('canContinue', true);
    this.set('hasStarted', true);
  },

  _stripWord(str) {
    str = replaceInvisibleCharacters(str);
    str = replaceSymbols(str);
    str = stripHtml(str);
    str = stripParentheses(str);
    return str;
  },

  actions: {
    answer() {
      this._answer();
    },
    nextWord() {
      this._nextWord();
    },
    enter() {
      if (this.get('canContinue')) {
        this._nextWord();
      } else {
        this._answer();
      }
    },
    restart() {
      this._reset();
    },
    close() {
      this.get('onClose')();
    },
    switchType() {
      if (!this.get('canContinue')) {
        this.set('answer', '');
        this._initQuiz();
        this.toggleProperty('answerWithWord');
      }
    }
  }
});
