import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { redirectToNokportal } from 'babel/utils/redirect';
import { all } from 'rsvp';

export default class MasterIndexRoute extends Route {
  @service contextHelper;

  @service intl;

  @service session;

  @service store;

  beforeModel(transistion) {
    redirectToNokportal(transistion);
  }

  async model() {
    const user = this.session.user;

    if (!user) {
      return this.session.singleLogout();
    }

    const [licenses, courses] = await all([user.licenses, user.courses]);
    let products = await all(licenses.map((license) => license.product));

    products = products.filter((product) => product);

    await all(
      products.map((product) => {
        let bookPromises = [];

        const bookIds = product.hasMany('books').ids();

        if (bookIds) {
          bookPromises = bookIds.map((bookId) =>
            this.store.findRecord('entity', bookId).catch(() => {})
          );
        }

        return all([product.image, product.cover, all(bookPromises)]);
      })
    );

    return { courses, products };
  }

  setupController() {
    super.setupController(...arguments);
    this.contextHelper.setActive(
      'pageTitle',
      this.intl.t('services.contextHelper.pageTitle.homescreen')
    );
  }

  resetController() {
    super.resetController(...arguments);
    this.contextHelper.setActive('pageTitle', null);
  }
}
