import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const BODY_CLASS_NAME = 'workspace';

export default class ContentWorkspaceAsideComponent extends Component {
  // SETUP

  @service router;

  constructor() {
    super(...arguments);
    document.body.classList.add(BODY_CLASS_NAME);
    this.router.on('routeDidChange', this.routerRouteDidChange);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    document.body.classList.remove(BODY_CLASS_NAME);
    this.router.off('routeDidChange', this.routerRouteDidChange);
  }

  // PROPERTIES

  @tracked element;

  // ACTIONS

  @action setElement(elem) {
    this.element = elem;
  }

  @action routerRouteDidChange(transition) {
    if (transition.from && transition.from.name) {
      const { from, to } = transition;

      if (from.name !== to.name) {
        const fromSegments = from.name.split('.').slice(0, 3);
        const toSegments = to.name.split('.').slice(0, 3);

        if (fromSegments.join('.') === toSegments.join('.')) {
          this.element?.scrollTo({ top: 0 });
        }
      }
    }
  }
}
