import Component from '@ember/component';

export default Component.extend({
  // EXTERNAL

  product: null,

  continueVisited: null,

  // COMPUTED

  // HOOKS

  didReceiveAttrs() {
    this._super(...arguments);
  }
});
