import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderTableComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-table';

  get settings() {
    return this.args.part?.settings;
  }

  get cells() {
    return (this.args.part?.rows || []).map((row) => {
      return (row.columns || []).map((column) => {
        if (
          (row.index === 0 && this.settings?.horizontal_heading) ||
          (column.index === 0 && this.settings?.vertical_heading)
        ) {
          column.isHeading = true;
        }

        return column;
      });
    });
  }

  get caption() {
    return this.args.part?.caption;
  }

  get tableAppearance() {
    return this.args.part.settings.appearance
      ? this.args.part.settings.appearance
      : 'standard';
  }
}
