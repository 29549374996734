import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import generateId from 'compton/utils/generate-id';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class CollectionOptionsComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * title (string)
   * hasIndex (boolean)
   * resetCollection (function)
   */

  @service session;

  @service contextHelper;

  @service confirmManager;

  @service intl;

  @service assignmentEvents;

  @service missionMode;

  @tracked popoverOpen = false;

  @tracked shareDialogOpen = false;

  @tracked previewModalOpen = false;

  @tracked showKey = true;

  get hasOptions() {
    if (this.session.user?.isTeacher) return true;
    return false;
  }

  get isClickable() {
    if (this.args.hasIndex) return true;
    if (this.hasOptions) return true;
    return false;
  }

  get buttonId() {
    return generateId();
  }

  get link() {
    const host = window.location.origin;
    const collection = this.args.collection;

    if (!collection.isTeacherCollection) {
      return `${host}/nodes/${collection.id}`;
    }

    let url = `${host}/nodes/${collection.children.firstObject.id}`;

    if (this.contextHelper.inContent) {
      url += `?content=${this.contextHelper.activeContent.id}`;
    }

    return url;
  }

  @cached
  get exercisesProxy() {
    const promise = resolve(this.args.collection?.children)
      .then((children) => this.missionMode.allowedEntities(children))

      .then((exercises) => {
        if (this.args.collection?.isTeacherCollection) {
          exercises = exercises.sortBy('title');
        }

        return exercises;
      });

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : [];
  }

  @cached
  get answersProxy() {
    const promise = resolve(
      this.assignmentEvents.getAnswersForExercises(this.exercises, false)
    );

    return load(promise, this);
  }

  get answers() {
    return this.answersProxy.isResolved ? this.answersProxy.value : null;
  }

  get notStarted() {
    if (!this.answers) return true;

    return this.answers?.every((answer) => {
      return answer.status === 'not-started';
    });
  }

  get showAuditKey() {
    const showAuditKey = this.exercises.any((exercise) => {
      if (exercise.body?.exercise?.assignments?.firstObject?.settings?.solution)
        return true;
      if (['assignments', 'studies'].includes(exercise?.type)) {
        return false;
      }
      return true;
    });

    return showAuditKey;
  }

  @action
  handleClick(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    this.popoverOpen = true;
  }

  @action
  handleClose() {
    this.popoverOpen = false;
  }

  @action
  openShareDialog() {
    this.shareDialogOpen = true;
    this.popoverOpen = false;
  }

  @action
  closeShareDialog() {
    this.shareDialogOpen = false;
  }

  @action
  resetAnswers() {
    this.popoverOpen = false;

    return this.confirmManager
      .confirm(
        this.intl.t('components.collectionOptions.reset', {
          collection: this.args.collection.title,
        })
      )
      .then(() => this._clearAnswers())
      .catch(() => {});
  }

  async _clearAnswers() {
    const collection = this.args.collection;

    // make sure children is resolved so this.exercises has the correct value
    await collection.children;

    const answers = await this.assignmentEvents.getAnswersForExercises(
      this.exercises,
      false
    );

    const { interactive } =
      await this.assignmentEvents.getOrCreateInteractiveForCollection(
        collection
      );

    await all(
      answers.map(async (answer) => {
        let teacherAssignment, exercise;

        if (answer.type === 'teacher_assignment') {
          // For teacher assignments we need to locate the correct generated
          // entity for the teacher assignment and use that as exercise when
          // reseting the answer.
          teacherAssignment = await answer.teacherAssignment;
          exercise = this.exercises.find(
            (exercise) =>
              exercise.belongsTo('teacherAssignment').id() ===
              teacherAssignment.id
          );
        } else {
          exercise = await answer.entity;
        }

        await this.assignmentEvents.resetAnswer(answer, exercise);
      })
    );

    // Reset interactive to initial state
    interactive.currentExercise = this.exercises[0];
    interactive.ended = null;
    interactive.correctExercises = [];

    if (!interactive.isSaving && !collection.isTeacherCollection) {
      // interactives cannot be saved for teacher collections since
      // there is no actual collection entity to connect them to.
      interactive.save();
    }

    this.args.resetCollection?.();
  }

  @action
  openPreviewModal() {
    this.previewModalOpen = true;
    this.popoverOpen = false;
  }

  @action
  closePreviewModal() {
    this.previewModalOpen = false;
  }
}
