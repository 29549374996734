import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class={{this.classes}}>\n  <a href=\"#\" class=\"student-list-item-link\" {{on \"click\" this.handleClick}}>\n    {{#if (and @showStatus this.status)}}\n      <AnswerStatusIcon @status={{this.status}} class=\"nr1\" />\n    {{/if}}\n\n    <span class=\"hyphens word-break-all\">\n      {{@student.showname}}\n    </span>\n\n    {{#if @showNumAnswers}}\n      {{#let (generate-id \"num-answers-badge\") as |id|}}\n        <XBadge\n          @id={{id}}\n          class=\"num-answers-badge nl1\"\n          @variant=\"flat\"\n          @size=\"small\"\n        >\n          {{this.numAnswers}}\n        </XBadge>\n\n        <XTooltip @for={{id}}>\n          {{t \"components.courseAudit.studentList.numAnswers\"}}\n        </XTooltip>\n      {{/let}}\n    {{/if}}\n  </a>\n</li>", {"contents":"<li class={{this.classes}}>\n  <a href=\"#\" class=\"student-list-item-link\" {{on \"click\" this.handleClick}}>\n    {{#if (and @showStatus this.status)}}\n      <AnswerStatusIcon @status={{this.status}} class=\"nr1\" />\n    {{/if}}\n\n    <span class=\"hyphens word-break-all\">\n      {{@student.showname}}\n    </span>\n\n    {{#if @showNumAnswers}}\n      {{#let (generate-id \"num-answers-badge\") as |id|}}\n        <XBadge\n          @id={{id}}\n          class=\"num-answers-badge nl1\"\n          @variant=\"flat\"\n          @size=\"small\"\n        >\n          {{this.numAnswers}}\n        </XBadge>\n\n        <XTooltip @for={{id}}>\n          {{t \"components.courseAudit.studentList.numAnswers\"}}\n        </XTooltip>\n      {{/let}}\n    {{/if}}\n  </a>\n</li>","moduleName":"babel/components/course-audit/student-list/item/index.hbs","parseOptions":{"srcName":"babel/components/course-audit/student-list/item/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { getLatestVersion } from 'babel/utils/get-answer';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class CourseAuditStudentListItemComponent extends Component {
  /**
   * Arguments:
   * student (object)
   * isActive (bool)
   * showStatus (bool)
   * showNumAnswers (bool)
   * exercise (object)
   * answers (array)
   * goTo (function)
   */

  @cached get studentAnswersProxy() {
    const studentId = this.args.student?.id;

    const promise = resolve(this.args.answers)
      .then((answers) =>
        all(
          answers.map((answer) =>
            resolve(answer.user).then((user) =>
              user.id === studentId ? answer : null
            )
          )
        )
      )
      .then((answers) => answers.filter((x) => x));

    return load(promise, this);
  }

  get studentAnswers() {
    return this.studentAnswersProxy.isResolved
      ? this.studentAnswersProxy.value
      : null;
  }

  @cached get answer() {
    const studentAnswers = this.studentAnswers;
    const answer = getLatestVersion(this.args.exercise, studentAnswers);

    return answer;
  }

  @cached get numAnswers() {
    const studentAnswers = this.studentAnswers;

    if (studentAnswers) {
      return studentAnswers
        .filter((x) => x.status !== 'not-started')
        .map((x) =>
          x.node_id ? x.node_id : x.belongsTo('teacherAssignment').id()
        )
        .filter((val, idx, self) => self.indexOf(val) === idx).length;
    }

    return 0;
  }

  get classes() {
    const classes = ['student-list-item'];

    if (this.args.isActive) {
      classes.push('student-list-item--active');
    }

    return classes.join(' ');
  }

  get status() {
    return this.answer?.computedStatus || 'not-started';
  }

  @action handleClick(evt) {
    evt.preventDefault();
    this.args.goTo?.(this.args.student);
  }
}
