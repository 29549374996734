import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { storageFor } from 'ember-local-storage';
import { resolve } from 'rsvp';

export default class ContentSheetComponent extends Component {
  /**
   * Arguments:
   * activeSection
   * content
   * sections
   * speakerContentInfo
   * answers
   * exercises
   * activeExercise
   * activeAnswer
   * settings
   * video
   * collections
   * wordlists
   * activeCategory
   * restrictedCollections
   * restrictedSections
   * previousSection
   * nextSection
   * routeToSection
   */

  @service router;

  @service contextHelper;

  @service missionMode;

  @service store;

  @storageFor('read-setting') readSettings;

  get hasNextSection() {
    return !(
      this.args.sections?.indexOf(this.args.activeSection) >=
      this.args.sections?.length - 1
    );
  }

  @cached get customContentProxy() {
    return load(
      resolve(this.contextHelper.activeMission?.sortedCustomContents),
      this
    );
  }

  get customContent() {
    return this.customContentProxy.isResolved
      ? this.customContentProxy.value
      : null;
  }

  get currentCustomContent() {
    return this.customContent?.find((c) => {
      return c.entity?.get('id') === this.args.content?.id;
    });
  }

  get nextMissionContent() {
    if (!this.customContent) return null;
    const index = this.customContent?.indexOf(this.currentCustomContent);
    return index >= 0 && this.customContent[index + 1];
  }

  get isWorkspaceOpen() {
    const currentRouteName = this.router.currentRouteName;

    return (
      currentRouteName.includes('master.contents.assignments') ||
      currentRouteName.includes('master.contents.wordlists') ||
      currentRouteName.includes('master.contents.notes')
    );
  }

  get hasPreviousSection() {
    return this.args.sections?.indexOf(this.args.activeSection) > 0;
  }

  get showDoneButton() {
    const mode = this.readSettings.get('mode');
    let isOnLast = !this.hasNextSection;
    // Of course we need exception for chapter scroll
    if (mode === 'scroll' && this.args.content?.template === 'chapter') {
      isOnLast = true;
    }
    return (this.hasAdditionalContent && isOnLast) || this.hasMissionNextButton;
  }

  get hasMissionNextButton() {
    return (
      this.contextHelper.activeMission &&
      !this.hasNextSection &&
      this.nextMissionContent
    );
  }

  get hasAdditionalContent() {
    return this.args.collections?.length || this.args.wordlists?.length;
  }

  get editor() {
    return this.args.activeSection?.contenteditor?.content;
  }

  @action openExercise(exercise) {
    return this.router.replaceWith({ queryParams: { exercise } });
  }
}
