import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { hash, resolve } from 'rsvp';

export default Component.extend({
  // SETUP

  tagName: '',

  // PARAMS

  entity: null,

  books: null,

  multiple: false,

  expandedEntities: null,

  importableTypes: null,

  controlled: false,

  onExpand() {},

  onCollapse() {},

  onClose: null,

  onImport: null,

  onShouldAllowPick() {
    return true;
  },

  // ACTIONS

  actions: {
    handleLoad() {
      return Promise.all([this.disabledEntities, this.books]).then(
        ([disabledEntities]) => disabledEntities
      );
    },

    handleEntityBrowserExpand(entity) {
      this._expandEntity(entity);
      return this.loadEntityRelationships.perform(entity);
    },

    handleEntityBrowserCollapse(entity) {
      this._collapseEntity(entity);
    },

    handleEntityBrowserPick(entity) {
      this._pickEntity(entity);
    },

    handleEntityBrowserUnpick(entity) {
      this._unpickEntity(entity);
    },

    handleImportButtonClick() {
      return this._import().then(() => {
        this._close();
      });
    },
  },

  // HOOKS

  init() {
    this._super(...arguments);
    this.set('pickedEntities', []);
  },

  // TASKS

  loadEntityRelationships: task(function* (entity) {
    yield hash(entity.getProperties('children', 'categories'));
  }),

  // PRIVATE

  _expandEntity(entity) {
    this.onExpand(entity);
  },

  _collapseEntity(entity) {
    this.onCollapse(entity);
  },

  _pickEntity(entity) {
    if (this.multiple) {
      this.pickedEntities.addObject(entity);
    } else {
      this.set('pickedEntity', entity);
    }
  },

  _unpickEntity(entity) {
    if (this.multiple) {
      this.pickedEntities.removeObject(entity);
    } else {
      this.set('pickedEntity', null);
    }
  },

  _close() {
    this.onClose();
  },

  _import() {
    const onImport = this.onImport;

    if (this.multiple) {
      return resolve(onImport(this.pickedEntities));
    }
    return resolve(onImport(this.pickedEntity));
  },

  _loadRelationships(entity) {
    return hash(entity.getProperties('children', 'categories'));
  },
});
