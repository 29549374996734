import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';
import { getOriginalAnswer } from 'babel/utils/get-answer';

export default class TbRenderAssignmentLinkComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  @service router;

  @service store;

  @service contextHelper;

  @service missionMode;

  baseClass = 'tb-render-assignment-link';

  asyncType = 'entity';

  get asyncId() {
    return this.args.part?.assignment_link_id;
  }

  get assignment() {
    return this.asyncData;
  }

  get assignmentHasPossibleAnswer() {
    return !['glossaries'].includes(this.assignment?.type);
  }

  get isExcluded() {
    if (this.contextHelper.inMission) {
      const includedEntities = this.missionMode.includedEntities;

      if (includedEntities.length === 0) {
        return false;
      }

      return !includedEntities.mapBy('id').includes(this.asyncId);
    }

    return false;
  }

  get answer() {
    if (!this.assignment) return null;
    return getOriginalAnswer(this.assignment, this.store.peekAll('answer'));
  }

  get status() {
    return this.answer?.computedStatus || 'not-started';
  }

  get routeName() {
    switch (this.assignment?.type) {
      case 'assignments':
      case 'exercises':
      case 'studies':
        return 'master.contents.assignments.show';
      case 'glossaries':
        return 'master.contents.wordlists.show';
      default:
        return null;
    }
  }

  @action openLink() {
    if (this.routeName && this.assignment?.id) {
      this.router.transitionTo(this.routeName, this.assignment.id).then(() => {
        const elem = document.querySelector('.content-workspace-aside-content');

        if (elem) {
          elem.scrollTo(0, 0);
        }
      });
    }
  }
}
