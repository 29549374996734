import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"header-base-entity-breadcrumb-list-item\" ...attributes>\n  <EntityLink\n    class=\"header-base-entity-breadcrumb-item-entity-link\"\n    data-test-header-breadcrumb-link\n    @entity={{@entity}}\n    {{on \"click\" this.handleEntityLinkClick}}\n  >\n    {{@entity.title}}\n  </EntityLink>\n  {{#unless @last}}\n    <span class=\"header-base-entity-breadcrumb-item-separator\" role=\"none\" />\n  {{/unless}}\n</li>", {"contents":"<li class=\"header-base-entity-breadcrumb-list-item\" ...attributes>\n  <EntityLink\n    class=\"header-base-entity-breadcrumb-item-entity-link\"\n    data-test-header-breadcrumb-link\n    @entity={{@entity}}\n    {{on \"click\" this.handleEntityLinkClick}}\n  >\n    {{@entity.title}}\n  </EntityLink>\n  {{#unless @last}}\n    <span class=\"header-base-entity-breadcrumb-item-separator\" role=\"none\" />\n  {{/unless}}\n</li>","moduleName":"babel/components/header/base/entity-breadcrumb/item/index.hbs","parseOptions":{"srcName":"babel/components/header/base/entity-breadcrumb/item/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import scrollToTopIfNeeded from 'babel/utils/scroll-to-top-if-needed';

export default class HeaderBaseEntityBreadcrumbComponent extends Component {
  @service router;

  @action handleEntityLinkClick() {
    scrollToTopIfNeeded(this.router);
  }
}
