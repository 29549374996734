import StorageObject from 'ember-local-storage/local/object';

const Storage = StorageObject.extend();

Storage.reopenClass({
  initialState() {
    return {
      // Integer in a range defined by template c-content-playsettings. At the moment [0, 2]. Will be mapped to actual playback speed by speaker-context.
      speechSpeed: 1,
      highlighting: {
        words: true,
        sentences: true,
      },
      followText: true,
      // true will "override" pre-recorded speech as default audio source and always use a TTS provider such as Acapela
      preferSpeechSynthesis: false,
      // User's preferred TTS voices, will be indexed by language code
      voices: {},
    };
  },
});

export default Storage;
