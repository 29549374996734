import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (t \"components.header.base.teacherGuidanceButton.label\") as |label|}}\n  <Header::Base::Button\n    ...attributes\n    @tooltip={{unless (media \"md\") label}}\n    @active={{this.isActive}}\n    @wide={{media \"md\"}}\n    @onClick={{this.handleButtonClick}}\n    as |button|\n  >\n    <button.Icon @name=\"guidance\" />\n\n    {{#if (media \"md\")}}\n      <button.Label>\n        {{label}}\n      </button.Label>\n    {{/if}}\n\n  </Header::Base::Button>\n{{/let}}", {"contents":"{{#let (t \"components.header.base.teacherGuidanceButton.label\") as |label|}}\n  <Header::Base::Button\n    ...attributes\n    @tooltip={{unless (media \"md\") label}}\n    @active={{this.isActive}}\n    @wide={{media \"md\"}}\n    @onClick={{this.handleButtonClick}}\n    as |button|\n  >\n    <button.Icon @name=\"guidance\" />\n\n    {{#if (media \"md\")}}\n      <button.Label>\n        {{label}}\n      </button.Label>\n    {{/if}}\n\n  </Header::Base::Button>\n{{/let}}","moduleName":"babel/components/header/base/teacher-guidance-button/index.hbs","parseOptions":{"srcName":"babel/components/header/base/teacher-guidance-button/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class HeaderBaseTeacherGuidanceButtonComponent extends Component {
  @service session;
  @service entityInspector;

  get isActive() {
    return (
      this.args.activeEntity?.id ===
        this.entityInspector?.inspectedEntity?.id &&
      this.entityInspector.modalIsOpen &&
      this.entityInspector.modalSection === 'guidance'
    );
  }

  @action
  handleButtonClick() {
    if (this.isActive) {
      this.entityInspector.closeModal();
    } else {
      this.entityInspector.openModal(this.args.activeEntity, 'guidance');
    }
  }
}
