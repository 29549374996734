import Component from '@glimmer/component';

export default class TbRenderInlineElementExtensionComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */

  get type() {
    return this.args.node?.attributes?.findBy('key', 'data-type')?.value;
  }

  get componentName() {
    return this.type ? `tb-render-inline/element/extension/${this.type}` : null;
  }
}
