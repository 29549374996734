import Mixin from '@ember/object/mixin';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import config from 'babel/config/environment';
import { logOpenedProduct } from 'babel/utils/log-opened-product';
import { getURLFromRouteInfo } from 'babel/utils/routing';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import { singularize } from 'ember-inflector';
import { all, reject, resolve } from 'rsvp';

import getUserLicenseForEntity from '../utils/get-user-license-for-entity';

function getEntityId(routeName, params) {
  const idKey =
    routeName &&
    [singularize(routeName.split('.').get('lastObject')), 'id'].join('_');

  if (params && idKey) {
    return params[idKey];
  }

  return null;
}

function transitionIsWithinWorkspace(transition) {
  return transition.targetName
    .split('.')
    .any((part) => ['assignments', 'wordlists', 'notes'].includes(part));
}

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  // SETUP

  languages: service(),

  // headData: service(),

  // snackbar: service(),

  missionMode: service(),

  router: service(),

  session: service(),

  store: service(),

  ajax: service(),

  speakerSession: service(),

  // HOOKS

  model(params, transition) {
    const { routeName, store, session, speakerSession } = this.getProperties(
      'routeName',
      'store',
      'session',
      'speakerSession'
    );

    const entityId = getEntityId(routeName, params);

    this.set('entityId', entityId);

    if (!entityId) {
      // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
      return reject(new DS.AdapterError());
    }

    const record = store.peekRecord('entity', entityId);
    const adapterOptions = {
      include: ['ancestors', 'children'],
    };

    if (this.get('includeGrandchildren')) {
      adapterOptions.include.push('grandchildren');
    }

    if (this.get('includeCategories')) {
      adapterOptions.include.push('categories');
    }

    let entityPromise;

    if (record && record.hasMany('ancestors').value() === null) {
      // If a record is loaded, but not the necessary relations
      // we reloaded it with all required data included in one request
      entityPromise = record.reload({ adapterOptions });
    } else {
      entityPromise = store.findRecord('entity', entityId, { adapterOptions });
    }

    return all([
      entityPromise,
      store
        .findAll('teacher-assignment')
        .then((teacherAssignments) =>
          all(
            teacherAssignments.map((teacherAssignment) =>
              all([
                teacherAssignment.get('books'),
                teacherAssignment.get('contents'),
              ])
            )
          ).then(() => teacherAssignments)
        ),
      store
        .findAll('wordlist')
        .then((wordlists) =>
          all(
            wordlists.map((wordlist) =>
              all([wordlist.get('books'), wordlist.get('contents')])
            )
          ).then(() => wordlists)
        ),
      store
        .findAll('note')
        .then((notes) =>
          all(
            notes.map((note) => all([note.get('books'), note.get('contents')]))
          ).then(() => notes)
        ),
      speakerSession.getJwt(),
    ]).then(([model, teacherAssignments, wordlists, notes]) => {
      logOpenedProduct(model, this.ajax, this.session);

      return all([
        model.get('ancestors'),
        model.get('book'),
        model.get('children'),
      ]).then(([ancestors, book, children]) => {
        const promises = [
          ...ancestors.rejectBy('type', 'contents').getEach('children'),
        ];

        promises.addObjects(
          children
            .filterBy('type', 'glossaries')
            .invoke('transformToWordlist', model)
        );

        teacherAssignments
          .filter((teacherAssignment) => teacherAssignment.hasContent(model))
          .invoke('createAsEntity', model);

        if (
          model.get('type') === 'books' &&
          children &&
          children.length === 0
        ) {
          // Books might be in the store from getting products from the public API.
          // If the user's accessible books are out of sync with the licenses,
          // children will be empty. We can't distinguish this from the actual case
          // of an empty book and therefore can't allow access.

          promises.push(
            session.refreshUserLicenses().then(() => {
              return children.reload().then((reloaded) => {
                if (!reloaded || reloaded.length === 0) {
                  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
                  return reject(new DS.ForbiddenError());
                }
              });
            })
          );
        }

        if (model.type === 'contents') {
          const sections = children?.filterBy('type', 'sections') || [];

          sections.forEach((section) => {
            if (section?.body?.reading_id) {
              promises.push(
                this.store.findRecord('material', section.body.reading_id)
              );
            }
          });
        }

        let customContent, mission;

        if (params.missionId && params.customContentId) {
          promises.push(
            this.store
              .findRecord('mission', params.missionId)
              .then((_mission) => {
                mission = _mission;
              })
              .catch(() => {})
          );

          promises.push(
            this.store
              .findRecord('custom-content', params.customContentId)
              .then((_customContent) => {
                customContent = _customContent;

                return all([
                  customContent.includedEntities,
                  customContent.includedTeacherAssignments,
                  customContent.includedWordlists,
                ]);
              })
              .catch(() => {})
          );
        }

        return all(promises).then(() => {
          return all(children.getEach('contenteditor')).then(
            (contentEditors) => {
              return all(contentEditors.compact().getEach('materials')).then(
                () => {
                  return this.prepareMissionModeIfNeeded(
                    mission,
                    customContent
                  ).then(() => {
                    let closeUrl = transition?.to?.queryParams?.close_url;

                    if (
                      !closeUrl &&
                      (model.type === 'contents' ||
                        model.type === 'interactives') &&
                      transition?.from &&
                      transition?.from?.name !== 'nodes' &&
                      transition?.from?.name !== 'application_loading'
                    ) {
                      try {
                        closeUrl = getURLFromRouteInfo(
                          transition.from,
                          this.router
                        );
                      } catch (err) {
                        // do nothing
                      }
                    }

                    return {
                      model,
                      teacherAssignments,
                      wordlists,
                      children,
                      notes,
                      book,
                      bookEntity: book,
                      mission,
                      customContent,
                      closeUrl,
                      returnUrl: transition?.to?.queryParams?.return_url,
                    };
                  });
                }
              );
            }
          );
        });
      });
    });
  },

  redirect(model, transition) {
    const section = transition?.to?.queryParams?.section;

    if (!section && transitionIsWithinWorkspace(transition)) {
      const firstSection = model.children
        .filterBy('type', 'sections')
        .filter((section) => this.get('missionMode').entityIsAllowed(section))
        .get('firstObject');

      if (firstSection) {
        next(() => {
          this.controllerFor('master.contents').set(
            'activeSectionId',
            firstSection.get('id')
          );
        });
      }
    }
  },

  resetController(controller, isExiting) {
    this._super(...arguments);

    if (isExiting) {
      this.missionMode.deactivate();
    }
  },

  setupController(
    controller,
    {
      model,
      teacherAssignments,
      wordlists,
      notes,
      bookEntity,
      mission,
      customContent,
      closeUrl,
    }
  ) {
    this._super(...arguments);

    controller.setProperties({
      model,
      teacherAssignments,
      wordlists,
      notes,
      closeUrl,
    });

    // Set content language
    const language =
      model.get('body.language') || bookEntity.get('body.language');
    this.set('languages.contentLanguage', language);

    if (mission && customContent) {
      this.missionMode.activate({ mission, customContent, closeUrl });
    }
  },

  // ACTIONS

  actions: {
    error(err, transition) {
      const routeName = this.get('routeName');
      const route = transition.to.find((route) => route.name === routeName);
      const entityId = getEntityId(routeName, route.params);

      // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
      if (err instanceof DS.ForbiddenError) {
        return getUserLicenseForEntity(
          entityId,
          this.get('session'),
          this.get('ajax'),
          config.endpoint,
          config.userApiEndpoint,
          transition
        ).catch(() => {
          this.router.transitionTo('master.forbidden');
        });
        // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
      } else if (err instanceof DS.AdapterError) {
        this.router.transitionTo('master.error').then(() => {
          const controller = this.controllerFor('master.error');

          if (controller) {
            controller.set('abortedTransition', transition);
          }
        });
      } else {
        return true;
      }
    },
  },

  // PRIVATE

  async prepareMissionModeIfNeeded(mission, customContent) {
    if (mission && customContent) {
      let customContentEntity;

      try {
        customContentEntity = await resolve(customContent.entity);
        await resolve(customContentEntity.children);
      } catch (err) {
        // do nothing
      }
    }
  },
});
