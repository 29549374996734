import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  abortedTransition: null,

  connectionStatus: service(),

  intl: service(),

  message: computed('connectionStatus', function () {
    const online = this.get('connectionStatus.online');

    if (!online) {
      return this.get('intl').t('components.errorPage.noInternetConnection');
    } else {
      return this.get('intl').t('components.errorPage.other');
    }
  }),

  actions: {
    tryAgain() {
      const abortedTransition = this.get('abortedTransition');

      if (abortedTransition) {
        return abortedTransition.retry();
      }
    },
  },
});
