import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"grid horizon-loop-container\">\n  {{#each this.alternatives as |alternative|}}\n    <div class={{alternative.cssClass}} data-test-choice-horizontal-grid-item>\n      <ExerciseView::Assignment::Choice::Horizontal::item\n        @exerciseType={{@exerciseType}}\n        @alternative={{alternative}}\n        @onChange={{@onChange}}\n        @showKey={{@showKey}}\n        @disabled={{@disabled}}\n        @showValidationAndOption={{@showValidationAndOption}}\n        @assignmentAnswer={{@assignmentAnswer}}\n        @groupValue={{@groupValue}}\n        @isMultiple={{@isMultiple}}\n        @onEnter={{@onEnter}}\n        @name={{@name}}\n        @assignmentId={{@assignmentId}}\n        @settings={{@settings}}\n      />\n    </div>\n  {{/each}}\n</div>", {"contents":"<div class=\"grid horizon-loop-container\">\n  {{#each this.alternatives as |alternative|}}\n    <div class={{alternative.cssClass}} data-test-choice-horizontal-grid-item>\n      <ExerciseView::Assignment::Choice::Horizontal::item\n        @exerciseType={{@exerciseType}}\n        @alternative={{alternative}}\n        @onChange={{@onChange}}\n        @showKey={{@showKey}}\n        @disabled={{@disabled}}\n        @showValidationAndOption={{@showValidationAndOption}}\n        @assignmentAnswer={{@assignmentAnswer}}\n        @groupValue={{@groupValue}}\n        @isMultiple={{@isMultiple}}\n        @onEnter={{@onEnter}}\n        @name={{@name}}\n        @assignmentId={{@assignmentId}}\n        @settings={{@settings}}\n      />\n    </div>\n  {{/each}}\n</div>","moduleName":"babel/components/exercise-view/assignment/choice/horizontal/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/choice/horizontal/index.hbs"}});
import ItemStateComponent from 'babel/components/exercise-view/common/item-state/index';

export default class ExerciseViewAssignmentCommonHorizontalComponent extends ItemStateComponent {
  /**
   * Arguments:
   * alternatives (array)
   */

  get alternatives() {
    const len = this.args.alternatives?.length ?? 0;

    return (this.args.alternatives || []).map((item) => {
      if (len < 2) item.cssClass = 'col-12';
      else if (len === 2 || len === 4) item.cssClass = 'col-6';
      else item.cssClass = 'col-4';
      return item;
    });
  }
}
