import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TbRenderInlineElementEncloseLinkComponent extends Component {
  /**
   * Arguments:
   * node (object)
   * material (object)
   */

  @service ajax;

  @service contextHelper;

  @service router;

  @service store;

  @service entityInspector;

  @tracked modalIsOpen = false;

  get material() {
    return this.args.material;
  }

  get type() {
    return this.args.node?.attributes?.findBy('key', 'data-link-type')?.value;
  }

  get id() {
    return this.args.node?.attributes?.findBy('key', 'data-id')?.value;
  }

  get routeArgs() {
    const routeArgs = ['nodes', this.id];

    const contentRoute = this.router.currentRoute.find(
      (route) => route.name === 'master.contents'
    );

    if (contentRoute) {
      let { queryParams } = contentRoute;
      queryParams = { ...queryParams };

      if (!this.contextHelper.hasReturnUrl) {
        queryParams.return_url = this.router.currentURL;
      }

      if (this.contextHelper.hasCloseUrl) {
        queryParams.close_url = this.contextHelper.closeUrl;
      }

      if (this.contextHelper.inMission) {
        this.contextHelper.activeMission.customContents.forEach((item) => {
          const entityId = item.belongsTo('entity').id();

          if (entityId === this.id) {
            // When following a link to a content that exists in the same mission
            // we are already in we should not set return url, instead we should
            // update the custom content id in query params. This way following
            // the link will have the same behaviour as navigating in the mission.
            queryParams.return_url = null;
            queryParams.custom_content = item.id;
          }
        });
      }

      routeArgs.addObject({ queryParams });
    }

    return routeArgs;
  }

  get href() {
    if (this.type === 'attachment') {
      return '#';
    } else if (this.type === 'node') {
      return this.router.urlFor(...this.routeArgs);
    }

    return null;
  }

  @action
  handleClick() {
    switch (this.type) {
      case 'attachment':
      case 'link':
        this.modalIsOpen = true;
        break;
      case 'node':
        this.router.transitionTo(...this.routeArgs);
        break;
    }
  }

  @action
  handleModalClose() {
    this.modalIsOpen = false;
  }

  @action
  handleBoxContinue() {
    this.modalIsOpen = false;
  }
}
