/**
 * Retrieves all parameters for a `RouteInfo` object and its parents in
 * correct oder, so that you can pass them to e.g.
 * `transitionTo(routeName, ...params)`.
 *
 * @param routeInfo
 */
function getParameters(routeInfo) {
  let allParameters = [];
  let current = routeInfo;
  do {
    const { params, paramNames } = current;
    const currentParameters = paramNames.map((n) => params[n]);
    allParameters = [...currentParameters, ...allParameters];
  } while ((current = current.parent));
  return allParameters;
}

/**
 * Builds the URL for a `RouteInfo` object and its parents.
 *
 * @param routeInfo
 *
 * {@link https://github.com/emberjs/rfcs/issues/658}
 */
function getURLFromRouteInfo(routeInfo, router) {
  const { name, queryParams } = routeInfo;
  const orderedParameters = getParameters(routeInfo);
  const url = router.urlFor(name, ...orderedParameters, { queryParams });
  return url;
}

export { getParameters, getURLFromRouteInfo };
