import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @collections}}\n\n  {{#each @collections as |collection index|}}\n\n    {{#unless (eq index 0)}}\n\n      <div class=\"collection-list-divider\"></div>\n\n    {{/unless}}\n\n    <CollectionListItem\n      @collection={{collection}}\n      @onOpenExercise={{@onOpenExercise}}\n      @startExpanded={{or (eq collection.template \"standard\") collection.isTeacherCollection}}\n      @onOpenCollection={{this.handleOpenCollection}}\n    />\n\n  {{/each}}\n\n{{/if}}\n", {"contents":"{{#if @collections}}\n\n  {{#each @collections as |collection index|}}\n\n    {{#unless (eq index 0)}}\n\n      <div class=\"collection-list-divider\"></div>\n\n    {{/unless}}\n\n    <CollectionListItem\n      @collection={{collection}}\n      @onOpenExercise={{@onOpenExercise}}\n      @startExpanded={{or (eq collection.template \"standard\") collection.isTeacherCollection}}\n      @onOpenCollection={{this.handleOpenCollection}}\n    />\n\n  {{/each}}\n\n{{/if}}\n","moduleName":"babel/components/collection-list/index.hbs","parseOptions":{"srcName":"babel/components/collection-list/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CollectionListComponent extends Component {
  /**
   * Arguments:
   * collections (array)
   */

  @service router;

  @action handleOpenCollection(collection, exercise) {
    return this.router.transitionTo(
      'master.contents.assignments.show',
      exercise.id
    );
  }
}
