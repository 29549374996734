import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CustomContentDispatcherService extends Service {
  @service intl;
  @service snackbar;

  @tracked highlightedCustomContent = null;

  async save(customContent, showSuccessMessage = true, adapterOptions = null) {
    try {
      const promise = await customContent.save(adapterOptions);
      if (showSuccessMessage) {
        this.#success('save');
      }
      this.#highlight(customContent);
      return promise;
    } catch (error) {
      this.#error('save');
      throw error;
    }
  }

  #success(method) {
    this.snackbar.enqueue(
      this.intl.t(
        `services.customContentDispatcher.${method}.snackbar.success`
      ),
      {
        variant: 'success',
        autoDismiss: true,
      }
    );
  }

  #error(method) {
    this.snackbar.enqueue(
      this.intl.t(`services.customContentDispatcher.${method}.snackbar.error`),
      {
        variant: 'error',
      }
    );
  }

  #highlight(customContent) {
    this.highlightedCustomContent = customContent;
    later(() => {
      this.highlightedCustomContent = null;
    }, 1000);
  }
}
