import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class\n    \"note-picker-list-item\"\n    (if this.isDisabled \"note-picker-list-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @note}}\n  >\n\n    {{@note.titleWithFallback}}\n\n  </XCheckbox>\n\n</label>", {"contents":"<label\n  class={{class\n    \"note-picker-list-item\"\n    (if this.isDisabled \"note-picker-list-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <XCheckbox\n    class=\"text-quarternary word-break-all\"\n    @checked={{this.isPicked}}\n    @disabled={{this.isDisabled}}\n    @twoWay={{false}}\n    @onChange={{fn (if this.isPicked @onUnpick @onPick) @note}}\n  >\n\n    {{@note.titleWithFallback}}\n\n  </XCheckbox>\n\n</label>","moduleName":"babel/components/note-picker/list/item/index.hbs","parseOptions":{"srcName":"babel/components/note-picker/list/item/index.hbs"}});
import Component from '@glimmer/component';

/*

import NoteModel from 'babel/models/note';

export interface NotePickerGridItemComponentArgs {
  note: NoteModel;
  pickedNotes: NoteModel[];
  disabledNotes: NoteModel[];
  onPick(note: NoteModel): void;
}

*/

export default class NotePickerGridItemComponent extends Component {
  get isPicked() {
    return this.args.pickedNotes.includes(this.args.note);
  }

  get isDisabled() {
    const disabledNotes = this.args.disabledNotes;

    return disabledNotes && disabledNotes.includes(this.args.note);
  }
}
