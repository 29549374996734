import { attr } from '@ember-data/model';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import ContentableModel from 'babel/models/contentable';
import urlHelper from 'babel/utils/urls';
import MultipartUpload from 'compton/utils/multipart-upload';
import { toQueryString } from 'compton/utils/object';
import { reject } from 'rsvp';

const AUDIO = 'audio';
const VIDEO = 'video';
const IMAGE = 'image';
const DOCUMENT = 'document';
const AUDIO_RECORDING = 'audio-recording';

const MEDIA_TYPES = [AUDIO, VIDEO, IMAGE, AUDIO_RECORDING];

const GOOGLE_DOCS_ENDPOINT = 'https://docs.google.com/gview';

const SIGNED_URL_ENDPOINT = `${config.endpoint}/api/v1/meta/files/upload-url`;

export default class FileModel extends ContentableModel {
  // SETUP

  @service ajax;

  @service intl;

  validations = {
    name: {
      presence: {
        message: function (_, __, record) {
          return record.intl.t('models.file.validations.name.presence');
        },
      },
    },
    externalUrl: {
      custom: {
        validation: function (_, __, model) {
          if (model.get('type') !== 'external') {
            return true;
          }
          if (!model.get('externalUrl')) {
            return false;
          }
          return urlHelper.isValidURL(model.get('externalUrl'));
        },
        message: function (_, __, record) {
          return record.get('intl').t('models.file.validations.url.valid');
        },
      },
    },
  };

  // ATTRIBUTES

  @attr('string') name;

  @attr('string') filename;

  @attr('number') filesize;

  @attr('string') type;

  @attr('string') url;

  @attr('string') userId;

  @attr('string') externalType;

  @attr('string') externalUrl;

  @attr('boolean') recorded;

  @attr('object', {
    defaultValue() {
      return {};
    },
  })
  metadata;

  // PROPERTIES

  @tracked upload;

  get title() {
    return this.name;
  }

  get mime() {
    return this.metadata?.mime;
  }

  get titleWithFallback() {
    return this.title || this.intl.t('models.file.titleWithFallback');
  }

  get source() {
    const url = this.url;

    if (this.type === DOCUMENT) {
      const hl = this.intl.locale;

      return [
        GOOGLE_DOCS_ENDPOINT,
        toQueryString({ hl, url, embedded: true }),
      ].join('?');
    }

    return url;
  }

  // METHODS

  isUser(user) {
    return this.userId === user.id;
  }

  async performUpload(file) {
    let contentType = file.type;

    // Since pages files gives no type on file.type
    if (!contentType && file.name?.endsWith('.pages')) {
      contentType = 'application/x-iwork-pages-sffpages';
    }

    const getSignedUrl = this.getSignedUrl;

    const upload = new MultipartUpload({
      file,
      contentType,
      contentLength: file.size,
      canSplitFile: false,
      returnETag: false,
      getSignedUrl,
    });

    this.upload = upload;

    try {
      await upload.perform();
    } catch (err) {
      return reject(err);
    }

    const type = this.recorded
      ? AUDIO_RECORDING
      : file.type.split('/').firstObject;

    this.filename = encodeURI(file.name);
    this.filesize = file.size;
    this.type = type && MEDIA_TYPES.includes(type) ? type : DOCUMENT;

    if (!file.metadata) file.metadata = {};

    this.metadata.mime = file.type;

    return this.save().finally(() => {
      this.upload = null;
    });
  }

  abortUpload() {
    const upload = this.upload;

    if (upload) {
      upload.abort();
    }
  }

  @action getSignedUrl({ mime_type, file_size }) {
    return this.ajax
      .request(SIGNED_URL_ENDPOINT, true, {
        type: 'POST',
        data: {
          contentType: mime_type,
          contentLength: file_size,
          id: this.id,
          name: encodeURI(this.name),
        },
      })
      .then(({ url }) => url);
  }
}
