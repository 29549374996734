import $ from 'jquery';
import { RecordedSpeechProvider } from 'speaker';

class DigilarRecordedSpeechProvider extends RecordedSpeechProvider {
  constructor(params) {
    super();

    // Destruct params to class variables
    ({
      recordedSpeechBaseUrl: this._recordedSpeechBaseUrl,
      singleRecordedSpeechConfig: this._singleRecordedSpeechConfig,
      prioritizeSingleRecordedSpeechAudioSource:
        this._prioritizeSingleRecordedSpeechAudioSource,
    } = params);

    this._hasValidSingleRecordedSpeechFile = false;
  }

  /**
   * Performs any kind of custom init as a first step before initializing any audio sources.
   * For example, fetch data from external APIs and/or apply custom logic to decide how to intialize the audio sources.
   * Must return a Promise that resolves when done
   */
  customInit() {
    // For a story, there is currently a single recorded speech file for the whole section rather than one for each text block component.
    // What is done here is to check if such a recorded speech file exists.
    // Note: In the future there will probably be stories with one recorded speech file per component, so we must be able to handle both cases which is done below.
    if (this._singleRecordedSpeechConfig) {
      const singleAudioSourcePromise = new Promise((resolve, reject) => {
        $.get(this._singleRecordedSpeechConfig.recordedSpeechUrl)
          .done((response) => {
            // Save state to be checked later
            this._hasValidSingleRecordedSpeechFile = true;
            // console.debug(`* URL for single file recorded speech is valid *`);
            resolve();
          })
          .fail((error) => {
            // Reset the url to mark the config as invalid
            this._singleRecordedSpeechConfig.recordedSpeechUrl = null;
            // console.debug(`- URL for single file recorded speech is NOT valid -`);
            resolve();
          });
      });

      return singleAudioSourcePromise;
    } else {
      // If there's no single audio source, just resolve the promise immediately to allow execution to continue
      // console.debug(`No single file recorded speech to check`);
      return Promise.resolve();
    }
  }

  /**
   * Makes it possible to skip text block processing for recorded speech audio sources for this
   * provider AND any providers of lower priority.
   * If no special cases are implemented, this method should always return false.
   */
  preventRecordedSpeechForTextBlocks() {
    // For Digilär's recorded speech, there is a special case when we have a single recorded speech audio file for the entire content/section.
    // When that is the case, no individual text blocks needs to be checked for recorded speech.
    return (
      this._hasValidSingleRecordedSpeechFile &&
      this._prioritizeSingleRecordedSpeechAudioSource
    );
  }

  /**
   * @param textBlockData The custom text block data returned by the DOM helper's getTextBlockRecordedSpeechData() function.
   * @param textBlockHtml The html (string) of the text block. Needed for example to create meta data to enable word/sentence highlighting.
   * @return An array of zero or more configuration objects for the audio sources to be created for the text block.
   * Note: Returning more than one config object means that the recorded speech has been split up into several sound files for that the block.
   * Zero configs (empty array) means that recorded speech will not be available for that the block.
   */
  getTextBlockAudioSourceData(textBlockData, textBlockHtml) {
    // For Digilär's own recorded speech, there is always a 1-to-1 relationship between a component (text block) and its audio file,
    const audioSources = [];

    if (textBlockData.recordedReadingUrl) {
      audioSources.push({
        url: textBlockData.recordedReadingUrl,
        // No meta data == Word/sentence highlighting not supported by Digilär
        textMetaData: null,
        // No custom data necessary
        data: {},
      });
    } else if (textBlockData.recordedSpeechId) {
      audioSources.push({
        url: `${this._recordedSpeechBaseUrl}/${textBlockData.recordedSpeechId}`,
        // No meta data == Word/sentence highlighting not supported by Digilär
        textMetaData: null,
        // No custom data necessary
        data: {},
      });
    }

    return audioSources;
  }

  /**
   * Specifies if all recorded speech URLs should be verified by HTTP GET requests.
   * Set to false for optimization if you're sure that all recorded speech URLs are already valid
   */
  shouldVerifyRecordedSpeechUrls() {
    // Note: No recorded speech URLs are verified anymore. Instead, if a load error occurs the speaker will automatically continue to the next audio source.
    return false;
  }

  /**
   * Called when an audio source from the current provider is starting to play.
   * Note, not called when play is resumed after pausing
   */
  playStarted(event) {}

  /**
   * Called when an audio source from the current provider has finished playing.
   */
  playEnded(event) {}

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Below are custom functions used only by DigilarAudioSourceHandler

  getSingleRecordedSpeechConfig() {
    return this._singleRecordedSpeechConfig;
  }

  hasValidSingleRecordedSpeechFile() {
    return this._hasValidSingleRecordedSpeechFile;
  }
}

export default DigilarRecordedSpeechProvider;
