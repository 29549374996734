import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { all, resolve } from 'rsvp';

export default class MasterCoursesShowRoute extends Route {
  @service session;

  @service courseContext;

  @service productContext;

  @service store;

  async model(params) {
    const course = await this.store.findRecord('course', params.course_id);
    const user = this.session.user;

    await all([course, course.products, user.licenses]);
    await resolve(course.usersMissingLicenses);

    return course;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    this.courseContext.removeAllContext();
    this.productContext.removeAllContext();

    this.courseContext.setCurrentCourse(model);

    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor('application').languageChanged();
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.courseContext.setCurrentCourse(null);
    }
  }
}
