import { hasMany } from '@ember-data/model';
import Model from 'babel/models/model';

export default class ContentableModel extends Model {
  // RELATIONSHIPS

  @hasMany('entity', { async: true, inverse: null }) books;

  @hasMany('entity', { async: true, inverse: null }) contents;

  // PROPERTIES

  get bookIds() {
    return this.books.mapBy('id');
  }

  get contentIds() {
    return this.contents.mapBy('id');
  }

  get hasBooks() {
    return this.bookIds.length > 0;
  }

  get hasContents() {
    return this.contentIds.length > 0;
  }

  // METHODS

  hasBook(book) {
    return this.bookIds.includes(book.id);
  }

  hasContent(content) {
    return this.contentIds.includes(content.id);
  }
}
