import { A } from '@ember/array';
import EmberObject, { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { all, hash, resolve } from 'rsvp';

export default Route.extend({
  store: service(),

  model({ mission_id }) {
    const model = this.modelFor('master.courses.show.audit');

    return all([
      this.store.findRecord('mission', mission_id),
      this.store.findAll('teacher-assignment'),
      get(model, 'course.teachers'),
      get(model, 'course.allBooks'),
    ]).then(([mission, teacherAssignments, teachers, allowedBooks]) =>
      all([
        mission.get('customContents').then((customContents) =>
          all(
            customContents.map((customContent) =>
              customContent.get('entity').then((entity) => {
                const promises = [
                  entity.get('book'),
                  entity.get('children'),
                  customContent.get('includedTeacherAssignments'),
                ];

                teacherAssignments
                  .sortBy('title')
                  .forEach((teacherAssignment) => {
                    if (teacherAssignment.hasContent(entity)) {
                      promises.push(
                        teacherAssignment.get('user').then((user) => {
                          if (teachers.includes(user)) {
                            return teacherAssignment.createAsEntity(entity);
                          }
                        })
                      );
                    }
                  });

                return all(promises).then(([book]) => {
                  if (book && allowedBooks && !allowedBooks.includes(book)) {
                    customContent.set('entity', null);
                    customContent.set('error', 'invalid');
                  }
                });
              })
            )
          ).catch(resolve)
        ),
        mission.get('receivers'),
      ]).then((responses) => {
        let groups = A();
        let students = responses.pop();

        if (students && students.length > 0) {
          const group = EmberObject.create();
          group.set('users', students);
          groups.addObject(group);

          students = students.map((x) => x.get('id'));
        } else {
          groups = model.groups.sortBy('name');
          students = model.students;
        }

        return hash({
          course: model.course,
          groups,
          students,
          mission,
        });
      })
    );
  },
});
