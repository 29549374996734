import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class FootnoteListItemComponent extends Component {
  @service footnotes;

  // PROPERTIES

  get index() {
    return this.footnotes.getIndex(this.args.footnote?.id);
  }

  get value() {
    return decodeURIComponent(this.args.footnote?.value);
  }

  // ACTIONS

  @action handleMouseDown(evt) {
    evt.preventDefault();
    window.focus();
  }

  @action handleClick() {
    const element = document.getElementById(
      ['footnote', this.args.footnote?.id].join('-')
    );

    if (element) {
      element.focus();
    }
  }
}
