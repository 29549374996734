import { NotFoundError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { MissingContentError } from 'babel/utils/errors';
import { reject, resolve } from 'rsvp';

export default class MasterContentsNotesEditRoute extends Route {
  @service store;

  async model({ note_id }) {
    let note;

    try {
      note = await this.store.findRecord('note', note_id);
    } catch (err) {
      return reject(new MissingContentError());
    }

    await resolve(note.books);

    return note;
  }

  afterModel(model) {
    const { book } = this.modelFor('master.contents');

    if (!model.hasBook(book)) {
      return reject(NotFoundError);
    }
  }

  resetController(controller) {
    const model = controller.model;

    if (!model.isDestroyed && !model.isDestroying) {
      model.rollback();
    }
  }
}
