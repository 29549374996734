import { later } from '@ember/runloop';

export default function animate() {
  return new Promise((resolve) => {
    document.querySelector('.content-cover')?.classList.add('cover-fade');
    document.querySelector('.cover-page-info')?.classList.add('cover-info-up');
    document
      .querySelector('.cover-page-actions')
      ?.classList.add('cover-actions');

    document
      .querySelector('.cover-page-image-container')
      ?.classList.add('cover-info-up');

    const contentCoverIndex = document.querySelector('.content-cover-index');

    if (contentCoverIndex) {
      contentCoverIndex.classList.add('content-cover-index--fadedown');
    }

    const cheader = document.querySelector('.header-base');

    if (cheader) {
      cheader.classList.add('fadeout');
    }

    later(() => {
      if (cheader) {
        cheader.classList.remove('fadeout');
      }

      resolve();
    }, 600);
  });
}
