import Component from '@glimmer/component';

const TAG_NAME_TO_TYPE_MAPPING = {
  b: 'format',
  i: 'format',
  u: 'format',
  strike: 'format',
  span: 'format',
  br: 'format',
  a: 'enclose',
  sub: 'format',
  sup: 'format',

  // Legacy
  em: 'format',
  strong: 'format',
  p: 'format',
  div: 'format',
  ol: 'format',
  ul: 'format',
  li: 'format',
};

export default class TbRenderInlineElementComponent extends Component {
  /**
   * Arguments:
   * node (object)
   * stripTags (bool)
   */

  get type() {
    const node = this.args.node;

    if (!node) return null;

    if (this.args.stripTags) return 'stripped';

    const { attributes, tagName } = node;
    const dataType = attributes.findBy('key', 'data-type');

    if (['formula', 'footnote'].includes(dataType?.value)) {
      return 'extension';
    }

    if (dataType?.value === 'text-gap') {
      return 'enclose';
    }

    if (dataType?.value === 'image' && tagName === 'span') {
      return 'image';
    }

    return TAG_NAME_TO_TYPE_MAPPING[tagName];
  }

  get componentName() {
    return this.type ? `tb-render-inline/element/${this.type}` : null;
  }
}
