import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ContentsStoryComponent extends Component {
  @tracked showContentSummary = false;

  @tracked showStoryModal = true;

  @action handleDone() {
    this.showContentSummary = true;
  }

  @action handleOnCloseModal() {
    this.showStoryModal = false;
  }

  get showSummary() {
    return this.showContentSummary && !this.args.hasNextSection;
  }

  get showDoneButton() {
    return !this.showContentSummary && this.args.showDoneButton;
  }
}
