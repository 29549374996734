// NOTE! The first voice of each language will be the default voice for that language.
// The order of remaining voices has no meaning.
// (How the voices are displayed is decided by the playsettings component)
const voices = {
  // Swedish
  sv: [
    { id: 'polly-elin-neural', label: 'Elin' },
    //  { id: 'acapela-elin22k', label: 'Elin' },
    { id: 'acapela_cloud-Emil22k_NT', label: 'Emil' },
    { id: 'acapela_cloud-Emma22k_NT', label: 'Emma' },
    { id: 'acapela_cloud-Erik22k_NT', label: 'Erik' },
    { id: 'acapela_cloud-Kal22k_NT', label: 'Kal (Gbg)' },
    { id: 'acapela_cloud-Samuel22k_NT', label: 'Samuel (Fin)' },
    { id: 'acapela_cloud-Mia22k_HQ', label: 'Mia (Skåne)' },
    { id: 'acapela_cloud-Filip22k_NT', label: 'Filip (barn)' },
    { id: 'acapela_cloud-Freja22k_NT', label: 'Freja (barn)' },
  ],

  // English
  en: [
    { id: 'polly-brian-neural', label: 'Brian (GB)' },
    { id: 'polly-amy-neural', label: 'Amy (GB)' },
    { id: 'polly-kimberly-neural', label: 'Kimberly (US)' },
    { id: 'polly-matthew-neural', label: 'Matthew (US)' },
    { id: 'polly-olivia-neural', label: 'Olivia (AU)' },
    { id: 'polly-aria-neural', label: 'Aria (NZ)' },
    { id: 'polly-ayanda-neural', label: 'Ayanda (SA)' },
    { id: 'polly-kajal-neural', label: 'Kajal (IN)' },
    //  { id: 'polly-raveena-standard', label: 'Raveena (IN)' },
    { id: 'acapela_cloud-Rhona22k_NT', label: 'Rhona (SC)' },
  ],

  // French
  fr: [
    { id: 'polly-gabrielle-neural', label: 'Gabrielle (CA)' },
    { id: 'polly-liam-neural', label: 'Liam (CA)' },
    { id: 'polly-lea-neural', label: 'Léa' },
    { id: 'acapela_cloud-Antoine22k_NT', label: 'Antoine' },
    { id: 'acapela_cloud-Bruno22k_NT', label: 'Bruno' },
    { id: 'acapela_cloud-Anais22k_NT', label: 'Anais' },
    { id: 'acapela_cloud-Claire22k_NT', label: 'Claire' },
  ],

  // German
  de: [
    { id: 'polly-vicki-neural', label: 'Vicki' },
    { id: 'polly-daniel-neural', label: 'Daniel' },
    { id: 'polly-hannah-neural', label: 'Hannah (AT)' },
    { id: 'acapela_cloud-Claudia22k_NT', label: 'Claudia' },
    { id: 'acapela_cloud-Andreas22k_NT', label: 'Andreas' },
    { id: 'acapela_cloud-Julia22k_NT', label: 'Julia' },
    { id: 'acapela_cloud-Klaus22k_NT', label: 'Klaus' },
  ],

  // Italian
  it: [
    { id: 'polly-bianca-neural', label: 'Bianca' },
    { id: 'acapela_cloud-Chiara22k_NT', label: 'Chiara' },
    { id: 'acapela_cloud-Fabiana22k_NT', label: 'Fabiana' },
    { id: 'acapela_cloud-Vittorio22k_NT', label: 'Vittorio' },
  ],

  // Spanish
  es: [
    { id: 'polly-lupe-neural', label: 'Lupe (US)' },
    { id: 'polly-pedro-neural', label: 'Pedro (US)' },
    { id: 'polly-mia-neural', label: 'Mia (MX)' },
    { id: 'polly-lucia-neural', label: 'Lucia' },
    { id: 'acapela_cloud-Ines22k_NT', label: 'Ines' },
    { id: 'acapela_cloud-Antonio22k_NT', label: 'Antonio' },
    { id: 'acapela_cloud-Rodrigo22k_NT', label: 'Rodrigo' },
    { id: 'acapela_cloud-Rosa22k_NT', label: 'Rosa' },
  ],

  // Danish
  da: [
    { id: 'polly-mads-standard', label: 'Mads' },
    { id: 'polly-naja-standard', label: 'Naja' },
    { id: 'acapela_cloud-Rasmus22k_NT', label: 'Rasmus' },
    { id: 'acapela_cloud-Mette22k_NT', label: 'Mette' },
  ],

  // Norwegian
  no: [
    { id: 'polly-ida-neural', label: 'Ida' },
    { id: 'polly-liv-standard', label: 'Liv' },
    { id: 'acapela_cloud-Kari22k_NT', label: 'Kari' },
    { id: 'acapela_cloud-Bente22k_NT', label: 'Bente' },
    { id: 'acapela_cloud-Olav22k_NT', label: 'Olav' },
  ],

  // The following languages have been added to support web page translations in Chrome.
  // Priority for voices: 1. AWS Polly neural, 2. Acapela, 3. AWS Polly standard
  // To add more voices, see the following links for complete lists of available voices:
  //
  // Polly: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
  // Acapela (login required): http://www.acapela-vaas.com/ReleasedDocumentation/voices_list.php

  // Arabic
  ar: [{ id: 'acapela_cloud-Leila22k_HQ', label: 'Leila' }],

  // Catalan
  ca: [
    { id: 'polly-arlet-neural', label: 'Arlet' },
    //     { id: 'acapela-laia22k', label: 'Laia' }
  ],

  // Chinese (Word highlighting working very poorly due to Chinese characters - only Acapela?)
  zh: [
    { id: 'polly-hiujin-neural', label: 'Hiujin' },
    //    { id: 'acapela-lulu22k', label: 'Lulu' }
  ],

  // Dutch
  nl: [
    { id: 'polly-laura-neural', label: 'Laura' },
    //     { id: 'acapela-daan22k', label: 'Daan' }
  ],

  // Finnish
  fi: [
    { id: 'polly-suvi-neural', label: 'Suvi' },
    //      { id: 'acapela-sanna22k', label: 'Sanna' }
  ],

  // Greek
  el: [{ id: 'acapela_cloud-Dimitris22k_HQ', label: 'Dimitris' }],

  // Hindi
  hi: [
    { id: 'polly-kajal-neural', label: 'Kajal' },
    //    { id: 'polly-aditi-standard', label: 'Aditi' }
  ],

  // Icelandic
  is: [{ id: 'polly-dora-standard', label: 'Dora' }],

  // Japanese (Word highlighting working very poorly due to Japanese characters - only Acapela?)
  ja: [
    { id: 'polly-takumi-neural', label: 'Takumi' },
    //     { id: 'acapela-sakura22k', label: 'Sakura' }
  ],

  // Korean
  ko: [
    { id: 'polly-seoyeon-neural', label: 'Seoyeon' },
    //    { id: 'acapela-minji22k', label: 'Minji' }
  ],

  // Polish
  pl: [{ id: 'acapela_cloud-Ania22k_HQ', label: 'Ania' }],

  // Portugese
  pt: [
    { id: 'polly-ines-neural', label: 'Ines' },
    //    { id: 'acapela-celia22k', label: 'Celia' }
  ],

  // Romanian
  ro: [{ id: 'polly-carmen-standard', label: 'Carmen' }],

  // Russian
  ru: [{ id: 'acapela_cloud-Alyona22k_HQ', label: 'Alyona' }],

  // Turkish
  tr: [{ id: 'acapela_cloud-Ipek22k_HQ', label: 'Ipek' }],

  // Welsh
  cy: [{ id: 'polly-gwyneth-standard', label: 'Gwyneth' }],

  // Czech
  cs: [{ id: 'acapela_cloud-Eliska22k_HQ', label: 'Eliska' }],
};

// If the same voices should be supported for several language codes, add the keys for those languages here
//
// Norwegian language mappings
voices.nn = voices.no;
voices.nb = voices.no;

export default voices;
