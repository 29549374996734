import ApplicationSerializer from 'babel/serializers/application';
import sanitizeHTML from 'babel/utils/sanitize-html';

export default class MissionSerializer extends ApplicationSerializer {
  normalize(_, hash) {
    if (hash && hash.attributes) {
      const attrs = ['title', 'description'];

      // Temp fix.
      const expectedValues = (
        hash.relationships.custom_contents?.data || []
      ).map((item) => item.id);

      const sortedCustomContentIds =
        hash.attributes.metadata?.sorted_custom_content_ids ??
        hash.attributes.sorted_custom_content_ids ??
        [];

      expectedValues.forEach((id) => {
        if (!sortedCustomContentIds.includes(id)) {
          sortedCustomContentIds.push(id);
        }
      });

      const result = [];

      sortedCustomContentIds.forEach((id) => {
        if (expectedValues.includes(id)) {
          result.push(id);
        }
      });

      hash.attributes.sorted_custom_content_ids = result;

      attrs.forEach((name) => {
        if (hash.attributes[name]) {
          hash.attributes[name] = sanitizeHTML(hash.attributes[name]);
        }
      });
    }

    return super.normalize(...arguments);
  }

  serialize() {
    const json = super.serialize(...arguments);

    if (json.data.attributes.startDate == null) {
      json.data.attributes.endDate = undefined;
    }

    // Temp fix.

    json.data.attributes.metadata ??= {};

    json.data.attributes.metadata.sorted_custom_content_ids =
      json.data.attributes.sorted_custom_content_ids;

    delete json.data.attributes.sorted_custom_content_ids;

    return json;
  }
}
