import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class MasterCoursesShowIndexController extends Controller {
  @service store;
  @service session;
  @service missionDispatcher;
  @service customContentDispatcher;
  @service fileDispatcher;

  @tracked expandedMissions = [];
  @tracked mission = null;
  @tracked customContent = null;
  @tracked pendingFile = null;
  @tracked isAddNewActionMenuOpen = false;
  @tracked isCourseMissionActivateModalOpen = false;
  @tracked isCourseMissionCopyFromModalOpen = false;
  @tracked courseMissionCopyToModalMeta = null;
  @tracked missionShareModalMeta = null;
  @tracked isSortingDisabled = true;

  addNewMissionButtonElement = null;

  get isCourseMissionCopyToModalOpen() {
    return !!this.courseMissionCopyToModalMeta;
  }

  get isMissionShareModalOpen() {
    return !!this.missionShareModalMeta;
  }

  get isMissionDetailModalOpen() {
    return !!this.mission;
  }

  get isCustomContentDetailModalOpen() {
    return !!this.customContent;
  }

  @cached
  get settingProxy() {
    const promise = resolve(this.model.course).then(
      (course) => course?.setting
    );

    return load(promise, this);
  }

  get setting() {
    return this.settingProxy.isResolved ? this.settingProxy.value : null;
  }

  get isShowingIntro() {
    return (
      this.session.user?.isTeacher &&
      this.setting &&
      !this.setting?.metadata?.hide_intro
    );
  }

  get missions() {
    const { course, missions } = this.model;

    const accessibleMissions = missions?.filter(
      (mission) => mission.isAccessible
    );

    const ids = course.sortedMissionIds;

    if (ids?.length > 0) {
      const sortedMissions = ids
        .map((id) => accessibleMissions.find((mission) => mission.id === id))
        .compact();

      const unsortedMissions = accessibleMissions
        .filter((mission) => !ids.includes(mission.id))
        .sortBy('created')
        .reverse();

      return [...unsortedMissions, ...sortedMissions];
    }

    return accessibleMissions;
  }

  @action
  async handleMissionBrowserActivate(mission) {
    await this.missionDispatcher.activate(mission);
    this.model.missions.update();
  }

  @action
  async handleMissionBrowserDeactivate(mission) {
    await this.missionDispatcher.deactivate(mission);
    this.model.missions.update();
  }

  @action
  handleAddNewMissionButtonClick() {
    this.#addNewMission();
  }

  @action
  handleMissionBrowserEdit(mission) {
    this.mission = mission;
  }

  @action
  handleMissionBrowserCopy(mission) {
    this.courseMissionCopyToModalMeta = {
      mission,
    };
  }

  @action
  handleMissionBrowserShare(mission) {
    this.missionShareModalMeta = {
      mission,
    };
  }

  @action
  handleMissionCopyToModalClose() {
    this.courseMissionCopyToModalMeta = null;
  }

  @action
  handleMissionShareModalClose() {
    this.missionShareModalMeta = null;
  }

  @action
  handleMissionDetailModalClose() {
    this.mission.rollback();
    this.mission = null;
  }

  @action
  async handleMissionDetailModalSave() {
    await this.missionDispatcher.save(this.mission, { fromOverview: true });
    await this.model.missions.update();
    this.mission = null;
  }

  @action
  async handleMissionDetailModalDelete() {
    await this.missionDispatcher.delete(this.mission, { fromOverview: true });
    await this.model.missions.update();
    this.mission = null;
  }

  @action
  async handleMissionBrowserExpand(mission) {
    this.expandedMissions.addObject(mission);
  }

  @action
  async handleMissionBrowserToggleActivation(mission) {
    await this.missionDispatcher.toggleActivation(mission, {
      fromOverview: true,
    });
    return this.model.missions.update();
  }

  @action
  async handleMissionBrowserHide(mission) {
    await this.missionDispatcher.hide(mission, { fromOverview: true });
    return this.model.missions.update();
  }

  @action
  handleMissionBrowserCollapse(mission) {
    this.expandedMissions.removeObject(mission);
  }

  @action
  async handleMissionBrowserSort(missions) {
    return this.model.course.sortMissions(missions);
  }

  @action
  handleMissionBrowserEditCustomContent(customContent) {
    this.customContent = customContent;
  }

  @action
  async handleCustomContentDetailModalSave(customContent) {
    await this.customContentDispatcher.save(customContent);
    this.customContent = null;
  }

  @action
  async handleMissionActivateModalActivate(missions) {
    await this.missionDispatcher.activateMany(missions, { fromOverview: true });
    await this.model.missions.update();
    this.isCourseMissionActivateModalOpen = false;
  }

  @action
  handleMissionActionMenuSelect(value) {
    switch (value) {
      case 'new':
        this.#addNewMission();
        break;
      case 'activate':
        this.isCourseMissionActivateModalOpen = true;
        break;
      case 'copy':
        this.isCourseMissionCopyFromModalOpen = true;
        break;
      default:
        break;
    }
  }

  @action
  async handleMissionCopyFromModalCopy(missions) {
    await this.missionDispatcher.copyManyToCourse(missions, this.model.course, {
      fromOverview: true,
    });
    await this.model.missions.update();
    this.isCourseMissionCopyFromModalOpen = false;
  }

  @action
  async handleMissionCopyToModalCopy(mission, courses) {
    await this.missionDispatcher.copyToManyCourses(mission, courses, {
      fromOverview: true,
    });
    this.courseMissionCopyToModalMeta = null;
  }

  @action
  handleCustomContentDetailModalClose() {
    this.customContent.rollback();
    this.customContent = null;
  }

  @action
  async handleMissionDetailModalFileUpload(fileData) {
    this.pendingFile = this.store.createRecord('file', {
      name: fileData.name,
      user: this.session.user,
    });

    try {
      await this.fileDispatcher.performUpload(this.pendingFile, fileData);
      return this.pendingFile;
    } finally {
      this.pendingFile = null;
    }
  }

  @action
  handleFileUploadAbort() {
    this.fileDispatcher.abortUpload(this.pendingFile);
    this.pendingFile = null;
  }

  @action
  async closeIntroModal() {
    const course = await resolve(this.model.course);
    const setting = await resolve(course?.setting);

    if (!setting) return;

    if (!setting.metadata) {
      setting.metadata = {};
    }

    setting.metadata.hide_intro = true;

    setting.save();
  }

  #addNewMission() {
    this.mission = this.model.course.newMission({
      hideSolutions: !this.setting?.showKey,
    });
    this.expandedMissions.addObject(this.mission);
  }

  @action
  toggleSort() {
    this.isSortingDisabled = !this.isSortingDisabled;
  }

  @action
  async handleMissionBrowserSchedule(mission, fields) {
    await this.missionDispatcher.schedule(mission, fields);
  }
}
