import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, and, equal } from '@ember/object/computed';

export default Component.extend({
  // SETUP

  classNames: ['word-browser-item'],

  classNameBindings: [
    'word.isDeleted:word-browser-item--deleted',
    'isTransformedConcepts:word-browser-item-transformed-concepts'
  ],

  // PARAMS

  word: null,

  onPerform: null,

  // PROPERTIES

  isReadOnly: alias('word.wordlist.isReadOnly'),

  isTransformed: alias('word.wordlist.isTransformed'),

  isGlossary: equal('word.wordlist.type', 'glossaries'),

  isConcepts: equal('word.wordlist.type', 'concepts'),

  isTransformedGlossary: and('isTransformed', 'isGlossary'),

  isTransformedConcepts: and('isTransformed', 'isConcepts'),

  buttonClass: computed(
    'isTransformedConcepts',
    'isTransformedGlossary',
    function() {
      if (this.get('isTransformedGlossary')) {
        return 'word-browser-item-glossary-button text-color';
      }

      return 'text-color';
    }
  ),

  wordExplanationPopoverIsOpen: false,
});
