function addSettingRelationship(item) {
  if (item.relationships?.setting) return;

  item.relationships.setting = {
    links: {
      related: `/api/v1/meta/settings/${item.id}`,
    },
  };
}

export function addCourseSettingRelationship(payload) {
  if (payload?.data?.type === 'courses') {
    addSettingRelationship(payload.data);
  } else if (
    Array.isArray(payload?.data) &&
    payload.data.find((item) => item.type === 'courses')
  ) {
    payload.data.map((item) => {
      if (item.type === 'courses') {
        addSettingRelationship(item);
      }
    });
  }
}
