function isIncluded(entries, entry, customContent, isActive) {
  if (!isActive || !entries) {
    return true;
  }

  if (!customContent.get('populated') && entries.get('length') === 0) {
    return true;
  }

  return entries.mapBy('id').includes(entry.get('id'));
}

function teacherAssignmentIsAllowed(
  teacherAssignmentEntity,
  customContent,
  isActive
) {
  return isIncluded(
    customContent.get('includedTeacherAssignments')?.toArray() || [],
    teacherAssignmentEntity.get('teacherAssignment'),
    customContent,
    isActive
  );
}

function teacherCollectionIsAllowed(teacherCollection, customContent) {
  const includedTeacherAssignments =
    customContent.get('includedTeacherAssignments')?.toArray() || [];

  if (
    !includedTeacherAssignments ||
    (!customContent.get('populated') &&
      includedTeacherAssignments.get('length') === 0)
  ) {
    return true;
  }

  return includedTeacherAssignments
    .mapBy('id')
    .any((id) => teacherCollection.get('children').mapBy('id').includes(id));
}

export function entityIsAllowedInCustomContent(
  entity,
  customContent,
  isActive = true
) {
  if (!entity) return false;
  if (!customContent) return true;

  if (entity.get('isTeacherAssignment')) {
    return teacherAssignmentIsAllowed(entity, customContent, isActive);
  }

  if (entity.get('isTeacherCollection')) {
    return teacherCollectionIsAllowed(entity, customContent);
  }

  let includedEntities;

  try {
    includedEntities = customContent.get('includedEntities').toArray();
  } catch (err) {
    // do nothing
  }

  if (!includedEntities) includedEntities = [];

  return isIncluded(includedEntities, entity, customContent, isActive);
}
