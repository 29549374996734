import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';

export default class TbRenderVrImageComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   */

  baseClass = 'tb-render-vr-image';

  asyncType = 'material';

  get asyncId() {
    return this.args.part?.media_id;
  }

  get material() {
    return this.asyncData;
  }

  get autoLoad() {
    return this.args.part?.settings?.auto_load;
  }

  get autoRotate() {
    return this.args.part?.settings?.auto_rotate;
  }

  get compass() {
    return this.args.part?.settings?.compass;
  }

  get yaw() {
    return this.args.part?.settings?.yaw;
  }

  get pitch() {
    return this.args.part?.settings?.pitch;
  }

  get hfov() {
    return this.args.part?.settings?.hfov;
  }

  get vaov() {
    return this.args.part?.settings?.vaov || 180;
  }
}
