import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import loadDataFromStore from 'babel/utils/load-data-from-store';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

const ALLOWED_TYPES = [
  'article',
  'embed',
  'audio',
  'formula',
  'image',
  'interactive-image',
  'link',
  'video',
  'vr-image',
  'word-explanation',
];

const COMPONENT_PREFIX = 'tb-render-inline/element/enclose';

const IMMERSION_TYPES = [
  'article',
  'audio',
  'embed',
  'image',
  'interactive-image',
  'video',
  'vr-image',
];

export default class TbRenderInlineElementEncloseComponent extends Component {
  /**
   * node (object)
   */

  @service store;

  get materialId() {
    return this.args.node?.attributes?.findBy('key', 'data-id')?.value;
  }

  get type() {
    return this.args.node?.attributes?.findBy('key', 'data-type')?.value;
  }

  get linkType() {
    return this.args.node?.attributes?.findBy('key', 'data-link-type')?.value;
  }

  @cached get materialPromise() {
    let promise = resolve();

    if (
      this.materialId &&
      this.type !== 'custom' &&
      (this.type !== 'link' || !['node', 'guidance'].includes(this.linkType))
    ) {
      promise = loadDataFromStore('material', this.materialId, this.store);
    }

    return promise;
  }

  @cached get materialProxy() {
    return load(this.materialPromise, this);
  }

  get material() {
    return this.materialProxy.isResolved ? this.materialProxy.value : null;
  }

  get typeIsAllowed() {
    return ALLOWED_TYPES.includes(this.type);
  }

  get isCustomComponent() {
    return ['custom', 'text-gap'].includes(this.type);
  }

  get componentName() {
    if (this.type) {
      if (IMMERSION_TYPES.includes(this.type)) {
        return [COMPONENT_PREFIX, 'immersion'].join('/');
      }

      return [COMPONENT_PREFIX, this.type].join('/');
    }

    return null;
  }
}
