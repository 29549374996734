import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { bool, equal } from '@ember/object/computed';
import { cancel, later } from '@ember/runloop';

const OFFSET_STYLE_VARIABLE_NAME = '--read-ruler-offset';
const SPACE_STYLE_VARIABLE_NAME = '--read-ruler-space';

export default Component.extend({
  // SETUP

  classNames: ['read-ruler-portal'],

  classNameBindings: [
    'isPerforming:read-ruler-portal--performing',
    'isMoving:read-ruler-portal--moving',
    'isResizing:read-ruler-portal--resizing'
  ],

  // PARAMS

  space: null,

  offset: null,

  onClose: null,

  onResize: null,

  onMove: null,

  // PROPERTIES

  performDelay: 60,

  minSpace: 28,

  height: null,

  method: null,

  lasty: 0,

  _resizeTimeout: null,

  _moveTimeout: null,

  isMoving: equal('method', 'move'),

  isResizing: equal('method', 'resize'),

  isPerforming: bool('method'),

  closeButtonId: computed('elementId', function() {
    return [this.get('elementId'), 'close-button'];
  }),

  moveButtonId: computed('elementId', function() {
    return [this.get('elementId'), 'move-button'];
  }),

  resizeButtonId: computed('elementId', function() {
    return [this.get('elementId'), 'resize-button'];
  }),

  // ACTIONS

  actions: {
    handleCloseButtonClick(evt) {
      evt.preventDefault();
      this.get('onClose')();
    },

    handleMoveButtonTouchStart(evt) {
      evt.preventDefault();
      document.addEventListener('touchend', this.documentTouchEnd);
      this._resizeTimeout = later(() => {
        this.set('method', 'move');
        this.set('lasty', evt.changedTouches[0].clientY);
        document.addEventListener('touchmove', this.documentTouchMove);
      }, this.get('performDelay'));
    },

    handleMoveButtonMouseDown(evt) {
      evt.preventDefault();
      document.addEventListener('mouseup', this.documentMouseUp);
      this._resizeTimeout = later(() => {
        this.set('method', 'move');
        document.addEventListener('mousemove', this.documentMouseMove);
      }, this.get('performDelay'));
    },

    handleResizeButtonTouchStart(evt) {
      evt.preventDefault();
      document.addEventListener('touchend', this.documentTouchEnd);
      this._resizeTimeout = later(() => {
        this.set('method', 'resize');
        this.set('lasty', evt.changedTouches[0].clientY);
        document.addEventListener('touchmove', this.documentTouchMove);
      }, this.get('performDelay'));
    },

    handleResizeButtonMouseDown(evt) {
      evt.preventDefault();
      document.addEventListener('mouseup', this.documentMouseUp);
      this._resizeTimeout = later(() => {
        this.set('method', 'resize');
        document.addEventListener('mousemove', this.documentMouseMove);
      }, this.get('performDelay'));
    }
  },

  // EVENTS

  windowResize() {
    this._calculateHeight();
  },

  documentTouchEnd() {
    this.set('method', null);
    document.removeEventListener('touchend', this.documentTouchEnd);
    document.removeEventListener('touchmove', this.documentTouchMove);
    cancel(this._resizeTimeout);
  },

  documentMouseUp() {
    this.set('method', null);
    document.removeEventListener('mouseup', this.documentMouseUp);
    document.removeEventListener('mousemove', this.documentMouseMove);
    cancel(this._resizeTimeout);
  },

  documentTouchMove(evt) {
    const clientY = evt.changedTouches[0].clientY;
    const movementY = clientY - this.get('lasty');
    this.set('lasty', clientY);
    switch (this.get('method')) {
      case 'move':
        this._move(movementY);
        break;
      case 'resize':
        this._resize(movementY);
        break;
    }
  },

  documentMouseMove({ movementY }) {
    switch (this.get('method')) {
      case 'move':
        this._move(movementY);
        break;
      case 'resize':
        this._resize(movementY);
        break;
    }
  },

  // HOOKS

  init() {
    this._super(...arguments);
    this.windowResize = this.windowResize.bind(this);
    this.documentTouchEnd = this.documentTouchEnd.bind(this);
    this.documentTouchMove = this.documentTouchMove.bind(this);
    this.documentMouseUp = this.documentMouseUp.bind(this);
    this.documentMouseMove = this.documentMouseMove.bind(this);
  },

  didInsertElement() {
    this._super(...arguments);
    this._calculateHeight();
    this._setStyleVariable(SPACE_STYLE_VARIABLE_NAME, this.get('space') + 'px');
    this._setStyleVariable(
      OFFSET_STYLE_VARIABLE_NAME,
      this.get('offset') + 'px'
    );
    window.addEventListener('resize', this.windowResize);
  },

  didDestroyElement() {
    window.removeEventListener('resize', this.windowResize);
  },

  // OBSERVERS

  spaceObserver: observer('space', function() {
    this._setStyleVariable(SPACE_STYLE_VARIABLE_NAME, this.get('space') + 'px');
  }),

  offsetObserver: observer('offset', function() {
    this._setStyleVariable(
      OFFSET_STYLE_VARIABLE_NAME,
      this.get('offset') + 'px'
    );
  }),

  // PRIVATE

  _move(amount) {
    let newAmount = parseInt(
      this._getStyleVariable(OFFSET_STYLE_VARIABLE_NAME)
    );
    newAmount -= amount;
    const limit =
      this.get('height') / 2 - this.get('space') / 2 - this.get('minSpace');

    newAmount = Math.min(newAmount, limit);
    newAmount = Math.max(newAmount, -limit);
    this.get('onMove')(newAmount);
  },

  _resize(amount) {
    let newAmount = parseInt(this._getStyleVariable(SPACE_STYLE_VARIABLE_NAME));
    newAmount += amount;
    newAmount = Math.max(newAmount, this.get('minSpace'));
    newAmount = Math.min(
      newAmount,
      this.get('height') + this.get('minSpace') * 2
    );
    this.get('onResize')(newAmount);
  },

  _calculateHeight() {
    const { height } = this.get('element').getBoundingClientRect();

    this.set('height', height);
  },

  _getStyleVariable(key) {
    return getComputedStyle(this.get('element')).getPropertyValue(key);
  },

  _setStyleVariable(key, value) {
    this.get('element').style.setProperty(key, value);
  }
});
