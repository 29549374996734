import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class CourseHeaderComponent extends Component {
  /**
   * Arguments:
   * course (object)
   */

  get imageStyle() {
    const url = this.args.course?.coverBackground;

    if (url) {
      return htmlSafe(`background-image: url(${url});`);
    }

    return null;
  }

  get coverClass() {
    const classes = ['course-header-cover'];

    if (!this.imageStyle) {
      classes.push('course-header-cover-bgcolor');
    }

    return classes.join(' ');
  }

  get effectClass() {
    const classes = ['course-header-effect'];

    if (!this.imageStyle) {
      classes.push('course-header-no-effect');
    }

    return classes.join(' ');
  }

  get textClass() {
    const classes = ['flex-auto', 'mb1'];

    if (this.imageStyle) {
      classes.push('text-color-inverted');
    }

    return classes.join(' ');
  }
}
