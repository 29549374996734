import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ImageCard extends Component {
  /**
   * Arguments:
   * image (string)
   * title (string)
   * icon (string)
   * materialType (string)
   * index (string)
   * searchValue (string)
   * backgroundColor (string)
   * imageSize (string)
   */

  get icon() {
    if (this.args.materialType === 'links') {
      return 'external';
    } else if (this.args.materialType === 'attachments') {
      return 'cloud-download';
    } else {
      return this.args.icon;
    }
  }
  get searchArr() {
    if (this.args.searchValue) {
      return this.args.searchValue.split(' ');
    } else {
      return null;
    }
  }

  get backgroundColor() {
    return this.args.backgroundColor ?? 'var(--background-shade)';
  }

  get boxClasses() {
    const classes = ['image-card-box'];

    if (this.args.imageSize === 'contain') {
      classes.push('image-card-box--contain');
    }

    return classes.join(' ');
  }

  @action setContainerElementBackground(elem) {
    elem.style.backgroundColor = this.backgroundColor;
  }
}
