import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class EntityInspectorComponent extends Component {
  @service router;

  @service session;

  @service entityInspector;

  @tracked fullscreen = false;

  get classNames() {
    const classNames = ['entity-inspector'];

    if (this.fullscreen) {
      classNames.push('entity-inspector--fullscreen');
    }

    return classNames.join(' ');
  }

  @action toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
  }

  @action close() {
    this.args.onClose();
  }
}
