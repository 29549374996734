import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Poller from 'babel/utils/Poller';
import DigilarSpeakerType from 'babel/utils/speaker/DigilarSpeakerType';

export default class EncourageListeningBubbleComponent extends Component {
  /**
   * Arguments:
   * element (object)
   */

  @service speaker;

  @tracked isPlaying = false;

  speakerAdapter = null;

  isBlockSelected = false;

  shouldDisableOnEnd = false;

  statePoller = null;

  speakerBlockDataAttribute = '[data-speaker-text-id]';

  constructor() {
    super(...arguments);
    this.speaker.digilarRecordedSpeechEnabled = true;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.handleStopEvent();
  }

  @action toggleListening() {
    this.speaker.encourageListeningEnabled = true;
    this.speaker.digilarRecordedSpeechEnabled = true;

    // If speaker was not active it should be inactive after reading
    if (!this.isBlockSelected) {
      this.shouldDisableOnEnd = !this.speaker.active;
    }

    if (!this.speakerAdapter) {
      this.speaker.setSpeakerActive(true);
    }

    const poller = new Poller();
    poller.setInterval(100);

    poller.start(this, function () {
      this.speakerAdapter = this.getSpeakerAdapter(this.args.element);
      if (this.speakerAdapter?._speaker) {
        this.speakerAdapter.on('play', this.handlePlayEvent);
        this.speakerAdapter.on('stop', this.handleStopEvent);

        this.startSpeaker(this.speakerAdapter);
        this.pollState(this.speakerAdapter);

        poller.stop();
      }
    });
  }

  @action handleStopEvent() {
    this.speaker.encourageListeningEnabled = false;
    this.isPlaying = false;
    this.statePoller?.stop();
  }

  @action handlePlayEvent(event) {
    if (this.isBlockSelected) {
      if (
        !this.isInsideBubble(this.speakerAdapter) &&
        !this.isMultipart(event)
      ) {
        this.isBlockSelected = false;
        this.speaker.encourageListeningEnabled = false;

        if (!event.isUserTriggered) {
          this.speakerAdapter.stop();

          if (this.shouldDisableOnEnd) {
            this.speaker.setSpeakerActive(false);
          }
        }

        this.speakerAdapter = null;
        this.statePoller?.stop();
        this.isPlaying = false;
      } else {
        this.isPlaying = this.speakerAdapter.isPlaying();
      }
    }
  }

  isMultipart(event) {
    return event.recordedSpeechPartIndex > 0;
  }

  getSpeakerAdapter(element) {
    if (!element) {
      return;
    }

    const firstSpeakerElement = element?.querySelector(
      this.speakerBlockDataAttribute
    );

    const speakerTextId = firstSpeakerElement?.dataset?.speakerTextId;
    const contexts = this.speaker.getRegisteredSpeakerContexts();

    return this.getSpeakerAdapterForSpeakerTextId(speakerTextId, contexts);
  }

  getSpeakerAdapterForSpeakerTextId(speakerTextId, contexts) {
    let speakerAdapter;

    for (const type of Object.values(DigilarSpeakerType)) {
      contexts.map((ctx) => {
        const context = ctx._speakerSandboxes[
          type
        ]?.speakerAdapter?._audioSources.filter((source) => {
          return source._originalConfig.id === speakerTextId;
        });
        if (context.length) {
          speakerAdapter = ctx?._speakerSandboxes[type]?.speakerAdapter;
        }
      });
    }

    return speakerAdapter;
  }

  getIncludesTextIndexes(speakerAdapter) {
    const allElements = [
      ...this.args.element?.querySelectorAll(this.speakerBlockDataAttribute),
    ];

    return allElements?.map((el) => {
      return speakerAdapter._audioSources?.findIndex((source) => {
        return el?.dataset?.speakerTextId === source._originalConfig.id
      });
    });
  }

  isInsideBubble(speakerAdapter) {
    const includedTextIndexes = this.getIncludesTextIndexes(speakerAdapter);

    const currentAudioSource = speakerAdapter.getCurrentAudioSource();

    return (
      currentAudioSource &&
      includedTextIndexes.includes(currentAudioSource.audioSourceIndex)
    );
  }

  pollState(speakerAdapter) {
    this.statePoller = new Poller();
    this.statePoller.setInterval(200);
    this.statePoller.start(this, function () {
      this.isPlaying = speakerAdapter?.isPlaying();
    });
  }

  startSpeaker(speakerAdapter) {
    if (this.isPlaying) {
      this.isPlaying = false;
      speakerAdapter.pause();
    } else {
      const includedTextIndexes = this.getIncludesTextIndexes(speakerAdapter);

      // Select first block in row
      if (!this.isBlockSelected) {
        speakerAdapter.select(includedTextIndexes[0], false);
        this.isBlockSelected = true;
      }

      // Play
      speakerAdapter.play({
        autoLoad: false,
        autoPlayWhenLoaded: true,
        isUserTriggered: true,
        playNextOnError: true,
      });
    }
  }
}
