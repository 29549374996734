import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { resolve } from 'rsvp';

export default class MasterMaterialsController extends Controller {
  @service router;

  @action handleClose() {
    return resolve(this.model.parent)
      .then((parent) => this.router.transitionTo('nodes', parent.id))
      .catch(() => {});
  }
}
