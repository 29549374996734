import { computed } from '@ember/object';
import DS from 'ember-data';

import Model from './model';

const { belongsTo, hasMany } = DS;

export default Model.extend({
  product: belongsTo('product', { async: true, inverse: null }),

  items: hasMany('missing-license-item', { async: true, inverse: null }),

  numMissing: computed('items.@each.status', function () {
    return this.get('items').filterBy('status', 'expired').length;
  }),
});
