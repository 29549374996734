import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{{ this.svg }}}", {"contents":"{{{ this.svg }}}","moduleName":"babel/components/qr-code-image/index.hbs","parseOptions":{"srcName":"babel/components/qr-code-image/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import QRCode from 'qrcode';

export default class QrCodeImageComponent extends Component {
  @tracked svg = '';

  constructor() {
    super(...arguments);

    QRCode.toString(
      this.args.text,
      { type: 'svg', errorCorrectionLevel: 'high' },
      (error, svg) => {
        if (error) {
          console.error(error);
          return;
        }

        this.svg = svg;
      }
    );
  }
}
