import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

const SIZE_TO_IMAGE_SIZE = {
  small: 30,
  medium: 40,
  large: 50,
};

export default class TbRenderInlineElementImageComponent extends Component {
  /**
   * Arguments:
   * node (object)
   */
  @service store;

  get imageId() {
    return this.args.node?.attributes?.findBy('key', 'data-image')?.value;
  }

  get position() {
    return this.args.node?.attributes?.findBy('key', 'data-position')?.value;
  }

  get size() {
    return this.args.node?.attributes?.findBy('key', 'data-size')?.value;
  }

  get imageWidth() {
    const maxDim = SIZE_TO_IMAGE_SIZE[this.size];

    let imageWidth = this.image?.imageWidth ?? maxDim;
    let imageHeight = this.image?.imageHeight ?? maxDim;

    if (imageWidth > imageHeight) {
      return maxDim;
    } else {
      return (imageWidth / imageHeight) * maxDim;
    }
  }

  get imageWidthRounded() {
    return Math.round(this.imageWidth);
  }

  get imageHeight() {
    const maxDim = SIZE_TO_IMAGE_SIZE[this.size];

    let imageWidth = this.image?.imageWidth ?? 0;
    let imageHeight = this.image?.imageHeight ?? 0;

    if (imageWidth > imageHeight) {
      return (imageHeight / imageWidth) * maxDim;
    } else {
      return maxDim;
    }
  }

  get imageHeightRounded() {
    return Math.round(this.imageHeight);
  }

  get retinaImageWidth() {
    return this.imageWidth * 2;
  }

  get retinaImageHeight() {
    return this.imageHeight * 2;
  }

  get hasSpacer() {
    return this.position === 'left' || this.position === 'right';
  }

  @cached
  get imageProxy() {
    let promise = resolve();

    if (this.imageId) {
      promise = this.store.findRecord('material', this.imageId);
    }

    return load(promise, this);
  }

  get image() {
    return this.imageProxy.isResolved ? this.imageProxy.value : null;
  }
}
