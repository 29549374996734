import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['focus-mode'],

  focusMode: service(),

  classNameBindings: ['focusMode.active:focus-mode-active'],

  actions: {
    leaveFocus() {
      this.get('focusMode').turnOff();
    }
  }
});
