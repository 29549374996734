import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"mission-browser\"\n  ...attributes\n  {{sortable-group\n    groupName=this.groupName\n    disabled=(not @sortable)\n    onChange=(optional @onSort)\n  }}\n>\n  {{#each @missions as |mission|}}\n    <MissionBrowser::Item\n      @mission={{mission}}\n      @groupName={{this.groupName}}\n      @expandedMissions={{@expandedMissions}}\n      @sortable={{this.sortable}}\n      @students={{@students}}\n      @displayToggleActivationSwitch={{this.displayToggleActivationSwitch}}\n      @onEdit={{@onEdit}}\n      @onCopy={{@onCopy}}\n      @onShare={{@onShare}}\n      @onExpand={{@onExpand}}\n      @onToggleActivation={{@onToggleActivation}}\n      @onCollapse={{@onCollapse}}\n      @onSchedule={{@onSchedule}}\n      @onCancelSchedule={{@onCancelSchedule}}\n      @onHide={{@onHide}}\n      @onEditCustomContent={{@onEditCustomContent}}\n    />\n  {{/each}}\n</div>", {"contents":"<div\n  class=\"mission-browser\"\n  ...attributes\n  {{sortable-group\n    groupName=this.groupName\n    disabled=(not @sortable)\n    onChange=(optional @onSort)\n  }}\n>\n  {{#each @missions as |mission|}}\n    <MissionBrowser::Item\n      @mission={{mission}}\n      @groupName={{this.groupName}}\n      @expandedMissions={{@expandedMissions}}\n      @sortable={{this.sortable}}\n      @students={{@students}}\n      @displayToggleActivationSwitch={{this.displayToggleActivationSwitch}}\n      @onEdit={{@onEdit}}\n      @onCopy={{@onCopy}}\n      @onShare={{@onShare}}\n      @onExpand={{@onExpand}}\n      @onToggleActivation={{@onToggleActivation}}\n      @onCollapse={{@onCollapse}}\n      @onSchedule={{@onSchedule}}\n      @onCancelSchedule={{@onCancelSchedule}}\n      @onHide={{@onHide}}\n      @onEditCustomContent={{@onEditCustomContent}}\n    />\n  {{/each}}\n</div>","moduleName":"babel/components/mission-browser/index.hbs","parseOptions":{"srcName":"babel/components/mission-browser/index.hbs"}});
import Component from '@glimmer/component';
import generateId from 'compton/utils/generate-id';

/*

import CustomContentModel from 'babel/models/custom-content';
import MissionModel from 'babel/models/mission';
import UserModel from 'babel/models/user';

interface MissionBrowserSignature {
  Args: {
    missions: MissionModel[];
    expandedMissions: MissionModel[];
    students: UserModel[];
    sortable?: boolean;
    displayToggleActivationSwitch?: boolean;
    onSort?(missions: MissionModel[]): void;
    onEdit(mission: MissionModel): void;
    onCopy?(mission: MissionModel): void;
    onShare(mission: MissionModel): void;
    onExpand(mission: MissionModel): void;
    onCollapse(mission: MissionModel): void;
    onToggleActivation?(mission: MissionModel): void;
    onSchedule?(mission: MissionModel): void;
    onCancelSchedule?(mission: MissionModel): void;
    onHide(mission: MissionModel): void;
    onEditCustomContent(customContent: CustomContentModel): void;
  };
  Element: HTMLDivElement;
}

*/

export default class MissionBrowserComponent extends Component {
  groupName = generateId();

  get sortable() {
    return this.args.sortable ?? false;
  }

  get displayToggleActivationSwitch() {
    return this.args.displayToggleActivationSwitch ?? false;
  }
}
