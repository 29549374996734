import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import appendQueryParams from 'babel/utils/append-query-params';
import routeArgsForEntity from 'babel/utils/route-args-for-entity';
import { scrollIntoViewIfNeeded } from 'compton/utils/dom';
import { hasModifier } from 'compton/utils/event';
import { load } from 'ember-async-data';
import { task } from 'ember-concurrency';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from 'ember-data';
import { resolve } from 'rsvp';

const { PromiseArray } = DS;

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}

export function extractRouteArgs(args) {
  args = args.slice();
  const possibleQueryParams = args[args.length - 1];

  let queryParams;
  if (
    possibleQueryParams &&
    Object.prototype.hasOwnProperty.call(possibleQueryParams, 'queryParams')
  ) {
    queryParams = args.pop().queryParams;
  } else {
    queryParams = {};
  }

  const routeName = args.shift();
  const models = args;

  return { routeName, models, queryParams };
}

export default class EntityBrowserTree extends Component {
  /**
   * Arguments:
   * entity (object)
   * parentEntity (object)
   * siblingIndex (number)
   * selectedEntity (object)
   * expandedEntities (array)
   * disabledEntities (array)
   * deph (number)
   * selectable (null)
   * pickable (bool, false)
   * routed (null)
   * multiple (null)
   * inspectable (null)
   * pickedEntity (object)
   * pickedEntities (array)
   * pickableTypes (array)
   * onSelect (function)
   * onPick (function)
   * onUnpick (function)
   * onExpand (function)
   * onCollapse (function)
   * onClose (null?)
   * onShouldAllowPick (bool)
   */

  @service router;

  @service missionMode;

  @service contextHelper;

  @tracked showingMeta = false;

  @tracked isLoading = false;

  @tracked isSelecting = false;

  baseClass = 'entity-browser-tree-item';

  element = null;

  get classNames() {
    const classes = [this.baseClass];

    if (this.isDisabled) {
      classes.push(`${this.baseClass}--disabled`);
    }
    if (this.isExpanded) {
      classes.push(`${this.baseClass}--expanded`);
    }
    if (this.isSelectable) {
      classes.push(`${this.baseClass}--selectable`);
    }
    if (this.isSelected) {
      classes.push(`${this.baseClass}--selected`);
    }
    if (this.isSemiRouteActive) {
      classes.push(`${this.baseClass}--semi-route-active`);
    }
    if (this.isSelecting) {
      classes.push(`${this.baseClass}--selecting`);
    }
    if (this.isPickable) {
      classes.push(`${this.baseClass}--pickable`);
    }
    if (this.isPicked) {
      classes.push(`${this.baseClass}--picked`);
    }
    if (this.isLoading) {
      classes.push(`${this.baseClass}--loading`);
    }
    if (this.showingMeta) {
      classes.push(`${this.baseClass}--metashowing`);
    }
    if (this.isExpandable) {
      classes.push(`${this.baseClass}--expandable`);
    }
    if (this.args.multiple) {
      classes.push(`${this.baseClass}--multiple`);
    }

    return classes.join(' ');
  }

  get index() {
    if (this.args.entity.space !== 'content') {
      return false;
    }

    return this.args.siblingIndex;
  }

  @cached
  get orginalRouteArgsProxy() {
    const entity = this.args.entity;
    return createPromiseArray(routeArgsForEntity(entity, {}, this.missionMode));
  }

  get orginalRouteArgs() {
    if (this.orginalRouteArgsProxy.isSettled) {
      return this.orginalRouteArgsProxy.content;
    }

    return null;
  }

  get shouldRouteWithSection() {
    return ['sections', 'contents'].includes(this.args.entity.type);
  }

  get shouldRouteWithExercise() {
    const entity = this.args.entity;

    return (
      ['assignments', 'exercises', 'studies'].includes(entity.type) &&
      entity.parent.space === 'content'
    );
  }

  @cached
  get routeArgsProxy() {
    const entity = this.args.entity;

    return createPromiseArray(
      routeArgsForEntity(entity).then((routeArgsProxy) => {
        // TODO Cleanup this mess.

        const router = this.router;

        const route = router.recognize(router.urlFor(...routeArgsProxy));

        const contentRoute = route.find(
          (route) => route.name === 'master.contents'
        );

        const currentRoute = router.currentRoute;

        const { routeName, models, queryParams } =
          extractRouteArgs(routeArgsProxy);

        const currentContentRoute = currentRoute.find(
          (route) => route.name === 'master.contents'
        );

        const shouldRouteWithExercise =
          this.shouldRouteWithExercise ||
          (entity.type === 'collections' && entity.space === 'content');

        if (
          currentContentRoute &&
          contentRoute &&
          currentContentRoute.params.content_id ===
            contentRoute.params.content_id
        ) {
          if (routeName === 'master.contents') {
            return [
              currentRoute.name,
              {
                queryParams: {
                  ...queryParams,
                  exercise: shouldRouteWithExercise
                    ? queryParams.exercise
                    : null,
                },
              },
            ];
          } else if (routeName.includes('master.contents.')) {
            return [
              routeName,
              ...models,
              {
                queryParams: {
                  ...currentRoute.queryParams,
                  exercise: shouldRouteWithExercise
                    ? currentRoute.queryParams.exercise
                    : null,
                },
              },
            ];
          }
        }

        return routeArgsProxy;
      })
    );
  }

  get routeArgs() {
    return this.routeArgsProxy.isSettled ? this.routeArgsProxy.content : null;
  }

  @cached
  get productProxy() {
    const promise = resolve(this.args.entity?.product);
    return load(promise, this);
  }

  get product() {
    return this.productProxy.isFulfilled ? this.productProxy.value : null;
  }

  @cached
  get productsProxy() {
    const promise = resolve(this.args.entity?.products);
    return load(promise, this);
  }

  get products() {
    return this.productsProxy.isResolved ? this.productsProxy.value : null;
  }

  get productId() {
    if (this.contextHelper.activeProduct) {
      return this.contextHelper.activeProduct.id;
    } else if (this.contextHelper.activeCourse && this.products) {
      const overlappingProduct = this.contextHelper.activeCourse.products.find(
        (product) => this.products.some((p) => p.id === product.id)
      );

      if (overlappingProduct) {
        return overlappingProduct.id;
      }
    }

    if (this.product) {
      return this.product.id;
    }

    return this.products?.firstObject?.id;
  }

  get href() {
    if (this.isExternal && !this.isSelectable && !this.isPickable) {
      return appendQueryParams(this.args.entity.body.external_url, {
        statisticsIsbn: this.productId,
      });
    }

    if (this.isJunior && !this.isSelectable && !this.isPickable) {
      return appendQueryParams(
        `${config.juniorUrl}/books/${this.args.entity.id}`,
        {
          statisticsIsbn: this.productId,
        }
      );
    }

    if (this.routable || this.routeArgs) {
      return this.router.urlFor(...this.routeArgs);
    }

    return null;
  }

  get isSelectable() {
    if (this.isExternal || this.isJunior) return false;

    return this.args.selectable ?? true;
  }

  get isDisabled() {
    if (this.args.disabledEntities?.includes(this.args.entity)) {
      return true;
    }

    const entityType = this.args.entity.type;

    if (this.args.pickable) {
      const pickableTypes = this.args.pickableTypes;

      return (
        !this._shouldAllowPick() ||
        (pickableTypes && !pickableTypes.includes(entityType))
      );
    }

    return false;
  }

  get isSemiRouteActive() {
    const orginalRouteArgs = this.orginalRouteArgs;

    if (!orginalRouteArgs) {
      return false;
    }

    const currentRoute = this.router.currentRoute;
    const route = extractRouteArgs(orginalRouteArgs);

    if (this.args.entity.type === 'sections') {
      return (
        currentRoute.name !== 'master.contents.index' &&
        currentRoute.name.includes('master.contents.') &&
        route.queryParams.section === currentRoute.queryParams.section &&
        this.router.isActive(route.routeName, ...route.models)
      );
    }

    return false;
  }

  get routeHasExercise() {
    const routeHasExercise = true;

    return routeHasExercise;
  }

  get isRouteActive() {
    const type = this.args.entity.type;

    if (['collections', 'books_external', 'books_junior'].includes(type)) {
      return false;
    }

    const orginalRouteArgs = this.orginalRouteArgs;

    if (!orginalRouteArgs) {
      return false;
    }

    const currentRoute = this.router.currentRoute;

    if (
      type === 'archives' &&
      currentRoute.name.split('.').includes('folders')
    ) {
      return false;
    }

    const { routeName, models, queryParams } =
      extractRouteArgs(orginalRouteArgs);

    const isContent =
      currentRoute.name === 'master.contents' ||
      currentRoute.name === 'master.contents.index';

    let withSection = true;

    if (this.shouldRouteWithSection) {
      withSection =
        isContent && queryParams.section === currentRoute.queryParams.section;
    }

    let withExercise = true;

    if (this.shouldRouteWithExercise) {
      withExercise =
        isContent && queryParams.exercise === currentRoute.queryParams.exercise;
    }

    return (
      this.router.isActive(routeName, ...models) && withSection && withExercise
    );
  }

  get isSelected() {
    if (!this.isSelectable) {
      return false;
    }

    if (this.args.routed) {
      return this.isRouteActive;
    }

    return this.args.selectedEntity === this.args.entity;
  }

  get isPickable() {
    if (this.args.pickable && this._shouldAllowPick()) {
      const pickableTypes = this.args.pickableTypes;

      if (pickableTypes) {
        return pickableTypes.includes(this.args.entity.type);
      }

      return true;
    }

    return false;
  }

  get typeIsPickable() {
    return this.args.pickableTypes.includes(this.args.entity.type);
  }

  get isPicked() {
    const entity = this.args.entity;

    if (this.args.multiple) {
      return this.args.pickedEntities.includes(entity);
    }

    return this.args.pickedEntity === entity;
  }

  get isExternal() {
    return this.args.entity.type === 'books_external';
  }

  get isJunior() {
    return this.args.entity.type === 'books_junior';
  }

  get isExpanded() {
    return this.args.expandedEntities.includes(this.args.entity);
  }

  get isExpandable() {
    if (this.isExternal || this.isJunior) return false;

    const template = this.args.entity.template;
    const childrenSum = this.args.entity.childrenSum;

    if (['quiz'].includes(template)) {
      return false;
    }

    return childrenSum === 0 ? false : true;
  }

  // ACTIONS

  @action
  handlePickCheckboxChange() {
    this._pickOrUnPick();
  }

  @action
  clickEntity(evt) {
    if (
      (!this.isExternal && !this.isJunior && !hasModifier(evt)) ||
      this.isPickable
    ) {
      if (evt.target?.tagName !== 'INPUT') {
        evt.preventDefault();
      }

      if (this.isDisabled) {
        this._toggle();
      } else if (this.isSelectable) {
        this._select();
      } else if (this.isPickable) {
        this._pickOrUnPick();
      }
    }
  }

  @action
  handleToggleButtonClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this._toggle();
  }

  @action
  handleToggleMetaButtonClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @action
  handleOpenEntityInspector() {
    this.args.onClose(this.args.entity);
  }

  @action
  openMeta() {
    this.showingMeta = true;
  }

  @action
  closeMeta() {
    this.showingMeta = false;
  }

  // OBSERVERS

  @action
  didInsert(element, isSettled) {
    if (this.isSelected || this.isSemiRouteActive) {
      scrollIntoViewIfNeeded(element);
    }
  }

  _shouldAllowPick() {
    const entity = this.args.entity;

    const parent = entity.parent;

    return this.args.onShouldAllowPick({
      type: entity.type,
      template: entity.template,
      parentType: parent.get('type'),
      parentTemplate: parent.get('template'),
    });
  }

  _select() {
    if (!this.args.selectable) {
      return;
    }

    let promise;

    if (this.args.routed && this.routeArgs) {
      promise = this.router.transitionTo(...this.routeArgs);
    } else {
      promise = this.onSelect(this.args.entity);
    }

    return this.select.perform(promise);
  }

  _pickOrUnPick() {
    if (this.args.multiple) {
      this.isPicked ? this._unpick() : this._pick();
    } else {
      this._pick();
    }
  }

  _pick() {
    if (this.isPickable) {
      this.args.onPick(this.args.entity);
    }
  }

  _unpick() {
    if (this.isPickable) {
      this.args.onUnpick(this.args.entity);
    }
  }

  _toggle() {
    if (this.isExpandable) {
      this.isExpanded ? this._collapse() : this._expand();
    }
  }

  _expand() {
    this.load.perform(this.args.onExpand(this.args.entity));
  }

  _collapse() {
    this.args.onCollapse(this.args.entity);
  }

  // TASKS

  @task
  *select(promise) {
    this.isSelecting = true;
    yield promise;
    this.isSelecting = false;
  }

  @task
  *load(promise) {
    this.isLoading = true;
    yield promise;
    this.isLoading = false;
  }
}
