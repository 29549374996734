import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<CourseAudit::TeacherAssignments::TeacherCollection\n  @selectedStudents={{@selectedStudents}}\n  @collection={{this.collection}}\n  @openAuditMode={{@openAuditMode}}\n/>\n", {"contents":"<CourseAudit::TeacherAssignments::TeacherCollection\n  @selectedStudents={{@selectedStudents}}\n  @collection={{this.collection}}\n  @openAuditMode={{@openAuditMode}}\n/>\n","moduleName":"babel/components/course-audit/teacher-assignments/teacher/index.hbs","parseOptions":{"srcName":"babel/components/course-audit/teacher-assignments/teacher/index.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { all, resolve } from 'rsvp';

export default class CourseAuditTeacherAssignmentsTeacherComponent extends Component {
  /**
   * Arguments:
   * teacher (object)
   * teacherAssignments (array)
   * selectedStudents (array)
   * openAuditMode (function)
   */

  @cached get teacherProxy() {
    return load(resolve(this.args.teacher), this);
  }

  get teacher() {
    return this.teacherProxy.isResolved ? this.teacherProxy.value : null;
  }

  @cached get assignmentsProxy() {
    const teacherId = this.teacher?.id;
    const teacherAssignments = this.args.teacherAssignments;

    let promise = resolve();

    if (teacherId && teacherAssignments) {
      promise = all(
        teacherAssignments.map((teacherAssignment) =>
          all([teacherAssignment.user, teacherAssignment.contents]).then(
            ([user, contents]) =>
              user?.id === teacherId
                ? teacherAssignment.createAsEntity(contents?.firstObject)
                : null
          )
        )
      ).then((assignments) => assignments.filter((x) => x));
    }

    return load(promise, this);
  }

  get assignments() {
    return this.assignmentsProxy.isResolved
      ? this.assignmentsProxy.value
      : null;
  }

  get collection() {
    return {
      title: this.teacher?.showname,
      isTeacherCollection: true,
      children: this.assignments,
      owner: this.teacher,
    };
  }
}
