import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiButton\n  class=\"mission-browser-item-toggle-button\"\n  data-expanded={{this.expanded}}\n  data-expanding={{this.expanding}}\n  @appearance=\"none\"\n  @onClick={{@onClick}}\n  ...attributes\n>\n  <DcIcon\n    class=\"mission-browser-item-toggle-button-icon\"\n    @name=\"small-down\"\n    @size=\"small\"\n  />\n</UiButton>", {"contents":"<UiButton\n  class=\"mission-browser-item-toggle-button\"\n  data-expanded={{this.expanded}}\n  data-expanding={{this.expanding}}\n  @appearance=\"none\"\n  @onClick={{@onClick}}\n  ...attributes\n>\n  <DcIcon\n    class=\"mission-browser-item-toggle-button-icon\"\n    @name=\"small-down\"\n    @size=\"small\"\n  />\n</UiButton>","moduleName":"babel/components/mission-browser/item/toggle-button/index.hbs","parseOptions":{"srcName":"babel/components/mission-browser/item/toggle-button/index.hbs"}});
import Component from '@glimmer/component';

export default class MissionBrowserItemToggleButtonComponent extends Component {
  get expanded() {
    return this.args.expanded ?? false;
  }

  get expanding() {
    return this.args.expanding ?? false;
  }
}
