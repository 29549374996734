import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{this.draggingClassName}}\n  ...attributes\n  {{did-insert this.setElement}}\n>\n  {{#each this.items as |item index|}}\n    <ExerciseView::Assignment::Match::Item\n      @withinContent={{@withinContent}}\n      @showKey={{@showKey}}\n      @showValidation={{@showValidation}}\n      @alternatives={{@assignment.content.alternatives}}\n      @assignmentAnswer={{this.input}}\n      @scope={{this.scope}}\n      @index={{index}}\n      @disabled={{@disabled}}\n      @onStart={{this.handleDragStart}}\n      @onEnd={{this.handleDragEnd}}\n      @onDrop={{this.onDroppableDrop}}\n      @droppableContent={{item.droppableContent}}\n      @droppableImage={{item.droppableImage}}\n      @draggableContent={{item.draggableContent}}\n      @incomplete={{@incomplete}}\n    />\n  {{/each}}\n</div>", {"contents":"<div\n  class={{this.draggingClassName}}\n  ...attributes\n  {{did-insert this.setElement}}\n>\n  {{#each this.items as |item index|}}\n    <ExerciseView::Assignment::Match::Item\n      @withinContent={{@withinContent}}\n      @showKey={{@showKey}}\n      @showValidation={{@showValidation}}\n      @alternatives={{@assignment.content.alternatives}}\n      @assignmentAnswer={{this.input}}\n      @scope={{this.scope}}\n      @index={{index}}\n      @disabled={{@disabled}}\n      @onStart={{this.handleDragStart}}\n      @onEnd={{this.handleDragEnd}}\n      @onDrop={{this.onDroppableDrop}}\n      @droppableContent={{item.droppableContent}}\n      @droppableImage={{item.droppableImage}}\n      @draggableContent={{item.draggableContent}}\n      @incomplete={{@incomplete}}\n    />\n  {{/each}}\n</div>","moduleName":"babel/components/exercise-view/assignment/match/index.hbs","parseOptions":{"srcName":"babel/components/exercise-view/assignment/match/index.hbs"}});
import { action } from '@ember/object';
import { cached } from '@glimmer/tracking';
import DragAssignmentComponent from 'babel/components/exercise-view/common/drag-assignment/index';
import generateId from 'compton/utils/generate-id';

export default class ExerciseViewAssignmentMatchComponent extends DragAssignmentComponent {
  /**
   * Arguments:
   * assignment (object)
   * canHaveAttachments (bool)
   * assignmentAnswer (object)
   * answer (object)
   * handleOnChange (function)
   * showValidation (bool)
   * showKey (bool)
   * disabled (bool)
   * showSuggestion (bool)
   * incomplete (bool)
   * image (object)
   */

  get input() {
    return this.args.assignmentAnswer?.input;
  }

  get hasValueImage() {
    return this.args.assignment?.settings?.value_image;
  }

  @cached get items() {
    const hasValueImage = this.hasValueImage;
    const alternatives = this.args.assignment?.content?.alternatives;
    const input = this.input;
    const showKey = this.args.showKey;

    return alternatives?.map((alternative, index) => {
      const key = input ? input.objectAt(index) : null;

      let keyValue;

      if (showKey || !key) {
        keyValue = alternative.key;
      } else {
        keyValue = alternatives.findBy('uuid', key.uuid).key;
      }

      return {
        droppableContent: alternative.value,
        droppableImage: hasValueImage && alternative.value_image,
        draggableContent: keyValue,
      };
    });
  }

  get scope() {
    return generateId('match-scope');
  }

  @action onDroppableDrop(draggableData, droppableData) {
    const input = this.input;
    const key = input?.objectAt(draggableData.index);
    const oldKey = input?.objectAt(droppableData.index);

    if (key) {
      key.assigned = true;
    }

    if (key && oldKey && key.uuid !== oldKey.uuid) {
      oldKey.assigned = false;

      input.replace(draggableData.index, 1, [oldKey]);
      input.replace(droppableData.index, 1, [key]);
    }

    if (this.args.assignmentAnswer) {
      this.args.assignmentAnswer.input = input;
    }

    this.args.handleOnChange?.(input);
  }
}
