import moment from 'moment';

export default function licenseInfo(license, intl, showErrors = false) {
  const result = { text: '', class: false, extended: false };

  let isError = false;

  if (license.get('isbn') && license.get('isbn').startsWith('EX')) {
    if (!license.license_id || license.is_expired) {
      result.text = intl.t('utils.licenseInfo.missingLicense');
      isError = true;
    } else if (!license.active_until) {
      result.class = 'text-color-success';
      result.text = intl.t('utils.licenseInfo.ready');
    } else {
      result.class = 'text-color-success';
      result.text = intl.t('utils.licenseInfo.started');
    }
  } else if (!license.license_id) {
    result.text = intl.t('utils.licenseInfo.missingLicense');
    isError = true;
  } else if (license.is_external) {
    if (license.is_expired) {
      result.class = 'text-color-error';
      result.text = intl.t('utils.licenseInfo.externalExpired', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    } else {
      result.class = moment(license.active_until).isBefore(
        moment().add(30, 'days')
      )
        ? 'text-color-error'
        : 'text-color-success';
      result.text = intl.t('utils.licenseInfo.externalExpires', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    }
  } else if (license.is_demo) {
    if (license.is_expired) {
      if (
        moment(license.active_until).isBefore(moment().subtract(365, 'days'))
      ) {
        result.text = intl.t('utils.licenseInfo.missingLicense');
      } else {
        result.text = intl.t('utils.licenseInfo.freeUsed');
      }

      result.class = 'text-color-error';
    } else {
      result.class = license.extended
        ? 'text-color-success'
        : 'text-color-error';
      result.text = intl.t('utils.licenseInfo.freeExpires', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
      result.extended = license.extended;
    }
  } else if (!license.active_until) {
    result.text = intl.t('utils.licenseInfo.ready');
  } else if (license.is_expired) {
    result.class = 'text-color-error';

    if (license.is_private) {
      result.text = intl.t('utils.licenseInfo.personalExpired', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    } else {
      result.class = 'text-color-error';
      result.text = intl.t('utils.licenseInfo.expired', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    }
  } else if (moment(license.active_until).isBefore(moment().add(30, 'days'))) {
    result.class = license.extended ? 'text-color-success' : 'text-color-error';

    if (license.is_private) {
      result.text = intl.t('utils.licenseInfo.personalExpires', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    } else {
      result.text = intl.t('utils.licenseInfo.expires', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    }

    if (license.extended) result.extended = true;
  } else {
    result.class = 'text-color-success';

    if (license.is_private) {
      result.text = intl.t('utils.licenseInfo.personalValidThru', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    } else {
      result.text = intl.t('utils.licenseInfo.validThru', {
        date: moment(license.active_until).format('YYYY-MM-DD'),
      });
    }

    if (license.extended) result.extended = true;
  }

  if (showErrors && isError) {
    result.class = 'text-color-error';
  }

  return result;
}
