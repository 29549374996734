import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export const DEFAULT_QUERY_PARAMS = Object.freeze({
  page: 1,
  sortBy: 'created',
  sortDirection: 'desc',
  scope: 'all',
});

const SORT_MENU_BUTTON_ITEMS = Object.freeze([
  {
    key: 'createdDesc',
    value: {
      by: 'created',
      direction: 'desc',
    },
  },
  {
    key: 'createdAsc',
    value: {
      by: 'created',
      direction: 'asc',
    },
  },
  {
    key: 'titleAsc',
    value: {
      by: 'title',
      direction: 'asc',
    },
  },
  {
    key: 'titleDesc',
    value: {
      by: 'title',
      direction: 'desc',
    },
  },
]);

const SCOPE_MENU_BUTTON_ITEMS = Object.freeze([
  {
    key: 'all',
    value: 'all',
  },
  {
    key: 'visible',
    value: 'visible',
  },
  {
    key: 'upcoming',
    value: 'upcoming',
  },
  {
    key: 'ended',
    value: 'ended',
  },
]);

export default class MasterCoursesShowMissionsController extends Controller {
  @service router;
  @service store;
  @service intl;
  @service session;
  @service missionDispatcher;
  @service customContentDispatcher;
  @service fileDispatcher;

  queryParams = Object.keys(DEFAULT_QUERY_PARAMS);

  @tracked page = DEFAULT_QUERY_PARAMS.page;
  @tracked sortBy = DEFAULT_QUERY_PARAMS.sortBy;
  @tracked sortDirection = DEFAULT_QUERY_PARAMS.sortDirection;
  @tracked scope = DEFAULT_QUERY_PARAMS.scope;
  @tracked expandedMissions = [];
  @tracked mission = null;
  @tracked customContent = null;
  @tracked pendingFile = null;
  @tracked isMissionAddActionMenuOpen = false;
  @tracked isCourseMissionCopyFromModalOpen = false;
  @tracked courseMissionCopyToModalMeta = null;
  @tracked missionShareModalMeta = null;

  get isMissionDetailModalOpen() {
    return !!this.mission;
  }

  get isCourseMissionCopyToModalOpen() {
    return !!this.courseMissionCopyToModalMeta;
  }

  get isMissionShareModalOpen() {
    return !!this.missionShareModalMeta;
  }

  get isCustomContentDetailModalOpen() {
    return !!this.customContent;
  }

  get sortMenuButtonItems() {
    return SORT_MENU_BUTTON_ITEMS.map(({ key, value }) => {
      return {
        label: this.intl.t(
          `controllers.master.courses.show.sortMenuButtonItems.${key}`
        ),
        value,
      };
    });
  }

  get sortMenuButtonLabel() {
    const items = SORT_MENU_BUTTON_ITEMS;

    const item =
      items.find(
        ({ value }) =>
          value.by === this.sortBy && value.direction === this.sortDirection
      ) ?? items.at(0);

    return this.intl.t(
      `controllers.master.courses.show.sortMenuButtonItems.${item.key}`
    );
  }

  get scopeMenuButtonItems() {
    return SCOPE_MENU_BUTTON_ITEMS.map(({ key, value }) => {
      return {
        label: this.intl.t(
          `controllers.master.courses.show.scopeMenuButtonItems.${key}`
        ),
        value,
      };
    });
  }

  get scopeMenuButtonLabel() {
    const items = SCOPE_MENU_BUTTON_ITEMS;

    const key = items.find(({ value }) => value === this.scope) ?? items.at(0);

    return this.intl.t(
      `controllers.master.courses.show.scopeMenuButtonItems.${key.value}`
    );
  }

  resetQueryParams() {
    Object.entries(DEFAULT_QUERY_PARAMS).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  @action
  handleSortMenuButtonChange({ by, direction }) {
    this.sortBy = by;
    this.sortDirection = direction;
    this.page = DEFAULT_QUERY_PARAMS.page;
  }

  @action
  handleScopeMenuButtonChange(scope) {
    this.scope = scope;
    this.page = DEFAULT_QUERY_PARAMS.page;
  }

  @action
  handleMissionActionMenuSelect(value) {
    switch (value) {
      case 'new':
        this.mission = this.model.course.newMission({
          hideSolutions: !this.model.setting.showKey,
        });
        this.expandedMissions.addObject(this.mission);
        break;
      case 'copy':
        this.isCourseMissionCopyFromModalOpen = true;
        break;
      default:
        break;
    }
  }

  @action
  handleMissionDetailModalClose() {
    this.mission.rollback();
    this.mission = null;
  }

  @action
  handleMissionBrowserEdit(mission) {
    this.mission = mission;
  }

  @action
  handleMissionBrowserCopy(mission) {
    this.courseMissionCopyToModalMeta = {
      mission,
    };
  }

  @action
  handleMissionBrowserShare(mission) {
    this.missionShareModalMeta = {
      mission,
    };
  }

  @action
  handleMissionCopyToModalClose() {
    this.courseMissionCopyToModalMeta = null;
  }

  @action
  handleMissionShareModalClose() {
    this.missionShareModalMeta = null;
  }

  @action
  async handleMissionDetailModalSave() {
    await this.missionDispatcher.save(this.mission);
    await this.#updateOrReplace();
    this.mission = null;
    this.page = DEFAULT_QUERY_PARAMS.page;
  }

  @action
  async handleMissionDetailModalDelete() {
    await this.missionDispatcher.delete(this.mission);
    await this.#updateOrReplace();
    this.mission = null;
  }

  @action
  async handleMissionBrowserExpand(mission) {
    this.expandedMissions.addObject(mission);
  }

  @action
  handleMissionBrowserCollapse(mission) {
    this.expandedMissions.removeObject(mission);
  }

  @action
  async handleMissionBrowserToggleActivation(mission) {
    await this.missionDispatcher.toggleActivation(mission);
    await this.#updateOrReplace();
  }

  @action
  handleMissionBrowserEditCustomContent(customContent) {
    this.customContent = customContent;
  }

  @action
  async handleCustomContentDetailModalSave(customContent) {
    await this.customContentDispatcher.save(customContent);
    this.customContent = null;
  }

  @action
  async handleMissionCopyFromModalCopy(missions) {
    await this.missionDispatcher.copyManyToCourse(missions, this.model.course);
    await this.#updateOrReplace();
    this.isCourseMissionCopyFromModalOpen = false;
  }

  @action
  async handleMissionCopyToModalCopy(mission, courses) {
    await this.missionDispatcher.copyToManyCourses(mission, courses);
    this.courseMissionCopyToModalMeta = null;
  }

  @action
  handleCustomContentDetailModalClose() {
    this.customContent.rollback();
    this.customContent = null;
  }

  @action
  async handleMissionBrowserSchedule(mission, fields) {
    await this.missionDispatcher.schedule(mission, fields);
    await this.#updateOrReplace();
  }

  @action
  async handleMissionBrowserCancelSchedule(mission) {
    await this.missionDispatcher.cancelSchedule(mission);
    await this.#updateOrReplace();
  }

  @action
  async handleMissionDetailModalFileUpload(fileData) {
    this.pendingFile = this.store.createRecord('file', {
      name: fileData.name,
      user: this.session.user,
    });

    try {
      await this.fileDispatcher.performUpload(this.pendingFile, fileData);
      return this.pendingFile;
    } finally {
      this.pendingFile = null;
    }
  }

  @action
  handleFileUploadAbort() {
    this.fileDispatcher.abortUpload(this.pendingFile);
    this.pendingFile = null;
  }

  async #updateOrReplace() {
    if (this.page === DEFAULT_QUERY_PARAMS.page) {
      return this.model.missions.update();
    } else {
      return this.router.replaceWith({
        queryParams: { page: DEFAULT_QUERY_PARAMS.page },
      });
    }
  }
}
