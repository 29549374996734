import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { storageFor } from 'ember-local-storage';
import { resolve } from 'rsvp';
import { WebPageTranslationDetector } from 'speaker';

export default class SpeakerProviderIconComponent extends Component {
  /**
   * Arguments:
   * content (object)
   */

  @service speakerSession;

  @service speaker;

  @storageFor('play-settings') speakerSettings;

  @tracked pageIsTranslated = false;

  @tracked translationLanguage = null;

  observer;

  constructor() {
    super(...arguments);

    const htmlTag = WebPageTranslationDetector.getHTMLElement();

    if (htmlTag) {
      this.observer = new MutationObserver(this._setPageTranslationInfo);
      this.observer.observe(htmlTag, { attributeFilter: ['class', 'lang'] });

      this._setPageTranslationInfo();
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.observer.disconnect();
    this.observer = null;
  }

  @cached get contentProxy() {
    return load(resolve(this.args.content), this);
  }

  get content() {
    return this.contentProxy.isResolved ? this.contentProxy.value : null;
  }

  @cached get bookProxy() {
    return load(resolve(this.content?.book), this);
  }

  get book() {
    return this.bookProxy.isResolved ? this.bookProxy.value : null;
  }

  get provider() {
    // Only need to check recorded speech providers if user has not checked
    // "always speech synthesis" and the page isn't translated.
    if (
      !this.pageIsTranslated &&
      !this.speakerSettings.get('preferSpeechSynthesis')
    ) {
      // storage for requires get :(
      if (this.content?.body?.recorded_speech_activated) {
        return 'digilar';
      } else if (
        this.book?.body?.ilt_activated &&
        this.speakerSession?.iltEnabled
      ) {
        return 'ilt';
      }
    }

    // Default to synthesis
    return 'synthesis';
  }

  get disabled() {
    if (this.pageIsTranslated && this.translationLanguage) {
      const supportedVoices =
        this.speaker.digilarSpeakerConfig.supportedVoices();

      if (!Object.keys(supportedVoices).includes(this.translationLanguage)) {
        return true;
      }
    }

    return false;
  }

  @action _setPageTranslationInfo() {
    this.pageIsTranslated = WebPageTranslationDetector.isTranslated();
    this.translationLanguage = WebPageTranslationDetector.detectLanguage();
  }
}
