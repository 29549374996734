import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class DiagnosisIntroComponent extends Component {
  /**
   * Arguments:
   * results (array)
   * interactive (object)
   * collection (object)
   * isTeacher (boolean)
   * scoreMax (number)
   */

  @service contextHelper;

  @tracked resultsOpen = false;

  get latestResult() {
    return this.args.results?.firstObject;
  }

  get allowedTime() {
    return this.args.collection?.timeAllowed;
  }

  get allowedAttempts() {
    return this.args.collection?.allowedAttempts;
  }

  get pointsRequired() {
    return this.args.collection.pointsRequired;
  }

  get attemptsLeft() {
    let attemptsLeft = this.allowedAttempts - (this.latestResult?.attempt || 0);

    if (attemptsLeft < 0) {
      attemptsLeft = 0;
    }

    return attemptsLeft;
  }

  get attemptsDone() {
    let attemptsDone = this.latestResult?.attempt + 1 || 1;

    if (attemptsDone > this.allowedAttempts && !this.args.isTeacher) {
      attemptsDone = this.allowedAttempts;
    }

    return attemptsDone;
  }

  get scoreMax() {
    return this.args.scoreMax;
  }

  get disabledInCourse() {
    if (this.contextHelper.inMission) {
      return false;
    }
    return this.contextHelper.activeCourse?.showDiagnosis === false;
  }

  get noAttemptsLeft() {
    return this.attemptsLeft <= 0 || this.scoreMax === 0;
  }

  get startDisabled() {
    if (this.args.isTeacher) {
      return false;
    }

    if (this.disabledInCourse) {
      return true;
    }

    if (this.latestResult && this.latestResult.isActive) {
      return false;
    }

    return this.noAttemptsLeft;
  }

  @action
  openResults() {
    this.resultsOpen = true;
  }

  @action
  closeResults() {
    this.resultsOpen = false;
  }

  @action
  handleStartButtonClick() {
    this.args.onStartButtonClick?.(this.latestResult, this.allowedTime);
  }
}
