import config from 'babel/config/environment';

const TRANSITION_TO_PATH = {
  'master.index': () => '/login',
  'master.admin.books': () => '/admin/books',
  'master.admin.teachers.list': () => '/admin/teachers',
  'master.admin.students.list': () => '/admin/students',
  'master.admin.courses': () => '/admin/courses',
  'master.profile.settings': () => '/profile/settings',
  'master.courses.new': (to) =>
    to?.queryParams?.productId
      ? `/courses/new?productId=${to.queryParams.productId}`
      : '/courses/new',
  'master.course-settings': (to) => `/course-settings/${to.params.course_id}`,
  activate: (to) => `/activate/${to.params.link_hash}`,
};

export function redirectToNokportal(transition) {
  if (config.nokPortalURL && config.nokPortalURL !== 'null') {
    window.location = `${config.nokPortalURL}${getPath(
      transition.targetName,
      transition.to
    )}`;
  }
}

export function getPath(targetName, to) {
  return TRANSITION_TO_PATH[targetName]
    ? TRANSITION_TO_PATH[targetName](to)
    : '';
}
