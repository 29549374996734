import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import TbRenderBlockComponent from 'babel/components/tb-render/block-component/component';
import { previousObject } from 'compton/utils/array';

import { numberToBase26 } from '../../../utils/number-to-base-26';

function previousObjectBy(arr, obj, key, value) {
  let previousObj = previousObject(arr, obj);

  while (previousObj && previousObj[key] !== value) {
    previousObj = previousObject(arr, previousObj);
  }

  return previousObj;
}

export default class TbRenderListComponent extends TbRenderBlockComponent {
  /**
   * Arguments:
   * part (object)
   * start (number)
   * itemGroups (array)
   */

  baseClass = 'tb-render-list';

  numberToBase26(number) {
    // Split and join the characters with a dash to get the speaker to read them
    // as letters instead of sentences.
    return numberToBase26(number).split('').join('-');
  }

  get itemGroups() {
    if (this.args.itemGroups) return this.args.itemGroups;

    const items = this.args.part?.value || [];

    const itemGroups = items.map((item) => {
      return {
        item,
        itemGroups: [],
        level: item.level,
      };
    });

    const nestedItemGroups = [];

    itemGroups.forEach((itemGroup) => {
      const parentItemGroup = previousObjectBy(
        itemGroups,
        itemGroup,
        'level',
        itemGroup.level - 1
      );

      if (parentItemGroup) {
        parentItemGroup.itemGroups.addObject(itemGroup);
        nestedItemGroups.addObject(itemGroup);
      }
    });

    itemGroups.removeObjects(nestedItemGroups);

    return itemGroups;
  }

  get style() {
    return this.args.part?.settings?.style || '';
  }

  get extraClasses() {
    return [[this.baseClass, dasherize(this.style)].join('--')];
  }

  get start() {
    return this.args.start ?? this.args.part?.settings?.start;
  }

  get cssStyle() {
    return htmlSafe(`--start: ${this.start};`);
  }
}
