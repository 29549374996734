import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ForbiddenError from '@ember-data/adapter/error';
import { tracked } from '@glimmer/tracking';
import config from 'babel/config/environment';
import getUserLicenseForEntity from 'babel/utils/get-user-license-for-entity';
import routeArgsForEntity from 'babel/utils/route-args-for-entity';
import { all, reject, resolve } from 'rsvp';

export default class NodesRoute extends Route {
  queryParams = {
    content: {
      refreshModel: true,
    },
    transition: {
      refreshModel: true,
    },
  };

  @service missionMode;

  @service router;

  @service session;

  @service ajax;

  @service store;

  @tracked nodeId;

  model({ node_id, content }) {
    this.nodeId = node_id;

    if (this.session.isAuthenticated) {
      return this.store.findRecord('entity', node_id).catch((err) => {
        if (content) {
          return all([
            this.store.findRecord('entity', content),
            this.store.findRecord('teacher-assignment', node_id),
          ]).then(([content, assignment]) =>
            resolve(assignment.contents).then(() =>
              assignment.createAsEntity(content)
            )
          );
        }

        return reject(err);
      });
    } else {
      return this.ajax
        .request(
          `${config.endpoint}/api/v1/content/entities/${node_id}/preview`,
          false,
          {
            type: 'GET',
          }
        )
        .catch(() => {});
    }
  }

  async afterModel(model, transition) {
    if (!model) {
      this.router.transitionTo('404');
    } else if (this.session.isAuthenticated) {
      if (this.session.inPreviewMode) {
        if (['assignments', 'exercises', 'studies'].includes(model.type)) {
          // When previewing an exercise we should always view it as though
          // the parent had template='standard'.
          const collection = await model.parent;

          if (
            collection.type === 'collections' &&
            collection.template !== 'standard'
          ) {
            collection.template = 'standard';
          }
        }
      }

      const {
        transition: transitionTo,
        todoId,
        customContentId,
        ...queryParams
      } = transition.to.queryParams;

      if (todoId) {
        queryParams.mission = todoId;
      }
      if (customContentId) {
        queryParams.custom_content = customContentId;
      }

      return routeArgsForEntity(model, queryParams, this.missionMode).then(
        (routeArgs) => {
          if (transitionTo) {
            return this.router.transitionTo(...routeArgs);
          } else {
            return this.router.replaceWith(...routeArgs);
          }
        }
      );
    }
  }

  @action
  error(err, transition) {
    if (err instanceof ForbiddenError) {
      return getUserLicenseForEntity(
        this.nodeId,
        this.session,
        this.ajax,
        config.endpoint,
        config.userApiEndpoint,
        transition
      ).catch((err) => {
        if (err && err.name === 'TransitionAborted') {
          return false;
        }

        this.router.transitionTo('master.forbidden');
      });
    }

    return true;
  }
}
